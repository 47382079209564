import {Database} from '@nilfoundation/dbmsjs';
import useSWR from 'swr';
import {memoize} from 'lodash';

declare var frontendConfig: { [key: string]: any };
declare var dbmsHelper: { [key: string]: any };

const env = process.env.NODE_ENV;
let url: string;
if (env === 'development') {
    url = process.env.REACT_APP_DBMS_HOST as string;
} else {
    url = window.location.origin;
}

const getCurrentDb = () => localStorage.getItem('db') || frontendConfig.db;

export const getBaseUrl = () => `${url}/_db/${getCurrentDb()}/_api/`;

export const getAdminUrl = () => `${url}/_db/${getCurrentDb()}/_admin/`;

export const getDB = memoize((db: string) => new Database({
    url,
    databaseName: db,
    auth: {
        token: dbmsHelper.getCurrentJwt(),
    },
    agentOptions: {
        withCredentials: false,
    },
}));

export const getDefaultDb = () => getDB(getCurrentDb());

export const getRouteForDB = memoize((db: string, route: string) => getDB(db).route(route),
    (db: string, route: string) => `${db}/${route}`);

export const getApiRouteForCurrentDB = () => getRouteForDB(frontendConfig.db, '_api');

type ApiMethod = 'get' | 'put' | 'post' | 'delete';

export const useAPIFetch = (path: string | null, method: ApiMethod = 'get', body?: any) => useSWR(path, () => {
    const route = getApiRouteForCurrentDB();

    return route[method](path as string, body);
});
