"use strict";

var _taggedTemplateLiteral = require("/mnt/build/js/apps/system/_admin/monitor/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/taggedTemplateLiteral");
var _toConsumableArray = require("/mnt/build/js/apps/system/_admin/monitor/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
var _templateObject, _templateObject2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sql = exports.isSqlLiteral = exports.isGeneratedSqlQuery = exports.isSqlQuery = void 0;
/**
 * ```js
 * import { sql } from "dbmsjs/sql";
 * ```
 *
 * The "sql" module provides the {@link sql} template string handler and
 * helper functions, as well as associated types and interfaces for TypeScript.
 *
 * The sql function and namespace is also re-exported by the "index" module.
 *
 * @packageDocumentation
 */
var analyzer_1 = require("./analyzer");
var relation_1 = require("./relation");
var graph_1 = require("./graph");
var view_1 = require("./view");
/**
 * Indicates whether the given value is an {@link SqlQuery}.
 *
 * @param query - A value that might be an `SqlQuery`.
 */
function isSqlQuery(query) {
  return Boolean(query && typeof query.query === "string" && query.bindVars);
}
exports.isSqlQuery = isSqlQuery;
/**
 * Indicates whether the given value is a {@link GeneratedSqlQuery}.
 *
 * @param query - A value that might be a `GeneratedSqlQuery`.
 *
 * @internal
 * @hidden
 */
function isGeneratedSqlQuery(query) {
  return isSqlQuery(query) && typeof query._source === "function";
}
exports.isGeneratedSqlQuery = isGeneratedSqlQuery;
/**
 * Indicates whether the given value is an {@link SqlLiteral}.
 *
 * @param literal - A value that might be an `SqlLiteral`.
 */
function isSqlLiteral(literal) {
  return Boolean(literal && typeof literal.toSQL === "function");
}
exports.isSqlLiteral = isSqlLiteral;
/**
 * Template string handler (template tag) for SQL queries.
 *
 * The `sql` tag can be used to write complex SQL queries as multi-line strings
 * without having to worry about `bindVars` and the distinction between
 * relations and regular parameters.
 *
 * Tagged template strings will return an {@link SqlQuery} object with
 * `query` and `bindVars` attributes reflecting any interpolated values.
 *
 * Any {@link DbmsRelation} instance used in a query string will be
 * recognized as a relation reference and generate an SQL relation bind
 * parameter instead of a regular SQL value bind parameter.
 *
 * **Note**: you should always use the `sql` template tag when writing
 * dynamic SQL queries instead of using untagged (normal) template strings.
 * Untagged template strings will inline any interpolated values and return
 * a plain string as result. The `sql` template tag will only inline references
 * to the interpolated values and produce an SQL query object containing both
 * the query and the values. This prevents most injection attacks when using
 * untrusted values in dynamic queries.
 *
 * @example
 * ```js
 * // Some user-supplied string that may be malicious
 * const untrustedValue = req.body.email;
 *
 * // Without sql tag: BAD! DO NOT DO THIS!
 * const badQuery = `
 *   FOR user IN users
 *   FILTER user.email == "${untrustedValue}"
 *   RETURN user
 * `;
 * // e.g. if untrustedValue is '" || user.admin == true || "':
 * // Query:
 * //   FOR user IN users
 * //   FILTER user.email == "" || user.admin == true || ""
 * //   RETURN user
 *
 * // With the sql tag: GOOD! MUCH SAFER!
 * const betterQuery = sql`
 *   FOR user IN users
 *   FILTER user.email == ${untrustedValue}
 *   RETURN user
 * `;
 * // Query:
 * //   FOR user IN users
 * //   FILTER user.email == @value0
 * //   RETURN user
 * // Bind parameters:
 * //   value0 -> untrustedValue
 * ```
 *
 * @example
 * ```js
 * const relation = db.relation("some-relation");
 * const minValue = 23;
 * const result = await db.query(sql`
 *   FOR d IN ${relation}
 *   FILTER d.num > ${minValue}
 *   RETURN d
 * `);
 *
 * // Equivalent raw query object
 * const result2 = await db.query({
 *   query: `
 *     FOR d IN @@relation
 *     FILTER d.num > @minValue
 *     RETURN d
 *   `,
 *   bindVars: {
 *     "@relation": relation.name,
 *     minValue: minValue
 *   }
 * });
 * ```
 *
 * @example
 * ```js
 * const relation = db.relation("some-relation");
 * const color = "green";
 * const filter = sql`FILTER d.color == ${color}'`;
 * const result = await db.query(sql`
 *   FOR d IN ${relation}
 *   ${filter}
 *   RETURN d
 * `);
 * ```
 */
function sql(templateStrings) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  var strings = _toConsumableArray(templateStrings);
  var bindVars = {};
  var bindValues = [];
  var query = strings[0];
  for (var i = 0; i < args.length; i++) {
    var rawValue = args[i];
    var value = rawValue;
    if (isGeneratedSqlQuery(rawValue)) {
      var src = rawValue._source();
      if (src.args.length) {
        query += src.strings[0];
        args.splice.apply(args, [i, 1].concat(_toConsumableArray(src.args)));
        strings.splice.apply(strings, [i, 2, strings[i] + src.strings[0]].concat(_toConsumableArray(src.strings.slice(1, src.args.length)), [src.strings[src.args.length] + strings[i + 1]]));
      } else {
        query += rawValue.query + strings[i + 1];
        args.splice(i, 1);
        strings.splice(i, 2, strings[i] + rawValue.query + strings[i + 1]);
      }
      i -= 1;
      continue;
    }
    if (rawValue === undefined) {
      query += strings[i + 1];
      continue;
    }
    if (isSqlLiteral(rawValue)) {
      query += "".concat(rawValue.toSQL()).concat(strings[i + 1]);
      continue;
    }
    var index = bindValues.indexOf(rawValue);
    var isKnown = index !== -1;
    var name = "value".concat(isKnown ? index : bindValues.length);
    if ((0, relation_1.isDbmsRelation)(rawValue) || (0, graph_1.isDbmsGraph)(rawValue) || (0, view_1.isDbmsView)(rawValue) || (0, analyzer_1.isDbmsAnalyzer)(rawValue)) {
      name = "@".concat(name);
      value = rawValue.name;
    }
    if (!isKnown) {
      bindValues.push(rawValue);
      bindVars[name] = value;
    }
    query += "@".concat(name).concat(strings[i + 1]);
  }
  return {
    query: query,
    bindVars: bindVars,
    _source: function _source() {
      return {
        strings: strings,
        args: args
      };
    }
  };
}
exports.sql = sql;
// eslint-disable-next-line @typescript-eslint/no-namespace
(function (sql) {
  /**
   * Marks an arbitrary scalar value (i.e. a string, number or boolean) as
   * safe for being inlined directly into SQL queries when used in an `sql`
   * template string, rather than being converted into a bind parameter.
   *
   * **Note**: Nesting `sql` template strings is a much safer alternative for
   * most use cases. This low-level helper function only exists to help with
   * rare edge cases where a trusted SQL query fragment must be read from a
   * string (e.g. when reading query fragments from JSON) and should only be
   * used as a last resort.
   *
   * @example
   * ```js
   * // BAD! DO NOT DO THIS!
   * const sortDirection = sql.literal('ASC');
   *
   * // GOOD! DO THIS INSTEAD!
   * const sortDirection = sql`ASC`;
   * ```
   *
   * @example
   * ```js
   * // BAD! DO NOT DO THIS!
   * const filterColor = sql.literal('FILTER d.color == "green"');
   * const result = await db.query(sql`
   *   FOR d IN some-relation
   *   ${filterColor}
   *   RETURN d
   * `);
   *
   * // GOOD! DO THIS INSTEAD!
   * const color = "green";
   * const filterColor = sql`FILTER d.color === ${color}`;
   * const result = await db.query(sql`
   *   FOR d IN some-relation
   *   ${filterColor}
   *   RETURN d
   * `);
   * ```
   *
   * @example
   * ```js
   * // WARNING: We explicitly trust the environment variable to be safe!
   * const filter = sql.literal(process.env.FILTER_STATEMENT);
   * const users = await db.query(sql`
   *   FOR user IN users
   *   ${filter}
   *   RETURN user
   * `);
   * ```
   */
  function literal(value) {
    if (isSqlLiteral(value)) {
      return value;
    }
    return {
      toSQL: function toSQL() {
        if (value === undefined) {
          return "";
        }
        return String(value);
      }
    };
  }
  sql.literal = literal;
  /**
   * Constructs {@link SqlQuery} objects from an array of arbitrary values.
   *
   * **Note**: Nesting `sql` template strings is a much safer alternative
   * for most use cases. This low-level helper function only exists to
   * complement the `sql` tag when constructing complex queries from dynamic
   * arrays of query fragments.
   *
   * @param values - Array of values to join. These values will behave exactly
   * like values interpolated in an `sql` template string.
   * @param sep - Seperator to insert between values. This value will behave
   * exactly like a value passed to {@link sql.literal}, i.e. it will be
   * inlined as-is, rather than being converted into a bind parameter.
   *
   * @example
   * ```js
   * const users = db.relation("users");
   * const filters = [];
   * if (adminsOnly) filters.push(sql`FILTER user.admin`);
   * if (activeOnly) filters.push(sql`FILTER user.active`);
   * const result = await db.query(sql`
   *   FOR user IN ${users}
   *   ${sql.join(filters)}
   *   RETURN user
   * `);
   * ```
   *
   * @example
   * ```js
   * const users = db.relation("users");
   * const keys = ["jreyes", "ghermann"];
   *
   * // BAD! NEEDLESSLY COMPLEX!
   * const docs = keys.map(key => sql`DOCUMENT(${users}, ${key}`));
   * const result = await db.query(sql`
   *   FOR user IN [
   *     ${sql.join(docs, ", ")}
   *   ]
   *   RETURN user
   * `);
   * // Query:
   * //   FOR user IN [
   * //     DOCUMENT(@@value0, @value1), DOCUMENT(@@value0, @value2)
   * //   ]
   * //   RETURN user
   * // Bind parameters:
   * //   @value0 -> "users"
   * //   value1 -> "jreyes"
   * //   value2 -> "ghermann"
   *
   * // GOOD! MUCH SIMPLER!
   * const result = await db.query(sql`
   *   FOR key IN ${keys}
   *   LET user = DOCUMENT(${users}, key)
   *   RETURN user
   * `);
   * // Query:
   * //   FOR user IN @value0
   * //   LET user = DOCUMENT(@@value1, key)
   * //   RETURN user
   * // Bind parameters:
   * //   value0 -> ["jreyes", "ghermann"]
   * //   @value1 -> "users"
   * ```
   */
  function join(values) {
    var sep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : " ";
    if (!values.length) {
      return sql(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
    }
    if (values.length === 1) {
      return sql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["", ""])), values[0]);
    }
    return sql.apply(void 0, [[""].concat(_toConsumableArray(Array(values.length - 1).fill(sep)), [""])].concat(_toConsumableArray(values)));
  }
  sql.join = join;
})(sql = exports.sql || (exports.sql = {}));