"use strict";

/**
 * Wrapper around browser `btoa` function to allow substituting a
 * Node.js-specific implementation.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base64Encode = void 0;
/**
 * @internal
 */
function base64Encode(str) {
  return btoa(str);
}
exports.base64Encode = base64Encode;