"use strict";

/**
 * Utility function for omitting properties by key.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.omit = void 0;
/**
 * @internal
 * @hidden
 */
function omit(obj, keys) {
  var result = {};
  for (var _i = 0, _Object$keys = Object.keys(obj); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];
    if (keys.includes(key)) continue;
    result[key] = obj[key];
  }
  return result;
}
exports.omit = omit;