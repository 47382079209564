module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="nodesContent" class="innerContent">\r\n\r\n    ';
 if (Object.keys(coords).length > 0) { 
__p+='\r\n\r\n        ';
 var disabled = ''; 
__p+='\r\n\r\n    <div class="pure-g">\r\n\r\n        <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-2">\r\n\r\n            <div class="sectionHeader pure-g">\r\n                <div class="pure-u-1-5">\r\n                    <div class="title">\r\n                        Coordinators\r\n                    </div>\r\n                </div>\r\n\r\n                <div class="pure-u-4-5">\r\n\r\n                    ';
 if (scaling === true && frontendConfig.db === '_system') { 
__p+='\r\n                        <div class="scaleGroup" id="scaleCoords">\r\n                            <div style="text-align: -webkit-right;">\r\n                                <span class="left" id="removeCoord"><i class="fa fa-minus"></i></span>\r\n                                <input id="plannedCoords" type="text" value="'+
((__t=( plannedCoords ))==null?'':__t)+
'"/>\r\n                                <span class="right" id="addCoord"><i class="fa fa-plus"></i></span>\r\n                            </div>\r\n                        </div>\r\n                    ';
 } 
__p+='\r\n\r\n                    <div class="information" id="infoCoords">\r\n            <span class="positive"><span> '+
((__t=( scaleProperties.coordsOk ))==null?'':__t)+
' </span><i\r\n                        class="fa fa-check-circle"></i></span>\r\n                        ';
 if (scaleProperties.coordsError) { 
__p+='\r\n                            <span class="negative"><span> '+
((__t=( scaleProperties.coordsError ))==null?'':__t)+
' </span><i\r\n                                        class="fa fa-exclamation-circle"></i></span>\r\n                        ';
 } 
__p+='\r\n                        ';
 if (scaleProperties.coordsPending && scaling === true && frontendConfig.db === '_system') { 
__p+='\r\n                            <span class="warning"><span> '+
((__t=( scaleProperties.coordsPending ))==null?'':__t)+
' </span><i\r\n                                        class="fa fa-circle-o-notch fa-spin"></i></span>\r\n                            <button class="abortClusterPlan navbar-btn btn-default">Abort</button>\r\n                        ';
 } 
__p+='\r\n                    </div>\r\n                </div>\r\n\r\n            </div>\r\n\r\n            <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title" style="clear: both">\r\n                <div class="pure-table-row">\r\n                    <div class="pure-u-9-24 left">Name</div>\r\n                    <div class="pure-u-9-24 left">Endpoint</div>\r\n                    <div class="pure-u-3-24">Version</div>\r\n                    <div class="pure-u-2-24 mid hide-small">Since</div>\r\n                    <div class="pure-u-1-24 mid"></div>\r\n                </div>\r\n            </div>\r\n\r\n            <div class="pure-g cluster-nodes coords-nodes pure-table pure-table-body">\r\n                ';
 _.each(coords, function(node, key) { 
__p+='\r\n                    ';
 var id = node.id + "-node"; 
__p+='\r\n\r\n                    <div class="pure-table-row '+
((__t=( disabled ))==null?'':__t)+
'" node="'+
((__t=( id ))==null?'':__t)+
'">\r\n\r\n                        <div class="pure-u-9-24 left" title="'+
((__t=( node.id ))==null?'':__t)+
'">\r\n                            '+
((__t=( node.ShortName ))==null?'':__t)+
'\r\n                            <i class="fa fa-bar-chart"></i>\r\n                            ';
 if (node.CanBeDeleted && frontendConfig.db === '_system') { 
__p+='\r\n                                <i class="fa fa-trash-o"></i>\r\n                            ';
 } 
__p+='\r\n                        </div>\r\n                        <div class="pure-u-9-24 left">'+
((__t=( node.Endpoint ))==null?'':__t)+
'</div>\r\n\r\n                        <div class="pure-u-3-24">'+
((__t=( node.Version ))==null?'':__t)+
'</div>\r\n\r\n                        ';
 var formatted = node.LastAckedTime.substr(11, 18); 
__p+='\r\n                        ';
 var full = node.LastAckedTime; 
__p+='\r\n                        <div class="pure-u-2-24 hide-small mid" title="'+
((__t=( full ))==null?'':__t)+
'">'+
((__t=( formatted ))==null?'':__t)+
'</div>\r\n\r\n                        <div class="pure-u-1-24 right state">\r\n                            ';
 if (node.Status === 'GOOD') { 
__p+='\r\n                                <i class="fa fa-check-circle tippy" title="'+
((__t=( node.SyncStatus ))==null?'':__t)+
'"></i>\r\n                            ';
 } else { 
__p+='\r\n                                <i class="fa fa-exclamation-circle"></i>\r\n                            ';
 } 
__p+='\r\n                        </div>\r\n                    </div>\r\n\r\n                ';
 }); 
__p+='\r\n            </div>\r\n        </div>\r\n\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (Object.keys(dbs).length > 0) { 
__p+='\r\n            ';
 var disabled = ''; 
__p+='\r\n            ';
 disabled = " dbserver"; 
__p+='\r\n        <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-2">\r\n            <div class="sectionHeader pure-g">\r\n                <div class="pure-u-1-5">\r\n                    <div class="title">\r\n                        DB Servers\r\n                    </div>\r\n                </div>\r\n\r\n                <div class="pure-u-4-5">\r\n\r\n                    ';
 if (scaling === true && frontendConfig.db === '_system') { 
__p+='\r\n                        <div class="scaleGroup" id="scaleCoords">\r\n                            <div style="text-align: -webkit-right;">\r\n                                <span class="left" id="removeDBs"><i class="fa fa-minus"></i></span>\r\n                                <input id="plannedDBs" value="'+
((__t=( plannedDBs ))==null?'':__t)+
'" type="text"/>\r\n                                <span class="right" id="addDBs"><i class="fa fa-plus"></i></span>\r\n                            </div>\r\n                        </div>\r\n                    ';
 } 
__p+='\r\n\r\n                    <div class="information" id="infoDBs">\r\n                        <span class="positive"><span> '+
((__t=( scaleProperties.dbsOk ))==null?'':__t)+
' </span><i\r\n                                    class="fa fa-check-circle"></i></span>\r\n                        ';
 if (scaleProperties.dbsError) { 
__p+='\r\n                            <span class="negative"><span> '+
((__t=( scaleProperties.dbsError ))==null?'':__t)+
' </span><i\r\n                                        class="fa fa-exclamation-circle"></i></span>\r\n                        ';
 } 
__p+='\r\n                        ';
 if (scaleProperties.dbsPending && scaling === true && frontendConfig.db === '_system') { 
__p+='\r\n                            <span class="warning"><span> '+
((__t=( scaleProperties.dbsPending ))==null?'':__t)+
' </span><i\r\n                                        class="fa fa-circle-o-notch fa-spin"></i></span>\r\n                            <button class="abortClusterPlan navbar-btn btn-default">Abort</button>\r\n                        ';
 } 
__p+='\r\n                    </div>\r\n\r\n                </div>\r\n\r\n            </div>\r\n\r\n            <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title" style="clear: both">\r\n                <div class="pure-table-row">\r\n                    <div class="pure-u-9-24 left">Name</div>\r\n                    <div class="pure-u-9-24 left">Endpoint</div>\r\n                    <div class="pure-u-3-24">Version</div>\r\n                    <div class="pure-u-2-24 mid hide-small">Since</div>\r\n                    <div class="pure-u-1-24 mid"></div>\r\n                </div>\r\n            </div>\r\n\r\n            ';
 } 
__p+='\r\n\r\n            <div class="pure-g cluster-nodes dbs-nodes pure-table pure-table-body">\r\n                ';
 _.each(dbs, function(node, key) { 
__p+='\r\n                    ';
 var id = node.id + "-node"; 
__p+='\r\n\r\n                    <div class="pure-table-row '+
((__t=( disabled ))==null?'':__t)+
'" node="'+
((__t=( id ))==null?'':__t)+
'">\r\n\r\n                        <div class="pure-u-9-24 left" title="'+
((__t=( node.id ))==null?'':__t)+
'">\r\n                            '+
((__t=( node.ShortName ))==null?'':__t)+
'\r\n                            <i class="fa fa-bar-chart"></i>\r\n                            ';
 if (node.CanBeDeleted && frontendConfig.db === '_system') { 
__p+='\r\n                                <i class="fa fa-trash-o"></i>\r\n                            ';
 } 
__p+='\r\n                        </div>\r\n                        <div class="pure-u-9-24 left">'+
((__t=( node.Endpoint ))==null?'':__t)+
'</div>\r\n\r\n                        <div class="pure-u-3-24">'+
((__t=( node.Version ))==null?'':__t)+
'</div>\r\n\r\n                        ';
 var formatted = node.LastAckedTime.substr(11, 18); 
__p+='\r\n                        ';
 var full = node.LastAckedTime; 
__p+='\r\n                        <div class="pure-u-2-24 hide-small mid" title="'+
((__t=( full ))==null?'':__t)+
'">'+
((__t=( formatted ))==null?'':__t)+
'</div>\r\n\r\n                        <div class="pure-u-1-24 right state">\r\n                            ';
 if (node.Status === 'GOOD') { 
__p+='\r\n                                <i class="fa fa-check-circle tippy" title="'+
((__t=( node.SyncStatus ))==null?'':__t)+
'"></i>\r\n                            ';
 } else { 
__p+='\r\n                                <i class="fa fa-exclamation-circle"></i>\r\n                            ';
 } 
__p+='\r\n                        </div>\r\n\r\n                    </div>\r\n                ';
 }); 
__p+='\r\n            </div>\r\n\r\n        </div>\r\n\r\n        ';
 if (Object.keys(agents).length > 0) { 
__p+='\r\n            <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\r\n                <div class="sectionHeader pure-g">\r\n                    <div class="pure-u-1-5">\r\n                        <div class="title">\r\n                            Agents\r\n                        </div>\r\n                    </div>\r\n\r\n                    <div class="pure-u-4-5">\r\n                        <div class="information" id="infoAgents">\r\n            <span class="positive"><span> '+
((__t=( scaleProperties.agentsOk ))==null?'':__t)+
' </span><i\r\n                        class="fa fa-check-circle"></i></span>\r\n                            ';
 if (scaleProperties.coordsError) { 
__p+='\r\n                                <span class="negative"><span> '+
((__t=( scaleProperties.agentError ))==null?'':__t)+
' </span><i\r\n                                            class="fa fa-exclamation-circle"></i></span>\r\n                            ';
 } 
__p+='\r\n                        </div>\r\n\r\n                    </div>\r\n                </div>\r\n\r\n                <div class="pure-g cluster-nodes-title pure-table pure-table-header pure-title" style="clear: both">\r\n                    <div class="pure-table-row">\r\n                        <div class="pure-u-9-24 left">Name</div>\r\n                        <div class="pure-u-3-24 left" style="text-align: center">Leading</div>\r\n                        <div class="pure-u-6-24 left">Endpoint</div>\r\n                        <div class="pure-u-3-24">Version</div>\r\n                        <!--div class="pure-u-2-24 mid hide-small">Since</div-->\r\n                        <div class="pure-u-3-24 mid"></div>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class="pure-g cluster-nodes agents-nodes pure-table pure-table-body">\r\n                    ';
 _.each(agents, function(node, key) { 
__p+='\r\n                        ';
 var id = node.id + "-node"; 
__p+='\r\n\r\n                        <div class="pure-table-row disabled" node="'+
((__t=( id ))==null?'':__t)+
'">\r\n\r\n                            <div class="pure-u-9-24 left" title="'+
((__t=( node.id ))==null?'':__t)+
'">\r\n                                '+
((__t=( node.id ))==null?'':__t)+
'\r\n                            </div>\r\n                            ';
 if (node.Leading) { 
__p+='\r\n                                <div class="pure-u-3-24 left" style="text-align: center">\r\n                                    <i class="fa fa-check-circle tippy" title="The current leading agent!"></i>\r\n                                </div>\r\n                            ';
 } else { 
__p+='\r\n                                <div class="pure-u-3-24 left" style="text-align: center">\r\n                                    <i style="color: rgba(0,0,0,.2); font-size: 16px;" class="fa fa-circle tippy"\r\n                                       title="This agent is not leading."></i>\r\n                                </div>\r\n                            ';
 } 
__p+='\r\n\r\n                            <div class="pure-u-6-24 left">'+
((__t=( node.Endpoint ))==null?'':__t)+
'</div>\r\n\r\n                            <div class="pure-u-3-24">'+
((__t=( node.Version ))==null?'':__t)+
'</div>\r\n\r\n                            ';

                                let formatted;
                                let full;
                                try {
                                    formatted = node.LastAckedTime.substr(11, 18);
                                    full = node.LastAckedTime;
                                } catch (ignore) {
                                    formatted = 'n/A';
                                    full = 'n/A';
                                }
                            
__p+='\r\n                            <!--div class="pure-u-2-24 hide-small mid" title="'+
((__t=( full ))==null?'':__t)+
'">'+
((__t=( formatted ))==null?'':__t)+
'</div-->\r\n\r\n                            <div class="pure-u-3-24 right state">\r\n                                ';
 if (node.Status === 'GOOD') { 
__p+='\r\n                                    <i class="fa fa-check-circle tippy" title="'+
((__t=( node.SyncStatus ))==null?'':__t)+
'"></i>\r\n                                ';
 } else { 
__p+='\r\n                                    <i class="fa fa-exclamation-circle"></i>\r\n                                ';
 } 
__p+='\r\n                            </div>\r\n\r\n                        </div>\r\n                    ';
 }); 
__p+='\r\n                </div>\r\n            </div>\r\n        ';
 } 
__p+='\r\n\r\n    </div>\r\n</div>\r\n\r\n';
}
return __p;
};
