/* jshint browser: true */
/* jshint unused: false */
/* global Backbone, dbmsHelper, $, _, window, templateEngine */

(function () {
  'use strict';

  window.LoggerView = Backbone.View.extend({
    el: '#content',
    logsel: '#logEntries',
    initDone: false,
    fetchedEntries: [],
    pageSize: 20,
    currentPage: 0,
    logTopics: {},
    logLevels: [],
    initialize: function initialize(options) {
      var self = this;
      if (options) {
        this.options = options;
        if (options.endpoint) {
          this.endpoint = options.endpoint;
        }
        if (options.contentDiv) {
          this.el = options.contentDiv;
        }
      }
      this.collection.setPageSize(this.pageSize);
      if (!this.initDone) {
        var url = dbmsHelper.databaseUrl('/_admin/log/level');
        if (this.endpoint) {
          url += "?serverId=".concat(encodeURIComponent(this.endpoint));
        }
        // first fetch all log topics + topics
        $.ajax({
          type: 'GET',
          cache: false,
          url: url,
          contentType: 'application/json',
          processData: false,
          success: function success(data) {
            self.logTopics = data;
            _.each(['fatal', 'error', 'warning', 'info', 'debug'], function (level) {
              self.logLevels.push(level);
            });
            self.initDone = true;
          }
        });
      }
    },
    remove: function remove() {
      this.$el.empty().off(); /* off to unbind the events */
      this.stopListening();
      this.unbind();
      delete this.el;
      return this;
    },
    currentLoglevel: undefined,
    defaultLoglevel: 'info',
    events: {
      'click #logLevelSelection': 'renderLogLevel',
      'click #logTopicSelection': 'renderLogTopic',
      'click #logFilters': 'resetFilters',
      'click #loadMoreEntries': 'loadMoreEntries',
      'click #downloadDisplayedEntries': 'downloadEntries'
    },
    template: templateEngine.createTemplate('loggerView.ejs'),
    templateEntries: templateEngine.createTemplate('loggerViewEntries.ejs'),
    renderLogTopic: function renderLogTopic(e) {
      var self = this;
      if (!this.logTopicOptions) {
        this.logTopicOptions = {};
      }
      var active;
      _.each(this.logTopics, function (topic, name) {
        if (self.logTopicOptions[name]) {
          active = self.logTopicOptions[name].active;
        }
        self.logTopicOptions[name] = {
          name: name,
          active: active || false
        };
      });
      var pos = $(e.currentTarget).position();
      pos.right = '30';
      this.logTopicView = new window.FilterSelectView({
        name: 'Topic',
        options: self.logTopicOptions,
        position: pos,
        callback: self.logTopicCallbackFunction.bind(this),
        multiple: true
      });
      this.logTopicView.render();
    },
    downloadEntries: function downloadEntries() {
      // sort entries (primary by date, then by lid)
      this.fetchedEntries.sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateB - dateA || b.lid - a.lid;
      });
      var currentDate = new Date();
      var fileName = "LOGS-".concat(currentDate.toISOString());
      dbmsHelper.downloadLocalBlob(JSON.stringify(this.fetchedEntries, null, 2), 'json', fileName);
    },
    loadMoreEntries: function loadMoreEntries() {
      this.convertModelToJSON();
    },
    logTopicCallbackFunction: function logTopicCallbackFunction(options) {
      this.logTopicOptions = options;
      this.applyFilter();
    },
    logLevelCallbackFunction: function logLevelCallbackFunction(options) {
      this.logLevelOptions = options;
      this.applyFilter();
    },
    resetFilters: function resetFilters() {
      _.each(this.logTopicOptions, function (option) {
        option.active = false;
      });
      _.each(this.logLevelOptions, function (option) {
        option.active = false;
      });
      this.applyFilter();
    },
    isFilterActive: function isFilterActive(filterobj) {
      var active = false;
      _.each(filterobj, function (obj) {
        if (obj.active) {
          active = true;
        }
      });
      return active;
    },
    changeButton: function changeButton(btn) {
      if (!btn) {
        $('#logTopicSelection').addClass('btn-primary').removeClass('btn-success');
        $('#logLevelSelection').addClass('btn-primary').removeClass('btn-success');
        $('#logFilters').hide();
        $('#filterDesc').html('');
      } else {
        if (btn === 'level') {
          $('#logLevelSelection').addClass('btn-success').removeClass('btn-primary');
          $('#logTopicSelection').addClass('btn-primary').removeClass('btn-success');
          $('#filterDesc').html(btn);
        } else if (btn === 'topic') {
          $('#logTopicSelection').addClass('btn-success').removeClass('btn-primary');
          $('#logLevelSelection').addClass('btn-primary').removeClass('btn-success');
          $('#filterDesc').html(btn);
        } else if (btn === 'both') {
          $('#logTopicSelection').addClass('btn-success').removeClass('btn-primary');
          $('#logLevelSelection').addClass('btn-success').removeClass('btn-primary');
          $('#filterDesc').html('level, topic');
        }
        $('#logFilters').show();
      }
    },
    applyFilter: function applyFilter() {
      var self = this;
      var isLevel = this.isFilterActive(this.logLevelOptions);
      var isTopic = this.isFilterActive(this.logTopicOptions);
      if (isLevel && isTopic) {
        // both filters active
        _.each($('#logEntries').children(), function (entry) {
          if (self.logLevelOptions[$(entry).attr('level')].active === false || self.logTopicOptions[$(entry).attr('topic')].active === false) {
            $(entry).hide();
          } else if (self.logLevelOptions[$(entry).attr('level')].active && self.logTopicOptions[$(entry).attr('topic')].active) {
            $(entry).show();
          }
        });
        this.changeButton('both');
      } else if (isLevel && !isTopic) {
        // only level filter active
        _.each($('#logEntries').children(), function (entry) {
          if (self.logLevelOptions[$(entry).attr('level')].active === false) {
            $(entry).hide();
          } else {
            $(entry).show();
          }
        });
        this.changeButton('level');
      } else if (!isLevel && isTopic) {
        // only topic filter active
        _.each($('#logEntries').children(), function (entry) {
          if (self.logTopicOptions[$(entry).attr('topic')].active === false) {
            $(entry).hide();
          } else {
            $(entry).show();
          }
        });
        this.changeButton('topic');
      } else if (!isLevel && !isTopic) {
        _.each($('#logEntries').children(), function (entry) {
          $(entry).show();
        });
        this.changeButton();
      }
      var count = 0;
      _.each($('#logEntries').children(), function (elem) {
        if ($(elem).css('display') === 'flex') {
          $(elem).css('display', 'block');
        }
        if ($(elem).css('display') === 'block') {
          count++;
        }
      });
      if (count === 1) {
        $('#noLogEntries').hide();
      } else if (count === 0) {
        $('#noLogEntries').show();
      } else {
        $('#noLogEntries').hide();
      }
    },
    renderLogLevel: function renderLogLevel(e) {
      var self = this;
      if (!this.logLevelOptions) {
        this.logLevelOptions = {};
      }
      var active;
      _.each(this.logLevels, function (name) {
        if (self.logLevelOptions[name]) {
          active = self.logLevelOptions[name].active;
        }
        self.logLevelOptions[name] = {
          name: name,
          active: active || false
        };
        var color = dbmsHelper.statusColors[name];
        if (color) {
          self.logLevelOptions[name].color = color;
        }
      });
      var pos = $(e.currentTarget).position();
      pos.right = '115';
      this.logLevelView = new window.FilterSelectView({
        name: 'Level',
        options: self.logLevelOptions,
        position: pos,
        callback: self.logLevelCallbackFunction.bind(this),
        multiple: false
      });
      this.logLevelView.render();
    },
    setActiveLoglevel: function setActiveLoglevel(e) {},
    initTotalAmount: function initTotalAmount() {
      var self = this;
      this.collection.fetch({
        data: $.param({
          test: true
        }),
        success: function success() {
          self.convertModelToJSON();
        }
      });
      this.fetchedAmount = true;
    },
    invertArray: function invertArray(array) {
      var rtnArr = [];
      var counter = 0;
      var i;
      for (i = array.length - 1; i >= 0; i--) {
        rtnArr[counter] = array[i];
        counter++;
      }
      return rtnArr;
    },
    convertModelToJSON: function convertModelToJSON() {
      if (!this.fetchedAmount) {
        this.initTotalAmount();
        return;
      }
      this.collection.page = this.currentPage;
      this.currentPage++;
      var self = this;
      var date;
      var entriesToAppend = [];
      this.collection.fetch({
        success: function success(settings) {
          self.collection.each(function (model) {
            date = new Date(model.get('timestamp') * 1000);
            var entry = {
              status: model.getLogStatus(),
              date: dbmsHelper.formatDT(date),
              timestamp: model.get('timestamp'),
              msg: model.get('text'),
              topic: model.get('topic')
            };
            entriesToAppend.push(entry);

            // keep history for export
            self.fetchedEntries.push(model.toJSON());
          });
          // invert order
          self.renderLogs(self.invertArray(entriesToAppend), settings.lastInverseOffset);
        }
      });
    },
    render: function render(initialRender) {
      var self = this;
      if (initialRender) {
        this.currentPage = 0;
        this.fetchedEntries = [];
      }
      if (this.initDone) {
        // render static content
        $(this.el).html(this.template.render({}));

        // fetch dyn. content
        this.convertModelToJSON();
      } else {
        window.setTimeout(function () {
          self.render(false);
        }, 100);
      }
      return this;
    },
    renderLogs: function renderLogs(entries, offset) {
      _.each(entries, function (entry) {
        if (entry.msg.indexOf('{' + entry.topic + '}') > -1) {
          entry.msg = entry.msg.replace('{' + entry.topic + '}', '');
        }
        entry.msg = dbmsHelper.escapeHtml(entry.msg);
      });
      if (this.currentPage === 0) {
        // render initial
        $(this.logsel).html(this.templateEntries.render({
          entries: entries
        }));
      } else {
        // append
        $(this.logsel).append(this.templateEntries.render({
          entries: entries
        }));
      }
      if (offset <= 0) {
        $('#loadMoreEntries').attr('disabled', true);
      } else {
        $('#loadMoreEntries').attr('disabled', false);
      }
      dbmsHelper.createTooltips();
      this.applyFilter();
    }
  });
})();