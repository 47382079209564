import { isPingableEndpoint } from '../api/dbEndpoint';

export const checkEndpoint = async (endpoint: string, username?: string, password?: string): Promise<boolean> => {
    if (!endpoint) return false
    try {
        const isPingable = await isPingableEndpoint({
            endpoint,
            username: username || '',
            password: password || '',
        })
        return isPingable
    } catch(e) {
        return false
    }
}
