/* jshint browser: true */
/* jshint unused: false */
/* global Backbone, templateEngine, $, window, sessionStorage, Storage, dbmsHelper, _ */
(function () {
  'use strict';

  window.NavigationView = Backbone.View.extend({
    el: '#navigationBar',
    subEl: '#subNavigationBar',
    events: {
      'change #dbmsRelationSelect': 'navigateBySelect',
      'click .tab': 'navigateByTab',
      'click li': 'switchTab',
      'click .dbmsLogo': 'selectMenuItem',
      'click .shortcut-icons p': 'showShortcutModal'
      // 'click #switchDatabase': 'switchDatabases'
    },

    renderFirst: true,
    activeSubMenu: undefined,
    initialize: function initialize(options) {
      var self = this;
      this.userCollection = options.userCollection;
      this.currentDB = options.currentDB;
      this.dbSelectionView = new window.DBSelectionView({
        collection: options.database,
        current: this.currentDB
      });
      this.userBarView = new window.UserBarView({
        userCollection: this.userCollection
      });
      this.notificationView = new window.NotificationView({
        collection: options.notificationCollection
      });
      this.statisticBarView = new window.StatisticBarView({
        currentDB: this.currentDB
      });
      this.isCluster = options.isCluster;
      this.foxxApiEnabled = options.foxxApiEnabled;
      this.statisticsInAllDatabases = options.statisticsInAllDatabases;
      this.handleKeyboardHotkeys();
      Backbone.history.on('all', function () {
        self.selectMenuItem();
      });
    },
    showShortcutModal: function showShortcutModal() {
      dbmsHelper.hotkeysFunctions.showHotkeysModal();
    },
    handleSelectDatabase: function handleSelectDatabase() {
      this.dbSelectionView.render($('#dbSelect'));
    },
    template: templateEngine.createTemplate('navigationView.ejs'),
    templateSub: templateEngine.createTemplate('subNavigationView.ejs'),
    render: function render() {
      $(this.subEl).html(this.templateSub.render({
        frontendConfig: window.frontendConfig,
        currentDB: this.currentDB.toJSON()
      }));
      this.renderDatabasesDropdown();
      $('#switchDatabase').change({
        self: this
      }, this.switchDatabases);
      dbmsHelper.checkDatabasePermissions(this.continueRender.bind(this), this.continueRender.bind(this));
    },
    continueRender: function continueRender(readOnly) {
      var self = this;
      $(this.el).html(this.template.render({
        currentDB: this.currentDB,
        statisticsInAllDatabases: this.statisticsInAllDatabases,
        isCluster: this.isCluster,
        foxxApiEnabled: this.foxxApiEnabled,
        readOnly: readOnly
      }));
      if (this.currentDB.get('name') !== '_system') {
        $('#dashboard').parent().remove();
      }
      this.dbSelectionView.render($('#dbSelect'));
      // this.notificationView.render($("#notificationBar"))

      var callback = function (error) {
        if (!error) {
          this.userBarView.render();
        }
      }.bind(this);
      this.userCollection.whoAmI(callback);
      // this.statisticBarView.render($("#statisticBar"))

      if (this.renderFirst) {
        this.renderFirst = false;
        this.selectMenuItem();
        $('.dbmsLogo').on('click', function () {
          self.selectMenuItem();
        });
      }
      self.resize();
      this.renderDatabasesDropdown();
      $('#switchDatabase').off('change');
      $('#switchDatabase').change({
        self: this
      }, this.switchDatabases);
      return this;
    },
    resize: function resize() {
      // set menu sizes - responsive
      var height = $(window).height() - $('.subMenuEntries').first().height();
      var navBar = $('#navigationBar');
      navBar.css('min-height', height);
      navBar.css('height', height);
    },
    navigateBySelect: function navigateBySelect() {
      var navigateTo = $('#dbmsRelationSelect').find('option:selected').val();
      window.App.navigate(navigateTo, {
        trigger: true
      });
    },
    handleKeyboardHotkeys: function handleKeyboardHotkeys() {
      dbmsHelper.enableKeyboardHotkeys(true);
    },
    navigateByTab: function navigateByTab(e) {
      var tab = e.target || e.srcElement;
      var navigateTo = tab.id;
      var dropdown = false;
      if ($(tab).hasClass('fa')) {
        return;
      }
      if (navigateTo === '') {
        navigateTo = $(tab).attr('class');
      }
      if (navigateTo === 'links') {
        dropdown = true;
        $('#link_dropdown').slideToggle(1);
        e.preventDefault();
      } else if (navigateTo === 'tools') {
        dropdown = true;
        $('#tools_dropdown').slideToggle(1);
        e.preventDefault();
      } else if (navigateTo === 'dbselection') {
        dropdown = true;
        $('#dbs_dropdown').slideToggle(1);
        e.preventDefault();
      }
      if (!dropdown) {
        window.App.navigate(navigateTo, {
          trigger: true
        });
        e.preventDefault();
      }
    },
    handleSelectNavigation: function handleSelectNavigation() {
      var self = this;
      $('#dbmsRelationSelect').change(function () {
        self.navigateBySelect();
      });
    },
    subViewConfig: {
      documents: 'relations',
      relation: 'relations'
    },
    subMenuConfig: {
      queries: [{
        name: 'Editor',
        route: 'query',
        active: true
      }, {
        name: 'Running Queries',
        route: 'queryManagement',
        params: {
          active: true
        },
        active: undefined
      }, {
        name: 'Slow Query History',
        route: 'queryManagement',
        params: {
          active: false
        },
        active: undefined
      }]
    },
    renderSubMenu: function renderSubMenu(id) {
      var self = this;
      if (id === undefined) {
        if (window.isCluster) {
          id = 'cluster';
        } else {
          id = 'dashboard';
        }
      }
      if (this.subMenuConfig[id]) {
        $(this.subEl + ' .bottom').html('');
        var cssclass = '';
        _.each(this.subMenuConfig[id], function (menu) {
          if (menu.active) {
            cssclass = 'active';
          } else {
            cssclass = '';
          }
          if (menu.disabled) {
            cssclass = 'disabled';
          }
          $(self.subEl + ' .bottom').append('<li class="subMenuEntry ' + cssclass + '"><a>' + menu.name + '</a></li>');
          if (!menu.disabled) {
            $(self.subEl + ' .bottom').children().last().bind('click', function (elem) {
              $('#subNavigationBar .breadcrumb').html('');
              self.activeSubMenu = menu;
              self.renderSubView(menu, elem);
            });
          }
        });
        $(this.subEl + ' .bottom').css('display', 'block');
      } else {
        $(this.subEl + ' .bottom').css('display', 'none');
      }
    },
    renderSubView: function renderSubView(menu, element) {
      // trigger routers route
      if (window.App[menu.route]) {
        if (window.App[menu.route].resetState) {
          window.App[menu.route].resetState();
        }
        window.App[menu.route]();
      } else if (menu.href) {
        window.App.navigate(menu.href, {
          trigger: true
        });
      }

      // select active sub view entry
      $(this.subEl + ' .bottom').children().removeClass('active');
      $(element.currentTarget).addClass('active');
    },
    switchTab: function switchTab(e) {
      var id = $(e.currentTarget).children().first().attr('id');
      if (id) {
        this.selectMenuItem(id + '-menu');
      }
    },
    selectMenuItem: function selectMenuItem(menuItem, noMenuEntry) {
      if (menuItem === undefined) {
        menuItem = window.location.hash.split('/')[0];
        menuItem = menuItem.substr(1, menuItem.length - 1);
      }

      // Location for selecting MainView Primary Navigaation Entry
      if (menuItem === '') {
        if (window.App.isCluster) {
          menuItem = 'cluster';
        } else {
          menuItem = 'dashboard';
        }
      } else if (menuItem === 'cNodes' || menuItem === 'dNodes') {
        menuItem = 'nodes';
      }
      try {
        this.renderSubMenu(menuItem.split('-')[0]);
      } catch (e) {
        this.renderSubMenu(menuItem);
      }
      $('.navlist li').removeClass('active');
      if (typeof menuItem === 'string') {
        if (noMenuEntry) {
          $('.' + this.subViewConfig[menuItem] + '-menu').addClass('active');
        } else if (menuItem) {
          $('.' + menuItem).addClass('active');
          $('.' + menuItem + '-menu').addClass('active');
        }
      }
      dbmsHelper.hideDbmsNotifications();
    },
    sortDatabases: function sortDatabases(obj) {
      var sorted;
      if (frontendConfig.authenticationEnabled) {
        // key, value tuples of database name and permission
        sorted = _.pairs(obj);
        sorted = _.sortBy(sorted, function (i) {
          return i[0].toLowerCase();
        });
        sorted = _.object(sorted);
      } else {
        // array contained only the database names
        sorted = _.sortBy(obj, function (i) {
          return i.toLowerCase();
        });
      }
      return sorted;
    },
    selectDatabase: function selectDatabase(data, dbToSelectName, errorCb) {
      try {
        localStorage.setItem('db', dbToSelectName);
        window.frontendConfig.db = dbToSelectName;
        frontendConfig.db = dbToSelectName;
        window.location.reload();
        dbmsHelper.dbmsNotification('Success', "switch to ".concat(dbToSelectName, "."));
      } catch (_unused) {
        errorCb();
      }
    },
    switchDatabases: function switchDatabases(e) {
      var self = e.data.self;
      var databaseName = e.target.value;
      var currentDbName = window.App.currentDB.get('name');
      if (currentDbName === databaseName) {
        return;
      }
      var prevDatabaseName = $('#switchDatabase').attr('data-current-db');
      var user = window.App.currentUser;
      var url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database', databaseName);
      $('#switchDatabase').attr('disabled', 'disabled');
      $('#switchDbsSpinner').show();
      if (!user) {
        console.log(databaseName);
        var errorCb = function errorCb() {};
        return self.selectDatabase({}, databaseName, errorCb);
      }
      $.ajax({
        url: url,
        success: function success(data) {
          $('#switchDatabase').attr('data-current-db', databaseName);
          var errorCb = function errorCb() {
            dbmsHelper.dbmsError('Error', "error: ".concat(databaseName));
            $('#switchDatabase').val(prevDatabaseName).change();
          };
          self.selectDatabase(data, databaseName, errorCb);
        },
        error: function error(data) {
          if (data.responseJSON && data.responseJSON.errorMessage) {
            dbmsHelper.dbmsError('Error', data.responseJSON.errorMessage);
          } else {
            dbmsHelper.dbmsError('Error', "error: ".concat(databaseName, ", ").concat(data.statusText));
          }
          $('#switchDatabase').val(prevDatabaseName).change();
        },
        complete: function complete() {
          $('#switchDatabase').removeAttr('disabled');
          $('#switchDbsSpinner').hide();
        }
      });
    },
    renderDatabasesDropdown: function renderDatabasesDropdown() {
      var self = this;
      var user = window.App.currentUser;
      var url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database', '_system');
      var noDatabasesCallback = function noDatabasesCallback() {
        $('#switchDatabase').attr('disabled', 'disabled');
        // Show create database modal
      };

      if (!user) {
        url = dbmsHelper.databaseUrl('/_api/database/user', '_system');
      }
      $.ajax({
        type: "GET",
        url: url,
        success: function success(permissions) {
          $('#switchDatabase').html('');
          var dbs = permissions.result;
          if (Object.keys(dbs).length > 0) {
            var fillCurrentDbData = function fillCurrentDbData(data) {
              if (fillCurrentDbData.called) {
                return;
              }
              $('#switchDatabase').attr('data-current-db', data);
              fillCurrentDbData.called = true;
            };
            var sortedObj = self.sortDatabases(dbs);
            ;
            var currentDbName = window.App.currentDB.get('name');
            _.each(sortedObj, function (rule, db) {
              var v;
              if (frontendConfig.authenticationEnabled) {
                v = db;
              } else {
                v = rule;
              }
              var escapedStr = _.escape(v);
              $('#switchDatabase').append('<option value="' + escapedStr + '"><pre>' + escapedStr + '</pre></option>');
            });
            $('#switchDatabase').val(currentDbName).change();
            fillCurrentDbData(currentDbName);
          } else {
            noDatabasesCallback();
          }
        },
        error: function error(e) {
          noDatabasesCallback();
        }
      });
    }
  });
})();