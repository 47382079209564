module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="paddingBox">\r\n    <div class="foxxDesc">\r\n        <p><span class="foxxName">'+
((__t=( model.name ))==null?'':__t)+
'</span></p>\r\n        <p><span class="foxxCategory">'+
((__t=( model.categories ))==null?'':__t)+
'</span></p>\r\n        <!--<p><i class="fa fa-star"></i><span class="foxxStars">23</span></p>-->\r\n    </div>\r\n    <div class="foxxActions">\r\n        ';
 if (upgrade) { 
__p+='\r\n        <button class="installFoxx btn-success" appId="'+
((__t=( model.name ))==null?'':__t)+
'" appVersion="'+
((__t=( model.latestVersion ))==null?'':__t)+
'">\r\n            Replace\r\n        </button>\r\n        ';
 } else { 
__p+='\r\n        <button class="installFoxx btn-success" appId="'+
((__t=( model.name ))==null?'':__t)+
'" appVersion="'+
((__t=( model.latestVersion ))==null?'':__t)+
'">\r\n            Install\r\n        </button>\r\n        ';
 } 
__p+='\r\n    </div>\r\n    <div class="borderBox"></div>\r\n    <img src="'+
((__t=( thumbnail ))==null?'':__t)+
'" height="50" width="50" alt="Icon for Service" class="icon">\r\n</div>\r\n\r\n';
}
return __p;
};
