module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="modal-dialog" class="modal fade createModalDialog" tabindex="-1" role="dialog"\r\n     aria-labelledby="myModalLabel" aria-hidden="true">\r\n    <div class="modal-dialog">\r\n        <!-- Modal content-->\r\n        <div class="modal-content">\r\n            ';
 if (title !== null) { 
__p+='\r\n            <div class="modal-header">\r\n                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\r\n                <a class="dbmsHeader">'+
((__t=( title ))==null?'':__t)+
'</a>\r\n            </div>\r\n            ';
 } 
__p+='\r\n\r\n            ';
 if (tabBar) { 
__p+='\r\n                <div class="modal-tabbar">\r\n                    <ul id="infoTab" class="nav nav-tabs">\r\n                        ';
 var counter = 0; 
__p+='\r\n                        ';
 _.each(tabBar, function(value) { 
__p+='\r\n                            ';
 if (counter === 0) { 
__p+='\r\n                            <li class="active"><a href="#'+
((__t=( value ))==null?'':__t)+
'" data-toggle="tab">'+
((__t=( value ))==null?'':__t)+
'</a></li>\r\n                            ';
 } else { 
__p+='\r\n                            <li><a href="#'+
((__t=( value ))==null?'':__t)+
'" data-toggle="tab">'+
((__t=( value ))==null?'':__t)+
'</a></li>\r\n                            ';
 } 
__p+='\r\n                            ';
 counter++; 
__p+='\r\n                        ';
 }); 
__p+='\r\n                    </ul>\r\n                </div>\r\n            ';
 } 
__p+='\r\n\r\n            <div class="modal-body">\r\n\r\n                ';
 if (tabBar) { 
__p+='\r\n                    <div class="tab-content">\r\n                        ';
 var counter = 0; 
__p+='\r\n                        ';
 _.each(tabBar, function(value) { 
__p+='\r\n                            ';
 if (counter === 0) { 
__p+='\r\n                            <div class="tab-pane tab-pane-modal active" id="'+
((__t=( value ))==null?'':__t)+
'">\r\n                            </div>\r\n                            ';
 } else { 
__p+='\r\n                            <div class="tab-pane tab-pane-modal" id="'+
((__t=( value ))==null?'':__t)+
'">\r\n                            </div>\r\n                            ';
 } 
__p+='\r\n                            ';
 counter++; 
__p+='\r\n                        ';
 }); 
__p+='\r\n                    </div>\r\n                ';
 } 
__p+='\r\n\r\n            </div>\r\n\r\n            ';
 if (!hideFooter) { 
__p+='\r\n                <div class="modal-footer">\r\n                    ';

                    _.each(buttons, function(value, key){
                        var type = value.type,
                                title = value.title,
                                disabled = '';
                        if (value.disabled) {
                            disabled = 'disabled';
                        }
                    
__p+='\r\n                    <button\r\n                        id="modalButton'+
((__t=( key ))==null?'':__t)+
'"\r\n                        type="button"\r\n                        class="btn btn-'+
((__t=( type ))==null?'':__t)+
' btn-lg"\r\n                        '+
((__t=( disabled ))==null?'':__t)+
'>\r\n                            '+
((__t=( title ))==null?'':__t)+
'\r\n                    </button>\r\n                    ';
 }); 
__p+='\r\n                </div>\r\n            ';
 } 
__p+='\r\n            \r\n            <div class="alert alert-danger modal-delete-confirmation" id="modal-delete-confirmation">\r\n                <strong>'+
((__t=( confirm||'Really delete?' ))==null?'':__t)+
'</strong>\r\n                <button id="modal-confirm-delete" type="button" class="btn btn-danger btn-lg pull-right modal-confirm-delete">Yes</button>\r\n                <button id="modal-abort-delete" type="button" class="btn btn-neutral btn-lg pull-right">No</button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
