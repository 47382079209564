module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

let tableStyle = '';
let textStyle = '';
let buttonText = '';

if (activeView === "text") {
    tableStyle = "display: none;";
    buttonText = "Show Table";
} else {
    textStyle = "display: none;";
    buttonText = "Show Text";
}

__p+='\r\n\r\n<div id="metricContent" style="padding-top: 0;">\r\n    <div class="bottomButtonBar">\r\n        <button id="downloadAs" class="pull-right btn-success">Download</button>\r\n        <button id="reloadMetrics" class="pull-right btn-success">Reload</button>\r\n        <button id="toggleView" class="pull-right btn-close">'+
((__t=( buttonText ))==null?'':__t)+
'</button>\r\n    </div>\r\n    <div id="metricsAsTable" style="'+
((__t=( tableStyle ))==null?'':__t)+
'">\r\n        <div class="header first">Counters</div>\r\n        <div class="pure-g" style="background: var(--c-primary-dark); padding-top: 10px;">\r\n            <div id="'+
((__t=( name ))==null?'':__t)+
'" class="metricsBox pure-u-1-1">\r\n                <table class="pure-table pure-table-metrics-counter">\r\n                    <thead>\r\n                    <tr>\r\n                        <th>Name</th>\r\n                        <th>Value</th>\r\n                        <th>Info</th>\r\n                    </tr>\r\n                    </thead>\r\n\r\n                    <tbody>\r\n                    ';
 _.each(collection.where({type: "COUNTER"}), (model) => {
                    _.each(model.get('metrics'), (metric) => {
                        let l = [];
                        _.each(Object.keys(metric.labels || {}), (label) => {
                            if (label === 'role' || label === 'shortname') {
                                return;
                            }
                            l.push(label + '=' + JSON.stringify(metric.labels[label]));
                        });
                        let labelString = ' {' + l.join(', ') + '}';
                        if (labelString.length <= 3) {
                            labelString = '';
                        }
                    
__p+='\r\n                    <tr>\r\n                        <td>'+
((__t=( model.get('name') + labelString ))==null?'':__t)+
'</td>\r\n                        <td class="alignRight">'+
((__t=( metric.value ))==null?'':__t)+
'</td>\r\n                        <td>'+
((__t=( model.get('info') ))==null?'':__t)+
'</td>\r\n                    </tr>\r\n                    ';
 }); 
__p+='\r\n                    ';
 }); 
__p+='\r\n                    </tbody>\r\n\r\n                    <tbody>\r\n                    <tr>\r\n                        <td style="border-right: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\r\n                            <div class="header">Gauges</div>\r\n                        </td>\r\n                        <td style="border-left: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\r\n                            <div class="header"></div>\r\n                        </td>\r\n                        <td style="border-left: 0; border-bottom: 1px solid rgba(0, 0, 0, .3);">\r\n                            <div class="header"></div>\r\n                        </td>\r\n                    </tr>\r\n                    </tbody>\r\n\r\n                    <tbody>\r\n                    ';
 _.each(collection.where({type: "GAUGE"}), (model) => {
                    _.each(model.get('metrics'), (metric) => {
                        let l = [];
                        _.each(Object.keys(metric.labels || {}), (label) => {
                            if (label === 'role' || label === 'shortname') {
                                return;
                            }
                            l.push(label + '=' + JSON.stringify(metric.labels[label]));
                        });
                        let labelString = ' {' + l.join(', ') + '}';
                        if (labelString.length <= 3) {
                            labelString = '';
                        }
                    
__p+='\r\n                    <tr>\r\n                        <td>'+
((__t=( model.get('name') + labelString ))==null?'':__t)+
'</td>\r\n                        <td class="alignRight">'+
((__t=( metric.value ))==null?'':__t)+
'</td>\r\n                        <td>'+
((__t=( model.get('info') ))==null?'':__t)+
'</td>\r\n                    </tr>\r\n                    ';
 }); 
__p+='\r\n                    ';
 }); 
__p+='\r\n                    </tbody>\r\n\r\n                </table>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="header child">Histograms</div>\r\n        <div class="pure-g" style="background: var(--c-primary-dark); padding-top: 10px;">\r\n            ';
 _.each(collection.where({type: "HISTOGRAM"}), (model) => {
            _.each(model.get('metrics'), (metric) => {
                let l = [];
                _.each(Object.keys(metric.labels || {}), (label) => {
                    if (label === 'role' || label === 'shortname') {
                        return;
                    }
                    l.push(label + '=' + JSON.stringify(metric.labels[label]));
                });
                let labelString = ' {' + l.join(', ') + '}';
                if (labelString.length <= 3) {
                    labelString = '';
                }
            
__p+='\r\n            <div id="'+
((__t=( name ))==null?'':__t)+
'" class="metricsBox pure-u-1-1 pure-u-sm-1-1 pure-u-md-1-2 pure-u-lg-1-3 pure-u-xl-1-3">\r\n                ';

                    let count = metric.count;
                    if (!metric.hasOwnProperty('count')) {
                        /* use value from highest bucket */
                        count = 0;
                        _.forEach(metric.buckets, (value, key) => {
                            try {
                                count = Number.parseFloat(value);
                            } catch (e) {
                                count = 'n/A';
                            }
                        });
                    }
                
__p+='\r\n                <div class="tableHeader">'+
((__t=( model.get("name") + labelString ))==null?'':__t)+
' ('+
((__t=( count ))==null?'':__t)+
')\r\n                    <div\r\n                            style="font-weight: 100">'+
((__t=( model.get("info") ))==null?'':__t)+
'</div>\r\n                </div>\r\n                <table class="pure-table pure-table-metrics-histogram">\r\n                    <thead>\r\n                    <tr>\r\n                        <th>Bucket</th>\r\n                        <th>Value</th>\r\n                    </tr>\r\n                    </thead>\r\n                    <tbody>\r\n\r\n                    ';
 _.forEach(metric.buckets, (value, key) => { 
__p+='\r\n                        <tr>\r\n                            <td class="alignRight">&lt;= '+
((__t=( key ))==null?'':__t)+
'</td>\r\n                            <td class="alignRight">'+
((__t=( value ))==null?'':__t)+
'</td>\r\n                        </tr>\r\n                    ';
 }); 
__p+='\r\n\r\n                    </tbody>\r\n                </table>\r\n            </div>\r\n            ';
 }); 
__p+='\r\n            ';
 }); 
__p+='\r\n\r\n        </div>\r\n    </div>\r\n    <div id="metricsAsText" style="'+
((__t=( textStyle ))==null?'':__t)+
'">\r\n        <pre>'+
((__t=( collection.metricsAsText() ))==null?'':__t)+
'</pre>\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
