import { createEvent, createStore } from "effector";
import { throttle } from "patronum";

export const resize = createEvent<[number, number]>()

export const throttledResize = throttle({
    source: resize,
    timeout: 300,
})

export const $screenSize = createStore([window.innerWidth, window.innerHeight])
  .on(throttledResize, (_, size) => size)
