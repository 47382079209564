/* jshint browser: true */
/* jshint unused: false */
/* global dbmsHelper, Backbone, window, $, _, frontendConfig */

(function () {
  'use strict';

  window.InfoView = Backbone.View.extend({
    el: '#content',
    initialize: function initialize(options) {
      this.relationName = options.relationName;
      this.model = this.collection;
    },
    events: {},
    render: function render() {
      var self = this;
      this.breadcrumb();
      window.dbmsHelper.buildRelationSubNav(this.relationName, 'Info');
      if (frontendConfig.isCluster) {
        var clusterData = {};
        var callbackShardCount = function callbackShardCount(error, data) {
          if (error) {
            dbmsHelper.dbmsError('Figures', 'Could not get figures.');
            // in error case: try to render the other information
            self.renderInfoView();
          }
          clusterData.shardCounts = data.count;
          self.renderInfoView(clusterData);
        };
        this.model.getShardCounts(callbackShardCount);
      } else {
        this.renderInfoView();
      }

      // check permissions and adjust views
      dbmsHelper.checkRelationPermissions(this.relationName, this.changeViewToReadOnly);
    },
    changeViewToReadOnly: function changeViewToReadOnly() {
      $('.breadcrumb').html($('.breadcrumb').html() + ' (read-only)');
    },
    breadcrumb: function breadcrumb() {
      $('#subNavigationBar .breadcrumb').html('<li><a>' + 'Relation: ' + _.escape(this.relationName.length > 64 ? this.relationName.substr(0, 64) + "..." : this.relationName) + '</a></li>');
    },
    renderInfoView: function renderInfoView(cluster) {
      if (this.model.get('locked')) {
        return 0;
      }
      var callbackRev = function (error, revision, figures) {
        if (error) {
          dbmsHelper.dbmsError('Figures', 'Could not get revision.');
        } else {
          var buttons = [];
          // analyze figures in cluster
          var tableContent = {
            figures: figures,
            revision: revision,
            model: this.model,
            cluster: cluster || {}
          };
          window.modalView.show('modalRelationInfo.ejs', 'Relation: ' + (this.model.get('name').length > 64 ? this.model.get('name').substr(0, 64) + "..." : this.model.get('name')), buttons, tableContent, null, null, null, null, null, 'content', true, true);
        }
      }.bind(this);
      var callback = function (error, data) {
        if (error) {
          dbmsHelper.dbmsError('Figures', 'Could not get figures.');
        } else {
          var figures = data;
          this.model.getRevision(callbackRev, figures);
        }
      }.bind(this);
      this.model.getFiguresCombined(callback, frontendConfig.isCluster);
    }
  });
})();