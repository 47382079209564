"use strict";

/**
 * ```ts
 * import type { Document, Edge } from "dbmsjs/documents";
 * ```
 *
 * The "documents" module provides document/edge related types for TypeScript.
 *
 * @packageDocumentation
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._documentHandle = void 0;
/**
 * @internal
 * @hidden
 */
function _documentHandle(selector, collectionName) {
  var strict = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  if (typeof selector !== "string") {
    if (selector._id) {
      return _documentHandle(selector._id, collectionName);
    }
    if (selector._key) {
      return _documentHandle(selector._key, collectionName);
    }
    throw new Error("Document handle must be a string or an object with a _key or _id attribute");
  }
  if (selector.includes("/")) {
    if (strict && !selector.startsWith("".concat(collectionName, "/"))) {
      throw new Error("Document ID \"".concat(selector, "\" does not match collection name \"").concat(collectionName, "\""));
    }
    return selector;
  }
  return "".concat(collectionName, "/").concat(selector);
}
exports._documentHandle = _documentHandle;