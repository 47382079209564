import { DocJson } from "../api/api"
import { getDefinition } from "./getDefinition"

export type ValidateError = {
    message: string,
    start: number,
    end: number,
}

const validateByType: {[key: string]: (value: any) => boolean} = {
    integer: (value: any) => typeof value === 'number' && Number.isInteger(value),
    boolean: (value: any) => typeof value === 'boolean',
    string: (value: any) => typeof value === 'string',
    number: (value: any) => typeof value === 'number',
}

export const validateJsonBySchema = (json: string, defName: string, doc: DocJson): ValidateError[] => {
    const definition = getDefinition(doc, defName)

    if (definition && definition.type === 'object') {
        try {
            const obj = JSON.parse(json)
            const errors: ValidateError[] = []

            if (!obj || typeof obj !== 'object') {
                return []
            }

            Object.keys(obj).forEach(key => {
                if (definition.properties && definition.properties[key]) {
                    const attrDefinition = definition.properties[key]
                    if (attrDefinition.type) {
                        const value = obj[key]
                        if (validateByType[attrDefinition.type] && !validateByType[attrDefinition.type](value)) {
                            const position = json.indexOf(`"${key}"`)
                            errors.push({
                                message: `${key} must be a ${attrDefinition.type}`,
                                start: position,
                                end: position + key.length + 2,
                            })
                        }
                    }
                }
            })

            definition.required && definition.required.forEach(key => {
                if (typeof obj[key] === 'undefined') {
                    errors.push({
                        message: `Required field \`${key}\` is missing`,
                        start: 0,
                        end: json.length,
                    })
                }
            })

            return errors
        } catch (e) {
            return []
        }
    }

    return []
}
