"use strict";

/**
 * Node.js implementation of the HTTP(S) request function.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
var _objectSpread = require("/mnt/build/js/apps/system/_admin/monitor/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRequest = exports.isBrowser = void 0;
var btoa_1 = require("./btoa");
var omit_1 = require("./omit");
var xhr_1 = require("./xhr");
exports.isBrowser = true;
/**
 * @internal
 */
function errorToJSON() {
  return {
    error: true,
    message: this.message
  };
}
/**
 * Create a function for performing requests against a given host.
 *
 * @param baseUrl - Base URL of the host, i.e. protocol, port and domain name.
 * @param agentOptions - Options to use for performing requests.
 *
 * @param baseUrl
 * @param agentOptions
 *
 * @internal
 * @hidden
 */
function createRequest(baseUrl, agentOptions) {
  var base = new URL(baseUrl);
  var auth = (0, btoa_1.base64Encode)("".concat(base.username || "root", ":").concat(base.password));
  base.username = "";
  base.password = "";
  var options = (0, omit_1.omit)(agentOptions, ["maxSockets"]);
  return function request(_ref, cb) {
    var method = _ref.method,
      reqUrl = _ref.url,
      headers = _ref.headers,
      body = _ref.body,
      timeout = _ref.timeout,
      expectBinary = _ref.expectBinary;
    var url = new URL(reqUrl.pathname, base);
    if (base.search || reqUrl.search) {
      url.search = reqUrl.search ? "".concat(base.search, "&").concat(reqUrl.search.slice(1)) : base.search;
    }
    if (!headers["authorization"]) {
      headers["authorization"] = "Basic ".concat(auth);
    }
    var _callback = function callback(err, res) {
      _callback = function callback() {
        return undefined;
      };
      cb(err, res);
    };
    var req = (0, xhr_1.default)(_objectSpread({
      useXDR: true,
      withCredentials: true
    }, options, {
      responseType: expectBinary ? "blob" : "text",
      url: String(url),
      body: body,
      method: method,
      headers: headers,
      timeout: timeout
    }), function (err, res) {
      if (!err) {
        var response = res;
        response.request = req;
        if (!response.body) response.body = "";
        if (options.after) {
          options.after(null, response);
        }
        _callback(null, response);
      } else {
        var error = err;
        error.request = req;
        error.toJSON = errorToJSON;
        if (options.after) {
          options.after(error);
        }
        _callback(error);
      }
    });
    if (options.before) {
      options.before(req);
    }
  };
}
exports.createRequest = createRequest;