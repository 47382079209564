import type { DocumentRelation, EdgeRelations } from "@nilfoundation/dbmsjs/build/relation";
import { createDomain } from "effector";
import { getDefaultDb } from "../utils/dbmsClient";
import { defaultSelectRelation } from "../utils/helpers";

const domain = createDomain("relations");

const createEffect = domain.createEffect.bind(domain);
const createStore = domain.createStore.bind(domain);


export const $relations = createStore<Array<DocumentRelation & EdgeRelations>>([])

export const fetchRelationDataFx = createEffect<string, {name: string, data: any[]}>()
export const fetchRelationsFx = createEffect<void, Array<DocumentRelation & EdgeRelations>>()

$relations.on(fetchRelationsFx.doneData, (_, relations) => relations.sort((a, b) => a.name.localeCompare(b.name)))

fetchRelationDataFx.use(async (relation: string) => {
  const db = getDefaultDb()
  const res = await db.query(defaultSelectRelation(relation))
  const result: any[] = []
  await res.forEach(item => {
    result.push(item)
  })
  return {name: relation, data: result}
})


fetchRelationsFx.use(async () => {
  const db = getDefaultDb()
  const response = await db.relations(true)
  return response
})
