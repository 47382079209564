/* jshint browser: true */
/* jshint unused: false */
/* global Backbone, window, arangoViewModel, dbmsHelper */
(function () {
  'use strict';

  window.DbmsViews = Backbone.Collection.extend({
    url: dbmsHelper.databaseUrl('/_api/view'),
    model: arangoViewModel,
    parse: function parse(response) {
      return response.result;
    }

    /*
    newIndex: function (object, callback) {
    }
    */
  });
})();