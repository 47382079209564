module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="install-foxx" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"\r\n     aria-hidden="true" style="display:none">\r\n    <div class="modal-header">\r\n        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\r\n        <a class="dbmsHeader">Install Service</a>\r\n    </div>\r\n    <div class="modal-body">\r\n        <table>\r\n            <tr>\r\n                <th class="relationTh">Name:</th>\r\n                <th class="relationTh"><strong>'+
((__t=( attributes.name ))==null?'':__t)+
'</strong></th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">&nbsp;</th>\r\n                <th class="relationTh">&nbsp;</th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Mount:</th>\r\n                <th class="relationTh">\r\n                    <input type="text" id="mount-point" name="mountpoint" value="/'+
((__t=( attributes.name ))==null?'':__t)+
'"\r\n                           placeholder="mount-path"/>\r\n                </th>\r\n                <th><a class="modalTooltips" title="The path where the app can be reached."><i\r\n                                class="fa fa-info-circle"></i></a></th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Version:</th>\r\n                <th class="relationTh">\r\n                    '+
((__t=( attributes.version ))==null?'':__t)+
'\r\n                <th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">System:</th>\r\n                <th class="relationTh">\r\n                    '+
((__t=( attributes.isSystem ? "Yes" : "No" ))==null?'':__t)+
'\r\n                <th>\r\n            </tr>\r\n        </table>\r\n    </div>\r\n    <div id="colFooter" class="modal-footer">\r\n        <button class="btn-success pull-right installFoxx">Install</button>\r\n        <button id="cancel" class="btn-danger pull-right">Cancel</button>\r\n    </div>\r\n</div>\r\n\r\n';
}
return __p;
};
