/*** IMPORTS FROM imports-loader ***/
(function() {

;
(function () {
  'use strict';

  sigma.utils.pkg('sigma.canvas.edges');

  /**
   * This method renders the edge as a dotted line.
   *
   * @param  {object}                   edge         The edge object.
   * @param  {object}                   source node  The edge source node.
   * @param  {object}                   target node  The edge target node.
   * @param  {CanvasRenderingContext2D} context      The canvas context.
   * @param  {configurable}             settings     The settings function.
   */
  sigma.canvas.edges.dotted = function (edge, source, target, context, settings) {
    var color = edge.active ? edge.active_color || settings('defaultEdgeActiveColor') : edge.color,
      prefix = settings('prefix') || '',
      size = edge[prefix + 'size'] || 1,
      edgeColor = settings('edgeColor'),
      defaultNodeColor = settings('defaultNodeColor'),
      defaultEdgeColor = settings('defaultEdgeColor');
    if (!color) switch (edgeColor) {
      case 'source':
        color = source.color || defaultNodeColor;
        break;
      case 'target':
        color = target.color || defaultNodeColor;
        break;
      default:
        color = defaultEdgeColor;
        break;
    }
    context.save();
    if (edge.active) {
      context.strokeStyle = settings('edgeActiveColor') === 'edge' ? color || defaultEdgeColor : settings('defaultEdgeActiveColor');
    } else {
      context.strokeStyle = color;
    }
    context.setLineDash([2]);
    context.lineWidth = size;
    context.beginPath();
    context.moveTo(source[prefix + 'x'], source[prefix + 'y']);
    context.lineTo(target[prefix + 'x'], target[prefix + 'y']);
    context.stroke();
    context.restore();
  };
})();
}.call(window));