import React, { useCallback, useRef } from 'react'
import {
  inputQuery,
  explain as explainEvent,
  query as queryEvent,
  setArgument,
  profile as profileEvent,
  $queryContainerStore,
} from '../model'
import { useStore } from 'effector-react'
import { Typography, Button, Input, Tooltip } from 'antd'
import classnames from 'clsx'
import { QueryEditor } from '../../../features/query-editor'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Explorer } from './Explorer'
import styles from '../styles.module.scss'

type QueryContainerProps = {
  height?: number
}

export const QueryContainer = ({ height }: QueryContainerProps) => {
  const onInput = useCallback((v) => {
    inputQuery(v)
  }, [])

  const queryCallback = useCallback(() => {
    queryEvent()
  }, [])
  const explainCallback = useCallback(() => {
    explainEvent()
  }, [])
  const profileCallback = useCallback(() => {
    profileEvent()
  }, [])

  const { query, params, syntaxError, queryPending, explainPending, profilePending, pending, relations } =
    useStore($queryContainerStore)

  const refTitle = useRef<HTMLElement>(null)
  const refButtons = useRef<HTMLDivElement>(null)

  const h =
    refTitle.current && refButtons.current && height
      ? height - refTitle.current.offsetHeight - refButtons.current.offsetHeight - 32
      : height

  return (
    <div
      className={classnames(
        styles.container,
        { [styles.container_params]: params.length > 0 },
        'test__query-container',
      )}
    >
      <div className={classnames(styles.input, 'test__query-editor-wrapper')}>
        <Typography.Title level={4} ref={refTitle}>
          Query &nbsp;
          <Tooltip title="API Doc">
            <QuestionCircleOutlined />
          </Tooltip>
        </Typography.Title>
        <QueryEditor
          value={query}
          relations={relations.map((r) => r.name)}
          error={syntaxError}
          onChange={onInput}
          height={h}
        />
        <div ref={refButtons} className={styles.input__buttons}>
          <Button
            type="primary"
            className='test__query-button'
            onClick={queryCallback}
            loading={queryPending}
            disabled={pending && !queryPending}
          >
            Query
          </Button>
          <Button
            type="ghost"
            className='test__explain-button'
            onClick={explainCallback}
            loading={explainPending}
            disabled={pending && !explainPending}
          >
            Explain
          </Button>
          <Button
            type="ghost"
            className='test__profile-button'
            onClick={profileCallback}
            loading={profilePending}
            disabled={pending && !profilePending}
          >
            Profile
          </Button>
        </div>
      </div>
      {params.length > 0 && (
        <div>
          <Typography.Title level={4}>Params</Typography.Title>
          <div className={classnames(styles.params, 'test__param-list')}>
            {params.map((param) => (
              <div key={param.name} className={classnames(styles.params__row, 'test__param-item')}>
                <div className={styles.params__name}>
                  <Typography.Text className='test__param-name'>{param.name}</Typography.Text>
                </div>
                <div className={styles.params__input}>
                  <Input
                    className='test__param-input'
                    value={param.value}
                    onChange={(e) => void setArgument({ name: param.name, value: e.target.value })}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.relations}>
        <Explorer />
      </div>
    </div>
  )
}
