/* jshint browser: true */
/* jshint unused: false */
/* global Backbone, location, document, _, dbmsHelper, window, $, templateEngine, frontendConfig */

(function () {
  'use strict';

  window.LoginView = Backbone.View.extend({
    el: '#content',
    el2: '.header',
    el3: '.footer',
    loggedIn: false,
    loginCounter: 0,
    events: {
      'keyPress #loginForm input': 'keyPress',
      'click #submitLogin': 'validate',
      'click #logout': 'logout',
      'click #triggerVisibilityToggle': 'passwordInputToggleVisibility'
    },
    template: templateEngine.createTemplate('loginView.ejs'),
    render: function render(loggedIn) {
      var self = this;
      $(this.el).html(this.template.render({}));
      $(this.el2).hide();
      $(this.el3).hide();
      var continueRender = function continueRender(user, errCallback, debug) {
        var url;
        if (!user) {
          url = dbmsHelper.databaseUrl('/_api/database/user');
        } else {
          url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database', '_system');
        }
        $.ajax({
          type: "GET",
          url: url,
          success: function success() {
            // enable db select and login button
            self.goTo();
          },
          error: function error(e) {
            if (errCallback) {
              errCallback();
            } else {
              // existing jwt login is not valid anymore => reload
              dbmsHelper.setCurrentJwt(null, null);
              location.reload(true);
            }
          }
        });
      };
      if (frontendConfig.authenticationEnabled && loggedIn !== true) {
        var usr = dbmsHelper.getCurrentJwtUsername();
        if (usr !== null && usr !== 'undefined' && usr !== undefined) {
          // try if existent jwt is valid
          var errCallback = function errCallback() {
            self.collection.logout();
            window.setTimeout(function () {
              $('#loginUsername').focus();
            }, 300);
          };
          continueRender(dbmsHelper.getCurrentJwtUsername(), errCallback);
        } else {
          window.setTimeout(function () {
            $('#loginUsername').focus();
          }, 300);
        }
      } else if (frontendConfig.authenticationEnabled && loggedIn) {
        continueRender(dbmsHelper.getCurrentJwtUsername(), null, 4);
      } else {
        continueRender(null, null, 3);
      }
      $('.bodyWrapper').show();
      return this;
    },
    clear: function clear() {
      $('#loginForm input').removeClass('form-error');
      $('.wrong-credentials').hide();
    },
    keyPress: function keyPress(e) {
      if (e.ctrlKey && e.keyCode === 13) {
        e.preventDefault();
        this.validate();
      } else if (e.metaKey && e.keyCode === 13) {
        e.preventDefault();
        this.validate();
      }
    },
    validate: function validate(event) {
      event.preventDefault();
      this.clear();
      var username = $('#loginUsername').val();
      var password = $('#loginPassword').val();
      if (!username) {
        // do not send unneccessary requests if no user is given
        return;
      }
      this.collection.login(username, password, this.loginCallback.bind(this, username, password));
    },
    loginCallback: function loginCallback(username, password, error) {
      var self = this;
      if (error) {
        if (self.loginCounter === 0) {
          self.loginCounter++;
          self.collection.login(username, password, this.loginCallback.bind(this, username));
          return;
        }
        self.loginCounter = 0;
        $('.wrong-credentials').show();
        $('#loginDatabase').html('');
        $('#loginDatabase').append('<option>_system</option>');
      } else {
        var url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(username) + '/database', '_system');
        if (frontendConfig.authenticationEnabled === false) {
          url = dbmsHelper.databaseUrl('/_api/database/user');
        }
        $('.wrong-credentials').hide();
        self.loggedIn = true;
        $.ajax({
          type: "GET",
          url: url,
          success: function success() {
            self.goTo();
          },
          error: function error() {
            $('.wrong-credentials').show();
          }
        });
      }
    },
    logout: function logout() {
      this.collection.logout();
    },
    goTo: function goTo() {
      var username = $('#loginUsername').val();
      var database = frontendConfig.db;
      var callback2 = function callback2(error) {
        if (error) {
          dbmsHelper.dbmsError('User', 'Could not fetch user settings');
        }
      };
      var path = window.location.protocol + '//' + window.location.host + frontendConfig.basePath + '/_db/' + encodeURIComponent(database) + '/_admin/monitor/index.html';
      if (frontendConfig.react) {
        path = window.location.protocol + '//' + window.location.host + '/_db/' + encodeURIComponent(database) + '/_admin/monitor/index.html';
      }
      if (frontendConfig.static) {
        path = window.location.protocol + '//' + window.location.host + '/index.html';
      }
      var continueFunction = function () {
        window.location.href = frontendConfig.static ? '/' : path;
        $(this.el2).show();
        $(this.el3).show();
        $('.bodyWrapper').show();
        $('.navbar').show();
        $('#currentUser').text(username);
        this.collection.loadUserSettings(callback2);
      }.bind(this);
      $.ajax({
        url: path,
        success: function success(data) {
          continueFunction();
        },
        error: function error(data) {
          $('#noAccess').show();
        }
      });
    },
    passwordInputToggleVisibility: function passwordInputToggleVisibility() {
      var input = $('#loginPassword');
      var icon = $('#triggerVisibilityToggle');
      var lockedIconClass = 'fa-eye-slash';
      var unlockedIconClass = 'fa-eye';
      var isPassword = input.prop('type') === 'password';
      if (isPassword) {
        input.prop('type', 'text');
        icon.addClass(unlockedIconClass);
        icon.removeClass(lockedIconClass);
        return;
      }
      input.prop('type', 'password');
      icon.addClass(lockedIconClass);
      icon.removeClass(unlockedIconClass);
    }
  });
})();