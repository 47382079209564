module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="currentInfoBox" class="infoBox" style="margin-top: 10px; margin-bottom: 30px; background: #fff">\n    <h4>'+
((__t=( title ))==null?'':__t)+
'</h4>\n    <p>'+
((__t=( message ))==null?'':__t)+
'</p>\n</div>';
}
return __p;
};
