module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var genClass = 'pure-u-1-3'; 
__p+='\r\n';
 var genClass2 = 'pure-u-2-3'; 
__p+='\r\n\r\n';
 var formatName = function(name) { 
__p+='\r\n    ';
 return name.charAt(0).toUpperCase() + string.slice(1); 
__p+='\r\n';
 }; 
__p+='\r\n\r\n<div id="graphSettingsView" class="innerContent">\r\n    <div class="pure-g" style="margin-top: -13px">\r\n        <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\r\n            <div class="pure-g pure-table pure-table-body">\r\n\r\n                ';
 _.each(general, function(val, key) { 
__p+='\r\n                    ';
 if (val.type === 'divider') { 
__p+='\r\n                    <div class="pure-u-1-1 left heading">'+
((__t=( val.name ))==null?'':__t)+
'</div>\r\n                    ';
 } else { 
__p+='\r\n                        <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">\r\n                            '+
((__t=( val.name ))==null?'':__t)+
'\r\n                        </div>\r\n                        <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">\r\n\r\n                            ';
 if (val.type === 'select') { 
__p+='\r\n                            <select id="g_'+
((__t=( key ))==null?'':__t)+
'">\r\n                                ';
 _.each(val, function(option, optKey) { 
__p+='\r\n                                    ';
 if (option.name) { 
__p+='\r\n                                    <option value="'+
((__t=( option.val ))==null?'':__t)+
'"> '+
((__t=( option.name ))==null?'':__t)+
' </option>\r\n                                    ';
 } 
__p+='\r\n                                ';
 }); 
__p+='\r\n                            </select>\r\n                            ';
 } 
__p+='\r\n\r\n                            ';
 if (val.type === 'string') { 
__p+='\r\n                            <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type="text" placeholder="attribute"></input>\r\n                            ';
 } 
__p+='\r\n\r\n                            ';
 if (val.type === 'number') { 
__p+='\r\n                            <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type="text" id="'+
((__t=( val ))==null?'':__t)+
'" value="'+
((__t=( val.value ))==null?'':__t)+
'"\r\n                                   placeholder=""></input>\r\n                            ';
 } 
__p+='\r\n\r\n                            ';
 if (val.type === 'range') { 
__p+='\r\n                            <label id="g_'+
((__t=( key ))==null?'':__t)+
'_label" class="rangeLabel">1</label>\r\n                            <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type=\'range\' min="0" max="9" val="1"/>\r\n                            ';
 } 
__p+='\r\n\r\n                            ';
 if (val.type === 'color') { 
__p+='\r\n                            <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type=\'color\' name=\'color\' value="'+
((__t=( VALUE ))==null?'':__t)+
'"/>\r\n                            ';
 } 
__p+='\r\n\r\n                            <i title="'+
((__t=( val.desc ))==null?'':__t)+
'" class="fa fa-info-circle gv-tooltips" aria-hidden="true"></i>\r\n                        </div>\r\n                    ';
 } 
__p+='\r\n\r\n                ';
 }); 
__p+='\r\n            </div>\r\n        </div>\r\n\r\n        <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\r\n            <div class="pure-g pure-table pure-table-body">\r\n                ';
 _.each(specific, function(val, key) { 
__p+='\r\n\r\n                    ';
 if (val.hide !== 'true') { 
__p+='\r\n                        ';
 if (val.type === 'divider') { 
__p+='\r\n                        <div class="pure-u-1-1 left heading">'+
((__t=( val.name ))==null?'':__t)+
'</div>\r\n                        ';
 } else { 
__p+='\r\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">\r\n                                '+
((__t=( val.name ))==null?'':__t)+
'\r\n                            </div>\r\n\r\n                            <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">\r\n\r\n                                ';
 var VALUE; 
__p+='\r\n                                ';
 if (val.value) { 
__p+='\r\n                                    ';
 VALUE = val.value 
__p+='\r\n                                ';
 } else { 
__p+='\r\n                                    ';
 VALUE = val.default 
__p+='\r\n                                ';
 } 
__p+='\r\n\r\n                                ';
 if (val.type === 'string') { 
__p+='\r\n                                <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type="text" placeholder="attribute"></input>\r\n                                ';
 } 
__p+='\r\n\r\n                                ';
 if (val.type === 'number') { 
__p+='\r\n                                <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type="text" placeholder="number"></input>\r\n                                ';
 } 
__p+='\r\n\r\n                                ';
 if (val.type === 'color') { 
__p+='\r\n                                <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type=\'color\' name=\'color\' value="'+
((__t=( VALUE ))==null?'':__t)+
'"/>\r\n                                ';
 } 
__p+='\r\n\r\n                                ';
 if (val.type === 'range') { 
__p+='\r\n                                <label id="g_'+
((__t=( key ))==null?'':__t)+
'_label" class="rangeLabel">1</label>\r\n                                <input id="g_'+
((__t=( key ))==null?'':__t)+
'" type=\'range\' min="0" max="9" val="1"/>\r\n                                ';
 } 
__p+='\r\n\r\n                                ';
 if (val.type === 'select') { 
__p+='\r\n                                <select id="g_'+
((__t=( key ))==null?'':__t)+
'">\r\n                                    ';
 _.each(val, function(option, optKey) { 
__p+='\r\n                                        ';
 if (option.name) { 
__p+='\r\n                                        <option value="'+
((__t=( option.val ))==null?'':__t)+
'"> '+
((__t=( option.name ))==null?'':__t)+
' </option>\r\n                                        ';
 } 
__p+='\r\n                                    ';
 }); 
__p+='\r\n                                </select>\r\n                                ';
 } 
__p+='\r\n                                <i title="'+
((__t=( val.desc ))==null?'':__t)+
'" class="fa fa-info-circle gv-tooltips" aria-hidden="true"></i>\r\n                            </div>\r\n                        ';
 } 
__p+='\r\n                    ';
 } 
__p+='\r\n                ';
 }); 
__p+='\r\n            </div>\r\n\r\n            <div class="pure-u-1-1">\r\n                <button id="saveGraphSettings" style="margin-top: 20px; margin-right: 10px;"\r\n                        class="btn-success pull-right">Save\r\n                </button>\r\n                <button id="restoreGraphSettings" style="margin-top: 20px;" class="btn-success pull-right">Restore\r\n                    defaults\r\n                </button>\r\n            </div>\r\n        </div>\r\n    </div>\r\n\r\n';
}
return __p;
};
