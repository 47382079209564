module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="application-detail-view">\r\n    <div class="headerBar" style="width: 100%">\r\n        <ul class="subMenuEntries subViewNavbar pull-left">\r\n        </ul>\r\n    </div>\r\n    <section class="info" id="information" style="width: 100%;">\r\n        <div class="pure-g">\r\n            <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-3-4 foxx-left">\r\n                <div class="foxx-icon-container pull-left">\r\n                    <img src="'+
((__t=( image ))==null?'':__t)+
'" alt="Icon for Service" class="icon"/>\r\n                </div>\r\n                <div class="pull-left" style="margin-left: 15px">\r\n                    <h3>\r\n                        '+
((__t=( app.attributes.name ))==null?'':__t)+
'\r\n                    </h3>\r\n                    <p class="description">'+
((__t=( app.attributes.description ))==null?'':__t)+
'</p>\r\n                </div>\r\n                <div class="categoryBar">\r\n                    <h4>Readme</h4>\r\n                </div>\r\n                <div id="readme" class="readme">\r\n                </div>\r\n            </div>\r\n            <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-1-4 foxx-right">\r\n                <div class="foxx-label-container">\r\n                    <p>\r\n                        <span>Author: </span><span>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span>Version: </span><span class="version">'+
((__t=( app.attributes.latestVersion ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span>GitHub: </span><span><a\r\n                                    href="https://www.github.com/'+
((__t=( app.attributes.location ))==null?'':__t)+
'">'+
((__t=( app.attributes.location ))==null?'':__t)+
'</a></span>\r\n                    </p>\r\n                    <p>\r\n                        ';
 if (app.attributes.license) { 
__p+='\r\n                            <span>License: </span><span>'+
((__t=( app.attributes.license ))==null?'':__t)+
'</span>\r\n                        ';
 } 
__p+='\r\n                    </p>\r\n                    <p>\r\n                        <span>Categories: </span><span>'+
((__t=( app.attributes.categories ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span></span>\r\n                    </p>\r\n                </div>\r\n                <div class="header_right">\r\n                    <input id="installService" type="button" class="btn-success" value="Install Service"/>\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </section>\r\n</div>\r\n';
}
return __p;
};
