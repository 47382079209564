import React, { useMemo } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  ReplicationType,
  replicationTypes,
  replicationTypesDictionary,
  sampleByReplicationType,
  toReplicationType,
} from '../../../constants/replicationTypes'
import { $connectorError, $isOpenAddModal, addConnectorFx, closeAddModal, Connector, submitAddModal } from '../model'
import { Input, Modal, Select, Typography, Segmented, Switch, InputNumber, Alert } from 'antd'
import { useStore } from 'effector-react'
import styles from './form.module.scss'
import { ConfigEditor } from '../../../features/config-editor'
import { ConnectionStatus } from '../../../features/config-editor/ConnectionStatus'
import { Field } from './Field'

// base validation
const AddConnectorFormSchema = Yup.object().shape({
  type: Yup.number().oneOf(Object.values(replicationTypes)).required('Required'),
  kind: Yup.string().oneOf(['Simple', 'Advanced']).required('Required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {}

// fields that depends on replication type
type ConfigOption =
  | {
      label: string
      name: keyof Connector
      type: 'string'
      required: boolean
      defaultValue?: string
    }
  | {
      label: string
      name: keyof Connector
      type: 'number'
      required: boolean
      defaultValue?: number
    }
  | {
      label: string
      name: keyof Connector
      type: 'boolean'
      required: boolean
      defaultValue?: boolean
    }

const connectorTypeOptions: Map<ReplicationType, ConfigOption[]> = new Map()
connectorTypeOptions.set(ReplicationType.bitcoin, [
  {
    label: 'Bind address',
    name: 'bind_address',
    type: 'string',
    required: true,
  },
  {
    label: 'Connect',
    name: 'connect',
    type: 'string',
    required: false,
  },
])
connectorTypeOptions.set(ReplicationType.follower, [
  {
    label: 'Endpoint',
    name: 'endpoint',
    type: 'string',
    required: true,
  },
  {
    label: 'Username',
    name: 'username',
    type: 'string',
    required: false,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'string',
    required: false,
  },
])

const kindOptions = ['Simple', 'Advanced']
export const ConnectorForm = () => {
  const options = useMemo(
    () => replicationTypes.map((value) => ({ label: replicationTypesDictionary.get(value), value })),
    [],
  )
  const isModalOpen = useStore($isOpenAddModal)
  const isAddLoading = useStore(addConnectorFx.pending)
  const connectorError = useStore($connectorError)

  return (
    <Formik<Connector & { config: { [key in ReplicationType]: string } }>
      initialValues={{
        type: 1,
        database: '_system',
        bind_address: '127.0.0.1:8529',
        endpoint: '',
        connect: '',
        username: '',
        password: '',
        kind: 'Simple',
        global: true,
        config: sampleByReplicationType,
      }}
      validationSchema={AddConnectorFormSchema}
      validate={(values) => {
        let errors = {}
        if (values.kind === 'Simple') {
          if (values.type === ReplicationType.follower) {
            if (!values.endpoint) {
              errors = {
                ...errors,
                endpoint: 'Required',
              }
            }
            if (!(values.global || values.database)) {
              errors = {
                ...errors,
                database: 'Required',
              }
            }
          }
          if (values.type === ReplicationType.bitcoin) {
            if (!values.bind_address) {
              errors = {
                ...errors,
                bind_address: 'Required',
              }
            }
          }
        }
        return errors
      }}
      onSubmit={(values) => {
        const replicationType = toReplicationType(values.type)

        const { kind, config, ...rest } = values
        const refinedKind: 'Simple' | 'Advanced' = kind === 'Simple' ? 'Simple' : 'Advanced'
        let configurationObj = {}
        if (kind === 'Advanced') {
          configurationObj = JSON.parse(config[replicationType])
        }
        const connector: Connector = {
          ...rest,
          kind: refinedKind,
          endpoint: (values.endpoint || '').replace('http://', 'tcp://'),
          configuration: configurationObj,
        }

        submitAddModal(connector)
      }}
    >
      {({ handleSubmit, getFieldProps, getFieldMeta }) => (
        <Modal
          title="Configure replication"
          open={isModalOpen}
          onCancel={() => closeAddModal()}
          okText="Apply Replication"
          onOk={() => handleSubmit()}
          confirmLoading={isAddLoading}
        >
          <form onSubmit={handleSubmit}>
            <div className={styles.form}>
              <Field
                label="Type"
                field={getFieldProps('type')}
                meta={getFieldMeta('type')}
                renderValue={(field) => (
                  <Select
                    defaultValue={2}
                    className={styles.form__input}
                    options={options}
                    value={field.value}
                    onChange={(value) => field.onChange({ target: { value, name: field.name } })}
                  />
                )}
              />
              {getFieldProps('type').value === ReplicationType.follower && (
                <Field
                  label="Global"
                  field={getFieldProps('global')}
                  meta={getFieldMeta('global')}
                  renderValue={(field) => (
                    <Switch
                      checked={field.value}
                      onChange={(value) =>
                        field.onChange({
                          target: { value, name: field.name },
                        })
                      }
                    />
                  )}
                />
              )}
              {(getFieldProps('type').value === ReplicationType.bitcoin || getFieldProps('global').value === false) && (
                <Field label="Database" field={getFieldProps('database')} meta={getFieldMeta('database')} />
              )}
              <Field
                label={<Typography.Text strong>Configuration</Typography.Text>}
                field={getFieldProps('kind')}
                meta={getFieldMeta('kind')}
                renderValue={(field) => (
                  <Segmented
                    options={kindOptions}
                    value={field.value}
                    defaultValue={kindOptions[0]}
                    name={field.name}
                    onChange={(value) => {
                      field.onChange({ target: { value, name: field.name } })
                    }}
                    onResize={noOp}
                    onResizeCapture={noOp}
                  />
                )}
              />
              {getFieldProps('kind').value === 'Advanced' && (
                <Field
                  label={null}
                  field={getFieldProps(`config.${getFieldProps('type').value}`)}
                  meta={getFieldMeta(`config.${getFieldProps('type').value}`)}
                  renderValue={(field) => (
                    <ConfigEditor
                      value={field.value}
                      onChange={(v) =>
                        field.onChange({
                          target: { value: v, name: field.name },
                        })
                      }
                      height={300}
                      definitionName={
                        getFieldProps('type').value === ReplicationType.follower
                          ? 'put_api_replication_applier_adjust'
                          : undefined
                      }
                    />
                  )}
                />
              )}
              {getFieldProps('kind').value === 'Simple' &&
                (connectorTypeOptions.get(getFieldProps('type').value) || []).map((option) => (
                  <Field
                    label={option.label}
                    field={getFieldProps(option.name)}
                    meta={getFieldMeta(option.name)}
                    renderValue={(field) => {
                      switch (option.type) {
                        case 'boolean':
                          return (
                            <Switch
                              checked={field.value}
                              onChange={(v) => field.onChange({ target: { value: v, name: field.name } })}
                            />
                          )
                        case 'number':
                          return (
                            <InputNumber
                              name={field.name}
                              className={styles.form__input}
                              value={field.value}
                              onBlur={field.onBlur}
                              onChange={(e) => field.onChange({ target: { value: e.target.value, name: field.name } })}
                            />
                          )
                        default:
                          return (
                            <Input
                              name={field.name}
                              className={styles.form__input}
                              value={field.value}
                              onBlur={field.onBlur}
                              onChange={(e) => {
                                field.onChange({ target: { value: e.target.value, name: field.name } })
                              }}
                            />
                          )
                      }
                    }}
                  />
                ))}
              {getFieldProps('kind').value === 'Simple' && getFieldProps('type').value === ReplicationType.follower && (
                <Field
                  label={'Connection status'}
                  field={getFieldProps('connection_status')}
                  meta={getFieldMeta('connection_status')}
                  renderValue={() => {
                    return (
                      <ConnectionStatus
                        url={getFieldMeta('endpoint').touched ? `${getFieldProps('endpoint').value}` : ''}
                        username={getFieldMeta('username').touched ? `${getFieldProps('username').value}` : ''}
                        password={getFieldMeta('password').touched ? `${getFieldProps('password').value}` : ''}
                      />
                    )
                  }}
                />
              )}
              {connectorError && (
                <div className={styles.group}>
                  <div className={styles.form__value}>
                    <Alert className={styles.form__alert} type='error' message={connectorError} showIcon />
                  </div>
                </div>
              )}
            </div>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
