module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td class="dbms-table-td table-cell1">'+
((__t=( model.get('name') ))==null?'':__t)+
'</td>\r\n<td class="dbms-table-td table-cell3">'+
((__t=( model.get('status') ))==null?'':__t)+
'</td>\r\n';
}
return __p;
};
