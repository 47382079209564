import React, { useEffect, useMemo, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json'
import { linter } from '@codemirror/lint'
import { aura } from '@uiw/codemirror-theme-aura'
import { EditorView, hoverTooltip } from '@codemirror/view'
import { validateJsonBySchema } from '../../utils/validateJsonBySchema';
import { DocJson, fetchApiDoc } from '../../api/api';

type ConfigEditorProps = {
  value: string;
  onChange: (value: string) => void;
  definitionName?: string;
  height?: number;
}

export const ConfigEditor = ({value, onChange, definitionName, height}: ConfigEditorProps) => {
    const [doc, setDoc] = useState<DocJson| null>(null);
    useEffect(() => {
        fetchApiDoc().then(setDoc)
    }, [])

  const extensions = useMemo(() => {
    const list = [
      json(),
      linter(jsonParseLinter(), {
        delay: 500,
      }),
      EditorView.lineWrapping,
    ];
    if (doc && definitionName) {
        const definition = doc.definitions[definitionName];
        list.push(
            linter((view) => {
                const text = view.state.doc.toJSON().join('\n')
                try {
                    JSON.parse(text);
                    const errors = validateJsonBySchema(text, definitionName, doc);
                    return errors.map(error => ({
                        from: error.start,
                        to: error.end,
                        message: error.message,
                        severity: 'error'
                    }))
                } catch (e) {
                    return []
                }
            }, {delay: 500})
        )
        list.push(
            hoverTooltip((view, pos) => {
                let {from, text} = view.state.doc.lineAt(pos)
                const linePos = pos - from
                const beforeQuote = text.indexOf('"', linePos - text.length)
                const afterQuote = text.indexOf('"', linePos)
                if (afterQuote === -1 || beforeQuote === -1) {
                    return null
                }

                const attrName = text.slice(beforeQuote + 1, afterQuote)
                if (definition.properties && definition.properties[attrName]) {
                    const attr = definition.properties[attrName]
                    if (attr && attr.description) {
                        return {
                            pos: from + beforeQuote,
                            end: from + afterQuote,
                            above: true,
                            create() {
                                let dom = document.createElement("div")
                                dom.innerHTML = (attr.description || '').replace(/\n/g, '<br/>')
                                return {dom}
                            }
                        }
                    }
                }
                return null
            })
        )
    }
    return list
  }, [definitionName, doc])

  return <CodeMirror
    theme={aura}
    value={value}
    height={height ? `${height}px` : ''}
    onChange={onChange}
    extensions={extensions}
  />
}
