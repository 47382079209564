/* jshint browser: true */
/* jshint unused: false */
/* global frontendConfig, dbmsHelper, Joi, Backbone, window, $, _ */

(function () {
  'use strict';

  window.SettingsView = Backbone.View.extend({
    el: '#content',
    readOnly: false,
    initialize: function initialize(options) {
      this.relationName = options.relationName;
      this.model = this.collection;
    },
    events: {},
    render: function render() {
      this.breadcrumb();
      dbmsHelper.buildRelationSubNav(this.relationName, 'Settings');
      this.renderSettings();
    },
    breadcrumb: function breadcrumb() {
      $('#subNavigationBar .breadcrumb').html('<li><a>' + 'Relation: ' + _.escape(this.relationName.length > 64 ? this.relationName.substr(0, 64) + "..." : this.relationName) + '</a></li>');
    },
    truncateRelation: function truncateRelation() {
      if (!this.readOnly) {
        this.model.truncateRelation();
        $('.modal-delete-confirmation').hide();
        window.modalView.hide();
      }
    },
    warmupCollection: function warmupCollection() {
      if (!this.readOnly) {
        this.model.warmupCollection();
        $('.modal-delete-confirmation').hide();
        window.modalView.hide();
      }
    },
    deleteCollection: function deleteCollection() {
      if (!this.readOnly) {
        this.model.destroy({
          error: function error(_, data) {
            dbmsHelper.dbmsError('Could not drop relation: ' + data.responseJSON.errorMessage);
          },
          success: function success() {
            window.App.navigate('#relations', {
              trigger: true
            });
          }
        });
      }
    },
    saveModifiedCollection: function saveModifiedCollection() {
      if (!this.readOnly) {
        var callback = function (error, isCoordinator) {
          if (error) {
            dbmsHelper.dbmsError('Error', 'Could not get coordinator info');
          } else {
            var newname;
            if (isCoordinator) {
              newname = this.model.get('name');
            } else {
              newname = $('#change-relation-name').val();
            }
            var self = this;
            var callbackChange = function callbackChange(error, data) {
              if (error) {
                self.render();
                dbmsHelper.dbmsError('Relation error: ' + data.responseJSON.errorMessage);
              } else {
                dbmsHelper.dbmsNotification('Relation: ' + 'Successfully changed.');
                window.App.navigate('#cSettings/' + newname, {
                  trigger: true
                });
              }
            };
            var callbackRename = function (error) {
              var abort = false;
              if (error) {
                dbmsHelper.dbmsError('Relation error: ' + error.responseText);
              } else {
                var wfs = $('#change-relation-sync').val();
                var replicationFactor;
                var writeConcern;
                if (frontendConfig.isCluster) {
                  replicationFactor = $('#change-replication-factor').val();
                  writeConcern = $('#change-write-concern').val();
                  try {
                    if (Number.parseInt(writeConcern) > Number.parseInt(replicationFactor)) {
                      // validation here, as our Joi integration misses some core features
                      dbmsHelper.dbmsError("Change Relation", "Write concern is not allowed to be greater than replication factor");
                      abort = true;
                    }
                  } catch (ignore) {}
                }
                if (!abort) {
                  this.model.changeRelation(wfs, replicationFactor, writeConcern, callbackChange);
                }
              }
            }.bind(this);
            if (frontendConfig.isCluster === false) {
              this.model.renameRelation(newname, callbackRename);
            } else {
              callbackRename();
            }
          }
        }.bind(this);
        window.isCoordinator(callback);
      }
    },
    changeViewToReadOnly: function changeViewToReadOnly() {
      window.App.settingsView.readOnly = true;
      $('.breadcrumb').html($('.breadcrumb').html() + ' (read-only)');
      // this method disables all write-based functions
      $('.modal-body input').prop('disabled', 'true');
      $('.modal-body select').prop('disabled', 'true');
      $('.modal-footer button').addClass('disabled');
      $('.modal-footer button').unbind('click');
    },
    renderSettings: function renderSettings() {
      var self = this;
      var callback = function (error, isCoordinator) {
        if (error) {
          dbmsHelper.dbmsError('Error', 'Could not get coordinator info');
        } else {
          var buttons = [];
          var tableContent = [];
          if (!isCoordinator) {
            if (this.model.get('name').substr(0, 1) === '_') {
              tableContent.push(window.modalView.createReadOnlyEntry('change-relation-name', 'Name', this.model.get('name'), false, '', true, [{
                rule: Joi.string().regex(/^[a-zA-Z]/),
                msg: 'Relation name must always start with a letter.'
              }, {
                rule: Joi.string().regex(/^[a-zA-Z0-9\-_]*$/),
                msg: 'Only Symbols "_" and "-" are allowed.'
              }, {
                rule: Joi.string().required(),
                msg: 'No relation name given.'
              }]));
            } else {
              tableContent.push(window.modalView.createTextEntry('change-relation-name', 'Name', this.model.get('name'), false, '', true, [{
                rule: Joi.string().regex(/^[a-zA-Z]/),
                msg: 'Relation name must always start with a letter.'
              }, {
                rule: Joi.string().regex(/^[a-zA-Z0-9\-_]*$/),
                msg: 'Only Symbols "_" and "-" are allowed.'
              }, {
                rule: Joi.string().required(),
                msg: 'No relation name given.'
              }]));
            }
          }
          var after = function () {
            tableContent.push(window.modalView.createReadOnlyEntry('change-relation-id', 'ID', this.model.get('id'), ''));
            tableContent.push(window.modalView.createReadOnlyEntry('change-relation-type', 'Type', this.model.get('type'), ''));
            tableContent.push(window.modalView.createReadOnlyEntry('change-relation-status', 'Status', this.model.get('status'), ''));
            buttons.push(window.modalView.createDeleteButton('Delete', this.deleteCollection.bind(this)));
            buttons.push(window.modalView.createDeleteButton('Truncate', this.truncateRelation.bind(this)));
            if (frontendConfig.engine === 'dcdb') {
              buttons.push(window.modalView.createNotificationButton('Load Indexes into Memory', this.warmupCollection.bind(this)));
            }
            buttons.push(window.modalView.createSuccessButton('Save', this.saveModifiedCollection.bind(this)));
            var tabBar = ['General', 'Indexes'];
            var templates = ['modalTable.ejs', 'indicesView.ejs'];
            window.modalView.show(templates, 'Modify Relation', buttons, tableContent, null, null, this.events, null, tabBar, 'content', true);
            $($('#infoTab').children()[1]).remove();
          }.bind(this);
          var callback2 = function callback2(error, data) {
            if (error) {
              dbmsHelper.dbmsError('Relation', 'Could not fetch properties');
            } else {
              var wfs = data.waitForSync;
              if (data.replicationFactor && frontendConfig.isCluster) {
                if (data.replicationFactor === 'satellite') {
                  tableContent.push(window.modalView.createReadOnlyEntry('change-replication-factor', 'Replication factor', data.replicationFactor, 'This relation is a SatelliteRelation. The replicationFactor is not' + ' changeable.', '', true));
                  tableContent.push(window.modalView.createReadOnlyEntry('change-write-concern', 'Write concern', JSON.stringify(data.writeConcern), 'This relation is a SatelliteRelation. The write concern is not' + ' changeable.', '', true));
                } else {
                  tableContent.push(window.modalView.createTextEntry('change-replication-factor', 'Replication factor', data.replicationFactor, 'The replicationFactor parameter is the total number of copies being kept, that is, it is one plus the number of followers. Must be a number.', '', true, [{
                    rule: Joi.string().allow('').optional().regex(/^[0-9]*$/),
                    msg: 'Must be a number.'
                  }]));
                  tableContent.push(window.modalView.createTextEntry('change-write-concern', 'Write concern', data.writeConcern, 'Numeric value. Must be at least 1. Must be smaller or equal compared to the replication factor. Total number of copies of the data in the cluster that are required for each write operation. If we get below this value the relation will be read-only until enough copies are created.', '', true, [{
                    rule: Joi.string().allow('').optional().regex(/^[1-9]*$/),
                    msg: 'Must be a number. Must be at least 1 and has to be smaller or equal compared to the replicationFactor.'
                  }]));
                }
              }

              // prevent "unexpected sync method error"
              tableContent.push(window.modalView.createSelectEntry('change-relation-sync', 'Wait for sync', wfs, 'Synchronize to disk before returning from a create or update of a document.', [{
                value: false,
                label: 'No'
              }, {
                value: true,
                label: 'Yes'
              }]));
            }
            after();

            // check permissions and adjust views
            dbmsHelper.checkRelationPermissions(self.relationName, self.changeViewToReadOnly.bind(this));
          };
          this.model.getProperties(callback2);
        }
      }.bind(this);
      window.isCoordinator(callback);
    }
  });
})();