module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

let advancedCounter = 2;

function createInput(row) {
        switch(row.type) {
case 'text':

__p+='\r\n<input type="text" class="form-control" id="'+
((__t=( row.id ))==null?'':__t)+
'" value="'+
((__t=( _.escape(row.value || '') ))==null?'':__t)+
'"\r\n       placeholder="'+
((__t=( row.placeholder || '' ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\r\n';

    break;
case 'blob':

__p+='\r\n<textarea id="'+
((__t=( row.id ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder || '' ))==null?'':__t)+
'"\r\n                '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>'+
((__t=( row.value || '' ))==null?'':__t)+
'</textarea>\r\n';

    break;
case 'password':

__p+='\r\n<input class="form-control" type="password" id="'+
((__t=( row.id ))==null?'':__t)+
'" value="'+
((__t=( row.value || '' ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder || '' ))==null?'':__t)+
'"\r\n        '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\r\n';

    break;
case 'readonly':

__p+='\r\n<div class="modal-text" id="'+
((__t=( row.id ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>\r\n    '+
((__t=( row.value || '' ))==null?'':__t)+
'\r\n</div>\r\n';

    break;
case 'checkbox':
    var checked = '',
            disabled = '';
    if (row.checked) {
        checked = 'checked';
    }
    if (row.disabled) {
        disabled = 'disabled';
    }

__p+='\r\n<input type="checkbox" id="'+
((__t=( row.id ))==null?'':__t)+
'" value="'+
((__t=( row.value ))==null?'':__t)+
'" '+
((__t=( checked ))==null?'':__t)+
' '+
((__t=( disabled ))==null?'':__t)+
'\r\n        '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'/>\r\n';

    break;
case 'select':

__p+='\r\n<select id="'+
((__t=( row.id ))==null?'':__t)+
'" class="modalSelect" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>\r\n    ';
 _.each(row.options, function(opt) { 
__p+='\r\n        <option value="'+
((__t=( opt.value ))==null?'':__t)+
'" '+
((__t=( row.selected === opt.value ? 'selected' : '' ))==null?'':__t)+
'\r\n                '+
((__t=( opt.style ? 'style="' + opt.style + '"' : '' ))==null?'':__t)+
'>\r\n            '+
((__t=( opt.label ))==null?'':__t)+
'\r\n        </option>\r\n    ';
 }) 
__p+='\r\n</select>\r\n';

    break;
case 'select2':

__p+='\r\n<input type="hidden" id="'+
((__t=( row.id ))==null?'':__t)+
'" value="'+
((__t=( row.value || '' ))==null?'':__t)+
'" placeholder="'+
((__t=( row.placeholder || '' ))==null?'':__t)+
'"/>\r\n';
 if (row.addDelete) { 
__p+='\r\n    <button class="graphViewer-icon-button gv-icon-small add"></button>\r\n';
 } 
__p+='\r\n';
 if (row.addDelete) { 
__p+='\r\n    <button class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete"></button>\r\n';
 } 
__p+='\r\n';

    break;
case 'jsoneditor':

__p+='\r\n<div id="jsoneditor" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'></div>\r\n';

    break
case 'table':

__p+='\r\n<table class="pure-table pure-table-bordered" id="'+
((__t=( row.id ))==null?'':__t)+
'" '+
((__t=( row.style ? 'style="' + row.style + '"' : '' ))==null?'':__t)+
'>\r\n    ';
 if (Array.isArray(row.head) && row.head.length) { 
__p+='\r\n        <thead>\r\n        <tr>\r\n            ';
 row.head.forEach(header => { 
__p+='\r\n\r\n                <th style="height: unset;">'+
((__t=( header ))==null?'':__t)+
'</th>\r\n            ';
 }); 
__p+='\r\n            <th style="height: unset; min-width: 30px;">\r\n                    <span style="display: inline-flex">\r\n                                <button class="graphViewer-icon-button gv-icon-small add addAfter"></button>\r\n                    </span>\r\n            </th>\r\n        </tr>\r\n        </thead>\r\n    ';
 } 
__p+='\r\n    <tbody>\r\n    ';
 row.rows.forEach((contentRow, idx) => {
        const contentRowId = `${row.id}-row-${idx}`;
    
__p+='\r\n    <tr id="'+
((__t=( contentRowId ))==null?'':__t)+
'">\r\n        ';
 contentRow.forEach(cell => {
            cell.style = [(cell.style || ''), "width: unset;", "margin: 0;"].join(' ');
        
__p+='\r\n        <td>\r\n            ';
 createInput(cell) 
__p+='\r\n        </td>\r\n        ';
 }); 
__p+='\r\n        <td>\r\n                            <span style="display: inline-flex">\r\n                                ';
 if (idx) { 
__p+='\r\n                                    <button style="margin-left: 5px;"\r\n                                            class="graphViewer-icon-button gv_internal_remove_line gv-icon-small delete addDelete">\r\n                                </button>\r\n                                ';
 } 
__p+='\r\n                            </span>\r\n        </td>\r\n    </tr>\r\n    ';
 }); 
__p+='\r\n    </tbody>\r\n</table>\r\n';

    break;
}
}

function generateAdvancedSection(header, content) { 
__p+='\r\n<div class="panel-group" id="accordion'+
((__t=( advancedCounter ))==null?'':__t)+
'">\r\n    <div class="panel panel-default">\r\n        <div class="panel-heading">\r\n            <a class="accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion'+
((__t=( advancedCounter ))==null?'':__t)+
'"\r\n               href="#collapseOne'+
((__t=( advancedCounter ))==null?'':__t)+
'">\r\n                <span>'+
((__t=( header ))==null?'':__t)+
'</span><span><b class="caret"></b></span>\r\n            </a>\r\n        </div>\r\n        <div id="collapseOne'+
((__t=( advancedCounter ))==null?'':__t)+
'" class="panel-collapse collapse out">\r\n            <div class="panel-body" style="margin-right: 15px;">\r\n                <table>\r\n                    <tbody>\r\n                    ';

                        _.each(content, function (row) {
                            createTR(row);
                        });
                    
__p+='\r\n                    </tbody>\r\n                </table>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n';
 advancedCounter++;}

var createTR = function(row) {
    var mandatory = '';
    if (row.mandatory) {
        mandatory = ' *';
    }

__p+='\r\n\r\n<tr class="tableRow" id="'+
((__t=( 'row_' + row.id ))==null?'':__t)+
'">\r\n    ';
 if (typeof row.label === 'string') { 
__p+='\r\n        <th class="relationTh">\r\n            '+
((__t=( row.label ))==null?'':__t)+
''+
((__t=( mandatory ))==null?'':__t)+
':\r\n        </th>\r\n    ';
 } 
__p+='\r\n\r\n    <th class="relationTh"'+
((__t=( row.info ? '' : ' colspan="2"' ))==null?'':__t)+
'>\r\n        ';
 createInput(row) 
__p+='\r\n        ';
 if (row.info) { 
__p+='\r\n    </th>\r\n    <th>\r\n          <span class="modalTooltips fa fa-info-circle" title="'+
((__t=( row.info ))==null?'':__t)+
'">\r\n          </span>\r\n        ';
 } 
__p+='\r\n    </th>\r\n</tr>\r\n';

};

__p+='\r\n';
 if (content) { 
__p+='\r\n    <table>\r\n        <tbody>\r\n        ';

            _.each(content, function (row) {
                createTR(row);
            });
        
__p+='\r\n        </tbody>\r\n    </table>\r\n';
 } 
__p+='\r\n';
 if (info) { 
__p+='\r\n    '+
((__t=( info ))==null?'':__t)+
'\r\n';
 } 
__p+='\r\n';
 if (advancedContent && Array.isArray(advancedContent)) {
    _.forEach(advancedContent, function (aC) {
        generateAdvancedSection(aC.header, aC.content);
    });
} else if (advancedContent) {
    generateAdvancedSection(advancedContent.header, advancedContent.content);
} 
__p+='\r\n\r\n';
}
return __p;
};
