/* jshint unused: false */
// eslint-disable-next-line no-unused-vars
/* global window, $, Backbone, document, d3, ReactDOM, React */
/* global dbmsHelper, btoa, atob, _, frontendConfig */

(function () {
  'use strict';

  var isCurrentCoordinator = false;
  window.Router = Backbone.Router.extend({
    toUpdate: [],
    dbServers: [],
    isCluster: undefined,
    foxxApiEnabled: undefined,
    statisticsInAllDatabases: undefined,
    lastRoute: undefined,
    maxNumberOfMoveShards: undefined,
    routes: {
      '': 'relations',
      'dashboard': 'dashboard',
      'replication': 'replication',
      'replication/applier/:endpoint/:database': 'applier',
      'relations': 'relations',
      'analyzers': 'analyzers',
      'new': 'newCollection',
      'login': 'login',
      'relation/:colid/documents/:pageid': 'documents',
      'cIndices/:colname': 'cIndices',
      'cSettings/:colname': 'cSettings',
      'cSchema/:colname': 'cSchema',
      'cInfo/:colname': 'cInfo',
      'relation/:colid/:docid': 'document',
      'queries': 'query',
      'databases': 'databases',
      'settings': 'databases',
      'services': 'applications',
      'services/install': 'installService',
      'services/install/new': 'installNewService',
      'services/install/github': 'installGitHubService',
      'services/install/upload': 'installUploadService',
      'services/install/remote': 'installUrlService',
      'service/:mount': 'applicationDetail',
      'store/:name': 'storeDetail',
      'graphs': 'graphManagement',
      'graphs/:name': 'showGraph',
      'metrics': 'metrics',
      'users': 'userManagement',
      'user/:name': 'userView',
      'user/:name/permission': 'userPermission',
      'userProfile': 'userProfile',
      'nodes': 'nodes',
      'shards': 'shards',
      'rebalanceShards': 'rebalanceShards',
      'maintenance': 'maintenance',
      'distribution': 'distribution',
      'node/:name': 'node',
      'nodeInfo/:id': 'nodeInfo',
      'logs': 'logger',
      'helpus': 'helpUs',
      'views': 'views',
      'view/:name': 'view',
      'graph/:name': 'graph',
      'graph/:name/settings': 'graphSettings',
      'support': 'support'
    },
    execute: function execute(callback, args) {
      if (this.lastRoute) {
        // service replace logic
        var replaceUrlFirst = this.lastRoute.split('/')[0];
        var replaceUrlSecond = this.lastRoute.split('/')[1];
        var replaceUrlThird = this.lastRoute.split('/')[2];
        if (replaceUrlFirst !== '#service') {
          if (window.App.replaceApp) {
            if (replaceUrlSecond !== 'install' && replaceUrlThird) {
              window.App.replaceApp = false;
              // console.log('set replace to false!');
            }
          } else {
            // console.log('set replace to false!');
            window.App.replaceApp = false;
          }
        }
        if (this.lastRoute.substr(0, 11) === '#relation' && this.lastRoute.split('/').length === 3) {
          this.documentView.cleanupEditor();
        }
        if (this.lastRoute === '#dasboard' || window.location.hash.substr(0, 5) === '#node') {
          // dom graph cleanup
          d3.selectAll('svg > *').remove();
        }
        if (this.lastRoute === '#logger') {
          if (this.loggerView.logLevelView) {
            this.loggerView.logLevelView.remove();
          }
          if (this.loggerView.logTopicView) {
            this.loggerView.logTopicView.remove();
          }
        }

        // react unmounting
        if (document.getElementById('react-content')) {
          ReactDOM.unmountComponentAtNode(document.getElementById('react-content'));
        }
        ReactDOM.unmountComponentAtNode(document.getElementById('content'));
      }
      this.lastRoute = window.location.hash;
      // this function executes before every route call
      $('#subNavigationBar .breadcrumb').html('');
      $('#subNavigationBar .bottom').html('');
      $('#loadingScreen').hide();
      $('#content').show();
      if (callback) {
        callback.apply(this, args);
      }
      if (this.lastRoute === '#services') {
        window.App.replaceApp = false;
      }
      if (this.graphViewer) {
        if (this.graphViewer.graphSettingsView) {
          this.graphViewer.graphSettingsView.hide();
        }
      }
      if (this.queryView) {
        if (this.queryView.graphViewer) {
          if (this.queryView.graphViewer.graphSettingsView) {
            this.queryView.graphViewer.graphSettingsView.hide();
          }
        }
      }
    },
    listenerFunctions: {},
    listener: function listener(event) {
      _.each(window.App.listenerFunctions, function (func, key) {
        void key;
        func(event);
      });
    },
    checkUser: function checkUser() {
      var self = this;
      if (window.location.hash === '#login') {
        return;
      }
      var startInit = function () {
        this.initOnce();

        // show hidden by default divs
        $('.bodyWrapper').show();
        $('.navbar').show();
      }.bind(this);
      var callback = function (error, user) {
        if (frontendConfig.authenticationEnabled) {
          self.currentUser = user;
          if (error || user === null) {
            if (window.location.hash !== '#login') {
              this.navigate('login', {
                trigger: true
              });
            }
          } else {
            startInit();
          }
        } else {
          startInit();
        }
      }.bind(this);
      if (frontendConfig.authenticationEnabled) {
        this.userCollection.whoAmI(callback);
      } else {
        this.initOnce();

        // show hidden by default divs
        $('.bodyWrapper').show();
        $('.navbar').show();
      }
    },
    initialize: function initialize() {
      var self = this;
      this.init = new Promise(function (resolve, reject) {
        self.initSucceeded = resolve;
        self.initFailed = reject;
      });

      // check frontend config for global conf settings
      this.isCluster = frontendConfig.isCluster;
      if (typeof frontendConfig.foxxApiEnabled === 'boolean') {
        this.foxxApiEnabled = frontendConfig.foxxApiEnabled;
      }
      if (typeof frontendConfig.statisticsInAllDatabases === 'boolean') {
        this.statisticsInAllDatabases = frontendConfig.statisticsInAllDatabases;
      }
      this.maxNumberOfMoveShards = frontendConfig.maxNumberOfMoveShards;
      document.addEventListener('keyup', this.listener, false);

      // This should be the only global object
      window.modalView = new window.ModalView();

      // foxxes
      this.foxxList = new window.FoxxCollection();

      // foxx repository
      this.foxxRepo = new window.FoxxRepository();
      if (frontendConfig.foxxStoreEnabled) {
        this.foxxRepo.fetch({
          success: function success() {
            if (self.serviceInstallView) {
              self.serviceInstallView.relation = self.foxxRepo;
            }
          }
        });
      }
      window.progressView = new window.ProgressView();
      this.userCollection = new window.ArangoUsers();
      this.initOnce = _.once(function () {
        var callback = function callback(error, isCoordinator) {
          if (isCoordinator === true) {
            isCurrentCoordinator = true;
            self.coordinatorRelation.fetch({
              success: function success() {
                self.fetchDBS();
              }
            });
          }
          if (error) {
            console.log(error);
          }
        };
        window.isCoordinator(callback);
        if (frontendConfig.isCluster === false) {
          this.initSucceeded(true);
        }
        this.dbmsDatabase = new window.DbmsDatabase();
        this.currentDB = new window.CurrentDatabase();
        this.dbmsRelationsStore = new window.DbmsRelations();
        this.dbmsDocumentStore = new window.DbmsDocument();
        this.dbmsViewsStore = new window.DbmsViews();

        // Cluster
        this.coordinatorRelation = new window.ClusterCoordinators();
        window.spotlightView = new window.SpotlightView({
          relation: this.dbmsRelationsStore
        });
        dbmsHelper.setDocumentStore(this.dbmsDocumentStore);
        this.dbmsRelationsStore.fetch({
          cache: false
        });
        this.footerView = new window.FooterView({
          relation: self.coordinatorRelation
        });
        this.notificationList = new window.NotificationCollection();
        this.currentDB.fetch({
          cache: false,
          success: function success() {
            self.naviView = new window.NavigationView({
              database: self.dbmsDatabase,
              currentDB: self.currentDB,
              notificationCollection: self.notificationList,
              userCollection: self.userCollection,
              isCluster: self.isCluster,
              foxxApiEnabled: self.foxxApiEnabled,
              statisticsInAllDatabases: self.statisticsInAllDatabases
            });
            self.naviView.render();
          }
        });
        this.queryCollection = new window.DbmsQueries();
        this.footerView.render();
        this.userConfig = new window.UserConfig({
          ldapEnabled: frontendConfig.ldapEnabled
        });
        this.userConfig.fetch();
        this.documentsView = new window.DocumentsView({
          collection: new window.DbmsDocuments(),
          documentStore: this.dbmsDocumentStore,
          relationsStore: this.dbmsRelationsStore
        });
        dbmsHelper.initSigma();
      }).bind(this);
      $(window).on('resize', function () {
        self.handleResize();
      });
    },
    analyzers: function analyzers() {
      this.checkUser();
      this.init.then(function () {
        return ReactDOM.render(React.createElement(window.AnalyzersReactView), document.getElementById('content'));
      });
    },
    node: function node(id) {
      var _this = this;
      this.checkUser();
      this.init.then(function () {
        if (_this.isCluster && frontendConfig.clusterApiJwtPolicy === 'jwt-all') {
          // no privileges to use cluster/nodes from the web UI
          _this.routes[''] = 'relations';
          _this.navigate('#relations', {
            trigger: true
          });
          return;
        }
        if (_this.isCluster === false) {
          _this.routes[''] = 'dashboard';
          _this.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        if (_this.nodeView) {
          _this.nodeView.remove();
        }
        _this.nodeView = new window.NodeView({
          coordid: id,
          coordinators: _this.coordinatorRelation,
          dbServers: _this.dbServers
        });
        _this.nodeView.render();
      });
    },
    shards: function shards() {
      var _this2 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this2.isCluster === false) {
          _this2.routes[''] = 'dashboard';
          _this2.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        // TODO re-enable React View, for now use old view:
        // ReactDOM.render(React.createElement(window.ShardsReactView),
        //   document.getElementById('content'));
        // Below code needs to be removed then again.
        if (_this2.shardsView) {
          _this2.shardsView.remove();
        }
        _this2.shardsView = new window.ShardsView({
          dbServers: _this2.dbServers
        });
        _this2.shardsView.render();
      });
    },
    rebalanceShards: function rebalanceShards() {
      var _this3 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this3.isCluster === false || isCurrentCoordinator === false || _this3.maxNumberOfMoveShards === 0) {
          _this3.routes[''] = 'dashboard';
          _this3.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        // this below is for when Rebalance Shards tab is not clickable, but user enters it through its URL
        else if (_this3.userCollection.authOptions.ro) {
          // if user can't edit the database,
          // it goes back to the Overview page
          _this3.routes[''] = 'nodes';
          _this3.navigate('#nodes', {
            trigger: true
          });
          return;
        }
        if (_this3.rebalanceShardsView) {
          _this3.rebalanceShardsView.remove();
        }
        _this3.rebalanceShardsView = new window.RebalanceShardsView({
          maxNumberOfMoveShards: _this3.maxNumberOfMoveShards
        });
        _this3.rebalanceShardsView.render();
      });
    },
    distribution: function distribution() {
      var _this4 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this4.currentDB.get('name') !== '_system') {
          if (!_this4.isCluster) {
            _this4.routes[''] = 'dashboard';
            _this4.navigate('#dashboard', {
              trigger: true
            });
          } else {
            _this4.routes[''] = 'relations';
            _this4.navigate('#relations', {
              trigger: true
            });
          }
          return;
        }
        if (_this4.shardDistributionView) {
          _this4.shardDistributionView.remove();
        }
        _this4.shardDistributionView = new window.ShardDistributionView({});
        _this4.shardDistributionView.render();
      });
    },
    maintenance: function maintenance() {
      var _this5 = this;
      this.checkUser();
      this.init.then(function () {
        if (frontendConfig.showMaintenanceStatus === false || _this5.currentDB.get('name') !== '_system') {
          if (!_this5.isCluster) {
            _this5.routes[''] = 'dashboard';
            _this5.navigate('#dashboard', {
              trigger: true
            });
          } else {
            _this5.routes[''] = 'cluster';
            _this5.navigate('#cluster', {
              trigger: true
            });
          }
          return;
        }
        if (_this5.maintenanceView) {
          _this5.maintenanceView.remove();
        }
        _this5.maintenanceView = new window.MaintenanceView({});
        _this5.maintenanceView.render();
      });
    },
    nodes: function nodes() {
      var _this6 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this6.isCluster === false) {
          _this6.routes[''] = 'dashboard';
          _this6.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        if (_this6.nodesView) {
          _this6.nodesView.remove();
        }
        _this6.nodesView = new window.NodesView({});
        _this6.nodesView.render();
      });
    },
    cNodes: function cNodes() {
      var _this7 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this7.isCluster === false) {
          _this7.routes[''] = 'dashboard';
          _this7.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        _this7.nodesView = new window.NodesView({
          coordinators: _this7.coordinatorRelation,
          dbServers: _this7.dbServers[0],
          toRender: 'coordinator'
        });
        _this7.nodesView.render();
      });
    },
    dNodes: function dNodes() {
      var _this8 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this8.isCluster === false) {
          _this8.routes[''] = 'dashboard';
          _this8.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        if (_this8.dbServers.length === 0) {
          _this8.navigate('#cNodes', {
            trigger: true
          });
          return;
        }
        _this8.nodesView = new window.NodesView({
          coordinators: _this8.coordinatorRelation,
          dbServers: _this8.dbServers[0],
          toRender: 'dbserver'
        });
        _this8.nodesView.render();
      });
    },
    sNodes: function sNodes() {
      var _this9 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this9.isCluster === false) {
          _this9.routes[''] = 'dashboard';
          _this9.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        _this9.scaleView = new window.ScaleView({
          coordinators: _this9.coordinatorRelation,
          dbServers: _this9.dbServers[0]
        });
        _this9.scaleView.render();
      });
    },
    addAuth: function addAuth(xhr) {
      var u = this.clusterPlan.get('user');
      if (!u) {
        xhr.abort();
        if (!this.isCheckingUser) {
          this.requestAuth();
        }
        return;
      }
      var user = u.name;
      var pass = u.passwd;
      var token = user.concat(':', pass);
      xhr.setRequestHeader('Authorization', 'Basic ' + btoa(token));
    },
    logger: function logger() {
      var _this10 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this10.loggerView) {
          _this10.loggerView.remove();
        }
        var co = new window.DbmsLogs({
          upto: true,
          loglevel: 4
        });
        _this10.loggerView = new window.LoggerView({
          collection: co
        });
        _this10.loggerView.render(true);
      });
    },
    applicationDetail: function applicationDetail(mount) {
      var _this11 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this11.foxxApiEnabled) {
          _this11.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        var callback = function () {
          if (this.hasOwnProperty('applicationDetailView')) {
            this.applicationDetailView.remove();
          }
          this.applicationDetailView = new window.ApplicationDetailView({
            model: this.foxxList.get(decodeURIComponent(mount))
          });
          this.applicationDetailView.model = this.foxxList.get(decodeURIComponent(mount));
          this.applicationDetailView.render('swagger');
        }.bind(_this11);
        if (_this11.foxxList.length === 0) {
          _this11.foxxList.fetch({
            cache: false,
            success: function success() {
              callback();
            }
          });
        } else {
          callback();
        }
      });
    },
    storeDetail: function storeDetail(mount) {
      var _this12 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this12.foxxApiEnabled) {
          _this12.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        var callback = function () {
          if (this.hasOwnProperty('storeDetailView')) {
            this.storeDetailView.remove();
          }
          this.storeDetailView = new window.StoreDetailView({
            model: this.foxxRepo.get(decodeURIComponent(mount)),
            collection: this.foxxList
          });
          this.storeDetailView.model = this.foxxRepo.get(decodeURIComponent(mount));
          this.storeDetailView.render();
        }.bind(_this12);
        if (_this12.foxxRepo.length === 0) {
          _this12.foxxRepo.fetch({
            cache: false,
            success: function success() {
              callback();
            }
          });
        } else {
          callback();
        }
      });
    },
    login: function login() {
      var callback = function (error, user) {
        if (!this.loginView) {
          this.loginView = new window.LoginView({
            collection: this.userCollection
          });
        }
        if (error || user === null || user === undefined) {
          this.loginView.render();
        } else {
          this.loginView.render(true);
        }
      }.bind(this);
      this.userCollection.whoAmI(callback);
    },
    relations: function relations() {
      var _this13 = this;
      this.checkUser();
      this.init.then(function () {
        var self = _this13;
        if (_this13.relationsView) {
          _this13.relationsView.remove();
        }
        _this13.relationsView = new window.RelationsView({
          collection: _this13.dbmsRelationsStore
        });
        _this13.dbmsRelationsStore.fetch({
          cache: false,
          success: function success() {
            self.relationsView.render();
          }
        });
      });
    },
    cIndices: function cIndices(colname) {
      var _this14 = this;
      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this14.dbmsRelationsStore.fetch({
          cache: false,
          success: function success() {
            if (self.indicesView) {
              self.indicesView.remove();
            }
            self.indicesView = new window.IndicesView({
              relationName: colname,
              collection: self.dbmsRelationsStore.findWhere({
                name: colname
              })
            });
            self.indicesView.render();
          }
        });
      });
    },
    cSettings: function cSettings(colname) {
      var _this15 = this;
      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this15.dbmsRelationsStore.fetch({
          cache: false,
          success: function success() {
            self.settingsView = new window.SettingsView({
              relationName: colname,
              collection: self.dbmsRelationsStore.findWhere({
                name: colname
              })
            });
            self.settingsView.render();
          }
        });
      });
    },
    cSchema: function cSchema(colname) {
      var _this16 = this;
      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this16.dbmsRelationsStore.fetch({
          cache: false,
          success: function success() {
            self.settingsView = new window.ValidationView({
              relationName: colname,
              collection: self.dbmsRelationsStore.findWhere({
                name: colname
              })
            });
            self.settingsView.render();
          }
        });
      });
    },
    cInfo: function cInfo(colname) {
      var _this17 = this;
      var self = this;
      this.checkUser();
      this.init.then(function () {
        _this17.dbmsRelationsStore.fetch({
          cache: false,
          success: function success() {
            self.infoView = new window.InfoView({
              relationName: colname,
              collection: self.dbmsRelationsStore.findWhere({
                name: colname
              })
            });
            self.infoView.render();
          }
        });
      });
    },
    documents: function documents(colid, pageid) {
      var _this18 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this18.documentsView) {
          _this18.documentsView.unbindEvents();
        }
        if (!_this18.documentsView) {
          _this18.documentsView = new window.DocumentsView({
            collection: new window.DbmsDocuments(),
            documentStore: _this18.dbmsDocumentStore,
            relationsStore: _this18.dbmsRelationsStore
          });
        }
        _this18.documentsView.setCollectionId(colid, pageid);
        _this18.documentsView.render();
        _this18.documentsView.delegateEvents();
      });
    },
    document: function document(colid) {
      var _this19 = this;
      this.checkUser();
      this.init.then(function () {
        var mode;
        if (_this19.documentView) {
          if (_this19.documentView.defaultMode) {
            mode = _this19.documentView.defaultMode;
          }
          _this19.documentView.remove();
        }
        _this19.documentView = new window.DocumentView({
          collection: _this19.dbmsDocumentStore
        });
        _this19.documentView.colid = colid;
        _this19.documentView.defaultMode = mode;
        var doc = window.location.hash.split('/')[2];
        doc = decodeURIComponent(doc);
        _this19.documentView.docid = doc;
        _this19.documentView.render();
        var callback = function (error, type) {
          void type;
          if (!error) {
            this.documentView.setType();
          } else {
            this.documentView.renderNotFound(colid, true);
          }
        }.bind(_this19);
        dbmsHelper.relationApiType(colid, null, callback);
      });
    },
    query: function query() {
      this.checkUser();
      this.init.then(function () {
        ReactDOM.render(React.createElement(window.QueryPage), document.getElementById('content'));
      });
    },
    graph: function graph(name) {
      var _this20 = this;
      this.checkUser();
      this.init.then(function () {
        // TODO better manage mechanism for both gv's
        if (_this20.graphViewer) {
          if (_this20.graphViewer.graphSettingsView) {
            _this20.graphViewer.graphSettingsView.remove();
          }
          _this20.graphViewer.killCurrentGraph();
          _this20.graphViewer.unbind();
          _this20.graphViewer.remove();
        }
        _this20.graphViewer = new window.GraphViewer({
          name: name,
          documentStore: _this20.dbmsDocumentStore,
          collection: new window.GraphCollection(),
          userConfig: _this20.userConfig
        });
        _this20.graphViewer.render();
      });
    },
    graphSettings: function graphSettings(name) {
      var _this21 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this21.graphSettingsView) {
          _this21.graphSettingsView.remove();
        }
        _this21.graphSettingsView = new window.GraphSettingsView({
          name: name,
          userConfig: _this21.userConfig
        });
        _this21.graphSettingsView.render();
      });
    },
    helpUs: function helpUs() {
      var _this22 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this22.testView) {
          _this22.helpUsView = new window.HelpUsView({});
        }
        _this22.helpUsView.render();
      });
    },
    support: function support() {
      var _this23 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this23.testView) {
          _this23.supportView = new window.SupportView({});
        }
        _this23.supportView.render();
      });
    },
    queryManagement: function queryManagement() {
      var _this24 = this;
      this.checkUser();
      ReactDOM.unmountComponentAtNode(document.getElementById('content'));
      this.init.then(function () {
        if (_this24.queryManagementView) {
          _this24.queryManagementView.remove();
        }
        _this24.queryManagementView = new window.QueryManagementView({
          relation: undefined
        });
        _this24.queryManagementView.render();
      });
    },
    databases: function databases() {
      var _this25 = this;
      this.checkUser();
      this.init.then(function () {
        var callback = function (error) {
          if (error) {
            dbmsHelper.dbmsError('DB', 'Could not get list of allowed databases');
            this.navigate('#', {
              trigger: true
            });
            $('#databaseNavi').css('display', 'none');
            $('#databaseNaviSelect').css('display', 'none');
          } else {
            if (this.databaseView) {
              // cleanup events and view
              this.databaseView.remove();
            }
            this.databaseView = new window.DatabaseView({
              users: this.userCollection,
              collection: this.dbmsDatabase
            });
            this.databaseView.render();
          }
        }.bind(_this25);
        dbmsHelper.databaseAllowed(callback);
      });
    },
    dashboard: function dashboard() {
      var _this26 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this26.dashboardView === undefined) {
          _this26.dashboardView = new window.DashboardView({
            dygraphConfig: window.dygraphConfig,
            database: _this26.dbmsDatabase
          });
        }
        _this26.dashboardView.render();
      });
    },
    replication: function replication() {
      var _this27 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this27.replicationView) {
          // this.replicationView.remove();
          _this27.replicationView = new window.ReplicationView({});
        }
        _this27.replicationView.render();
      });
    },
    applier: function applier(endpoint, database) {
      var _this28 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this28.applierView === undefined) {
          _this28.applierView = new window.ApplierView({});
        }
        _this28.applierView.endpoint = atob(endpoint);
        _this28.applierView.database = atob(database);
        _this28.applierView.render();
      });
    },
    graphManagement: function graphManagement() {
      var _this29 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this29.graphManagementView) {
          _this29.graphManagementView.undelegateEvents();
        }
        _this29.graphManagementView = new window.GraphManagementView({
          collection: new window.GraphCollection(),
          relationCollection: _this29.dbmsRelationsStore
        });
        _this29.graphManagementView.render();
      });
    },
    showGraph: function showGraph(name) {
      var _this30 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this30.graphManagementView) {
          _this30.graphManagementView = new window.GraphManagementView({
            collection: new window.GraphCollection(),
            relationCollection: _this30.dbmsRelationsStore
          });
          _this30.graphManagementView.render(name, true);
        } else {
          _this30.graphManagementView.loadGraphViewer(name);
        }
      });
    },
    applications: function applications() {
      var _this31 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this31.foxxApiEnabled) {
          _this31.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        if (_this31.applicationsView === undefined) {
          _this31.applicationsView = new window.ApplicationsView({
            collection: _this31.foxxList
          });
        }
        _this31.applicationsView.reload();
      });
    },
    installService: function installService() {
      var _this32 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this32.foxxApiEnabled) {
          _this32.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        if (!frontendConfig.foxxStoreEnabled) {
          _this32.navigate('#services/install/upload', {
            trigger: true
          });
          return;
        }
        window.modalView.clearValidators();
        if (_this32.serviceInstallView) {
          _this32.serviceInstallView.remove();
        }
        _this32.serviceInstallView = new window.ServiceInstallView({
          collection: _this32.foxxRepo,
          functionsCollection: _this32.foxxList
        });
        _this32.serviceInstallView.render();
      });
    },
    installNewService: function installNewService() {
      var _this33 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this33.foxxApiEnabled) {
          _this33.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        window.modalView.clearValidators();
        if (_this33.serviceNewView) {
          _this33.serviceNewView.remove();
        }
        _this33.serviceNewView = new window.ServiceInstallNewView({
          collection: _this33.foxxList
        });
        _this33.serviceNewView.render();
      });
    },
    installGitHubService: function installGitHubService() {
      var _this34 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this34.foxxApiEnabled) {
          _this34.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        window.modalView.clearValidators();
        if (_this34.serviceGitHubView) {
          _this34.serviceGitHubView.remove();
        }
        _this34.serviceGitHubView = new window.ServiceInstallGitHubView({
          collection: _this34.foxxList
        });
        _this34.serviceGitHubView.render();
      });
    },
    installUrlService: function installUrlService() {
      var _this35 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this35.foxxApiEnabled) {
          _this35.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        window.modalView.clearValidators();
        if (_this35.serviceUrlView) {
          _this35.serviceUrlView.remove();
        }
        _this35.serviceUrlView = new window.ServiceInstallUrlView({
          collection: _this35.foxxList
        });
        _this35.serviceUrlView.render();
      });
    },
    installUploadService: function installUploadService() {
      var _this36 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this36.foxxApiEnabled) {
          _this36.navigate('#dashboard', {
            trigger: true
          });
          return;
        }
        window.modalView.clearValidators();
        if (_this36.serviceUploadView) {
          _this36.serviceUploadView.remove();
        }
        _this36.serviceUploadView = new window.ServiceInstallUploadView({
          collection: _this36.foxxList
        });
        _this36.serviceUploadView.render();
      });
    },
    handleSelectDatabase: function handleSelectDatabase() {
      var _this37 = this;
      this.checkUser();
      this.init.then(function () {
        return _this37.naviView.handleSelectDatabase();
      });
    },
    handleResize: function handleResize() {
      if (this.dashboardView) {
        this.dashboardView.resize();
      }
      if (this.graphManagementView && Backbone.history.getFragment() === 'graphs') {
        this.graphManagementView.handleResize($('#content').width());
      }
      if (this.queryView && Backbone.history.getFragment() === 'queries') {
        this.queryView.resize();
      }
      if (this.naviView) {
        this.naviView.resize();
      }
      if (this.graphViewer && Backbone.history.getFragment().indexOf('graph') > -1) {
        this.graphViewer.resize();
      }
      if (this.documentsView && Backbone.history.getFragment().indexOf('documents') > -1) {
        this.documentsView.resize();
      }
      if (this.documentView && Backbone.history.getFragment().indexOf('relation') > -1) {
        this.documentView.resize();
      }
      if (this.validationView && Backbone.history.getFragment().indexOf('cSchema') > -1) {
        this.validationView.resize();
      }
    },
    userPermission: function userPermission(name) {
      var _this38 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this38.userPermissionView) {
          _this38.userPermissionView.remove();
        }
        _this38.userPermissionView = new window.UserPermissionView({
          collection: _this38.userCollection,
          databases: _this38.dbmsDatabase,
          username: name
        });
        _this38.userPermissionView.render();
      });
    },
    userView: function userView(name) {
      var _this39 = this;
      this.checkUser();
      this.init.then(function () {
        _this39.userView = new window.UserView({
          collection: _this39.userCollection,
          username: name
        });
        _this39.userView.render();
      });
    },
    userManagement: function userManagement() {
      var _this40 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this40.userManagementView) {
          _this40.userManagementView.remove();
        }
        _this40.userManagementView = new window.UserManagementView({
          collection: _this40.userCollection
        });
        _this40.userManagementView.render();
      });
    },
    userProfile: function userProfile() {
      var _this41 = this;
      this.checkUser();
      this.init.then(function () {
        if (!_this41.userManagementView) {
          _this41.userManagementView = new window.UserManagementView({
            collection: _this41.userCollection
          });
        }
        _this41.userManagementView.render(true);
      });
    },
    view: function view(name) {
      var _this42 = this;
      var self = this;
      this.checkUser();
      this.init.then(function () {
        if (_this42.viewView) {
          _this42.viewView.remove();
        }
        _this42.dbmsViewsStore.fetch({
          success: function success() {
            self.viewView = new window.ViewView({
              model: self.dbmsViewsStore.get(name),
              name: name
            });
            self.viewView.render();
          }
        });
      });
    },
    views: function views() {
      var _this43 = this;
      this.checkUser();
      this.init.then(function () {
        if (_this43.viewsView) {
          _this43.viewsView.remove();
        }
        _this43.viewsView = new window.ViewsView({
          collection: _this43.dbmsViewsStore
        });
        _this43.viewsView.render();
      });
    },
    fetchDBS: function fetchDBS(callback) {
      var self = this;
      var cb = false;
      this.coordinatorRelation.each(function (coordinator) {
        self.dbServers.push(new window.ClusterServers([], {
          host: coordinator.get('address')
        }));
      });
      this.initSucceeded(true);
      _.each(this.dbServers, function (dbservers) {
        dbservers.fetch({
          success: function success() {
            if (cb === false) {
              if (callback) {
                callback();
                cb = true;
              }
            }
          }
        });
      });
    },
    getNewRoute: function getNewRoute(host) {
      return 'http://' + host;
    },
    registerForUpdate: function registerForUpdate(o) {
      this.toUpdate.push(o);
      o.updateUrl();
    }
  });
})();