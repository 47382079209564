"use strict";

/**
 * Utility function for normalizing URLs.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalizeUrl = void 0;
/**
 * @internal
 * @hidden
 */
function normalizeUrl(url) {
  var raw = url.match(/^(tcp|ssl|tls)((?::|\+).+)/);
  if (raw) url = (raw[1] === "tcp" ? "http" : "https") + raw[2];
  var unix = url.match(/^(?:(http|https)\+)?unix:\/\/(\/.+)/);
  if (unix) url = "".concat(unix[1] || "http", "://unix:").concat(unix[2]);
  return url;
}
exports.normalizeUrl = normalizeUrl;