/* jshint browser: true */
/* jshint unused: false */
/* global Backbone, $, window */

(function () {
  'use strict';

  window.PaginationView = Backbone.View.extend({
    // Subclasses need to overwrite this
    collection: null,
    paginationDiv: '',
    idPrefix: '',
    rerender: function rerender() {},
    jumpTo: function jumpTo(page) {
      this.collection.setPage(page);
      this.rerender();
    },
    firstPage: function firstPage() {
      this.jumpTo(1);
    },
    lastPage: function lastPage() {
      this.jumpTo(this.collection.getLastPageNumber());
    },
    firstDocuments: function firstDocuments() {
      this.jumpTo(1);
    },
    lastDocuments: function lastDocuments() {
      this.jumpTo(this.collection.getLastPageNumber());
    },
    prevDocuments: function prevDocuments() {
      this.jumpTo(this.collection.getPage() - 1);
    },
    nextDocuments: function nextDocuments() {
      this.jumpTo(this.collection.getPage() + 1);
    },
    renderPagination: function renderPagination() {
      $(this.paginationDiv).html('');
      var self = this;
      var currentPage = this.collection.getPage();
      var totalPages = this.collection.getLastPageNumber();
      var target = $(this.paginationDiv);
      var options = {
        page: currentPage,
        lastPage: totalPages,
        click: function click(i) {
          var split = window.location.hash.split('/');
          if (split[2] === 'documents') {
            options.page = i;
            window.location.hash = split[0] + '/' + split[1] + '/' + split[2] + '/' + i;
          } else {
            self.jumpTo(i);
            options.page = i;
          }
        }
      };
      target.html('');
      target.pagination(options);
      $(this.paginationDiv).prepend('<ul class="pre-pagi"><li><a id="' + this.idPrefix + '_first" class="pagination-button">' + '<span><i class="fa fa-angle-double-left"/></span></a></li></ul>');
      $(this.paginationDiv).append('<ul class="las-pagi"><li><a id="' + this.idPrefix + '_last" class="pagination-button">' + '<span><i class="fa fa-angle-double-right"/></span></a></li></ul>');
    }
  });
})();