export enum ReplicationType {
    bitcoin = 2,
    follower = 1,
}

export const replicationTypes = [
    ReplicationType.follower,
    ReplicationType.bitcoin,
]

export const replicationTypesDictionary = new Map()

replicationTypesDictionary.set(ReplicationType.bitcoin, 'bitcoin')
replicationTypesDictionary.set(ReplicationType.follower, 'follower')

export const sampleByReplicationType = {
    [ReplicationType.follower]: JSON.stringify({
        "endpoint": "",
        "username": "username",
        "password": "password",
        "maxConnectRetries": 10,
        "connectTimeout": 1,
        "requestTimeout": 1,
        "chunkSize": 10000000,
        "autoStart": false,
        "adaptivePolling": false,
        "includeSystem": true,
        "verbose": true,
        "autoResync": true,
    }, null, 2),
    [ReplicationType.bitcoin]: JSON.stringify({

    }, null, 2),
}

export const toReplicationType = (value: number): ReplicationType => {
    switch(value) {
        case ReplicationType.bitcoin:
            return ReplicationType.bitcoin
        case ReplicationType.follower:
            return ReplicationType.follower
        default:
            throw new Error('Not supported replication type')
    }
}
