import { View } from '@nilfoundation/dbmsjs/build/view'
import { forward, sample, combine, createDomain, Effect, Event } from 'effector'
import { getDefaultDb } from '../../utils/dbmsClient'
import { profile as fetchProfile, explain as fetchExplain } from '../../api/dbEndpoint'
import { pending, throttle } from 'patronum'
import { $relations, fetchRelationDataFx, fetchRelationsFx } from '../../models/relations'
import { parseParams } from '../../utils/helpers'

const domain = createDomain('query')
const createEffect = domain.createEffect.bind(domain)
const createEvent = domain.createEvent.bind(domain)
const createStore = domain.createStore.bind(domain)

const positionStored = localStorage.getItem('query-page__panel-position') || '300'
// ui moving
export const updatePosition = createEvent<number>()
export const $position = createStore<number>(parseInt(positionStored)).on(updatePosition, (_, position) => position)

const savePositionFx = createEffect<number, void>({
  handler: (position) => {
    localStorage.setItem('query-page__panel-position', position.toString())
  },
})

throttle({
  source: $position,
  timeout: 100,
  target: savePositionFx,
})

// result of query

export const $result = createStore<{
  type: 'query' | 'explain' | 'profile' | 'explorer'
  data: any[]
  text: string
}>({
  type: 'query',
  data: [],
  text: '',
})

// page related
export const openPage = createEvent()
export const closePage = createEvent()

// query
export const inputQuery = createEvent<string>()
export const $query = createStore(localStorage.getItem('query') || '').on(inputQuery, (_, query) => query)

const saveQueryFx = createEffect<string, void>({
  handler: (query) => {
    localStorage.setItem('query', query)
  },
})

throttle({
  source: $query,
  timeout: 1000,
  target: saveQueryFx,
})

export const bgQuery = createEvent<string>()

// button actions
export const query = createEvent()
export const explain = createEvent()
export const profile = createEvent()

// parameters
export const $params = $query.map(parseParams)

export const setArgument = createEvent<{ name: string; value: string }>()

export const $arguments = createStore<Record<string, string>>({}).on(setArgument, (args, { name, value }) => ({
  ...args,
  [name]: value,
}))

export const $paramsWithArguments = combine($params, $arguments, (params, args) =>
  params.map((p) => ({ ...p, value: args[p.name], key: p.name })),
)

const $queryWithArguments = combine($query, $paramsWithArguments, (query, args) => ({
  query,
  args: args.reduce<{ [key: string]: string }>((res, item) => {
    if (item.name.slice(0, 1) === '@') {
      res[item.name] = item.value
    } else {
      try {
        res[item.name] = JSON.parse(item.value)
      } catch (e) {
        // skip value as string
        res[item.name] = item.value
      }
    }
    return res
  }, {}),
}))

type QueryAndParams = { query: string; args?: { [key: string]: any } }

export const doQueryFx = createEffect<QueryAndParams, Document[], any>()
export const doExplainFx = createEffect<QueryAndParams, any, any>()
export const doProfileFx = createEffect<QueryAndParams, any, any>()

export const fetchViewFx = createEffect<void, View[]>({
  handler: async () => {
    const db = getDefaultDb()
    const response = await db.views()
    return response
  },
})

doQueryFx.use(async ({ query, args }) => {
  const db = getDefaultDb()
  const res = await db.query(query, args)
  const result: any[] = []
  await res.forEach((item) => {
    result.push(item)
  })
  return result
})

doExplainFx.use(async ({ query, args }) => {
  const res = await fetchExplain(query, args)
  return res
})

doProfileFx.use(async ({ query, args }) => {
  const res = await fetchProfile(query, args)
  return res
})

// linking

const queryError = doQueryFx.fail.map<{ error: string; query: string }>(({ error, params }) => {
  return {
    error: (error?.response?.body?.errorMessage && `${error.response.body.errorMessage}`) || 'Db error',
    query: params.query,
  }
})

export const $queryError = createStore<{
  error: string
  query: string
}>({
  error: '',
  query: '',
})
  .on(queryError, (_, error) => error)
  .reset(doQueryFx)
  .reset(openPage)

// data result
$result
  .on(doQueryFx.doneData, (_, result) => ({
    type: 'query',
    data: result,
    text: '',
  }))
  .on(fetchRelationDataFx.doneData, (_, { data, name }) => ({
    type: 'explorer',
    data,
    text: name,
  }))
  .on(doQueryFx.fail, () => ({
    type: 'query',
    data: [],
    text: '',
  }))
  .on(doExplainFx.fail, () => ({
    type: 'explain',
    data: [],
    text: '',
  }))
  .on(doExplainFx.doneData, (_, result) => ({
    type: 'explain',
    data: [],
    text: result,
  }))
  .on(doProfileFx.fail, () => ({
    type: 'profile',
    data: [],
    text: '',
  }))
  .on(doProfileFx.doneData, (_, result) => ({
    type: 'profile',
    data: [],
    text: result,
  }))

// button effects
const mapEventToEffect = new Map<Event<void>, Effect<QueryAndParams, any>>()
mapEventToEffect.set(query, doQueryFx)
mapEventToEffect.set(explain, doExplainFx)
mapEventToEffect.set(profile, doProfileFx)
for (const [event, effect] of mapEventToEffect) {
  sample({
    clock: event,
    source: $queryWithArguments,
    target: effect,
  })
}

forward({
  from: openPage,
  to: fetchRelationsFx,
})

forward({
  from: bgQuery,
  to: fetchRelationDataFx,
})

export const $queryProcessing = pending({ effects: [doExplainFx, doQueryFx, doProfileFx], of: 'some' })

const positionRegex = /[0-9]+:[0-9]+/

export const $syntaxQueryError = combine($queryError, $query, ({ query: errorQuery, error }, currentQuery) => {
  if (currentQuery !== errorQuery) {
    return null
  }
  if (error.length > 0 && error.includes('while parsing') && error.includes('at position')) {
    const slice = error.slice(error.indexOf('at position') + 11)
    const position = positionRegex.exec(slice)
    if (position && position.length > 0) {
      const [line] = position[0].split(':')
      return {
        line: parseInt(line, 10),
        message: error,
        query: query,
      }
    }
  }
  return null
})

// combined store to render dependenly

export const $queryContainerStore = combine(
  $query,
  $paramsWithArguments,
  $syntaxQueryError,
  doQueryFx.pending,
  doExplainFx.pending,
  doProfileFx.pending,
  $queryProcessing,
  $relations,
  (
    $query,
    $paramsWithArguments,
    $syntaxQueryError,
    doQueryFxPending,
    doExplainFxPending,
    doProfileFxPending,
    $queryProcessing,
    $relations,
  ) => ({
    query: $query,
    params: $paramsWithArguments,
    syntaxError: $syntaxQueryError,
    queryPending: doQueryFxPending,
    explainPending: doExplainFxPending,
    profilePending: doProfileFxPending,
    pending: $queryProcessing,
    relations: $relations,
  }),
)
