/* jshint browser: true */
/* jshint unused: false */
/* global window, _, dbmsHelper, Backbone */

(function () {
  'use strict';

  window.ArangoMetrics = Backbone.Collection.extend({
    txt: {},
    // call original Backbone.Model#fetch with `dataType` equal `text` for $.ajax
    fetch: function fetch(options) {
      options = _.extend(options || {}, {
        dataType: 'text'
      });
      this.constructor.__super__.fetch.call(this, options);
    },
    metricsAsText: function metricsAsText() {
      return this.txt;
    },
    comparator: function comparator(metric) {
      return metric.get('name');
    },
    // store response in content attribute
    parse: function parse(response) {
      var models = [];
      // store original response to provide download later
      this.txt = response;
      var json = window.parsePrometheusTextFormat(response);
      _.each(json, function (entry) {
        models.push({
          name: entry.name,
          type: entry.type,
          info: entry.help,
          metrics: entry.metrics
        });
      });
      return models;
    },
    initialize: function initialize(options) {
      if (options.endpoint) {
        this.endpoint = options.endpoint;
      }
    },
    model: window.ArangoMetricModel,
    url: function url() {
      if (this.endpoint) {
        return dbmsHelper.databaseUrl('/_admin/metrics/v2?serverId=' + encodeURIComponent(this.endpoint));
      }
      return dbmsHelper.databaseUrl('/_admin/metrics/v2');
    }
  });
})();