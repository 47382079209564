import { $queryError, $queryProcessing, $result } from '../model'
import React, { useMemo } from 'react'
import { useStore } from 'effector-react'
import { Typography, Table, Checkbox, Spin } from 'antd'
import { columnSort } from '../../../utils/columnSort'
import { combine } from 'effector'
import classnames from 'clsx'
import styles from '../styles.module.scss'

const booleanRender = (value: boolean) => <Checkbox defaultChecked={value} disabled />
const objRender = (value: any) => <pre>{JSON.stringify(value)}</pre>

const dataTypes = ['query', 'explorer']

const queryResultStore = combine($queryError, $result, $queryProcessing, ($queryError, $result, $queryProcessing) => ({
  queryError: $queryError,
  result: $result,
  queryProcessing: $queryProcessing,
}))

export const QueryResult = () => {
  const { queryError, result, queryProcessing } = useStore(queryResultStore)
  const [dataColumns, data] = useMemo(() => {
    if (!dataTypes.includes(result.type)) {
      return []
    }
    if (result.data.length > 0) {
      const item = result.data[0]
      if (typeof item === 'object') {
        if (item === null) {
          return [
            [
              {
                dataIndex: 'value',
                key: 'value',
                title: 'value',
                render: objRender,
              },
            ],
            result.data.map((x) => ({ value: x })),
          ]
        }

        return [
          Object.keys(item)
            .map((key) => {
              const type = typeof item[key]
              return {
                dataIndex: key,
                key: key,
                title: key,
                render: type === 'boolean' ? booleanRender : type === 'object' ? objRender : undefined,
              }
            })
            .sort(columnSort),
          result.data,
        ]
      } else {
        return [
          [
            {
              dataIndex: 'value',
              key: 'value',
              title: 'value',
              render: objRender,
            },
          ],
          result.data.map((x) => ({ value: x })),
        ]
      }
    } else {
      return []
    }
  }, [result])

  return (
    <div className={classnames(styles.result, 'test__result')}>
      <Typography.Title level={4} className="test__result-head">
        {result.type === 'explorer' ? result.text : 'Result'}
      </Typography.Title>
      {queryError.error.length > 0 && result.type === 'query' && (
        <Typography.Text type="danger" className="test__result-error">
          {queryError.error}
        </Typography.Text>
      )}
      {queryError.error.length === 0 && dataTypes.includes(result.type) && (
        <Table
          dataSource={data}
          columns={dataColumns}
          loading={queryProcessing}
          rowKey="_key"
          pagination={false}
          className={classnames(styles.result__table, 'test__result-table')}
        />
      )}
      {queryError.error.length === 0 && !dataTypes.includes(result.type) && (
        <Spin spinning={queryProcessing}>
          <pre className='test__result-explain'>{result.text}</pre>
        </Spin>
      )}
    </div>
  )
}
