module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<a href="#" class="tab disabled" id="dbselection">\n    <div class="dbselection"><i class="fa fa-database"></i><span class="db-name">DB: </span> '+
((__t=( current ))==null?'':__t)+
'\n        <!-- <i class="fa fa-caret-square-o-down"></i> -->\n    </div>\n    <!-- <b class="caret"></b> -->\n</a>\n\n<!--\n<ul class="link-dropdown-menu" id="dbs_dropdown">\n';

if (list.length > 0) {

__p+='\n    <li class="dropdown-header">Databases</li>\n  ';

_.each(list, function(i) {

__p+='\n    <li class="dropdown-item">\n        <a id="'+
((__t=( _.escape(i) ))==null?'':__t)+
'" class="dbSelectionLink tab" href="#">'+
((__t=( _.escape(i) ))==null?'':__t)+
'</a>\n    </li>\n  ';

});

__p+='\n';

}

__p+='\n    ';
 if (current === "_system") { 
__p+='\n    ';
 if (list.length >= 2) { 
__p+='\n      <li class="divider"></li>\n    ';
 } 
__p+='\n    <li class="dropdown-header">Manager</li>\n    <li id="databaseNavi" class="dropdown-item">\n      <a id="databases" class="tab" href="#databases">Manage Databases</a>\n    </li>\n    ';
 } 
__p+='\n</ul>\n-->\n';
}
return __p;
};
