"use strict";

/**
 * Wrapper around the `xhr` module for HTTP(S) requests in the browser.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @internal
 * @hidden
 */
// eslint-disable-next-line @typescript-eslint/no-var-requires
exports.default = require("xhr");