module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="maintenanceContent" class="innerContent">\r\n    <div class="pure-g">\r\n        <div class="pure-u-1-1 pure-u-md-1-1 pure-u-lg-1-1 pure-u-xl-1-1">\r\n            ';
 if (error) { 
__p+='\r\n                <div id="maintenanceMode" class="infoBox errorBox" style="margin-top: 15px; margin-bottom: 20px">\r\n                    <h4>Attention <i class="fa fa-check fa-exclamation"></i></h4>\r\n                    <p>Could not fetch maintenance mode information. This may be due to missing privileges.</p>\r\n                </div>\r\n            ';
 } else if (maintenanceMode) { 
__p+='\r\n                <div id="maintenanceMode" class="infoBox warningBox" style="margin-top: 15px; margin-bottom: 20px">\r\n                    <h4>Maintenance mode enabled <i class="fa fa-check fa-exclamation"></i></h4>\r\n                    <p>The cluster supervision maintenance mode is currently enabled.</p>\r\n                    <p>While the maintenance mode is enabled, the agency will not organize failovers for shards that are\r\n                        located on unavailable leaders or follower DB servers. This mode can be used for controlled\r\n                        shutdown/restart of servers, e.g. or for upgrading or other planned maintenance.</p>\r\n                </div>\r\n                ';
 if (canChange) { 
__p+='\r\n                    <button id="disableMaintenanceMode" class="btn-success pull-right"\r\n                            style="margin-right: 20px;">Disable Maintenance Mode\r\n                    </button>\r\n                ';
 } 
__p+='\r\n            ';
 } else { 
__p+='\r\n                <div id="maintenanceMode" class="infoBox successBox" style="margin-top: 15px; margin-bottom: 20px">\r\n                    <h4>Maintenance mode disabled <i class="fa fa-check"></i></h4>\r\n                    <p>The cluster supervision maintenance mode is currently disabled.</p>\r\n                    <p>This is the expected setting for normal operations: in case a leader or follower DB server\r\n                        becomes unavailable, the agency will organize failovers for all affected shards.\r\n                </div>\r\n                ';
 if (canChange) { 
__p+='\r\n                    <button id="enableMaintenanceMode" class="btn-warning pull-right"\r\n                            style="margin-right: 20px; margin-bottom: 20px;">Enable Maintenance Mode\r\n                    </button>\r\n                ';
 } 
__p+='\r\n            ';
 } 
__p+='\r\n        </div>\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
