import { ConfigProvider, theme } from 'antd'

import styles from './styles.module.scss'
import React, { useEffect, useRef } from 'react'
import { $position, closePage, openPage, updatePosition } from './model'
import { QueryContainer } from './components/QueryContainer'
import { QueryResult } from './components/QueryResult'
import { useResizable } from 'klond90-react-resizable-layout'
import { $screenSize, resize } from '../../models/ui'
import { useStore } from 'effector-react'

export const QueryPage = () => {
  const [_, vh] = useStore($screenSize)

  const maxPosition = vh - 300

  useEffect(() => {
    openPage()
    return () => {
      closePage()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      resize([window.innerWidth, window.innerHeight])
    })
  }, [])

  const refContainer = useRef<HTMLDivElement>(null)

  const { position, separatorProps, isDragging } = useResizable({
    axis: 'y',
    initial: $position.getState(),
    min: 250,
    max: maxPosition,
    containerRef: refContainer,
  })

  useEffect(() => {
    updatePosition(position)
  }, [position])

  const realPosition = Math.min(position, maxPosition)

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#725c70',
          },
          algorithm: theme.darkAlgorithm,
        }}
      >
        <div className={styles.page} ref={refContainer}>
          <div style={{ height: realPosition, paddingBottom: '16px' }}>
            <QueryContainer height={realPosition} />
          </div>
          <hr
            {...separatorProps}
            className={`${styles.page__separator} ${isDragging ? styles.page__separator_focus : ''}`}
          />
          <QueryResult />
        </div>
      </ConfigProvider>
    </>
  )
}
