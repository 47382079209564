module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="react-content">\r\n</div>\r\n\r\n<div id="replicationContent" class="innerContent replicationContent">\r\n    ';
 if (mode !== 0) { 
__p+='\r\n    <div class="pure-g cluster-values" style="margin-top: 0;">\r\n\r\n        <div class="pure-u-1-2 pure-u-md-1-3">\r\n            <div class="valueWrapper">\r\n                <div id="info-state-id" class="value">'+
((__t=( info.state ))==null?'':__t)+
'</div>\r\n                <div class="graphLabel">State</div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="pure-u-1-2 pure-u-md-1-3">\r\n            <div class="valueWrapper">\r\n                <div id="info-mode-id" class="value">'+
((__t=( info.mode ))==null?'':__t)+
'</div>\r\n                <div class="graphLabel">Mode</div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="pure-u-1-2 pure-u-md-1-3">\r\n            <div class="valueWrapper">\r\n                ';
 if (info.role === 'follower' && mode !== 3) { 
__p+='\r\n                    <div id="info-role-id" class="value">Follower</div>\r\n                ';
 } else if (info.role === 'leader' && mode !== 3) { 
__p+='\r\n                    <div id="info-role-id" class="value">Leader</div>\r\n                ';
 } else { 
__p+='\r\n                <div id="info-role-id" class="value">'+
((__t=( info.role ))==null?'':__t)+
'</div>\r\n                ';
 } 
__p+='\r\n                <div class="graphLabel">Role</div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="pure-u-1-2 pure-u-md-1-3">\r\n            <div class="valueWrapper">\r\n                <div id="info-level-id" class="value">'+
((__t=( info.level ))==null?'':__t)+
'</div>\r\n                <div class="graphLabel">Level</div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="pure-u-1-2 pure-u-md-1-3">\r\n            <div class="valueWrapper">\r\n                <div id="info-msg-id" class="value">'+
((__t=( info.health ))==null?'':__t)+
'</div>\r\n                <div class="graphLabel">Health</div>\r\n            </div>\r\n        </div>\r\n\r\n        ';
 if (info.role === 'follower') { 
__p+='\r\n            <div class="pure-u-1-2 pure-u-md-1-3">\r\n                <div class="valueWrapper">\r\n                    <div id="logger-lastLogTick-id" class="value"><i class="fa fa-circle-o-notch fa-spin"></i></div>\r\n                    <div class="graphLabel">Server ID</div>\r\n                </div>\r\n            </div>\r\n        ';
 } else { 
__p+='\r\n            <div class="pure-u-1-2 pure-u-md-1-3">\r\n                <div class="valueWrapper">\r\n                    <div id="logger-lastLogTick-id" class="value"><i class="fa fa-circle-o-notch fa-spin"></i></div>\r\n                    <div class="graphLabel">Last tick</div>\r\n                </div>\r\n            </div>\r\n        ';
 } 
__p+='\r\n\r\n    </div>\r\n    ';
 } 
__p+='\r\n\r\n    ';
 if (mode === 0) { 
__p+='\r\n    <div class="repl-state">\r\n        <table class="pure-table half-split">\r\n            <tbody>\r\n            <tr>\r\n                <td>\r\n                    <div><i class="fa fa-server"></i></div>\r\n                    <div id="info-state-id">'+
((__t=( info.state ))==null?'':__t)+
'</div>\r\n                </td>\r\n            </tr>\r\n            <tr>\r\n                <td>\r\n                    <div>This node is not replicating from any other node. Also there are no active followers\r\n                        found.\r\n                    </div>\r\n                </td>\r\n            </tr>\r\n            </tbody>\r\n        </table>\r\n    </div>\r\n    ';
 } 
__p+='\r\n\r\n    ';
 if (mode === 5) { 
__p+='\r\n    <div class="repl-info">\r\n        <div class="categoryBar">\r\n            <h4>Info</h4>\r\n        </div>\r\n        <table class="pure-table">\r\n            <tbody>\r\n            <tr>\r\n                <td>Mode:</td>\r\n                <td id="info-mode-id">'+
((__t=( info.mode ))==null?'':__t)+
'</td>\r\n            </tr>\r\n            <tr>\r\n                <td>Level:</td>\r\n                <td id="info-level-id">'+
((__t=( info.level ))==null?'':__t)+
'</td>\r\n            </tr>\r\n            <tr>\r\n                <td>Role:</td>\r\n                ';
 if (mode === 3) { 
__p+='\r\n                <td id="info-role-id">'+
((__t=( info.role ))==null?'':__t)+
'</td>\r\n                ';
 } else { 
__p+='\r\n                    <td id="info-role-id">Follower</td>\r\n                ';
 } 
__p+='\r\n            </tr>\r\n            </tbody>\r\n        </table>\r\n    </div>\r\n    ';
 } 
__p+='\r\n\r\n    ';
 if (info.role === 'leader') { 
__p+='\r\n        <div class="repl-comparison">\r\n            <div class="categoryBar">\r\n                <h4>Comparison</h4>\r\n            </div>\r\n\r\n            <div class="pure-g cluster-graphs">\r\n\r\n                <div class="pure-u-1-1 pure-u-md-1-2">\r\n                    <div class="graphWrapper">\r\n                        <div id="replicationTimeChart">\r\n                            <svg></svg>\r\n                        </div>\r\n                        <div class="graphLabel">COMMUNICATION</div>\r\n                    </div>\r\n                </div>\r\n\r\n                <div class="pure-u-1-1 pure-u-md-1-2">\r\n                    <div class="graphWrapper">\r\n                        <div id="replicationTickChart">\r\n                            <svg></svg>\r\n                        </div>\r\n                        <div class="graphLabel">LAST TICKS</div>\r\n                    </div>\r\n                </div>\r\n\r\n            </div>\r\n\r\n        </div>\r\n\r\n        ';
 if (mode === 3) { 
__p+='\r\n            <div class="repl-nodes">\r\n                <div class="categoryBar">\r\n                    <h4>Nodes</h4>\r\n                </div>\r\n            </div>\r\n            <table class="pure-table">\r\n                <tbody>\r\n                <tr>\r\n                    <td>Leader:</td>\r\n                    <td id="nodes-leader-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\r\n                </tr>\r\n                <tr>\r\n                    <td>Followers:</td>\r\n                    <td id="nodes-followers-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\r\n                </tr>\r\n                </tbody>\r\n            </table>\r\n        ';
 } 
__p+='\r\n    ';
 } 
__p+='\r\n\r\n    ';
 if (info.role === 'leader') { 
__p+='\r\n        <div class="repl-logger">\r\n            <div class="categoryBar">\r\n                <h4>Leader State</h4>\r\n            </div>\r\n            <table class="pure-table">\r\n                <tbody>\r\n                <tr>\r\n                    <td>Server ID:</td>\r\n                    <td id="logger-serverid-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\r\n                </tr>\r\n                <tr>\r\n                    <td>Time:</td>\r\n                    <td id="logger-time-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\r\n                </tr>\r\n                <tr>\r\n                    <td>Operations:</td>\r\n                    <td id="logger-totalEvents-id"><i class="fa fa-circle-o-notch fa-spin"></i></td>\r\n                </tr>\r\n                </tbody>\r\n            </table>\r\n        </div>\r\n\r\n        <div class="repl-logger-clients">\r\n            <div class="categoryBar">\r\n                <h4>Follower States</h4>\r\n            </div>\r\n            <table class="pure-table" id="repl-logger-clients">\r\n                <thead>\r\n                <tr>\r\n                    <th>Syncer ID</th>\r\n                    <th>Server ID</th>\r\n                    <th>Client info</th>\r\n                    <th>Time</th>\r\n                    <th>Last served tick</th>\r\n                </tr>\r\n                </thead>\r\n                <tbody>\r\n                </tbody>\r\n            </table>\r\n        </div>\r\n    ';
 } 
__p+='\r\n\r\n    ';
 if (info.role === 'follower') { 
__p+='\r\n        <div class="repl-applier">\r\n            <div class="categoryBar">\r\n                <h4>Follower States</h4>\r\n            </div>\r\n\r\n            <table id="repl-follower-table" class="pure-table" style="width: 100%">\r\n                <thead>\r\n                <tr>\r\n                    <th>Database</th>\r\n                    <th>Enabled</th>\r\n                    <th>Phase</th>\r\n                    <th>Replicating from</th>\r\n                    <th>Last tick</th>\r\n                    <th>State</th>\r\n                </tr>\r\n                </thead>\r\n\r\n                <tbody>\r\n                </tbody>\r\n\r\n            </table>\r\n        </div>\r\n    ';
 } 
__p+='\r\n\r\n</div>\r\n';
}
return __p;
};
