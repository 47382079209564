module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="application-detail-view">\r\n    ';
 var visible = " visibility: hidden; "; 
__p+='\r\n    ';
 if (installed) { 
__p+='\r\n        ';
 visible = " visibility: visible; "; 
__p+='\r\n    ';
 } 
__p+='\r\n\r\n    <div class="headerBar" style="width: 100%">\r\n        <ul class="subMenuEntries subViewNavbar pull-left" style="'+
((__t=( visible ))==null?'':__t)+
'">\r\n            <li id="service-info" class="subMenuEntry active"><a>Info</a></li>\r\n            <li id="service-api" class="subMenuEntry "><a>API</a></li>\r\n            <!--<li id="service-readme" class="subMenuEntry "><a>Readme</a></li>-->\r\n            <li id="service-settings" class="subMenuEntry "><a>Settings</a></li>\r\n        </ul>\r\n\r\n        <div class="headerButtonBar" style="display: none">\r\n            <ul class="headerButtonList">\r\n                <li>\r\n                    <a id="app-deps"\r\n                       class="headerButton '+
((__t=( _.isEmpty(app.get('deps')) ? 'disabled' : '' ))==null?'':__t)+
' '+
((__t=( app.hasUnconfiguredDependencies() ? 'error' : '' ))==null?'':__t)+
'">\r\n                        <span class="fa fa-cubes" title="Dependencies"></span>\r\n                    </a>\r\n                </li>\r\n                <li id="app-scripts" class="dropdown">\r\n                    <a class="headerButton '+
((__t=( _.isEmpty(app.get('scripts')) ? 'disabled' : '' ))==null?'':__t)+
'" title="Scripts">\r\n                        <i class="fa fa-code"></i>\r\n                    </a>\r\n                    <ul class="script-dropdown-menu dropdown-toolbar dropdown-menu" id="scripts_dropdown">\r\n                        ';
 _.each(app.get('scripts'), function (title, name) { 
__p+='\r\n                            <li class="dropdown-item">\r\n                                <a class="tab" data-script="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( title ))==null?'':__t)+
'</a>\r\n                            </li>\r\n                        ';
 }) 
__p+='\r\n                    </ul>\r\n                </li>\r\n                <li>\r\n                    <a id="app-tests" class="headerButton">\r\n                        <span class="fa fa-flask" title="Tests"></span>\r\n                    </a>\r\n                </li>\r\n                <li>\r\n                    <a id="download-app" class="headerButton '+
((__t=( app.isSystem() ? 'disabled' : '' ))==null?'':__t)+
'">\r\n                        <span class="fa fa-download" title="Download"></span>\r\n                    </a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n\r\n    <section class="info" id="informationfunctions" style="display: none">\r\n        <div class="header">\r\n            <div id="hidden_buttons" style="display: none">\r\n                <button class="delete btn-danger" '+
((__t=( app.isSystem()?"disabled":"" ))==null?'':__t)+
' >Delete</button>\r\n                <button id="app-replace" class="app-replace upgrade btn-warning" '+
((__t=( app.isSystem()?"disabled":"" ))==null?'':__t)+
'>\r\n                    Replace\r\n                </button>\r\n                <button id="app-switch-mode"\r\n                        class="app-switch-mode switch-mode btn-warning">'+
((__t=( app.get('development')?'Set Production':'Set Development' ))==null?'':__t)+
'</button>\r\n            </div>\r\n        </div>\r\n    </section>\r\n\r\n    <section class="info" id="information" style="width: 100%;">\r\n        <div class="pure-g">\r\n\r\n            <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-3-4 foxx-left">\r\n                <div class="foxx-icon-container pull-left">\r\n                    <img src="'+
((__t=( app.thumbnailUrl ))==null?'':__t)+
'" alt="Icon for Service" class="icon"/>\r\n                </div>\r\n                <div class="pull-left" style="margin-left: 15px">\r\n                    <h3>\r\n                        '+
((__t=( app.attributes.name ))==null?'':__t)+
'\r\n                    </h3>\r\n                    <p class="description">'+
((__t=( app.attributes.description ))==null?'':__t)+
'</p>\r\n                </div>\r\n                <div id="app-warning" class="app-warning"\r\n                     style="clear: both; display:'+
((__t=( app.needsAttention()?'block':'none' ))==null?'':__t)+
';">\r\n                    <h4>Out of order</h4>\r\n                    <p id="app-warning-broken" style="display:'+
((__t=( app.isBroken()?'block':'none' ))==null?'':__t)+
';">This service has\r\n                        failed to mount due to an error.</p>\r\n                    <p id="app-warning-config" style="display:'+
((__t=( app.needsConfiguration()?'block':'none' ))==null?'':__t)+
';">This\r\n                        service has not yet been configured properly.</p>\r\n                    <p id="app-warning-deps" style="display:'+
((__t=( app.hasUnconfiguredDependencies()?'block':'block' ))==null?'':__t)+
';">\r\n                        Some dependencies have not yet been set up.</p>\r\n                </div>\r\n                <div class="categoryBar">\r\n                    <h4>Readme</h4>\r\n                </div>\r\n                <div id="readme" class="readme">\r\n                    ';
 if (app.get('readme')) { 
__p+='\r\n                        '+
((__t=( marked(app.get('readme')) ))==null?'':__t)+
'\r\n                    ';
 } else { 
__p+='\r\n                        <p>No README data found.</p>\r\n                    ';
 } 
__p+='\r\n                </div>\r\n            </div>\r\n\r\n            <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-1-4 foxx-right">\r\n                <div class="foxx-label-container">\r\n                    <p>\r\n                        <span>Author: </span><span>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span>Mount: </span><span>'+
((__t=( app.attributes.mount ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span>Mode: </span><span\r\n                                class="mode '+
((__t=( app.get('development')?'development':'production' ))==null?'':__t)+
'">'+
((__t=( app.get('development')?'Development':'Production' ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        <span>Version: </span><span class="version">'+
((__t=( app.attributes.version ))==null?'':__t)+
'</span>\r\n                    </p>\r\n                    <p>\r\n                        ';
 if (app.attributes.license) { 
__p+='\r\n                            <span>License: </span><span>'+
((__t=( app.attributes.license ))==null?'':__t)+
'</span>\r\n                        ';
 } 
__p+='\r\n                    </p>\r\n                    <p>\r\n                        ';
 if (app.get('development') === true) { 
__p+='\r\n                            <span>Path: </span><span class="developmentSpan">'+
((__t=( app.attributes.path ))==null?'':__t)+
'</span>\r\n                        ';
 } else { 
__p+='\r\n                            <span></span>\r\n                        ';
 } 
__p+='\r\n                    </p>\r\n                </div>\r\n                <div class="header_right">\r\n                    <input type="button" class="open btn-success" disabled="true" value="Show Interface"/>\r\n                </div>\r\n            </div>\r\n\r\n        </div>\r\n    </section>\r\n    <main>\r\n        <div id="app-info" class="app-info" style="display: none;">\r\n            <h4>Info</h4>\r\n            <p id="app-info-swagger">Documentation for this service is not yet configured.</p>\r\n        </div>\r\n        <div id="swagger" class="swagger" style="display: none;">\r\n            <a id="jsonLink">Swagger</a>\r\n            <div id="swaggerJsonContent" style="display: none">\r\n                <div id="swaggerJsonEditor" style="height: 100%;"></div>\r\n            </div>\r\n        </div>\r\n        <div id="settings" class="readme" style="display: none; padding: 15px;">\r\n            ';
 if (_.isEmpty(app.get('config'))) { 
__p+='\r\n                <p>No configurable settings available.</p>\r\n            ';
 } 
__p+='\r\n            <div class="buttons" style="margin-left: -10px; margin-top: 20px;"></div>\r\n        </div>\r\n    </main>\r\n    <!--<aside class="meta" id="sideinformation">\r\n      <dl>\r\n        <dt>Author</dt>\r\n        <dd>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</dd>\r\n        <dt>Contributors</dt>\r\n        <dd>\r\n          ';
 if (app.attributes.contributors && app.attributes.contributors.length > 0) { 
__p+='\r\n          <ul>\r\n          ';
 _.each(app.attributes.contributors, function (contributor) { 
__p+='\r\n            <li><a href="mailto:'+
((__t=( contributor.email ))==null?'':__t)+
'">'+
((__t=( contributor.name ))==null?'':__t)+
'</a></li>\r\n          ';
 }); 
__p+='\r\n          </ul>\r\n          ';
 } else { 
__p+='\r\n          No contributors\r\n          ';
 } 
__p+='\r\n        </dd>\r\n        <dt>Links</dt>\r\n        <dd>\r\n          <ul>\r\n            <li>\r\n              <a href="'+
((__t=( baseUrl ))==null?'':__t)+
'/_admin/monitor/foxxes/docs/standalone/index.html?mount='+
((__t=( encodeURIComponent(app.get('mount')) ))==null?'':__t)+
'" target="_blank">\r\n                API Documentation\r\n              </a>\r\n            </li>\r\n            <li>\r\n              <a href="'+
((__t=( baseUrl ))==null?'':__t)+
'/_admin/monitor/foxxes/docs/swagger.json?mount='+
((__t=( encodeURIComponent(app.get('mount')) ))==null?'':__t)+
'" target="_blank">\r\n                API as JSON\r\n              </a>\r\n            </li>\r\n            ';
 if (app.attributes.repository && app.attributes.repository.type == 'git') { 
__p+='\r\n              <li><a href="'+
((__t=( app.attributes.repository.url ))==null?'':__t)+
'" target="_blank">Git Repository</a></li>\r\n            ';
 } 
__p+='\r\n          </ul>\r\n        </dd>\r\n      </dl>\r\n    </aside>-->\r\n</div>\r\n';
}
return __p;
};
