import type { Definition, DocJson } from '../api/api';

type StrictDefinition = Definition & { type: string };


export const getDefinition = (spec: DocJson, type: string): StrictDefinition | null => {
    console.log(spec, type)
    const definition = spec.definitions[type];
    if (!definition) {
        return null
    }
    console.log('def', definition)
    if (definition.$ref) {
        const ref = definition.$ref;
        const refPath = ref.split('/');
        if (refPath[0] === '#' && refPath[1] === 'definitions') {
            return getDefinition(spec, refPath[2]);
        }
        return null;
    }
    if (definition.type && typeof definition.type === 'string') {
        const d = {...definition, type: definition.type || ''}
        return d;
    }
    return null;
};
