module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="navlist dbms-relation-ul" id="dbmsCollectionUl">\r\n    ';
 if (isCluster && frontendConfig.clusterApiJwtPolicy !== 'jwt-all') { 
__p+='\r\n        <li class="nodes-menu"><a id="nodes" class="tab" href="#nodes">Nodes</a></li>\r\n    ';
 } else if (!isCluster) { 
__p+='\r\n        <li class="dashboard-menu"><a id="dashboard" class="tab" href="#dashboard">Dashboard</a></li>\r\n    ';
 } 
__p+='\r\n    ';
 if (currentDB.get('isSystem') || currentDB.get(!isCluster)) { 
__p+='\r\n        <li class="navbar-spacer big"></li>\r\n    ';
 } 
__p+='\r\n    ';
 if (currentDB.get('isSystem')) { 
__p+='\r\n        <li class="databases-menu"><a id="databases" class="tab" href="#databases">Databases</a></li>\r\n    ';
 } 
__p+='\r\n    ';
 if (currentDB.get('isSystem')) { 
__p+='\r\n        ';
 if (!readOnly) { 
__p+='\r\n            <li class="users-menu"><a id="users" class="tab" href="#users">Users</a></li>\r\n        ';
 } 
__p+='\r\n    ';
 } 
__p+='\r\n    ';
 if (currentDB.get('isSystem') && !isCluster) { 
__p+='\r\n        <li class="replication-menu"><a id="replication" class="tab" href="#replication">Replication</a></li>\r\n    ';
 } 
__p+='\r\n    ';
 if (!isCluster) { 
__p+='\r\n        <li class="logs-menu"><a id="logs" class="tab" href="#logs">Logs</a></li>\r\n    ';
 } 
__p+='\r\n    <li class="navbar-spacer big"></li>\r\n    <li>\r\n        <div class="db-selector">\r\n            <i class="fa fa-database"></i>\r\n            <form id="dbForm">\r\n                <select id="switchDatabase" class="form-control">\r\n                    <option>database</option>\r\n                </select>\r\n            </form>\r\n        </div>\r\n    </li>\r\n    ';
 if (isCluster && frontendConfig.clusterApiJwtPolicy !== 'jwt-all') { 
__p+='\r\n        <li class="shards-menu"><a id="shards" class="tab" href="#shards">Shards</a></li>\r\n    ';
 } 
__p+='\r\n    <li class="relations-menu"><a id="relations" class="tab" href="#relations">Relations</a></li>\r\n    <li class="queries-menu"><a id="queries" class="tab" href="#queries">Queries</a></li>\r\n</ul>\r\n\r\n<div class="social-media-panel">\r\n    <ul class="list-inline">\r\n        <li>\r\n            <a\r\n                target="_blank"\r\n                href="https://twitter.com/nil_foundation"\r\n                class="btn-social btn-outline">\r\n                <i class="fa-brands fa-twitter"></i>\r\n            </a>\r\n        </li>\r\n\r\n        <li>\r\n            <a\r\n                target="_blank"\r\n                href="https://www.linkedin.com/company/nil-foundation"\r\n                class="btn-social btn-outline">\r\n                <i class="fa-brands fa-linkedin"></i>\r\n            </a>\r\n        </li>\r\n\r\n        <li>\r\n            <a\r\n                target="_blank"\r\n                href="https://t.me/nilfoundation"\r\n                class="btn-social btn-outline">\r\n                <i class="fa-brands fa-telegram"></i>\r\n            </a>\r\n        </li>\r\n\r\n        <li>\r\n            <a\r\n                target="_blank"\r\n                href="http://github.com/nilfoundation"\r\n                class="btn-social btn-outline">\r\n                <i class="fa-brands fa-github"></i>\r\n            </a>\r\n        </li>\r\n\r\n        <li>\r\n            <a\r\n                target="_blank"\r\n                href="https://discord.gg/KmTAEjbmM3"\r\n                class="btn-social btn-outline">\r\n                <i class="fa-brands fa-discord"></i>\r\n            </a>\r\n        </li>\r\n    </ul>\r\n</div>\r\n';
}
return __p;
};
