module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var figuresData = content.figures || {};
var revision = content.revision;
var clusterData = content.cluster || {};

if (!figuresData.figures) {
    figuresData.figures = {};
}

__p+='\r\n\r\n<div>\r\n    <div style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid rgba(0, 0, 0, .3);">\r\n        Properties\r\n    </div>\r\n    <table id="relationInfoTable" class="dbms-table">\r\n        <tr id="relationSyncBox">\r\n            <th class="relationInfoTh2">Wait for sync:</th>\r\n            <th class="relationInfoTh">\r\n                <div id="show-relation-sync" class="modal-text">\r\n                    '+
((__t=( figuresData.waitForSync ))==null?'':__t)+
'\r\n                </div>\r\n            </th>\r\n            <th class="tooltipInfoTh">\r\n                <div>\r\n                <span class="modalTooltips fa fa-info-circle"\r\n                      title="Synchronize to disk before returning from a create or update of a document.">\r\n                </span>\r\n                </div>\r\n            </th>\r\n        </tr>\r\n\r\n        <tr>\r\n            <th class="relationInfoTh2">ID:</th>\r\n            <th class="relationInfoTh">\r\n                <div id="show-relation-id" class="modal-text">\r\n                    '+
((__t=( content.model.get("id") ))==null?'':__t)+
'\r\n                </div>\r\n            <th>\r\n        </tr>\r\n\r\n        <tr id="relationRevBox">\r\n            <th class="relationInfoTh2">Revision:</th>\r\n            <th class="relationInfoTh">\r\n                <div id="show-relation-rev" class="modal-text">\r\n                    '+
((__t=( revision.revision ))==null?'':__t)+
'\r\n                </div>\r\n            </th>\r\n            <th class="relationInfoTh">\r\n            </th>\r\n        </tr>\r\n\r\n        <tr>\r\n            <th class="relationInfoTh2">Type:</th>\r\n            <th class="relationInfoTh">\r\n                <div class="modal-text">'+
((__t=( content.model.get("type") ))==null?'':__t)+
'</div>\r\n            </th>\r\n            <th class="relationInfoTh">\r\n            </th>\r\n        </tr>\r\n\r\n        <tr>\r\n            <th class="relationInfoTh2">Status:</th>\r\n            <th class="relationInfoTh">\r\n                <div class="modal-text">'+
((__t=( content.model.get("status") ))==null?'':__t)+
'</div>\r\n            </th>\r\n            <th class="relationInfoTh">\r\n            </th>\r\n        </tr>\r\n\r\n        ';
 if (figuresData.hasOwnProperty('cacheEnabled') && figuresData.cacheEnabled) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Cache enabled:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.cacheEnabled ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.hasOwnProperty('isSystem')) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">System relation:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.isSystem ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.hasOwnProperty('syncByRevision')) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Uses revision trees:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.syncByRevision ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n    </table>\r\n\r\n    ';
 if (figuresData.hasOwnProperty('figures')) { 
__p+='\r\n        <div\r\n                style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid var(--c-primary-blur); border-top: 1px solid var(--c-primary-blur)">\r\n            Figures\r\n        </div>\r\n        <table id="relationFiguresTable" class="dbms-table">\r\n\r\n            ';
 if (figuresData.figures.hasOwnProperty('documentsSize')) { 
__p+='\r\n                <tr>\r\n                    <th class="relationInfoTh2">Estimated documents size:</th>\r\n                    <th class="relationInfoTh">\r\n                        <div class="modal-text">'+
((__t=( prettyBytes(figuresData.figures.documentsSize) ))==null?'':__t)+
'</div>\r\n                    </th>\r\n                    <th class="tooltipInfoTh">\r\n                        <div>\r\n                <span class="modalTooltips fa fa-info-circle"\r\n                      title="The approximate cumulative size of all documents in this relation (on the leader). This is an estimate provided by the storage engine and may not be accurate.">\r\n                </span>\r\n                        </div>\r\n                    </th>\r\n                </tr>\r\n            ';
 } 
__p+='\r\n\r\n            ';
 if (figuresData.figures.hasOwnProperty('indexes')) { 
__p+='\r\n\r\n                ';
 if (figuresData.figures.indexes.hasOwnProperty('count')) { 
__p+='\r\n                    ';
 let amountOfIndexes = figuresData.figures.indexes.count; 
__p+='\r\n                    <tr>\r\n                        <th class="relationInfoTh2">Number of indexes:</th>\r\n                        <th class="relationInfoTh">\r\n                            <div class="modal-text">'+
((__t=( amountOfIndexes ))==null?'':__t)+
'</div>\r\n                        </th>\r\n                        <th class="tooltipInfoTh">\r\n                        </th>\r\n                    </tr>\r\n\r\n                    ';
 if (amountOfIndexes > 0 && figuresData.figures.indexes.hasOwnProperty('size')) { 
__p+='\r\n                        <tr>\r\n                            <th class="relationInfoTh2">Estimated size of indexes:</th>\r\n                            <th class="relationInfoTh">\r\n                                <div class="modal-text">'+
((__t=( prettyBytes(figuresData.figures.indexes.size) ))==null?'':__t)+
'</div>\r\n                            </th>\r\n                            <th class="tooltipInfoTh">\r\n                                <div>\r\n                  <span class="modalTooltips fa fa-info-circle"\r\n                        title="The approximate cumulative size of all indexes entries in this relation (on the leader). This is an estimate provided by the storage engine and may not be accurate.">\r\n                  </span>\r\n                                </div>\r\n                            </th>\r\n                        </tr>\r\n                    ';
 } 
__p+='\r\n\r\n                ';
 } 
__p+='\r\n\r\n            ';
 } 
__p+='\r\n        </table>\r\n\r\n    ';
 } 
__p+='\r\n\r\n    <div\r\n            style="color: #717d90; font-weight: 600; font-size: 12.5pt; padding: 10px; border-bottom: 1px solid var(--c-primary-blur); border-top: 1px solid var(--c-primary-blur)">\r\n        Data distribution\r\n    </div>\r\n    <table id="relationDistributionTable" class="dbms-table">\r\n        ';
 if (figuresData.replicationFactor) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Replication factor:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.replicationFactor ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="tooltipInfoTh">\r\n                    <div>\r\n                <span class="modalTooltips fa fa-info-circle"\r\n                      title="Determines how many copies of each shard are kept on different DB-Servers.">\r\n                </span>\r\n                    </div>\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.writeConcern) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Write concern:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.writeConcern ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="tooltipInfoTh">\r\n                    <div>\r\n                <span class="modalTooltips fa fa-info-circle"\r\n                      title="Determines how many copies of each shard are required to be in sync on the different DB-Servers. If there are less then these many copies in the cluster a shard will refuse to write. Writes to shards with enough up-to-date copies will succeed at the same time however. The value of writeConcern can not be larger than replicationFactor">\r\n                </span>\r\n                    </div>\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.shardKeys) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Shard keys:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( JSON.stringify(figuresData.shardKeys) ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="tooltipInfoTh">\r\n                    <div>\r\n                <span class="modalTooltips fa fa-info-circle"\r\n                      title="Contains the names of document attributes that are used to determine the target shard for documents.">\r\n                </span>\r\n                    </div>\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.distributeShardsLike) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Distribute shards like:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.distributeShardsLike ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.smartJoinAttribute) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">SmartJoin attribute:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.smartJoinAttribute ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.hasOwnProperty('isSmartChild') && figuresData.isSmartChild) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">SmartChild:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.isSmartChild ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.hasOwnProperty('isDisjoint') && figuresData.isDisjoint) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Disjoint relation:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.isDisjoint ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.numberOfShards) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Number of shards:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">'+
((__t=( figuresData.numberOfShards ))==null?'':__t)+
'</div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n\r\n        ';
 if (figuresData.shards) { 
__p+='\r\n            <tr>\r\n                <th class="relationInfoTh2">Shards:</th>\r\n                <th class="relationInfoTh">\r\n                    <div class="modal-text">\r\n                        <table class="pure-table pure-table-inner">\r\n                            <thead>\r\n                            <tr>\r\n                                <th>Name</th>\r\n                                <th>Documents</th>\r\n                                <th>Leader</th>\r\n                                <th>Follower(s)</th>\r\n                            </tr>\r\n\r\n                            ';

                                let allShards = Object.keys(figuresData.shards);
                                let shardCounts = {};
                                if (clusterData.hasOwnProperty('shardCounts')) {
                                    shardCounts = clusterData.shardCounts;
                                }
                            allShards.forEach(function(shardName, index) {
                                let count;
                                if (shardCounts.hasOwnProperty(shardName)) {
                                    count = shardCounts[shardName];
                                } else {
                                    count = 'n/A';
                                }
                            
__p+='\r\n\r\n                            <tr>\r\n                                <td>\r\n                                    <span>'+
((__t=( shardName ))==null?'':__t)+
'</span>\r\n                                </td>\r\n                                <td>\r\n                                    <span>'+
((__t=( count ))==null?'':__t)+
'</span>\r\n                                </td>\r\n                                <td>\r\n                                    ';

                                        let leaderName = figuresData.shards[shardName][0];
                                        try {
                                            leaderName = window.dbmsHelper.getDatabaseShortName(leaderName);
                                        } catch (ignore) {
                                        }
                                    
__p+='\r\n                                    <span>'+
((__t=( leaderName ))==null?'':__t)+
'</span>\r\n                                </td>\r\n                                <td>\r\n                                    <ul>\r\n                                        ';
 figuresData.shards[shardName].splice(1, figuresData.shards[shardName].length).forEach(follower => {
                                            let followerName = follower;
                                            try {
                                                followerName = window.dbmsHelper.getDatabaseShortName(followerName);
                                            } catch (ignore) {
                                            }
                                        
__p+='\r\n                                        <li>\r\n                                            <span>'+
((__t=( followerName ))==null?'':__t)+
'</span>\r\n                                        </li>\r\n                                        ';
 }); 
__p+='\r\n\r\n                                    </ul>\r\n                                </td>\r\n                            </tr>\r\n\r\n                            ';
 }); 
__p+='\r\n\r\n                            </thead>\r\n                        </table>\r\n                    </div>\r\n                </th>\r\n                <th class="relationInfoTh">\r\n                </th>\r\n            </tr>\r\n        ';
 } 
__p+='\r\n    </table>\r\n\r\n</div>\r\n';
}
return __p;
};
