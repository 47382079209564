module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="arangoToolbar arangoToolbarTop arangoFoxxToolbarTop">\r\n    <div class="pull-left">\r\n    </div>\r\n\r\n    <div class="pull-right">\r\n    </div>\r\n</div>\r\n\r\n<div class="contentDiv createModalDialog">\r\n    <div id="urlFoxx" class="foxxesInner modal-body" style="min-height: 150px;">\r\n        <div>\r\n            <p>\r\n                Download a Foxx service from a public available url. Access using credentials in the URL is allowed\r\n                (https://username:password@www.example.com/).\r\n            </p>\r\n            <table>\r\n                <tr class="tableRow">\r\n                    <th class="relationInfoTh">\r\n                        URL*:\r\n                    </th>\r\n                    <th class="relationInfoTh">\r\n                        <input type="text" id="repository" value="" placeholder="https://www.example.com/foxx.zip">\r\n                    </th>\r\n                </tr>\r\n            </table>\r\n        </div>\r\n    </div>\r\n    <div class="modal-footer">\r\n        ';
 if (upgrade) { 
__p+='\r\n            <button id="installUrlService" class="btn-success" style="float:right" disabled>Replace</button>\r\n        ';
 } else { 
__p+='\r\n            <button id="installUrlService" class="btn-success" style="float:right" disabled>Install</button>\r\n        ';
 } 
__p+='\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
