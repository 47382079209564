/* jshint browser: true */
/* jshint unused: false */
/* global dbmsHelper, _, Backbone, window, templateEngine, $ */

(function () {
  'use strict';

  window.UserPermissionView = Backbone.View.extend({
    el: '#content',
    template: templateEngine.createTemplate('userPermissionView.ejs'),
    initialize: function initialize(options) {
      this.username = options.username;
    },
    remove: function remove() {
      this.$el.empty().off(); /* off to unbind the events */
      this.stopListening();
      this.unbind();
      delete this.el;
      return this;
    },
    events: {
      'click #userPermissionView .dbCheckbox': 'setDBPermission',
      'click #userPermissionView .collCheckbox': 'setCollPermission',
      'click .db-row': 'toggleAccordion'
    },
    render: function render(open, error) {
      var self = this;
      this.collection.fetch({
        fetchAllUsers: true,
        success: function success() {
          self.continueRender(open, error);
        }
      });
    },
    toggleAccordion: function toggleAccordion(e) {
      // if checkbox was hit
      if ($(e.target).attr('type')) {
        return;
      }
      if ($(e.target).parent().hasClass('noAction')) {
        return;
      }
      if ($(e.target).hasClass('inner') || $(e.target).is('span') || $(e.target).hasClass('fa-info-circle')) {
        return;
      }
      var visible = $(e.currentTarget).find('.relation-row').is(':visible');
      var db = $(e.currentTarget).attr('id').split('-')[0];
      $('.relation-row').hide();

      // unhighlight db labels
      $('.db-label').css('font-weight', 200);
      $('.db-label').css('color', '#8a969f');

      // if relations are available
      if ($(e.currentTarget).find('.relation-row').children().length > 4) {
        // if menu was already visible -> then hide
        $('.db-row .fa-caret-down').hide();
        $('.db-row .fa-caret-right').show();
        if (visible) {
          $(e.currentTarget).find('.relation-row').hide();
        } else {
          // else show menu
          $(e.currentTarget).find('.relation-row').fadeIn('fast');
          // highlight db label
          $(e.currentTarget).find('.db-label').css('font-weight', 600);
          $(e.currentTarget).find('.db-label').css('color', 'rgba(64, 74, 83, 1)');
          // caret animation
          $(e.currentTarget).find('.fa-caret-down').show();
          $(e.currentTarget).find('.fa-caret-right').hide();
        }
      } else {
        // caret animation
        $('.db-row .fa-caret-down').hide();
        $('.db-row .fa-caret-right').show();
        dbmsHelper.dbmsNotification('Permissions', 'No relations in "' + db + '" available.');
      }
    },
    setCollPermission: function setCollPermission(e) {
      var db = $(e.currentTarget).attr('db');
      var relation = $(e.currentTarget).attr('relation');
      var value;
      if ($(e.currentTarget).hasClass('readOnly')) {
        value = 'ro';
      } else if ($(e.currentTarget).hasClass('readWrite')) {
        value = 'rw';
      } else if ($(e.currentTarget).hasClass('noAccess')) {
        value = 'none';
      } else {
        value = 'undefined';
      }
      this.sendCollPermission(this.currentUser.get('user'), db, relation, value);
    },
    setDBPermission: function setDBPermission(e) {
      var db = $(e.currentTarget).attr('name');
      var value;
      if ($(e.currentTarget).hasClass('readOnly')) {
        value = 'ro';
      } else if ($(e.currentTarget).hasClass('readWrite')) {
        value = 'rw';
      } else if ($(e.currentTarget).hasClass('noAccess')) {
        value = 'none';
      } else {
        value = 'undefined';
      }
      if (db === '_system') {
        // special case, ask if user really want to revoke persmission here
        var buttons = [];
        var tableContent = [];
        tableContent.push(window.modalView.createReadOnlyEntry('db-system-revoke-button', 'Caution', 'You are changing the _system database permission. Really continue?', undefined, undefined, false));
        buttons.push(window.modalView.createSuccessButton('Ok', this.sendDBPermission.bind(this, this.currentUser.get('user'), db, value)));
        buttons.push(window.modalView.createCloseButton('Cancel', this.rollbackInputButton.bind(this, db)));
        window.modalView.show('modalTable.ejs', 'Change _system Database Permission', buttons, tableContent);
      } else {
        this.sendDBPermission(this.currentUser.get('user'), db, value);
      }
    },
    rollbackInputButton: function rollbackInputButton(name, error) {
      // this method will refetch server permission state and re-render everything again from scratch
      var open;
      _.each($('.relation-row'), function (elem, key) {
        if ($(elem).is(':visible')) {
          open = $(elem).parent().attr('id');
        }
      });
      if (open) {
        this.render(open, error);
      } else {
        this.render();
      }
      window.modalView.hide();
    },
    sendCollPermission: function sendCollPermission(user, db, relation, value) {
      var self = this;
      if (value === 'undefined') {
        this.revokeCollPermission(user, db, relation);
      } else {
        $.ajax({
          type: 'PUT',
          url: dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database/' + encodeURIComponent(db) + '/' + encodeURIComponent(relation)),
          contentType: 'application/json',
          data: JSON.stringify({
            grant: value
          }),
          success: function success() {
            self.rollbackInputButton(null);
          },
          error: function error(e) {
            self.rollbackInputButton(null, e);
          }
        });
      }
    },
    revokeCollPermission: function revokeCollPermission(user, db, relation) {
      var self = this;
      $.ajax({
        type: 'DELETE',
        url: dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database/' + encodeURIComponent(db) + '/' + encodeURIComponent(relation)),
        contentType: 'application/json',
        success: function success(e) {
          self.rollbackInputButton(null, e);
        },
        error: function error(e) {
          self.rollbackInputButton(null, e);
        }
      });
    },
    sendDBPermission: function sendDBPermission(user, db, value) {
      var self = this;
      if (value === 'undefined') {
        this.revokeDBPermission(user, db);
      } else {
        $.ajax({
          type: 'PUT',
          url: dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database/' + encodeURIComponent(db)),
          contentType: 'application/json',
          data: JSON.stringify({
            grant: value
          }),
          success: function success() {
            self.rollbackInputButton(null);
          },
          error: function error(e) {
            self.rollbackInputButton(null, e);
          }
        });
      }
    },
    revokeDBPermission: function revokeDBPermission(user, db) {
      var self = this;
      $.ajax({
        type: 'DELETE',
        url: dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(user) + '/database/' + encodeURIComponent(db)),
        contentType: 'application/json',
        success: function success(e) {
          self.rollbackInputButton(null, e);
        },
        error: function error(e) {
          self.rollbackInputButton(null, e);
        }
      });
    },
    continueRender: function continueRender(open, error) {
      var self = this;
      this.currentUser = this.collection.findWhere({
        user: this.username
      });
      var url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(self.currentUser.get('user')) + '/database?full=true');

      // FETCH COMPLETE DB LIST
      $.ajax({
        type: 'GET',
        url: url,
        contentType: 'application/json',
        success: function success(data) {
          // fetching available dbs and permissions
          self.finishRender(data.result, open, error);
        },
        error: function error(data) {
          dbmsHelper.dbmsError('User', 'Could not fetch user permissions');
        }
      });
    },
    finishRender: function finishRender(permissions, open, error) {
      // sort permission databases
      var sortedArr = _.pairs(permissions);
      sortedArr.sort();
      sortedArr = _.object(sortedArr);
      $(this.el).html(this.template.render({
        permissions: sortedArr
      }));
      // * wildcard at the end
      $('.noAction').first().appendTo('.pure-table-body');
      $('.pure-table-body').height(window.innerHeight - 200);
      if (open) {
        $('#' + open).click();
      }
      if (error && error.responseJSON && error.responseJSON.errorMessage) {
        dbmsHelper.dbmsError('User', error.responseJSON.errorMessage);
      }
      // style default radio boxes
      this.styleDefaultRadios(permissions);
      // tooltips
      dbmsHelper.createTooltips();
      // check if current user is root
      this.checkRoot();
      this.breadcrumb();
    },
    checkRoot: function checkRoot() {
      // disable * in _system db for root user, because it is not allowed to lower permissions there
      if (this.currentUser.get('user') === 'root') {
        $('#_system-db #___-relation input').attr('disabled', 'true');
      }
    },
    styleDefaultRadios: function styleDefaultRadios(permissions, refresh) {
      var serverLevelDefaultPermission;
      try {
        serverLevelDefaultPermission = permissions['*'].permission;
      } catch (ignore) {
        // just ignore, not part of the response
      }
      var self = this;
      var getMaxPermissionAccess = function getMaxPermissionAccess(databaseLevel, relationLevel, serverLevel) {
        // will return max permission level
        if (databaseLevel === 'undefined' && relationLevel === 'undefined' && serverLevel !== 'undefined') {
          return serverLevel;
        } else if (relationLevel === 'undefined' || relationLevel === 'none') {
          // means - use default is selected here
          if (serverLevel === 'rw' || databaseLevel === 'rw') {
            return 'rw';
          } else if (serverLevel === 'ro' || databaseLevel === 'ro') {
            return 'ro';
          } else if (serverLevel === 'none' || databaseLevel === 'none') {
            return 'none';
          } else {
            return 'undefined';
          }
        } else if (serverLevel === 'rw' || databaseLevel === 'rw' || relationLevel === 'rw') {
          return 'rw';
        } else if (serverLevel === 'ro' || databaseLevel === 'ro' || relationLevel === 'ro') {
          return 'ro';
        } else if (databaseLevel === 'none' || relationLevel === 'none') {
          if (serverLevel !== 'undefined') {
            return serverLevel;
          }
          return 'none';
        } else {
          return 'undefined';
        }
      };
      var someFunction = function someFunction(permissions) {
        $('.db-row input').css('box-shadow', 'none');
        // var cssShadow = '#2ecc71 0px 1px 4px 4px';
        var cssShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px 4px';
        _.each(permissions, function (perms, database) {
          if (perms.relations) {
            // Default Database Permission (wildcard)
            var databaseLevelDefaultPermission = perms.permission;
            // Default Collection Permission (wildcard)
            var relationLevelDefaultPermission = perms.relations['*'];
            if (databaseLevelDefaultPermission === 'undefined') {
              if (serverLevelDefaultPermission) {
                if (serverLevelDefaultPermission === 'rw') {
                  $('#' + database + '-db .mid > .readWrite').first().css('box-shadow', cssShadow);
                } else if (serverLevelDefaultPermission === 'ro') {
                  $('#' + database + '-db .mid > .readOnly').first().css('box-shadow', cssShadow);
                } else if (serverLevelDefaultPermission === 'none') {
                  $('#' + database + '-db .mid > .noAccess').first().css('box-shadow', cssShadow);
                }
              }
            }
            _.each(perms.relations, function (access, relation) {
              if (relation.charAt(0) !== '_' && relation.charAt(0) !== '*') {
                if (access === 'undefined') {
                  // This means, we have no specific value set, we need now to compare if relation level wildcard is set and
                  // if database level wildcard is set and choose the max of it to display.
                  var calculatedCollectionPermission = getMaxPermissionAccess(databaseLevelDefaultPermission, relationLevelDefaultPermission, serverLevelDefaultPermission);
                  if (calculatedCollectionPermission === 'rw') {
                    $('#' + database + '-db #' + relation + '-relation .readWrite').css('box-shadow', cssShadow);
                  } else if (calculatedCollectionPermission === 'ro') {
                    $('#' + database + '-db #' + relation + '-relation .readOnly').css('box-shadow', cssShadow);
                  } else if (calculatedCollectionPermission === 'none') {
                    $('#' + database + '-db #' + relation + '-relation .noAccess').css('box-shadow', cssShadow);
                  }
                }
              }
            });
          }
        });
      };
      if (refresh) {
        var url = dbmsHelper.databaseUrl('/_api/user/' + encodeURIComponent(self.currentUser.get('user')) + '/database?full=true');
        // FETCH COMPLETE DB LIST
        $.ajax({
          type: 'GET',
          url: url,
          contentType: 'application/json',
          success: function success(data) {
            someFunction(data.result);
          },
          error: function error(data) {
            dbmsHelper.dbmsError('User', 'Could not fetch user permissions');
          }
        });
      } else {
        someFunction(permissions);
      }
      window.modalView.hide();
    },
    breadcrumb: function breadcrumb() {
      var self = this;
      if (window.App.naviView) {
        $('#subNavigationBar .breadcrumb').html('<li><a>' + 'User: ' + dbmsHelper.escapeHtml(this.currentUser.get('user')) + '</a></li>');
        dbmsHelper.buildUserSubNav(self.currentUser.get('user'), 'Permissions');
      } else {
        window.setTimeout(function () {
          self.breadcrumb();
        }, 100);
      }
    }
  });
})();