/* global window, Backbone, $, dbmsHelper */
(function () {
  'use strict';

  window.Graph = Backbone.Model.extend({
    idAttribute: '_key',
    urlRoot: dbmsHelper.databaseUrl('/_api/gharial'),
    isNew: function isNew() {
      return !this.get('_id');
    },
    parse: function parse(raw) {
      return raw.graph || raw;
    },
    addEdgeDefinition: function addEdgeDefinition(edgeDefinition, options) {
      if (options) {
        edgeDefinition.options = options;
      }
      $.ajax({
        async: false,
        type: 'POST',
        url: this.urlRoot + '/' + encodeURIComponent(this.get('_key')) + '/edge',
        data: JSON.stringify(edgeDefinition),
        error: function error(err) {
          dbmsHelper.dbmsError(err.responseJSON.errorMessage);
        }
      });
    },
    deleteEdgeDefinition: function deleteEdgeDefinition(edgeCollection) {
      $.ajax({
        async: false,
        type: 'DELETE',
        url: this.urlRoot + '/' + encodeURIComponent(this.get('_key')) + '/edge/' + encodeURIComponent(edgeCollection),
        error: function error(err) {
          dbmsHelper.dbmsError(err.responseJSON.errorMessage);
        }
      });
    },
    modifyEdgeDefinition: function modifyEdgeDefinition(edgeDefinition, options) {
      if (options) {
        edgeDefinition.options = options;
      }
      $.ajax({
        async: false,
        type: 'PUT',
        url: this.urlRoot + '/' + encodeURIComponent(this.get('_key')) + '/edge/' + encodeURIComponent(edgeDefinition.relation),
        data: JSON.stringify(edgeDefinition),
        error: function error(err) {
          dbmsHelper.dbmsError(err.responseJSON.errorMessage);
        }
      });
    },
    addVertexCollection: function addVertexCollection(vertexCollectionName) {
      $.ajax({
        async: false,
        type: 'POST',
        url: this.urlRoot + '/' + encodeURIComponent(this.get('_key')) + '/vertex',
        data: JSON.stringify({
          relation: vertexCollectionName
        }),
        error: function error(err) {
          dbmsHelper.dbmsError(err.responseJSON.errorMessage);
        }
      });
    },
    deleteVertexCollection: function deleteVertexCollection(vertexCollectionName) {
      $.ajax({
        async: false,
        type: 'DELETE',
        url: this.urlRoot + '/' + encodeURIComponent(this.get('_key')) + '/vertex/' + encodeURIComponent(vertexCollectionName),
        error: function error(err) {
          dbmsHelper.dbmsError(err.responseJSON.errorMessage);
        }
      });
    },
    defaults: {
      name: '',
      edgeDefinitions: [],
      orphanCollections: []
    }
  });
})();