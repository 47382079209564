import React, { ReactNode } from 'react'
import { FieldInputProps, FieldMetaProps } from 'formik'
import styles from './form.module.scss'
import { Input, Typography } from 'antd'

type FieldProps = {
  label: ReactNode | null
  field: FieldInputProps<any>
  meta: FieldMetaProps<any>
  renderValue?: (field: FieldInputProps<any>) => ReactNode
}

export const Field = ({ label, field, meta, renderValue }: FieldProps) =>
  label ? (
    <div className={styles.form__field}>
      <div className={styles.form__label}>{label}</div>
      <div className={styles.form__value}>
        {renderValue ? (
          renderValue(field)
        ) : (
          <Input name={field.name} className={styles.form__input} value={field.value} onChange={field.onChange} />
        )}

        <div className='form__error'>
          {meta.error && meta.touched && <Typography.Text type="danger">{meta.error}</Typography.Text>}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.group}>
      <div className={styles.form__value}>
        {renderValue ? (
          renderValue(field)
        ) : (
          <Input name={field.name} className={styles.form__input} value={field.value} onChange={field.onChange} />
        )}
        <div className='form__error'>
          {meta.error && meta.touched && <Typography.Text type="danger">{meta.error}</Typography.Text>}
        </div>
      </div>
    </div>
  )
