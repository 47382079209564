module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="headerBar">\r\n    <div class="search-field">\r\n        <input type="text" value="'+
((__t=( searchString ))==null?'':__t)+
'" id="databaseSearchInput" class="search-input form-control"\r\n               placeholder="Search..."/>\r\n        <!-- <img id="databaseSearchSubmit" class="search-submit-icon">-->\r\n        <i id="databaseSearchSubmit" class="fa fa-search"></i>\r\n    </div>\r\n    <div class="headerButtonBar">\r\n        <ul class="headerButtonList">\r\n            <li class="enabled">\r\n                <a id="databaseToggle" class="btn btn-default btn-lg sectionHeader__button">\r\n                    <span class="fa fa-gear" title="Settings"></span>\r\n                </a>\r\n            </li>\r\n        </ul>\r\n    </div>\r\n    <div class="title add" id="createDatabase">\r\n        <button id="newDatabase" class="pure-button btn btn-primary btn-lg sectionHeader__button">\r\n            Add Database\r\n        </button>\r\n    </div>\r\n</div>\r\n\r\n<div id="databaseDropdown2" class="headerDropdown">\r\n    <div id="databaseDropdown" class="dropdownInner">\r\n        <ul>\r\n            <li class="nav-header">Sorting</li>\r\n            <li><a>\r\n                    <label class="checkbox checkboxLabel">\r\n                        <input class="css-checkbox" type="checkbox" id="dbSortDesc">\r\n                        <i class="fa"></i>\r\n                        Sort descending\r\n                    </label>\r\n                </a></li>\r\n        </ul>\r\n    </div>\r\n</div>\r\n\r\n<div class="contentDiv" id="dbManagementView">\r\n    <div class="pure-u-1 pure-u-md-1 pure-u-lg-1 pure-u-xl-1 pure-u-sm-1">\r\n        <table class="dbms-table">\r\n            <thead>\r\n            <tr>\r\n                <th class="dbms-table-th table-cell0">Name</th>\r\n                <th class="dbms-table-th table-cell2">Actions</th>\r\n            </tr>\r\n            </thead>\r\n            <tbody id="dbsThumbnailsIn">\r\n            ';
 collection.forEach(function(db) {
                var name = db.get("name");
            
__p+='\r\n\r\n            ';
 if (name !== '_system') { 
__p+='\r\n                <tr>\r\n                    <td class=\'dbms-table-td table-cell0\'>'+
((__t=( _.escape(name) ))==null?'':__t)+
'</td>\r\n                    <td class=\'dbms-table-td table-cell3\'>\r\n                        ';
 if (!readOnly) { 
__p+='\r\n                            <span class="fa fa-gear editDatabase" id="'+
((__t=( _.escape(name) ))==null?'':__t)+
'_edit-database"\r\n                                  alt="Edit database" title="Edit database"></span>\r\n                        ';
 } 
__p+='\r\n                    </td>\r\n                </tr>\r\n            ';
 }; 
__p+='\r\n\r\n            ';
 }); 
__p+='\r\n            </tbody>\r\n        </table>\r\n    </div>\r\n</div>\r\n\r\n';
}
return __p;
};
