module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="dbms-tabbar" id="'+
((__t=( content.id ))==null?'':__t)+
'">\n    ';
 _.each(content.titles, function(k, v) { 
__p+='\n        ';
 var name = content.titles[v][0]; 
__p+='\n        ';
 var elid = content.titles[v][1]; 
__p+='\n        <button class="arangodb-tabbar" id="'+
((__t=( elid ))==null?'':__t)+
'">'+
((__t=( name ))==null?'':__t)+
'</button>\n    ';
 }); 
__p+='\n</div>\n';
}
return __p;
};
