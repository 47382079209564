import React from 'react'
import { List, Typography } from 'antd'
import { useStore } from 'effector-react'
import classnames from 'clsx'
import { bgQuery } from '../model'
import { $relations, fetchRelationsFx } from '../../../models/relations'
import styles from '../styles.module.scss'

export const Explorer = () => {
  const relations = useStore($relations)
  const relationsLoading = useStore(fetchRelationsFx.pending)

  return (
    <>
      <Typography.Title level={4}>Explorer</Typography.Title>
      <div>
        <Typography.Text className={styles.relations__header}>Relations</Typography.Text>
        <List
          itemLayout="horizontal"
          loading={relationsLoading}
          dataSource={relations}
          renderItem={(item) => (
            <List.Item
              onClick={() => {
                bgQuery(item.name)
              }}
              className={classnames(styles.relations__item, 'test__explorer-item')}
              title={item.name}
            >
              <Typography.Text>{item.name}</Typography.Text>
            </List.Item>
          )}
        />
        {relations.length === 0 && !relationsLoading && (
          <Typography.Text>
            No relations. You could add them in <Typography.Link href="#relations">relation page</Typography.Link>.
          </Typography.Text>
        )}
      </div>
    </>
  )
}
