module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 notifications.forEach(function(n) { 
__p+='\n    <li class="dropdown-item">\n        <div class="notificationItem">\n            ';
 if (n.get("info")) { 
__p+='\n            <i title="'+
((__t=( n.get("info") ))==null?'':__t)+
'" class="notificationInfoIcon fa fa-info-circle"></i>\n            ';
 } 
__p+='\n\n            <i id="'+
((__t=( n.cid ))==null?'':__t)+
'" class="fa fa-times-circle-o"></i>\n            <a class="notificationItemTitle">'+
((__t=( n.get("title") ))==null?'':__t)+
'</a>\n            <div class="notificationItemContent">'+
((__t=( n.get("content") ))==null?'':__t)+
'</div>\n\n        </div>\n    </li>\n';
 }); 
__p+='\n';
}
return __p;
};
