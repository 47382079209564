"use strict";

/**
 * Utility function for constructing a multipart form in the browser.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toForm = void 0;
/**
 * @internal
 * @hidden
 */
function toForm(fields, callback) {
  var form;
  try {
    form = new FormData();
    for (var _i = 0, _Object$keys = Object.keys(fields); _i < _Object$keys.length; _i++) {
      var key = _Object$keys[_i];
      var value = fields[key];
      if (value === undefined) continue;
      if (!(value instanceof Blob) && (typeof value === "object" || typeof value === "function")) {
        value = JSON.stringify(value);
      }
      form.append(key, value);
    }
  } catch (e) {
    callback(e);
    return;
  }
  callback(null, {
    body: form
  });
}
exports.toForm = toForm;