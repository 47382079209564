module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 _.each(entries, function (entry) { 
__p+='\n<div class="pure-g" level="'+
((__t=( entry.status.toLowerCase() ))==null?'':__t)+
'" topic="'+
((__t=( entry.topic.toLowerCase() ))==null?'':__t)+
'">\n    <div class="pure-u-1-24">\n        <p class="labels">\n            ';
 var lcolor = dbmsHelper.statusColors[entry.status.toLowerCase()]; 
__p+='\n            ';
 var tcolor = dbmsHelper.alphabetColors[entry.topic.charAt(0).toLowerCase()]; 
__p+='\n            <span class="tippy level" title="Level: '+
((__t=( entry.status ))==null?'':__t)+
'" style="background: '+
((__t=( lcolor ))==null?'':__t)+
'"></span>\n            <span class="tippy topic" title="Topic: '+
((__t=( entry.topic ))==null?'':__t)+
'" style="background: '+
((__t=( tcolor ))==null?'':__t)+
'"></span>\n        </p>\n    </div>\n    <div class="pure-u-19-24 desc">\n        <p class="msg">'+
((__t=( entry.msg ))==null?'':__t)+
'</p>\n    </div>\n    <div class="pure-u-4-24 date">\n        <p>\n            ';
 var x = moment(entry.timestamp, "X").fromNow() 
__p+='\n            <i class="fa fa-clock-o" aria-hidden="true"></i>\n            <span class="tippy" title="'+
((__t=( entry.date ))==null?'':__t)+
'">'+
((__t=( x ))==null?'':__t)+
'</span>\n        </p>\n    </div>\n</div>\n';
 }); 
__p+='\n';
}
return __p;
};
