module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="arangoToolbar arangoToolbarTop" style="margin-bottom: 5px;">\r\n    <div class="pull-left">\r\n        <div style="font-size: 14pt; margin-left: 10px; margin-top: 5px; font-weight: 200">\r\n            Collections\r\n        </div>\r\n    </div>\r\n    <div class="pull-right">\r\n        <button id="toggleAllShards" class="btn-success">Toggle all</button>\r\n    </div>\r\n</div>\r\n<div id="shardsContent" class="innerContent">\r\n    ';
 var genClass1 = 'pure-u-6-24'; 
__p+='\r\n    ';
 var genClass2 = 'pure-u-6-24'; 
__p+='\r\n    ';
 var disabled = ' '; 
__p+='\r\n    ';
 var relationName; 
__p+='\r\n    ';
 var first = 0; 
__p+='\r\n\r\n    ';

    var relationInSync = function (name) {
        var result = false;
        if (_.isEqual(relations[name].Current, relations[name].Plan)) {
            return true;
        }
        return result;
    };
    
__p+='\r\n\r\n    ';
 _.each(relations, function(relation, name) { 
__p+='\r\n    <div class="sectionShard" id="'+
((__t=( name ))==null?'':__t)+
'">\r\n        ';
 relationName = name 
__p+='\r\n\r\n        ';
 if (first === 0) { 
__p+='\r\n        <div class="sectionHeader pure-g" style="margin-top: -20px;">\r\n            ';
 first++; 
__p+='\r\n            ';
 } else { 
__p+='\r\n            <div class="sectionHeader pure-g">\r\n                ';
 } 
__p+='\r\n\r\n                <div class="pure-u-22-24">\r\n                    <div class="title" style="position: relative; top: -4px;">\r\n                        ';
 var numShards = Object.keys(relation.Plan).length; 
__p+='\r\n                        '+
((__t=( name ))==null?'':__t)+
' ('+
((__t=( numShards + ' shard' + (numShards !== 1 ? 's' : '') ))==null?'':__t)+
')\r\n                    </div>\r\n                </div>\r\n                <div class="pure-u-2-24 shardSyncIcons">\r\n                    ';
 if (visible.indexOf(name) !== -1) { 
__p+='\r\n                        <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-down"></i>\r\n                    ';
 } else { 
__p+='\r\n                        <i style="margin-left: 10px; color: #fff;" class="fa fa-arrow-right"></i>\r\n                    ';
 } 
__p+='\r\n                    ';
 if (relationInSync(name)) { 
__p+='\r\n                        <i style="margin-left: 10px;" class="fa fa-check-circle"></i>\r\n                    ';
 } else { 
__p+='\r\n                        <i style="margin-left: 10px;" class="fa fa-times-circle"></i>\r\n                    ';
 } 
__p+='\r\n                </div>\r\n            </div>\r\n\r\n            ';
 if (visible.indexOf(name) !== -1) { 
__p+='\r\n            <div class="sectionShardContent">\r\n                ';
 } else { 
__p+='\r\n                <div class="sectionShardContent" style="display: none">\r\n                    ';
 } 
__p+='\r\n                    <div class="pure-g pure-table pure-table-header pure-title" style="clear: both">\r\n                        <div class="pure-table-row">\r\n                            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Shard</div>\r\n                            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">Leader</div>\r\n                            <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left">Followers</div>\r\n                            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' right">Sync</div>\r\n                        </div>\r\n                    </div>\r\n\r\n                    ';
 var counter = 0; var shardClass; 
__p+='\r\n                    ';
 _.each(relation.Plan, function(shard, name) { 
__p+='\r\n                        <div class="pure-g pure-table pure-table-body">\r\n\r\n                            ';
 if (shard.progress) { 
__p+='\r\n                                ';
 shardClass = 'disabled'; 
__p+='\r\n                            ';
 } 
__p+='\r\n\r\n                            <div id="'+
((__t=( relationName ))==null?'':__t)+
'-'+
((__t=( name ))==null?'':__t)+
'" class="pure-table-row noHover '+
((__t=( shardClass ))==null?'':__t)+
'"\r\n                                 relation="'+
((__t=( relationName ))==null?'':__t)+
'" shard="'+
((__t=( name ))==null?'':__t)+
'" leader="'+
((__t=( shard.leader ))==null?'':__t)+
'">\r\n\r\n                                ';
 if (shard.leader.substring(0, 1) === '_') { 
__p+='\r\n                                    ';
 shard.leader = shard.leader.slice(1) 
__p+='\r\n                                ';
 } 
__p+='\r\n\r\n                                <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left">'+
((__t=( name ))==null?'':__t)+
'</div>\r\n                                <div class="shardLeader '+
((__t=( genClass1 ))==null?'':__t)+
' positive left"><span>'+
((__t=( shard.leader ))==null?'':__t)+
'</span>\r\n                                </div>\r\n\r\n                                ';
 var found = null; 
__p+='\r\n                                ';
 _.each(shard.followers, function(db) { 
__p+='\r\n                                    ';
 if (db === shard.leader) { 
__p+='\r\n                                        ';
 found = true; 
__p+='\r\n                                    ';
 } 
__p+='\r\n                                ';
 }); 
__p+='\r\n\r\n                                ';
 if (found) { 
__p+='\r\n                                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"><i class="fa fa-circle-o-notch fa-spin"></i></div>\r\n                                ';
 } else { 
__p+='\r\n                                    ';
 if (shard.followers.length === 0) { 
__p+='\r\n                                        <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left"> no followers</div>\r\n                                    ';
 } else { 
__p+='\r\n                                        ';
 var string = ''; 
__p+='\r\n                                        ';
 var counter2 = 0; 
__p+='\r\n                                        ';
 var shardCssClass = 'inSync'; 
__p+='\r\n\r\n                                        ';
 var shardsNotInSync = 0; 
__p+='\r\n                                        ';
 _.each(shard.followers, function(db) { 
__p+='\r\n\r\n                                            ';
 if (relations[relationName].Current[name].followers.indexOf(db) > -1) { 
__p+='\r\n                                                ';
 shardCssClass = 'inSync'; 
__p+='\r\n                                            ';
 } else { 
__p+='\r\n                                                ';
 shardCssClass = 'notInSync'; 
__p+='\r\n                                                ';
 shardsNotInSync++; 
__p+='\r\n                                            ';
 } 
__p+='\r\n\r\n                                            ';
 if (shard.followers.length === 1) { 
__p+='\r\n                                                ';
 string += '<span class="' + shardCssClass + '">' + db + '</span> '; 
__p+='\r\n                                            ';
 } else { 
__p+='\r\n                                                ';
 if (counter2 === 0) { 
__p+='\r\n                                                    ';
 string += '<span class="' + shardCssClass + '">' + db + '</span>'; counter2++; 
__p+='\r\n                                                ';
 } else { 
__p+='\r\n                                                    ';
 string += ", " + '<span class="' + shardCssClass + '">' + db + '</span>'; 
__p+='\r\n                                                ';
 } 
__p+='\r\n                                            ';
 } 
__p+='\r\n                                        ';
 }); 
__p+='\r\n\r\n                                        <div class="shardFollowers '+
((__t=( genClass2 ))==null?'':__t)+
' left">'+
((__t=( string ))==null?'':__t)+
'</div>\r\n                                    ';
 } 
__p+='\r\n\r\n                                    ';
 if (relationInSync(relationName)) { 
__p+='\r\n                                        <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i\r\n                                                    class="fa fa-check-circle"></i></div>\r\n                                    ';
 } else { 
__p+='\r\n                                        ';
 if (shardsNotInSync > 0) { 
__p+='\r\n                                            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><span\r\n                                                        class="shardProgress"><i\r\n                                                            class="fa fa-circle-o-notch fa-spin fa-fw"></i></span></div>\r\n                                        ';
 } else { 
__p+='\r\n                                            <div class="'+
((__t=( genClass1 ))==null?'':__t)+
' left progressWrapper"><i\r\n                                                        class="fa fa-check-circle"></i></div>\r\n                                        ';
 } 
__p+='\r\n                                    ';
 } 
__p+='\r\n\r\n                                ';
 } 
__p+='\r\n\r\n                            </div>\r\n                        </div>\r\n\r\n                        ';
 counter++; 
__p+='\r\n                    ';
 }); 
__p+='\r\n                </div>\r\n\r\n            </div>\r\n            ';
 }); 
__p+='\r\n\r\n        </div>\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
