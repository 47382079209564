module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td class="writable sorting_1">\n    ';
 if (isReadOnly) { 
__p+='\n    <span class="key">'+
((__t=( key ))==null?'':__t)+
'</span>\n    ';
 } else { 
__p+='\n    <input type="text" class="key" placeholder="attribute" '+
((__t=( key?"value=" + key:"" ))==null?'':__t)+
'></input>\n    ';
 } 
__p+='\n</td>\n<td class="writeable rightCell">\n    ';
 if (isReadOnly) { 
__p+='\n    <span class="val">'+
((__t=( value ))==null?'':__t)+
'</span>\n    ';
 } else { 
__p+='\n    <textarea class="val" placeholder="value">'+
((__t=( value?value:"" ))==null?'':__t)+
'</textarea>\n    ';
 } 
__p+='\n</td>\n</td>\n<td class="leftCell">\n    <a class="deleteAttribute">\n        <span class="fa fa-minus-circle" data-original-title="Delete attribute"></span>\n    </a>\n</td>\n';
}
return __p;
};
