module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="headerBar">\n    <div class="search-field">\n        <input type="text" value="'+
((__t=( searchString ))==null?'':__t)+
'" id="graphManagementSearchInput" class="search-input form-control"\n               placeholder="Search..."/>\n        <i id="graphManagementSearchSubmit" class="fa fa-search"></i>\n        <!-- <img id="graphManagementSearchSubmit" class="search-submit-icon"> -->\n    </div>\n    <div class="headerButtonBar">\n        <ul class="headerButtonList">\n            <li class="enabled">\n                <a id="graphManagementToggle" class="btn btn-default btn-lg sectionHeader__button">\n                    <span class="fa fa-gear" title="Settings"></span>\n                </a>\n            </li>\n        </ul>\n    </div>\n    <div class="title add" id="createGraph">\n        <button id="newGraph" class="pure-button btn btn-lg btn-primary sectionHeader__button">\n            Add Graph\n        </button>\n    </div>\n</div>\n\n<div id="graphManagementDropdown2" class="headerDropdown">\n    <div id="graphManagementDropdown" class="dropdownInner">\n        <ul>\n            <li class="nav-header">Sorting</li>\n            <li><a>\n                    <label class="checkbox checkboxLabel">\n                        <input class="css-checkbox" type="checkbox" id="graphSortDesc">\n                        <i class="fa"></i>\n                        Sort descending\n                    </label>\n                </a></li>\n        </ul>\n    </div>\n\n</div>\n\n<div class="contentDiv" id="dbmsRelationsContainer">\n    <div class="pure-u-1 pure-u-md-1 pure-u-lg-1 pure-u-xl-1 pure-u-sm-1">\n        <table class="dbms-table">\n            <thead>\n            <tr>\n                <th class="dbms-table-th table-cell0">Name</th>\n                <th class="dbms-table-th table-cell1">Type</th>\n                <th class="dbms-table-th table-cell2">Actions</th>\n            </tr>\n            </thead>\n            <tbody id="graphManagementThumbnailsIn">\n            ';
 graphs.forEach(function(graph) {
                var graphName = graph.get("_key");
                var isSmart = graph.get("isSmart");
                var isDisjoint = graph.get("isDisjoint");
                var isSatellite = graph.get("isSatellite") || (graph.get("replicationFactor") == "satellite");
            
__p+='\n            <tr class="tile-graph">\n                <td class=\'dbms-table-td table-cell0\'>'+
((__t=( graphName ))==null?'':__t)+
'</td>\n                <td class=\'dbms-table-td table-cell1\'>\n                    ';
 if (isSmart === true) { 
__p+='\n                        ';
 if (isDisjoint === true) { 
__p+='\n                            Disjoint Smart\n                        ';
 } else { 
__p+='\n                            Smart\n                        ';
 } 
__p+='\n                    ';
 } else if (isSatellite === true) { 
__p+='\n                        Satellite\n                    ';
 } 
__p+='\n                </td>\n                <td class=\'dbms-table-td table-cell2\'>\n                        <span class="fa fa-gear editGraph" id="'+
((__t=( graphName ))==null?'':__t)+
'_settings" alt="Edit graph"\n                              title="Edit graph"></span>\n                </td>\n            </tr>\n            ';
 }); 
__p+='\n            </tbody>\n        </table>\n    </div>\n</div>\n';
}
return __p;
};
