import {ChangeEvent, useEffect, useRef} from "react";

export const getChangeHandler = (setter: (value: string) => void) => {
    return (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setter(event.currentTarget.value);
    };
};

export const usePrevious = (value: any) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};


export const defaultSelectRelation = (relation: string) => `FOR doc\nIN ${relation}\nRETURN doc`

export type Param = {
    name: string,
}

const paramRegex = new RegExp('@?@([a-zA-Z]+)', 'g')

export const parseParams = (query: string): Param[] => {
    const params = query.match(paramRegex)
    if (params) {
      let names: Record<string, boolean> = {}
      for (let param of params) {
        names[param.slice(1)] = true
      }
      return Object.keys(names).sort().map(name => ({name}))
    } else {
        return []
    }
  }
