module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tr class="foxx-store-row">\r\n    <td class="foxx-store-main">\r\n        <div class="foxx-name">'+
((__t=( name ))==null?'':__t)+
''+
((__t=( legacy ? ' (legacy)' : '' ))==null?'':__t)+
'</div>\r\n        <div class="foxx-author">'+
((__t=( author ))==null?'':__t)+
'</div>\r\n        <div class="foxx-description">'+
((__t=( description ))==null?'':__t)+
'</div>\r\n    </td>\r\n    <td class="foxx-store-version">\r\n        <div class="foxx-version">'+
((__t=( latestVersion ))==null?'':__t)+
'</div>\r\n    </td>\r\n    <td class="foxx-store-install">\r\n        <button class="btn-success install-app" appId="'+
((__t=( name ))==null?'':__t)+
'" appVersion="'+
((__t=( latestVersion ))==null?'':__t)+
'">Install\r\n        </button>\r\n    </td>\r\n</tr>\r\n\r\n';
}
return __p;
};
