module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var escaped = function (value) {
    return value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;").replace(/'/g, "&#39;");
};
var cutByResolution = function (str) {
    if (str.length > 256) {
        return escaped(str.substr(0, 256)) + '...';
    }
    return escaped(str);
};

__p+='\n\n<div class="pure-g pure-table pure-table-header pure-title no-padding">\n    <div class="pure-table-row">\n        <div class="pure-u-19-24">\n            <div class="title">Content</div>\n        </div>\n        <div class="pure-u-3-24">\n            <div class="">_key</div>\n        </div>\n        <div class="pure-u-2-24">\n            <div class="actions">\n                <a id="addDocumentButton" class="pull-right addButton"><span title="Add a document"><i\n                                class="fa fa-plus-circle"></i></span></a>\n            </div>\n        </div>\n    </div>\n</div>\n\n<div class="pure-g pure-table pure-table-body no-padding">\n    ';
 if (docs.length === 0) { 
__p+='\n        <div class="pure-table-row pure-u-1-1">\n            <span class="dataTables_empty">No documents</span>\n        </div>\n    ';

    }
    var odd = true;
    _.each(docs.models, function(doc) {
        var tempObj = {};
        var jsonModel = doc.toJSON();

        Object.keys(jsonModel.content).forEach(function (k) {
            if ((k !== '_id' || k !== '_rev' || k !== '_key')) {
                tempObj[k] = jsonModel.content[k];
            }
        });
        tmpObj = JSON.stringify(tempObj);
    
__p+='\n\n    <div class="pure-table-row '+
((__t=( odd?'odd':'even' ))==null?'':__t)+
'" id="row_'+
((__t=( doc.get('key') ))==null?'':__t)+
'">\n\n        <div class="pure-u-19-24">\n            <div class="padding-right">\n                <pre class="hljs-pre" title="'+
((__t=( escaped(tmpObj) ))==null?'':__t)+
'"><code\n                            class="json">'+
((__t=( cutByResolution(tmpObj) ))==null?'':__t)+
'</code></pre>\n            </div>\n        </div>\n        <div class="pure-u-3-24">\n            <div class="key">'+
((__t=( doc.get('key') ))==null?'':__t)+
'</div>\n        </div>\n        <div class="pure-u-2-24">\n            <div class="actions">\n                <a class="deleteButton">\n                    <span data-original-title="Delete document" title="Delete document"><i\n                                class="fa fa-minus-circle"></i></i></span></a>\n            </div>\n        </div>\n\n    </div>\n\n    ';

        odd = !odd;
    });
    
__p+='\n\n</div>\n';
}
return __p;
};
