import axios from 'axios'
import { getBaseUrl } from '../utils/dbmsClient';
declare var dbmsHelper: { [key: string]: any };
declare var frontendConfig: { [key: string]: any };

export const client = axios.create({
    baseURL: getBaseUrl(),
})

client.interceptors.request.use(
  config => {
    config.headers = config.headers || {}
    config.headers.Authorization = `Bearer ${dbmsHelper.getCurrentJwt()}`
    return config
  }
)

export interface Definition {
    description?: string,
    type?: string,
    properties?: {
        [key: string]: Definition,
    },
    $ref?: string,
    items?: Definition,
    required?: string[],
}

export interface DocJson {
    definitions: {
        [key: string]: Definition,
    }
}

let initedDoc: DocJson | null = null

let docPromise: Promise<DocJson> | null = null

export const fetchApiDoc = (): Promise<DocJson> => {
    if (initedDoc) {
        return Promise.resolve(initedDoc)
    }
    if (docPromise) {
        return docPromise
    }
    docPromise = new Promise((resolve) => {
        import('../../../api-docs.json').then(doc => {
            initedDoc = doc as DocJson
            resolve(doc)
        })
    })
    return docPromise
}
