/* jshint browser: true */
/* global Backbone, $, _, window, Joi, dbmsHelper, templateEngine */
(function () {
  'use strict';

  window.ServiceInstallNewView = Backbone.View.extend({
    el: '#content',
    readOnly: false,
    template: templateEngine.createTemplate('serviceInstallNewView.ejs'),
    remove: function remove() {
      this.$el.empty().off(); /* off to unbind the events */
      this.stopListening();
      this.unbind();
      delete this.el;
      return this;
    },
    events: {
      'click #installNewService': 'installNewService',
      'keydown input': 'checkValidators'
    },
    checkValidators: function checkValidators() {
      if (window.modalView._validators.length !== 4) {
        window.modalView.clearValidators();
        this.setNewAppValidators();
      }
    },
    initialize: function initialize() {
      if (window.App.replaceApp) {
        this._upgrade = true;
      }
    },
    render: function render() {
      // if repo not fetched yet, wait
      $(this.el).html(this.template.render({
        services: this.collection,
        upgrade: this._upgrade
      }));
      this.renderSelects();
      this.breadcrumb();
      this.setNewAppValidators();
      dbmsHelper.createTooltips('.modalTooltips');
      return this;
    },
    installNewService: function installNewService() {
      dbmsHelper.createMountPointModal(this.generateNewFoxxApp.bind(this));
    },
    generateNewFoxxApp: function generateNewFoxxApp() {
      if (window.modalView.modalTestAll()) {
        var mount, info, options;
        if (this._upgrade) {
          mount = window.App.replaceAppData.mount;
        } else {
          mount = window.dbmsHelper.escapeHtml($('#new-app-mount').val());
          if (mount.charAt(0) !== '/') {
            mount = '/' + mount;
          }
        }
        info = {
          name: window.dbmsHelper.escapeHtml($('#new-app-name').val()),
          documentCollections: _.map($('#new-app-document-relations').select2('data'), function (d) {
            return window.dbmsHelper.escapeHtml(d.text);
          }),
          edgeCollections: _.map($('#new-app-edge-relations').select2('data'), function (d) {
            return window.dbmsHelper.escapeHtml(d.text);
          }),
          //        authenticated: window.dbmsHelper.escapeHtml($("#new-app-name").val()),
          author: window.dbmsHelper.escapeHtml($('#new-app-author').val()),
          license: window.dbmsHelper.escapeHtml($('#new-app-license').val()),
          description: window.dbmsHelper.escapeHtml($('#new-app-description').val())
        };
        options = dbmsHelper.getFoxxFlags();
        this.collection.install('generate', info, mount, options, this.installCallback.bind(this));
      }
      window.modalView.hide();
    },
    checkValidation: function checkValidation() {
      window.modalView.modalTestAll();
    },
    installCallback: function installCallback(result) {
      window.App.navigate('#services', {
        trigger: true
      });
      window.App.applicationsView.installCallback(result);
    },
    renderSelects: function renderSelects() {
      $('#new-app-document-relations').select2({
        tags: [],
        showSearchBox: false,
        minimumResultsForSearch: -1,
        width: '336px'
      });
      $('#new-app-edge-relations').select2({
        tags: [],
        showSearchBox: false,
        minimumResultsForSearch: -1,
        width: '336px'
      });
    },
    setNewAppValidators: function setNewAppValidators() {
      window.modalView.modalBindValidation({
        id: 'new-app-author',
        validateInput: function validateInput() {
          return [{
            rule: Joi.string().required().min(1),
            msg: 'Has to be non empty.'
          }];
        }
      });
      window.modalView.modalBindValidation({
        id: 'new-app-name',
        validateInput: function validateInput() {
          return [{
            rule: Joi.string().required().regex(/^[a-zA-Z\-_][a-zA-Z0-9\-_]*$/),
            msg: "Can only contain a to z, A to Z, 0-9, '-' and '_'. Cannot start with a number."
          }];
        }
      });
      window.modalView.modalBindValidation({
        id: 'new-app-description',
        validateInput: function validateInput() {
          return [{
            rule: Joi.string().required().min(1),
            msg: 'Has to be non empty.'
          }];
        }
      });
      window.modalView.modalBindValidation({
        id: 'new-app-license',
        validateInput: function validateInput() {
          return [{
            rule: Joi.string().required().regex(/^[a-zA-Z0-9 .,;-]+$/),
            msg: "Can only contain a to z, A to Z, 0-9, '-', '.', ',' and ';'."
          }];
        }
      });
      window.modalView.modalTestAll();
    },
    breadcrumb: function breadcrumb() {
      var self = this;
      if (window.App.naviView) {
        var replaceString = 'New';
        if (this._upgrade) {
          replaceString = 'Replace (' + window.App.replaceAppData.mount + ')';
        }
        $('#subNavigationBar .breadcrumb').html('<li><a><a href="#services">Services:</a> ' + replaceString + '</a></li>');
        dbmsHelper.buildServicesSubNav('New');
      } else {
        window.setTimeout(function () {
          self.breadcrumb();
        }, 100);
      }
    }
  });
})();