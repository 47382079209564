module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="filterSelectBox">\n    <div class="filterLabel">'+
((__t=( name ))==null?'':__t)+
'<span><i class="fa fa-close" id="closeFilter"></i></span></div>\n\n    <div class="filterInput">\n        <input type="text" id="'+
((__t=( name ))==null?'':__t)+
'-filter" placeholder="Filter '+
((__t=( name ))==null?'':__t)+
'" aria-label="Filter labels"\n               autocomplete="off" class="form-control">\n    </div>\n\n    <div class="filterOptions">\n        <div id="showAll"><strong>Show all</strong></div>\n        ';
 var cssClass; 
__p+='\n        ';
 _.each(options, function (option) { 
__p+='\n            ';
 if (option.active) { 
__p+='\n                ';
 cssClass = 'active'; 
__p+='\n            ';
 } else { 
__p+='\n                ';
 cssClass = 'inactive'; 
__p+='\n            ';
 } 
__p+='\n            <div class="'+
((__t=( cssClass ))==null?'':__t)+
'" name="'+
((__t=( option.name ))==null?'':__t)+
'" id="'+
((__t=( option.name ))==null?'':__t)+
'-option">\n                ';
 var color = option.color || '#f6f8fa'; 
__p+='\n\n                ';
 if (cssClass === 'active') { 
__p+='\n                    <span class="marked"><i class="fa fa-check"></i></span>\n                ';
 } else { 
__p+='\n                    <span style="visibility: hidden;" class="marked"><i class="fa fa-check"></i></span>\n                ';
 } 
__p+='\n\n                <span class="color" style="background-color: '+
((__t=( color ))==null?'':__t)+
'">&nbsp;</span>\n                <span class="name">'+
((__t=( option.name ))==null?'':__t)+
'</span>\n            </div>\n        ';
 }); 
__p+='\n    </div>\n\n</div>\n\n';
}
return __p;
};
