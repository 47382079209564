module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="outputEditorWrapper'+
((__t=( counter ))==null?'':__t)+
'" class="outputEditorWrapper">\r\n    <div class="arangoToolbar arangoToolbarTop">\r\n        <div class="pull-left">\r\n            <span class="toolbarType">'+
((__t=( type ))==null?'':__t)+
'</span>\r\n            <span id="spinner"><i class="fa fa-spinner fa-spin"></i><i>Query is operating ...</i></span>\r\n        </div>\r\n        <div class="pull-right">\r\n            <span class="action closeResult" element="outputEditor'+
((__t=( counter ))==null?'':__t)+
'"><i class="fa fa-close"\r\n                                                                                     style="display: none"></i></span>\r\n        </div>\r\n        <div class="pull-right">\r\n            <div class="switchAce" counter="'+
((__t=( counter ))==null?'':__t)+
'">\r\n                <span id="json-switch" val="JSON" counter="'+
((__t=( counter ))==null?'':__t)+
'">JSON</span>\r\n                <span id="table-switch" val="Table" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none">Table</span>\r\n                <span id="graph-switch" val="Graph" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none">Graph</span>\r\n                <span id="geo-switch" val="Geo" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none">Geo</span>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div id="outputEditor'+
((__t=( counter ))==null?'':__t)+
'" style="opacity: 0.5"></div>\r\n    <div id="sentWrapper'+
((__t=( counter ))==null?'':__t)+
'" class="sentWrapper" style="display: none">\r\n        <div class="pull-left">\r\n            <div id="sentQueryEditor'+
((__t=( counter ))==null?'':__t)+
'"></div>\r\n        </div>\r\n        <div class="pull-right">\r\n            <div id="sentBindParamEditor'+
((__t=( counter ))==null?'':__t)+
'"></div>\r\n        </div>\r\n    </div>\r\n    <div class="arangoToolbar arangoToolbarBottom">\r\n        <div class="pull-right">\r\n            ';
 if (type === 'Query') { 
__p+='\r\n            <button id="copy2gV" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none; margin-right: 0;"\r\n                    class="btn-success">Graph Viewer\r\n            </button>\r\n            <button id="downloadQueryResult" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none; margin-right: 0;"\r\n                    class="btn-success">Download\r\n            </button>\r\n            <button id="downloadCsvResult" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none; margin-right: 0;"\r\n                    class="btn-success">CSV\r\n            </button>\r\n            <button id="copy2aqlEditor" counter="'+
((__t=( counter ))==null?'':__t)+
'" style="display: none; margin-right: 8px;"\r\n                    class="btn-success">Copy To Editor\r\n            </button>\r\n            <button id="cancelCurrentQuery" class="btn-danger" style="margin-right: 5px">Cancel</button>\r\n            ';
 } 
__p+='\r\n        </div>\r\n    </div>\r\n</div>\r\n\r\n\r\n</div>\r\n';
}
return __p;
};
