/* global Backbone, window, dbmsHelper, frontendConfig */

(function () {
  'use strict';

  window.CurrentDatabase = Backbone.Model.extend({
    url: dbmsHelper.databaseUrl('/_api/database/current', frontendConfig.db),
    parse: function parse(data) {
      return data.result;
    }
  });
})();