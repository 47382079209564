var options = [{
  name: 'verbose',
  defaultValue: false,
  type: 'boolean',
  tip: 'Verbose description'
}, {
  name: 'include system',
  defaultValue: false,
  type: 'boolean',
  tip: 'Include system description'
}, {
  name: 'incremental',
  defaultValue: true,
  type: 'boolean',
  tip: 'Incremental description'
}, {
  name: 'autoResync',
  defaultValue: true,
  type: 'boolean',
  tip: 'autoResync description'
}, {
  name: 'type',
  defaultValue: '1',
  type: 'number',
  tip: 'Type description'
}, {
  name: 'database',
  defaultValue: '_system',
  type: 'string',
  tip: 'Database description'
}, {
  name: 'assumevalid',
  defaultValue: '%s',
  type: 'string',
  tip: 'If this block is in the chain assume that it and its ancestors are valid and potentially skip their script verification'
}, {
  name: 'datadir',
  defaultValue: null,
  type: 'string',
  tip: 'Specify data directory'
}, {
  name: 'blocksdir',
  defaultValue: '<datadir>',
  type: 'string',
  tip: 'Specify directory to hold blocks subdirectory for *.dat files'
}, {
  name: 'blocknotify',
  defaultValue: null,
  type: 'string',
  tip: 'Execute command when the best block changes'
}, {
  name: 'blockreconstructionextratxn',
  defaultValue: null,
  type: 'string',
  tip: 'Extra transactions to keep in memory for compact block reconstructions'
}, {
  name: 'blocksonly',
  defaultValue: null,
  type: 'string',
  tip: 'Whether to reject transactions from network peers. Automatic broadcast and rebroadcast of any transactions from inbound peers is disabled, unless the peer has the "forcerelay" permission. RPC transactions are not affected'
}, {
  name: 'coinstatsindex',
  defaultValue: null,
  type: 'string',
  tip: 'Maintain coinstats index used by the gettxoutsetinfo RPC'
}, {
  name: 'conf',
  defaultValue: null,
  type: 'string',
  tip: 'Specify path to read-only configuration file. Relative paths will be prefixed by datadir location'
}, {
  name: 'dbbatchsize',
  defaultValue: null,
  type: 'string',
  tip: 'Maximum database write batch size in bytes'
}, {
  name: 'dbcache',
  defaultValue: null,
  type: 'string',
  tip: 'Maximum database cache size <n> MiB (%d to %d, default: %d). In addition, unused mempool memory is shared for this cache (see maxmempool)'
}, {
  name: 'includeconf',
  defaultValue: null,
  type: 'string',
  tip: 'Specify additional configuration file, relative to the -datadir path'
}, {
  name: 'loadblock',
  defaultValue: null,
  type: 'string',
  tip: 'Imports blocks from external file on startup'
}, {
  name: 'maxmempool',
  defaultValue: null,
  type: 'number',
  tip: 'Keep the transaction memory pool below <n> megabytes'
}, {
  name: 'maxorphantx',
  defaultValue: null,
  type: 'number',
  tip: 'Keep at most <n> unconnectable transactions in memory'
}, {
  name: 'mempoolexpiry',
  defaultValue: null,
  type: 'number',
  tip: 'Do not keep transactions in the mempool longer than <n> hours'
}, {
  name: 'minimumchainwork',
  defaultValue: null,
  type: 'string',
  tip: 'Minimum work assumed to exist on a valid chain in hex'
}, {
  name: 'par',
  defaultValue: null,
  type: 'number',
  tip: 'Set the number of script verification threads'
}, {
  name: 'persistmempool',
  defaultValue: null,
  type: 'number',
  tip: 'Whether to save the mempool on shutdown and load on restart'
}, {
  name: 'prune',
  defaultValue: null,
  type: 'number',
  tip: 'Reduce storage requirements by enabling pruning'
}, {
  name: 'reindex',
  defaultValue: null,
  type: 'boolean',
  tip: 'Rebuild chain state and block index from the blk*.dat files on disk'
}, {
  name: 'reindex-chainstate',
  defaultValue: null,
  type: 'boolean',
  tip: 'Rebuild chain state from the currently indexed blocks. When in pruning mode or if blocks on disk might be corrupted, use full -reindex instead'
}, {
  name: 'settings',
  defaultValue: null,
  type: 'string',
  tip: '"Specify path to dynamic settings data file. Can be disabled with -nosettings. File is written at runtime and not meant to be edited by users (use %s instead for custom settings). Relative paths will be prefixed by datadir location'
}, {
  name: 'txindex',
  defaultValue: null,
  type: 'boolean',
  tip: 'Maintain a full transaction index, used by the getrawtransaction rpc call'
}, {
  name: 'blockfilterindex',
  defaultValue: null,
  type: 'string',
  tip: 'Maintain an index of compact filters by block'
}, {
  name: 'addnode',
  defaultValue: null,
  type: 'string',
  tip: 'Add a node to connect to and attempt to keep the connection open (see the addnode RPC help for more info). This option can be specified multiple times to add multiple nodes; connections are limited to %u at a time and are counted separately from the -maxconnections limit'
}, {
  name: 'asmap',
  defaultValue: null,
  type: 'string',
  tip: 'Specify asn mapping used for bucketing of the peers (default: %s). Relative paths will be prefixed by the net-specific datadir location'
}, {
  name: 'bantime',
  defaultValue: null,
  type: 'number',
  tip: 'Default duration (in seconds) of manually configured bans'
}, {
  name: 'bind',
  defaultValue: null,
  type: 'string',
  tip: 'Bind to given address and always listen on it (default: 0.0.0.0)'
}, {
  name: 'connect',
  defaultValue: null,
  type: 'boolean',
  tip: 'Connect only to the specified node; -noconnect disables automatic connections (the rules for this peer are the same as for -addnode). This option can be specified multiple times to connect to multiple nodes'
}, {
  name: 'dns',
  defaultValue: null,
  type: 'number',
  tip: 'Allow DNS lookups for -addnode, -seednode and -connect'
}, {
  name: 'dnsseed',
  defaultValue: null,
  type: 'boolean',
  tip: 'Query for peer addresses via DNS lookup, if low on addresses'
}, {
  name: 'externalip',
  defaultValue: null,
  type: 'string',
  tip: 'Specify your own public address'
}, {
  name: 'fixedseeds',
  defaultValue: null,
  type: 'boolean',
  tip: 'Allow fixed seeds if DNS seeds don\'t provide peers'
}, {
  name: 'forcednsseed',
  defaultValue: null,
  type: 'boolean',
  tip: 'Always query for peer addresses via DNS lookup'
}, {
  name: 'listen',
  defaultValue: null,
  type: 'boolean',
  tip: 'Accept connections from outside'
}, {
  name: 'listenonion',
  defaultValue: null,
  type: 'boolean',
  tip: 'Automatically create Tor onion service'
}, {
  name: 'maxconnections',
  defaultValue: null,
  type: 'number',
  tip: 'Maintain at most <n> connections to peers (default: %u). This limit does not apply to connections manually added via -addnode or the addnode RPC, which have a separate limit of %u.'
}, {
  name: 'maxreceivebuffer',
  defaultValue: null,
  type: 'number',
  tip: 'Maximum per-connection receive buffer, <n>*1000 bytes'
}, {
  name: 'maxsendbuffer',
  defaultValue: null,
  type: 'number',
  tip: 'Maximum per-connection send buffer, <n>*1000 bytes'
}, {
  name: 'maxtimeadjustment',
  defaultValue: null,
  type: 'number',
  tip: 'Maximum allowed median peer time offset adjustment. Local perspective of time may be influenced by peers forward or backward by this amount. (default: %u seconds)'
}, {
  name: 'maxuploadtarget',
  defaultValue: null,
  type: 'number',
  tip: 'Tries to keep outbound traffic under the given target (in MiB per 24h). Limit does not apply to peers with \'download\' permission. 0 = no limit'
}, {
  name: 'onion',
  defaultValue: '-proxy',
  type: 'string',
  tip: 'Use separate SOCKS5 proxy to reach peers via Tor onion services'
}, {
  name: 'i2psam',
  defaultValue: 'none',
  type: 'string',
  tip: 'I2P SAM proxy to reach I2P peers and accept I2P connections'
}, {
  name: 'i2pacceptincoming',
  defaultValue: true,
  type: 'boolean',
  tip: 'If set and -i2psam is also set then incoming I2P connections are accepted via the SAM proxy. If this is not set but -i2psam is set then only outgoing connections will be made to the I2P network. Ignored if -i2psam is not set. Listening for incoming I2P connections is done through the SAM proxy, not by binding to a local address and port'
}, {
  name: 'onlynet',
  defaultValue: null,
  type: 'string',
  tip: 'Make outgoing connections only through network <net>'
}, {
  name: 'peerbloomfilters',
  defaultValue: null,
  type: 'boolean',
  tip: 'Support filtering of blocks and transaction with bloom filters'
}, {
  name: 'peerblockfilters',
  defaultValue: null,
  type: 'boolean',
  tip: 'Serve compact block filters to peers per BIP 157'
}, {
  name: 'permitbaremultisig',
  defaultValue: null,
  type: 'boolean',
  tip: 'Relay non-P2SH multisig'
}, {
  name: 'port',
  defaultValue: null,
  type: 'number',
  tip: 'Listen for connections on <port>. Nodes not using the default ports (default: %u, testnet: %u, signet: %u, regtest: %u) are unlikely to get incoming connections. Not relevant for I2P'
}, {
  name: 'proxy',
  defaultValue: null,
  type: 'string',
  tip: 'Connect through SOCKS5 proxy, set -noproxy to disable'
}, {
  name: 'proxyrandomize',
  defaultValue: null,
  type: 'boolean',
  tip: 'Randomize credentials for every proxy connection. This enables Tor stream isolation'
}, {
  name: 'seednode',
  defaultValue: null,
  type: 'string',
  tip: 'Connect to a node to retrieve peer addresses, and disconnect. This option can be specified multiple times to connect to multiple nodes.'
}, {
  name: 'networkactive',
  defaultValue: true,
  type: 'boolean',
  tip: 'Enable all P2P network activity'
}, {
  name: 'timeout',
  defaultValue: null,
  type: 'number',
  tip: 'Specify socket connection timeout in milliseconds'
}, {
  name: 'peertimeout',
  defaultValue: null,
  type: 'number',
  tip: 'Specify a p2p connection timeout delay in seconds'
}, {
  name: 'torcontrol',
  defaultValue: null,
  type: 'string',
  tip: 'Tor control port to use if onion listening enabled'
}, {
  name: 'torpassword',
  defaultValue: null,
  type: 'string',
  tip: 'Tor control port password'
}, {
  name: 'whitebind',
  defaultValue: null,
  type: 'string',
  tip: 'Bind to the given address and add permission flags to the peers connecting to it'
}, {
  name: 'whitelist',
  defaultValue: null,
  type: 'string',
  tip: 'Add permission flags to the peers connecting from the given IP address (e.g. 1.2.3.4) or CIDR-notated network (e.g. 1.2.3.0/24)'
}, {
  name: 'checkblocks',
  defaultValue: null,
  type: 'number',
  tip: 'How many blocks to check at startup'
}, {
  name: 'checklevel',
  defaultValue: null,
  type: 'number',
  tip: 'How thorough the block verification of -checkblocks is: %s'
}, {
  name: 'checkblockindex',
  defaultValue: null,
  type: 'number',
  tip: 'Do a consistency check for the block tree, chainstate, and other validation data structures occasionally'
}, {
  name: 'checkaddrman',
  defaultValue: null,
  type: 'number',
  tip: 'Run addrman consistency checks every <n> operations'
}, {
  name: 'checkmempool',
  defaultValue: null,
  type: 'number',
  tip: 'Run mempool consistency checks every <n> transactions. Use 0 to disable'
}, {
  name: 'checkpoints',
  defaultValue: null,
  type: 'boolean',
  tip: 'Enable rejection of any forks from the known historical chain until block'
}, {
  name: 'deprecatedrpc',
  defaultValue: null,
  type: 'number',
  tip: 'Allows deprecated RPC method(s) to be used'
}, {
  name: 'stopafterblockimport',
  defaultValue: null,
  type: 'boolean',
  tip: 'Stop running after importing blocks from disk'
}, {
  name: 'stopatheight',
  defaultValue: null,
  type: 'number',
  tip: 'Stop running after reaching the given height in the main chain'
}, {
  name: 'limitancestorcount',
  defaultValue: null,
  type: 'number',
  tip: 'Do not accept transactions if number of in-mempool ancestors is <n> or more'
}, {
  name: 'limitancestorsize',
  defaultValue: null,
  type: 'number',
  tip: 'Do not accept transactions whose size with all in-mempool ancestors exceeds <n> kilobytes'
}, {
  name: 'limitdescendantcount',
  defaultValue: null,
  type: 'number',
  tip: 'Do not accept transactions if any ancestor would have <n> or more in-mempool descendants'
}, {
  name: 'limitdescendantsize',
  defaultValue: null,
  type: 'number',
  tip: 'Do not accept transactions if any ancestor would have more than <n> kilobytes of in-mempool descendants'
}, {
  name: 'addrmantest',
  defaultValue: null,
  type: 'boolean',
  tip: 'Allows to test address relay on localhost'
}, {
  name: 'capturemessages',
  defaultValue: null,
  type: 'boolean',
  tip: 'Capture all P2P messages to disk'
}, {
  name: 'mocktime',
  defaultValue: null,
  type: 'number',
  tip: 'Replace actual time with'
}, {
  name: 'maxsigcachesize',
  defaultValue: null,
  type: 'number',
  tip: 'Limit sum of signature cache and script execution cache sizes to <n> MiB'
}, {
  name: 'maxtipage',
  defaultValue: null,
  type: 'number',
  tip: 'Maximum tip age in seconds to consider node in initial block download'
}, {
  name: 'printpriority',
  defaultValue: null,
  type: 'boolean',
  tip: 'Log transaction fee rate'
}, {
  name: 'uacomment',
  defaultValue: null,
  type: 'string',
  tip: 'Append comment to the user agent string'
}, {
  name: 'acceptnonstdtxn',
  defaultValue: null,
  type: 'boolean',
  tip: 'Relay and mine \"non-standard\" transactions, testnet/regtest only'
}, {
  name: 'incrementalrelayfee',
  defaultValue: null,
  type: 'number',
  tip: 'Fee rate (in %s/kvB) used to define cost of relay, used for mempool limiting and BIP 125 replacement'
}, {
  name: 'dustrelayfee',
  defaultValue: null,
  type: 'number',
  tip: 'Fee rate (in %s/kvB) used to define dust, the value of an output such that it will cost more than its value in fees at this fee rate to spend it'
}, {
  name: 'bytespersigop',
  defaultValue: null,
  type: 'number',
  tip: 'Equivalent bytes per sigop in transactions for relay and mining'
}, {
  name: 'datacarrier',
  defaultValue: null,
  type: 'number',
  tip: 'Equivalent bytes per sigop in transactions for relay and mining'
}, {
  name: 'datacarriersize',
  defaultValue: null,
  type: 'number',
  tip: 'Maximum size of data in data carrier transactions we relay and mine'
}, {
  name: 'minrelaytxfee',
  defaultValue: null,
  type: 'number',
  tip: 'Fees (in %s/kvB) smaller than this are considered zero fee for relaying, mining and transaction creation'
}, {
  name: 'whitelistforcerelay',
  defaultValue: null,
  type: 'boolean',
  tip: 'Add \'forcerelay\' permission to whitelisted inbound peers with default permissions. This will relay transactions even if the transactions were already in the mempool'
}, {
  name: 'whitelistrelay',
  defaultValue: null,
  type: 'boolean',
  tip: 'Add \'relay\' permission to whitelisted inbound peers with default permissions. This will accept relayed transactions even when not relaying transactions'
}, {
  name: 'blockmaxweight',
  defaultValue: null,
  type: 'number',
  tip: 'Set maximum BIP141 block weight'
}, {
  name: 'blockmintxfee',
  defaultValue: null,
  type: 'number',
  tip: 'Set lowest fee rate (in %s/kvB) for transactions to be included in block creation'
}, {
  name: 'blockversion',
  defaultValue: null,
  type: 'number',
  tip: 'Override block version to test forking scenarios'
}, {
  name: 'rest',
  defaultValue: null,
  type: 'boolean',
  tip: 'Accept public REST requests'
}, {
  name: 'rpcallowip',
  defaultValue: null,
  type: 'string',
  tip: 'Allow JSON-RPC connections from specified source. Valid for <ip> are a single IP (e.g. 1.2.3.4), a network/netmask (e.g. 1.2.3.4/255.255.255.0) or a network/CIDR (e.g. 1.2.3.4/24). This option can be specified multiple times'
}, {
  name: 'rpcauth',
  defaultValue: null,
  type: 'string',
  tip: 'Username and HMAC-SHA-256 hashed password for JSON-RPC connections. The field <userpw> comes in the format: <USERNAME>:<SALT>$<HASH>. A canonical python script is included in share/rpcauth. The client then connects normally using the rpcuser=<USERNAME>/rpcpassword=<PASSWORD> pair of arguments. This option can be specified multiple times'
}, {
  name: 'rpcbind',
  defaultValue: null,
  type: 'string',
  tip: 'Bind to given address to listen for JSON-RPC connections. Do not expose the RPC server to untrusted networks such as the public internet! This option is ignored unless -rpcallowip is also passed. Port is optional and overrides -rpcport. Use [host]:port notation for IPv6. This option can be specified multiple times (default: 127.0.0.1 and ::1 i.e., localhost)'
}, {
  name: 'rpccookiefile',
  defaultValue: 'data dir',
  type: 'string',
  tip: 'Location of the auth cookie. Relative paths will be prefixed by a net-specific datadir location'
}, {
  name: 'rpcpassword',
  defaultValue: null,
  type: 'string',
  tip: 'Password for JSON-RPC connections'
}, {
  name: 'rpcport',
  defaultValue: null,
  type: 'number',
  tip: 'Listen for JSON-RPC connections on <port>'
}, {
  name: 'rpcserialversion',
  defaultValue: null,
  type: 'number',
  tip: 'Sets the serialization of raw transaction or block hex returned in non-verbose mode, non-segwit(0) or segwit(1)'
}, {
  name: 'rpcservertimeout',
  defaultValue: null,
  type: 'number',
  tip: 'Timeout during HTTP requests'
}, {
  name: 'rpcthreads',
  defaultValue: null,
  type: 'number',
  tip: 'Set the number of threads to service RPC calls'
}, {
  name: 'rpcuser',
  defaultValue: null,
  type: 'string',
  tip: 'Username for JSON-RPC connections'
}, {
  name: 'rpcwhitelist',
  defaultValue: null,
  type: 'string',
  tip: 'Set a whitelist to filter incoming RPC calls for a specific user'
}, {
  name: 'rpcwhitelistdefault',
  defaultValue: null,
  type: 'boolean',
  tip: 'Sets default behavior for rpc whitelisting. Unless rpcwhitelistdefault is set to 0, if any -rpcwhitelist is set, the rpc server acts as if all rpc users are subject to empty-unless-otherwise-specified whitelists'
}, {
  name: 'rpcworkqueue',
  defaultValue: null,
  type: 'number',
  tip: 'Set the depth of the work queue to service RPC calls'
}, {
  name: 'server',
  defaultValue: null,
  type: 'string',
  tip: 'Accept command line and JSON-RPC commands'
}, {
  name: 'flushinterval',
  defaultValue: null,
  type: 'number',
  tip: 'Time to wait between flushing chainstate to disk'
}, {
  name: 'writeinterval',
  defaultValue: null,
  type: 'number',
  tip: 'Time to wait between writing chainstate to disk'
}];
module.exports = options;