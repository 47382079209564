module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="headerBar">\n    <div class="search-field">\n        <input type="text" value="'+
((__t=( searchString ))==null?'':__t)+
'" id="viewsSearchInput" class="search-input form-control"\n               placeholder="Search..."/>\n        <i id="viewsSearchSubmit" class="fa fa-search"></i>\n    </div>\n    <div class="headerButtonBar">\n        <ul class="headerButtonList">\n            <li class="enabled">\n                <button id="viewsToggle" type="button" class="btn btn-default btn-lg sectionHeader__button">\n                    <span class="fa fa-gear" title="Settings" />\n                </button>\n            </li>\n        </ul>\n    </div>\n    <div class="title add" id="createView">\n        <button id="newView" class="pure-button btn btn-primary btn-lg sectionHeader__button">\n            Add View\n        </button>\n    </div>\n</div>\n\n<div id="viewsDropdown2" class="headerDropdown">\n    <div id="viewsDropdown" class="dropdownInner">\n        <ul>\n            <li class="nav-header">Sorting</li>\n            <li><a>\n                    <label class="checkbox checkboxLabel">\n                        <input class="css-checkbox" type="checkbox" id="viewsSortDesc">\n                        <i class="fa"></i>\n                        Sort descending\n                    </label>\n                </a></li>\n        </ul>\n    </div>\n</div>\n\n<div class="contentDiv" id="">\n    <div class="pure-u-1 pure-u-md-1 pure-u-lg-1 pure-u-xl-1 pure-u-sm-1">\n        <table class="dbms-table">\n            <thead>\n            <tr>\n                <th class="dbms-table-th table-cell1">Name</th>\n                <th class="dbms-table-th table-cell2">Type</th>\n                <th class="dbms-table-th table-cell3">Actions</th>\n            </tr>\n            </thead>\n            <tbody id="viewsThumbnailsIn">\n            ';
 views.forEach(function(view) {
                var viewName = view.name;
            
__p+='\n            <tr id="gotoView">\n                <td class=\'dbms-table-td table-cell1\'>'+
((__t=( viewName ))==null?'':__t)+
'</td>\n                <td class=\'dbms-table-td table-cell2\'>'+
((__t=( view.type ))==null?'':__t)+
'</td>\n                <td class=\'dbms-table-td table-cell3\'></td>\n            </tr>\n            ';
 }); 
__p+='\n            </tbody>\n        </table>\n    </div>\n</div>\n\n';
}
return __p;
};
