"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Database = exports.sql = exports.dbmsjs = void 0;
var database_1 = require("./database");
module.exports = exports = dbmsjs;
function dbmsjs(config, name) {
  if (typeof config === "string" || Array.isArray(config)) {
    var url = config;
    return new database_1.Database(url, name);
  }
  return new database_1.Database(config);
}
exports.dbmsjs = dbmsjs;
exports.default = dbmsjs;
var sql_1 = require("./sql");
Object.defineProperty(exports, "sql", {
  enumerable: true,
  get: function get() {
    return sql_1.sql;
  }
});
var database_2 = require("./database");
Object.defineProperty(exports, "Database", {
  enumerable: true,
  get: function get() {
    return database_2.Database;
  }
});