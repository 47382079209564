module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="paddingBox">\r\n    <div class="foxxDesc">\r\n        <p><span class="foxxMount">'+
((__t=( model.get("mount") ))==null?'':__t)+
'</span></p>\r\n        <p><span class="foxxName">'+
((__t=( model.get("name") ))==null?'':__t)+
'</span></p>\r\n        <p><span class="foxxName">'+
((__t=( model.get("version") ))==null?'':__t)+
'</span></p>\r\n        <!--<p><span class="foxxCategory">'+
((__t=( model.get("category") ))==null?'':__t)+
'</span></p>-->\r\n    </div>\r\n    <!-- <div class="foxxActions"><button class="installFoxx btn-success" appId="'+
((__t=( model.name ))==null?'':__t)+
'" appVersion="'+
((__t=( model.latestVersion ))==null?'':__t)+
'" >Interface</button></div>-->\r\n    <div class="borderBox"></div>\r\n    <img src="'+
((__t=( model.thumbnailUrl ))==null?'':__t)+
'" height="50" width="50" alt="Icon for Service" class="icon">\r\n    ';
 if(model.isDevelopment()) { 
__p+='\r\n        <div class="tileBadge">\r\n        <span>\r\n          <div class="corneredBadge development">\r\n            Development\r\n          </div>\r\n        </span>\r\n        </div>\r\n    ';
 } else { 
__p+='\r\n        <div class="tileBadge">\r\n        <span>\r\n          <div class="corneredBadge production">\r\n            Production\r\n          </div>\r\n        </span>\r\n        </div>\r\n    ';
 } 
__p+='\r\n</div>\r\n\r\n';
}
return __p;
};
