module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="tableWrapper">\n    ';
 var type = type; 
__p+='\n    <table class="dbms-table" id="'+
((__t=( content.id ))==null?'':__t)+
'">\n        <thead>\n        <tr>\n            ';
 var hcounter = 0; 
__p+='\n            ';
 _.each(content.titles, function(y) { 
__p+='\n            <th class="dbms-table-th table-cell'+
((__t=( hcounter ))==null?'':__t)+
'">'+
((__t=( y ))==null?'':__t)+
'</th>\n            ';
 hcounter++; }); 
__p+='\n        </tr>\n        </thead>\n        <tbody>\n        ';
 _.each(content.rows, function(k) { var counter = 0; 
__p+='\n        <tr>\n            ';
 _.each(k, function(x) { 
__p+='\n                ';
 if (type && type[counter] === 'pre') { 
__p+='\n                <td class="dbms-table-td table-cell'+
((__t=( counter ))==null?'':__t)+
'">\n                    <pre class="tablePre">'+
((__t=( (content.unescaped && content.unescaped[counter] ? x : _.escape(x)) ))==null?'':__t)+
'</pre>\n                </td>\n                ';
 } else { 
__p+='\n                <td class="dbms-table-td table-cell'+
((__t=( counter ))==null?'':__t)+
'">'+
((__t=( (content.unescaped && content.unescaped[counter] ? x : _.escape(x)) ))==null?'':__t)+
'</td>\n                ';
 } 
__p+='\n            ';
 counter++;}); 
__p+='\n        </tr>\n        ';
 }); 
__p+='\n\n        ';
 if (content.rows.length === 0) { 
__p+='\n            <tr>\n                ';
 var xcounter = 0; 
__p+='\n                ';
 _.each(content.titles, function(y) { 
__p+='\n                    ';
 if (xcounter === 0) { 
__p+='\n                        <td>No content.</td>\n                    ';
 } else { 
__p+='\n                        <td></td>\n                    ';
 } 
__p+='\n                ';
 xcounter++; }); 
__p+='\n            </tr>\n        ';
 }; 
__p+='\n        </tbody>\n    </table>\n</div>\n';
}
return __p;
};
