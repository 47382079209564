/* global window, Backbone, dbmsHelper */
(function () {
  'use strict';

  window.ArangoQuery = Backbone.Model.extend({
    urlRoot: dbmsHelper.databaseUrl('/_api/user'),
    defaults: {
      name: '',
      type: 'custom',
      value: ''
    }
  });
})();