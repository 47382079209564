import { createDomain, forward, sample } from 'effector'
import { getReplicationMode, startApplierConfig, stopApplier, updateApplierConfig } from '../../api/dbEndpoint'
import { ReplicationType } from '../../constants/replicationTypes'
import { getDefaultDb } from '../../utils/dbmsClient'

const domain = createDomain('connectors')

export type Connector = {
  database: string
  type: ReplicationType
  kind: 'Simple' | 'Advanced'
  global?: boolean
  endpoint?: string
  username?: string
  password?: string
  bind_address?: string
  connect?: string
  configuration?: Object
}

export const $connectors = domain.createStore<Array<Connector>>([])

export const $selectedConnector = domain.createStore<Connector | null>(null)

export const showConnectorError = domain.createEvent<string>()

export const openPage = domain.createEvent()

export const fetchConnectors = domain.createEffect<void, Array<Connector>>()

export const openAddModal = domain.createEvent()
export const closeAddModal = domain.createEvent()

export const $isOpenAddModal = domain
  .createStore(false)
  .on(openAddModal, () => true)
  .on(closeAddModal, () => false)

export const submitAddModal = domain.createEvent<Connector>()

export const addConnectorFx = domain.createEffect<Connector, void, Error & { response: any }>()

addConnectorFx.use(async (connector) => {
  const { configuration, database, type, kind, ...rest } = connector
  const db = getDefaultDb()
  const isGlobal = type === ReplicationType.follower && rest.global
  if (type === ReplicationType.bitcoin) {
    await db.createDatabase(connector.database)
  }

  const { mode } = await getReplicationMode()

  if (mode !== 0) {
    await stopApplier(isGlobal)
  }

  const config =
    kind === 'Advanced'
      ? {
          database: connector.database,
          type: connector.type,
          ...configuration,
        }
      : {
          database,
          type,
          max_mempool_size: 1,
          db_write_interval: 15,
          db_flush_interval: 10,
          includeSystem: false,
          incremental: true,
          autoResync: true,
          ...rest,
        }

  const res = await updateApplierConfig(config, isGlobal)
  await startApplierConfig(isGlobal)
  setTimeout(() => {
    location.reload()
  }, 1000)
  return res
})

forward({
  from: submitAddModal,
  to: addConnectorFx,
})

sample({
  clock: addConnectorFx.fail,
  target: showConnectorError,
  fn: ({ error }) => {
    return (
      (error.response?.data?.errorMessage &&
        `${error.response.data.errorMessage}`) ||
      ''
    )
  },
})

export const $connectorError = domain
  .createStore<string>('')
  .on(showConnectorError, (_, error) => error)
  .reset(openAddModal)
  .reset(closeAddModal)
  .reset(submitAddModal)


forward({
  from: addConnectorFx.done,
  to: closeAddModal,
})
