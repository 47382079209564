/* jshint browser: true */
/* jshint unused: false */
/* global window, _, dbmsHelper */
(function () {
  'use strict';

  window.DbmsLogs = window.PaginatedCollection.extend({
    upto: false,
    loglevel: 0,
    totalPages: 0,
    parse: function parse(response) {
      var myResponse = [];
      _.each(response.lid, function (val, i) {
        myResponse.push({
          level: response.level[i],
          lid: val,
          topic: response.topic[i],
          text: response.text[i],
          timestamp: response.timestamp[i],
          totalAmount: response.totalAmount
        });
      });
      this.totalAmount = response.totalAmount;
      this.totalPages = Math.ceil(this.totalAmount / this.pagesize);
      return myResponse;
    },
    initialize: function initialize(options) {
      if (options.upto === true) {
        this.upto = true;
      }
      this.loglevel = options.loglevel;
      if (options.endpoint) {
        this.endpoint = options.endpoint;
      }
    },
    model: window.newArangoLog,
    url: function url() {
      var type;
      var rtnStr;
      var size;
      var inverseOffset = this.totalAmount - (this.page + 1) * this.pagesize;
      if (inverseOffset < 0 && this.page === this.totalPages - 1) {
        inverseOffset = 0;
        size = this.totalAmount % this.pagesize;
      } else {
        size = this.pagesize;
      }

      // if totalAmount (first fetch) = 0, then set size to 1 (reduce traffic)
      if (this.totalAmount === 0) {
        size = 1;
      }
      if (this.upto) {
        type = 'upto';
      } else {
        type = 'level';
      }
      rtnStr = "/_admin/log?".concat(encodeURIComponent(type), "=").concat(encodeURIComponent(this.loglevel), "&size=").concat(encodeURIComponent(size), "&offset=").concat(encodeURIComponent(inverseOffset));
      if (this.endpoint) {
        rtnStr += "&serverId=".concat(encodeURIComponent(this.endpoint));
      }
      this.lastInverseOffset = inverseOffset;
      return dbmsHelper.databaseUrl(rtnStr);
    }
  });
})();