!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t() : "function" == typeof define && define.amd ? define("Joi", [], t) : "object" == typeof exports ? exports.Joi = t() : e.Joi = t();
}(this, function () {
  return function (e) {
    function t(n) {
      if (r[n]) return r[n].exports;
      var i = r[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(i.exports, i, i.exports, t), i.l = !0, i.exports;
    }
    var r = {};
    return t.m = e, t.c = r, t.i = function (e) {
      return e;
    }, t.d = function (e, r, n) {
      t.o(e, r) || Object.defineProperty(e, r, {
        configurable: !1,
        enumerable: !0,
        get: n
      });
    }, t.n = function (e) {
      var r = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(r, "a", r), r;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 32);
  }([function (e, t, r) {
    "use strict";

    (function (e, n) {
      var i = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
          return typeof e;
        } : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
        },
        o = r(17),
        a = r(15),
        s = r(37),
        u = r(16),
        c = r(18),
        l = {};
      t.clone = function (r, n) {
        if ("object" !== (void 0 === r ? "undefined" : i(r)) || null === r) return r;
        n = n || new Map();
        var o = n.get(r);
        if (o) return o;
        var a = void 0,
          s = !1;
        if (Array.isArray(r)) a = [], s = !0;else if (e.isBuffer(r)) a = e.from(r);else if (r instanceof Date) a = new Date(r.getTime());else if (r instanceof RegExp) a = new RegExp(r);else {
          var u = Object.getPrototypeOf(r);
          u && u.isImmutable ? a = r : (a = Object.create(u), s = !0);
        }
        if (n.set(r, a), s) for (var c = Object.getOwnPropertyNames(r), l = 0; l < c.length; ++l) {
          var f = c[l],
            p = Object.getOwnPropertyDescriptor(r, f);
          p && (p.get || p.set) ? Object.defineProperty(a, f, p) : a[f] = t.clone(r[f], n);
        }
        return a;
      }, t.merge = function (r, n, o, a) {
        if (t.assert(r && "object" === (void 0 === r ? "undefined" : i(r)), "Invalid target value: must be an object"), t.assert(null === n || void 0 === n || "object" === (void 0 === n ? "undefined" : i(n)), "Invalid source value: must be null, undefined, or an object"), !n) return r;
        if (Array.isArray(n)) {
          t.assert(Array.isArray(r), "Cannot merge array onto an object"), !1 === a && (r.length = 0);
          for (var s = 0; s < n.length; ++s) {
            r.push(t.clone(n[s]));
          }
          return r;
        }
        for (var u = Object.keys(n), c = 0; c < u.length; ++c) {
          var l = u[c];
          if ("__proto__" !== l) {
            var f = n[l];
            f && "object" === (void 0 === f ? "undefined" : i(f)) ? !r[l] || "object" !== i(r[l]) || Array.isArray(r[l]) !== Array.isArray(f) || f instanceof Date || e.isBuffer(f) || f instanceof RegExp ? r[l] = t.clone(f) : t.merge(r[l], f, o, a) : null !== f && void 0 !== f ? r[l] = f : !1 !== o && (r[l] = f);
          }
        }
        return r;
      }, t.applyToDefaults = function (e, r, n) {
        if (t.assert(e && "object" === (void 0 === e ? "undefined" : i(e)), "Invalid defaults value: must be an object"), t.assert(!r || !0 === r || "object" === (void 0 === r ? "undefined" : i(r)), "Invalid options value: must be true, falsy or an object"), !r) return null;
        var o = t.clone(e);
        return !0 === r ? o : t.merge(o, r, !0 === n, !1);
      }, t.cloneWithShallow = function (e, r) {
        if (!e || "object" !== (void 0 === e ? "undefined" : i(e))) return e;
        var n = l.store(e, {
            and: r,
            name: "n",
            sid: "-w6oemw"
          }),
          o = t.clone(e);
        return l.restore(o, e, n), o;
      }, l.store = function (e, r) {
        for (var n = {}, i = 0; i < r.length; ++i) {
          var o = r[i],
            a = t.reach(e, o);
          void 0 !== a && (n[o] = a, l.reachSet(e, o, void 0));
        }
        return n;
      }, l.restore = function (e, t, r) {
        for (var n = Object.keys(r), i = 0; i < n.length; ++i) {
          var o = n[i];
          l.reachSet(e, o, r[o]), l.reachSet(t, o, r[o]);
        }
      }, l.reachSet = function (e, t, r) {
        for (var n = t.split("."), i = e, o = 0; o < n.length; ++o) {
          var a = n[o];
          o + 1 === n.length && (i[a] = r), i = i[a];
        }
      }, t.applyToDefaultsWithShallow = function (e, r, n) {
        if (t.assert(e && "object" === (void 0 === e ? "undefined" : i(e)), "Invalid defaults value: must be an object"), t.assert(!r || !0 === r || "object" === (void 0 === r ? "undefined" : i(r)), "Invalid options value: must be true, falsy or an object"), t.assert(n && Array.isArray(n), "Invalid keys"), !r) return null;
        var o = t.cloneWithShallow(e, n);
        if (!0 === r) return o;
        var a = l.store(r, {
          and: n,
          name: "a",
          sid: "ydjmai"
        });
        return t.merge(o, r, !1, !1), l.restore(o, r, a), o;
      }, t.deepEqual = function (r, n, o, a) {
        o = o || {
          prototype: !0
        };
        var s = void 0 === r ? "undefined" : i(r);
        if (s !== (void 0 === n ? "undefined" : i(n))) return !1;
        if ("object" !== s || null === r || null === n) return r === n ? 0 !== r || 1 / r == 1 / n : r !== r && n !== n;
        if (a = a || [], -1 !== a.indexOf(r)) return !0;
        if (a.push(r), Array.isArray(r)) {
          if (!Array.isArray(n)) return !1;
          if (!o.part && r.length !== n.length) return !1;
          for (var u = 0; u < r.length; ++u) {
            if (o.part) {
              for (var c = !1, l = 0; l < n.length; ++l) {
                if (t.deepEqual(r[u], n[l], o)) {
                  c = !0;
                  break;
                }
              }
              return c;
            }
            if (!t.deepEqual(r[u], n[u], o)) return !1;
          }
          return !0;
        }
        if (e.isBuffer(r)) {
          if (!e.isBuffer(n)) return !1;
          if (r.length !== n.length) return !1;
          for (var f = 0; f < r.length; ++f) {
            if (r[f] !== n[f]) return !1;
          }
          return !0;
        }
        if (r instanceof Date) return n instanceof Date && r.getTime() === n.getTime();
        if (r instanceof RegExp) return n instanceof RegExp && r.toString() === n.toString();
        if (o.prototype && Object.getPrototypeOf(r) !== Object.getPrototypeOf(n)) return !1;
        var p = Object.getOwnPropertyNames(r);
        if (!o.part && p.length !== Object.getOwnPropertyNames(n).length) return !1;
        for (var h = 0; h < p.length; ++h) {
          var d = p[h],
            g = Object.getOwnPropertyDescriptor(r, d);
          if (g.get) {
            if (!t.deepEqual(g, Object.getOwnPropertyDescriptor(n, d), o, a)) return !1;
          } else if (!t.deepEqual(r[d], n[d], o, a)) return !1;
        }
        return !0;
      }, t.unique = function (e, t) {
        var r = void 0;
        if (t) {
          r = [];
          var n = new Set();
          e.forEach(function (e) {
            var i = e[t];
            n.has(i) || (n.add(i), r.push(e));
          });
        } else r = Array.from(new Set(e));
        return r;
      }, t.mapToObject = function (e, t) {
        if (!e) return null;
        for (var r = {}, n = 0; n < e.length; ++n) {
          t ? e[n][t] && (r[e[n][t]] = !0) : r[e[n]] = !0;
        }
        return r;
      }, t.intersect = function (e, r, n) {
        if (!e || !r) return [];
        for (var i = [], o = Array.isArray(e) ? t.mapToObject(e) : e, a = {}, s = 0; s < r.length; ++s) {
          if (o[r[s]] && !a[r[s]]) {
            if (n) return r[s];
            i.push(r[s]), a[r[s]] = !0;
          }
        }
        return n ? null : i;
      }, t.contain = function (e, r, n) {
        var o = null;
        "object" !== (void 0 === e ? "undefined" : i(e)) || "object" !== (void 0 === r ? "undefined" : i(r)) || Array.isArray(e) || Array.isArray(r) ? r = [].concat(r) : (o = r, r = Object.keys(r)), n = n || {}, t.assert("string" == typeof e || "object" === (void 0 === e ? "undefined" : i(e)), "Reference must be string or an object"), t.assert(r.length, "Values array cannot be empty");
        var a = void 0,
          s = void 0;
        if (n.deep) {
          a = t.deepEqual;
          var u = n.hasOwnProperty("only"),
            c = n.hasOwnProperty("part");
          s = {
            prototype: u ? n.only : !!c && !n.part,
            part: u ? !n.only : !c || n.part
          };
        } else a = function a(e, t) {
          return e === t;
        };
        for (var l = !1, f = new Array(r.length), p = 0; p < f.length; ++p) {
          f[p] = 0;
        }
        if ("string" == typeof e) {
          for (var h = "(", d = 0; d < r.length; ++d) {
            var g = r[d];
            t.assert("string" == typeof g, "Cannot compare string reference to non-string value"), h += (d ? "|" : "") + t.escapeRegex(g);
          }
          var y = new RegExp(h + ")", "g");
          l = !!e.replace(y, function (e, t) {
            var n = r.indexOf(t);
            return ++f[n], "";
          });
        } else if (Array.isArray(e)) for (var v = 0; v < e.length; ++v) {
          for (var m = !1, b = 0; b < r.length && !1 === m; ++b) {
            m = a(r[b], e[v], s) && b;
          }
          !1 !== m ? ++f[m] : l = !0;
        } else for (var _ = Object.getOwnPropertyNames(e), w = 0; w < _.length; ++w) {
          var E = _[w],
            x = r.indexOf(E);
          if (-1 !== x) {
            if (o && !a(o[E], e[E], s)) return !1;
            ++f[x];
          } else l = !0;
        }
        for (var A = !1, j = 0; j < f.length; ++j) {
          if (A = A || !!f[j], n.once && f[j] > 1 || !n.part && !f[j]) return !1;
        }
        return (!n.only || !l) && A;
      }, t.flatten = function (e, r) {
        for (var n = r || [], i = 0; i < e.length; ++i) {
          Array.isArray(e[i]) ? t.flatten(e[i], n) : n.push(e[i]);
        }
        return n;
      }, t.reach = function (e, r, n) {
        if (!1 === r || null === r || void 0 === r) return e;
        "string" == typeof (n = n || {}) && (n = {
          separator: n
        });
        for (var o = r.split(n.separator || "."), a = e, s = 0; s < o.length; ++s) {
          var u = o[s];
          if ("-" === u[0] && Array.isArray(a) && (u = u.slice(1, u.length), u = a.length - u), !a || "object" !== (void 0 === a ? "undefined" : i(a)) && "function" != typeof a || !(u in a) || "object" !== (void 0 === a ? "undefined" : i(a)) && !1 === n.functions) {
            t.assert(!n.strict || s + 1 === o.length, "Missing segment", u, "in reach path ", r), t.assert("object" === (void 0 === a ? "undefined" : i(a)) || !0 === n.functions || "function" != typeof a, "Invalid segment", u, "in reach path ", r), a = n.default;
            break;
          }
          a = a[u];
        }
        return a;
      }, t.reachTemplate = function (e, r, n) {
        return r.replace(/{([^}]+)}/g, function (r, i) {
          var o = t.reach(e, i, n);
          return void 0 === o || null === o ? "" : o;
        });
      }, t.formatStack = function (e) {
        for (var t = [], r = 0; r < e.length; ++r) {
          var n = e[r];
          t.push([n.getFileName(), n.getLineNumber(), n.getColumnNumber(), n.getFunctionName(), n.isConstructor()]);
        }
        return t;
      }, t.formatTrace = function (e) {
        for (var t = [], r = 0; r < e.length; ++r) {
          var n = e[r];
          t.push((n[4] ? "new " : "") + n[3] + " (" + n[0] + ":" + n[1] + ":" + n[2] + ")");
        }
        return t;
      }, t.callStack = function (e) {
        var r = Error.prepareStackTrace;
        Error.prepareStackTrace = function (e, t) {
          return t;
        };
        var n = {};
        Error.captureStackTrace(n, this);
        var i = n.stack;
        return Error.prepareStackTrace = r, t.formatStack(i).slice(1 + e);
      }, t.displayStack = function (e) {
        var r = t.callStack(void 0 === e ? 1 : e + 1);
        return t.formatTrace(r);
      }, t.abortThrow = !1, t.abort = function (e, r) {
        if ("test" === n.env.NODE_ENV || !0 === t.abortThrow) throw new Error(e || "Unknown error");
        var i = "";
        r || (i = t.displayStack(1).join("\n\t")), console.log("ABORT: " + e + "\n\t" + i), n.exit(1);
      }, t.assert = function (e) {
        if (!e) {
          for (var r = arguments.length, n = Array(r > 1 ? r - 1 : 0), i = 1; i < r; i++) {
            n[i - 1] = arguments[i];
          }
          if (1 === n.length && n[0] instanceof Error) throw n[0];
          var a = n.filter(function (e) {
            return "" !== e;
          }).map(function (e) {
            return "string" == typeof e ? e : e instanceof Error ? e.message : t.stringify(e);
          });
          throw new o.AssertionError({
            message: a.join(" ") || "Unknown error",
            actual: !1,
            expected: !0,
            operator: "==",
            stackStartFunction: t.assert
          });
        }
      }, t.Bench = function () {
        this.ts = 0, this.reset();
      }, t.Bench.prototype.reset = function () {
        this.ts = t.Bench.now();
      }, t.Bench.prototype.elapsed = function () {
        return t.Bench.now() - this.ts;
      }, t.Bench.now = function () {
        var e = n.hrtime();
        return 1e3 * e[0] + e[1] / 1e6;
      }, t.escapeRegex = function (e) {
        return e.replace(/[\^\$\.\*\+\-\?\=\!\:\|\\\/\(\)\[\]\{\}\,]/g, "\\$&");
      }, t.base64urlEncode = function (r, n) {
        return t.assert("string" == typeof r || e.isBuffer(r), "value must be string or buffer"), (e.isBuffer(r) ? r : e.from(r, n || "binary")).toString("base64").replace(/\+/g, "-").replace(/\//g, "_").replace(/\=/g, "");
      }, t.base64urlDecode = function (t, r) {
        if ("string" != typeof t) throw new Error("Value not a string");
        if (!/^[\w\-]*$/.test(t)) throw new Error("Invalid character");
        var n = e.from(t, "base64");
        return "buffer" === r ? n : n.toString(r || "binary");
      }, t.escapeHeaderAttribute = function (e) {
        return t.assert(/^[ \w\!#\$%&'\(\)\*\+,\-\.\/\:;<\=>\?@\[\]\^`\{\|\}~\"\\]*$/.test(e), "Bad attribute value (" + e + ")"), e.replace(/\\/g, "\\\\").replace(/\"/g, '\\"');
      }, t.escapeHtml = function (e) {
        return c.escapeHtml(e);
      }, t.escapeJavaScript = function (e) {
        return c.escapeJavaScript(e);
      }, t.escapeJson = function (e) {
        return c.escapeJson(e);
      }, t.once = function (e) {
        if (e._hoekOnce) return e;
        var t = !1,
          r = function r() {
            if (!t) {
              t = !0;
              for (var r = arguments.length, n = Array(r), i = 0; i < r; i++) {
                n[i] = arguments[i];
              }
              e.apply(null, n);
            }
          };
        return r._hoekOnce = !0, r;
      }, t.isInteger = Number.isSafeInteger, t.ignore = function () {}, t.inherits = u.inherits, t.format = u.format, t.transform = function (e, r, n) {
        t.assert(null === e || void 0 === e || "object" === (void 0 === e ? "undefined" : i(e)) || Array.isArray(e), "Invalid source object: must be null, undefined, an object, or an array");
        var o = "object" === (void 0 === n ? "undefined" : i(n)) && null !== n ? n.separator || "." : ".";
        if (Array.isArray(e)) {
          for (var a = [], s = 0; s < e.length; ++s) {
            a.push(t.transform(e[s], r, n));
          }
          return a;
        }
        for (var u = {}, c = Object.keys(r), l = 0; l < c.length; ++l) {
          var f = c[l],
            p = f.split(o),
            h = r[f];
          t.assert("string" == typeof h, 'All mappings must be "." delineated strings');
          for (var d = void 0, g = u; p.length > 1;) {
            d = p.shift(), g[d] || (g[d] = {}), g = g[d];
          }
          d = p.shift(), g[d] = t.reach(e, h, n);
        }
        return u;
      }, t.uniqueFilename = function (e, t) {
        t = t ? "." !== t[0] ? "." + t : t : "", e = s.resolve(e);
        var r = [Date.now(), n.pid, a.randomBytes(8).toString("hex")].join("-") + t;
        return s.join(e, r);
      }, t.stringify = function () {
        try {
          for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
            t[r] = arguments[r];
          }
          return JSON.stringify.apply(null, t);
        } catch (e) {
          return "[Cannot display object: " + e.message + "]";
        }
      }, t.shallow = function (e) {
        for (var t = {}, r = Object.keys(e), n = 0; n < r.length; ++n) {
          var i = r[n];
          t[i] = e[i];
        }
        return t;
      }, t.wait = function (e) {
        return new Promise(function (t) {
          return setTimeout(t, e);
        });
      }, t.block = function () {
        return new Promise(t.ignore);
      };
    }).call(t, r(3).Buffer, r(7));
  }, function (e, t, r) {
    "use strict";

    var n = r(0);
    t.create = function (e, t) {
      n.assert("string" == typeof e, "Invalid reference key:", e);
      var r = n.clone(t),
        i = function e(t, i) {
          return n.reach(e.isContext ? i.context : t, e.key, r);
        };
      return i.isContext = e[0] === (r && r.contextPrefix || "$"), i.key = i.isContext ? e.slice(1) : e, i.path = i.key.split(r && r.separator || "."), i.depth = i.path.length, i.root = i.path[0], i.isJoi = !0, i.toString = function () {
        return (i.isContext ? "context:" : "ref:") + i.key;
      }, i;
    }, t.isRef = function (e) {
      return "function" == typeof e && e.isJoi;
    }, t.push = function (e, r) {
      t.isRef(r) && !r.isContext && e.push(r.root);
    };
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    var i = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      o = function () {
        function e(e, t) {
          for (var r = 0; r < t.length; r++) {
            var n = t[r];
            n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          return r && e(t.prototype, r), n && e(t, n), t;
        };
      }(),
      a = r(0),
      s = r(11),
      u = r(1),
      c = r(6),
      l = null,
      f = null,
      p = {
        Set: r(9)
      };
    p.defaults = {
      abortEarly: !0,
      convert: !0,
      allowUnknown: !1,
      skipFunctions: !1,
      stripUnknown: !1,
      language: {},
      presence: "optional",
      strip: !1,
      noDefaults: !1,
      escapeHtml: !1
    }, e.exports = p.Any = function () {
      function e() {
        n(this, e), f = f || r(4), this.isJoi = !0, this._type = "any", this._settings = null, this._valids = new p.Set(), this._invalids = new p.Set(), this._tests = [], this._refs = [], this._flags = {}, this._description = null, this._unit = null, this._notes = [], this._tags = [], this._examples = [], this._meta = [], this._inner = {};
      }
      return e.prototype._init = function () {
        return this;
      }, e.prototype.createError = function (e, t, r, n) {
        var i = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : this._flags;
        return c.create(e, t, r, n, i);
      }, e.prototype.createOverrideError = function (e, t, r, n, i, o) {
        return c.create(e, t, r, n, this._flags, i, o);
      }, e.prototype.checkOptions = function (e) {
        var t = r(21),
          n = t.options.validate(e);
        if (n.error) throw new Error(n.error.details[0].message);
      }, e.prototype.clone = function () {
        var e = Object.create(Object.getPrototypeOf(this));
        e.isJoi = !0, e._currentJoi = this._currentJoi, e._type = this._type, e._settings = this._settings, e._baseType = this._baseType, e._valids = this._valids.slice(), e._invalids = this._invalids.slice(), e._tests = this._tests.slice(), e._refs = this._refs.slice(), e._flags = a.clone(this._flags), e._description = this._description, e._unit = this._unit, e._notes = this._notes.slice(), e._tags = this._tags.slice(), e._examples = this._examples.slice(), e._meta = this._meta.slice(), e._inner = {};
        for (var t = Object.keys(this._inner), r = 0; r < t.length; ++r) {
          var n = t[r];
          e._inner[n] = this._inner[n] ? this._inner[n].slice() : null;
        }
        return e;
      }, e.prototype.concat = function (e) {
        a.assert(e instanceof p.Any, "Invalid schema object"), a.assert("any" === this._type || "any" === e._type || e._type === this._type, "Cannot merge type", this._type, "with another type:", e._type);
        var t = this.clone();
        if ("any" === this._type && "any" !== e._type) {
          for (var r = e.clone(), n = ["_settings", "_valids", "_invalids", "_tests", "_refs", "_flags", "_description", "_unit", "_notes", "_tags", "_examples", "_meta", "_inner"], i = 0; i < n.length; ++i) {
            r[n[i]] = t[n[i]];
          }
          t = r;
        }
        t._settings = t._settings ? s.concat(t._settings, e._settings) : e._settings, t._valids.merge(e._valids, e._invalids), t._invalids.merge(e._invalids, e._valids), t._tests = t._tests.concat(e._tests), t._refs = t._refs.concat(e._refs), a.merge(t._flags, e._flags), t._description = e._description || t._description, t._unit = e._unit || t._unit, t._notes = t._notes.concat(e._notes), t._tags = t._tags.concat(e._tags), t._examples = t._examples.concat(e._examples), t._meta = t._meta.concat(e._meta);
        for (var o = Object.keys(e._inner), u = "object" === t._type, c = 0; c < o.length; ++c) {
          var l = o[c],
            f = e._inner[l];
          if (f) {
            var h = t._inner[l];
            if (h) {
              if (u && "children" === l) {
                for (var d = {}, g = 0; g < h.length; ++g) {
                  d[h[g].key] = g;
                }
                for (var y = 0; y < f.length; ++y) {
                  var v = f[y].key;
                  d[v] >= 0 ? h[d[v]] = {
                    key: v,
                    schema: h[d[v]].schema.concat(f[y].schema)
                  } : h.push(f[y]);
                }
              } else t._inner[l] = t._inner[l].concat(f);
            } else t._inner[l] = f.slice();
          }
        }
        return t;
      }, e.prototype._test = function (e, t, r, n) {
        var i = this.clone();
        return i._tests.push({
          func: r,
          name: e,
          arg: t,
          options: n
        }), i;
      }, e.prototype.options = function (e) {
        a.assert(!e.context, "Cannot override context"), this.checkOptions(e);
        var t = this.clone();
        return t._settings = s.concat(t._settings, e), t;
      }, e.prototype.strict = function (e) {
        var t = this.clone(),
          r = void 0 !== e && !e;
        return t._settings = s.concat(t._settings, {
          convert: r
        }), t;
      }, e.prototype.raw = function (e) {
        var t = void 0 === e || e;
        if (this._flags.raw === t) return this;
        var r = this.clone();
        return r._flags.raw = t, r;
      }, e.prototype.error = function (e) {
        a.assert(e && (e instanceof Error || "function" == typeof e), "Must provide a valid Error object or a function");
        var t = this.clone();
        return t._flags.error = e, t;
      }, e.prototype.allow = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        var n = this.clone();
        t = a.flatten(t);
        for (var i = 0; i < t.length; ++i) {
          var o = t[i];
          a.assert(void 0 !== o, "Cannot call allow/valid/invalid with undefined"), n._invalids.remove(o), n._valids.add(o, n._refs);
        }
        return n;
      }, e.prototype.valid = function () {
        var e = this.allow.apply(this, arguments);
        return e._flags.allowOnly = !0, e;
      }, e.prototype.invalid = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        var n = this.clone();
        t = a.flatten(t);
        for (var i = 0; i < t.length; ++i) {
          var o = t[i];
          a.assert(void 0 !== o, "Cannot call allow/valid/invalid with undefined"), n._valids.remove(o), n._invalids.add(o, n._refs);
        }
        return n;
      }, e.prototype.required = function () {
        if ("required" === this._flags.presence) return this;
        var e = this.clone();
        return e._flags.presence = "required", e;
      }, e.prototype.optional = function () {
        if ("optional" === this._flags.presence) return this;
        var e = this.clone();
        return e._flags.presence = "optional", e;
      }, e.prototype.forbidden = function () {
        if ("forbidden" === this._flags.presence) return this;
        var e = this.clone();
        return e._flags.presence = "forbidden", e;
      }, e.prototype.strip = function () {
        if (this._flags.strip) return this;
        var e = this.clone();
        return e._flags.strip = !0, e;
      }, e.prototype.applyFunctionToChildren = function (e, t, r, n) {
        if (e = [].concat(e), 1 !== e.length || "" !== e[0]) {
          n = n ? n + "." : "";
          var i = ("" === e[0] ? e.slice(1) : e).map(function (e) {
            return n + e;
          });
          throw new Error("unknown key(s) " + i.join(", "));
        }
        return this[t].apply(this, r);
      }, e.prototype.default = function (e, t) {
        "function" != typeof e || u.isRef(e) || (!e.description && t && (e.description = t), this._flags.func || a.assert("string" == typeof e.description && e.description.length > 0, "description must be provided when default value is a function"));
        var r = this.clone();
        return r._flags.default = e, u.push(r._refs, e), r;
      }, e.prototype.empty = function (e) {
        var t = this.clone();
        return void 0 === e ? delete t._flags.empty : t._flags.empty = f.schema(this._currentJoi, e), t;
      }, e.prototype.when = function (e, t) {
        a.assert(t && "object" === (void 0 === t ? "undefined" : i(t)), "Invalid options"), a.assert(void 0 !== t.then || void 0 !== t.otherwise, 'options must have at least one of "then" or "otherwise"');
        var n = t.hasOwnProperty("then") ? this.concat(f.schema(this._currentJoi, t.then)) : void 0,
          o = t.hasOwnProperty("otherwise") ? this.concat(f.schema(this._currentJoi, t.otherwise)) : void 0;
        l = l || r(10);
        var s = {
          then: n,
          otherwise: o
        };
        Object.prototype.hasOwnProperty.call(t, "is") && (s.is = t.is);
        var u = l.when(e, s);
        return u._flags.presence = "ignore", u._baseType = this, u;
      }, e.prototype.description = function (e) {
        a.assert(e && "string" == typeof e, "Description must be a non-empty string");
        var t = this.clone();
        return t._description = e, t;
      }, e.prototype.notes = function (e) {
        a.assert(e && ("string" == typeof e || Array.isArray(e)), "Notes must be a non-empty string or array");
        var t = this.clone();
        return t._notes = t._notes.concat(e), t;
      }, e.prototype.tags = function (e) {
        a.assert(e && ("string" == typeof e || Array.isArray(e)), "Tags must be a non-empty string or array");
        var t = this.clone();
        return t._tags = t._tags.concat(e), t;
      }, e.prototype.meta = function (e) {
        a.assert(void 0 !== e, "Meta cannot be undefined");
        var t = this.clone();
        return t._meta = t._meta.concat(e), t;
      }, e.prototype.example = function () {
        a.assert(1 === arguments.length, "Missing example");
        var e = arguments.length <= 0 ? void 0 : arguments[0],
          t = this.clone();
        return t._examples.push(e), t;
      }, e.prototype.unit = function (e) {
        a.assert(e && "string" == typeof e, "Unit name must be a non-empty string");
        var t = this.clone();
        return t._unit = e, t;
      }, e.prototype._prepareEmptyValue = function (e) {
        return "string" == typeof e && this._flags.trim ? e.trim() : e;
      }, e.prototype._validate = function (e, t, r, n) {
        var i = this,
          o = e;
        t = t || {
          key: "",
          path: [],
          parent: null,
          reference: n
        }, this._settings && (r = s.concat(r, this._settings));
        var l = [],
          f = function f() {
            var n = void 0;
            if (void 0 !== e) n = i._flags.raw ? o : e;else if (r.noDefaults) n = e;else if (u.isRef(i._flags.default)) n = i._flags.default(t.parent, r);else if ("function" != typeof i._flags.default || i._flags.func && !i._flags.default.description) n = a.clone(i._flags.default);else {
              var s = void 0;
              null !== t.parent && i._flags.default.length > 0 && (s = [a.clone(t.parent), r]);
              var c = p._try(i._flags.default, s);
              n = c.value, c.error && l.push(i.createError("any.default", {
                error: c.error
              }, t, r));
            }
            if (l.length && "function" == typeof i._flags.error) {
              var f = i._flags.error.call(i, l);
              l = "string" == typeof f ? [i.createOverrideError("override", {
                reason: l
              }, t, r, f)] : [].concat(f).map(function (e) {
                return e instanceof Error ? e : i.createOverrideError(e.type || "override", e.context, t, r, e.message, e.template);
              });
            }
            return {
              value: i._flags.strip ? void 0 : n,
              finalValue: n,
              errors: l.length ? l : null
            };
          };
        if (this._coerce) {
          var h = this._coerce.call(this, e, t, r);
          if (h.errors) return e = h.value, l = l.concat(h.errors), f();
          e = h.value;
        }
        this._flags.empty && !this._flags.empty._validate(this._prepareEmptyValue(e), null, p.defaults).errors && (e = void 0);
        var d = this._flags.presence || r.presence;
        if ("optional" === d) {
          if (void 0 === e) {
            var g = this._flags.hasOwnProperty("default") && void 0 === this._flags.default;
            if (!g || "object" !== this._type) return f();
            e = {};
          }
        } else {
          if ("required" === d && void 0 === e) return l.push(this.createError("any.required", null, t, r)), f();
          if ("forbidden" === d) return void 0 === e ? f() : (l.push(this.createError("any.unknown", null, t, r)), f());
        }
        if (this._valids.has(e, t, r, this._flags.insensitive)) return f();
        if (this._invalids.has(e, t, r, this._flags.insensitive) && (l.push(this.createError("" === e ? "any.empty" : "any.invalid", {
          value: e,
          invalids: this._invalids.values({
            stripUndefined: !0
          })
        }, t, r)), r.abortEarly || void 0 === e)) return f();
        if (this._base) {
          var y = this._base.call(this, e, t, r);
          if (y.errors) return e = y.value, l = l.concat(y.errors), f();
          if (y.value !== e) {
            if (e = y.value, this._valids.has(e, t, r, this._flags.insensitive)) return f();
            if (this._invalids.has(e, t, r, this._flags.insensitive) && (l.push(this.createError("" === e ? "any.empty" : "any.invalid", {
              value: e,
              invalids: this._invalids.values({
                stripUndefined: !0
              })
            }, t, r)), r.abortEarly)) return f();
          }
        }
        if (this._flags.allowOnly && (l.push(this.createError("any.allowOnly", {
          value: e,
          valids: this._valids.values({
            stripUndefined: !0
          })
        }, t, r)), r.abortEarly)) return f();
        for (var v = 0; v < this._tests.length; ++v) {
          var m = this._tests[v],
            b = m.func.call(this, e, t, r);
          if (b instanceof c.Err) {
            if (l.push(b), r.abortEarly) return f();
          } else e = b;
        }
        return f();
      }, e.prototype._validateWithOptions = function (e, t, r) {
        t && this.checkOptions(t);
        var n = s.concat(p.defaults, t),
          i = this._validate(e, null, n),
          o = c.process(i.errors, e);
        return r ? r(o, i.value) : {
          error: o,
          value: i.value,
          then: function then(e, t) {
            return o ? Promise.reject(o).catch(t) : Promise.resolve(i.value).then(e);
          },
          catch: function _catch(e) {
            return o ? Promise.reject(o).catch(e) : Promise.resolve(i.value);
          }
        };
      }, e.prototype.validate = function (e, t, r) {
        return "function" == typeof t ? this._validateWithOptions(e, null, t) : this._validateWithOptions(e, t, r);
      }, e.prototype.describe = function () {
        var e = this,
          t = {
            type: this._type
          },
          r = Object.keys(this._flags);
        if (r.length) if (["empty", "default", "lazy", "label"].some(function (t) {
          return e._flags.hasOwnProperty(t);
        })) {
          t.flags = {};
          for (var n = 0; n < r.length; ++n) {
            var i = r[n];
            "empty" === i ? t.flags[i] = this._flags[i].describe() : "default" === i ? u.isRef(this._flags[i]) ? t.flags[i] = this._flags[i].toString() : "function" == typeof this._flags[i] ? t.flags[i] = {
              description: this._flags[i].description,
              function: this._flags[i]
            } : t.flags[i] = this._flags[i] : "lazy" === i || "label" === i || (t.flags[i] = this._flags[i]);
          }
        } else t.flags = this._flags;
        this._settings && (t.options = a.clone(this._settings)), this._baseType && (t.base = this._baseType.describe()), this._description && (t.description = this._description), this._notes.length && (t.notes = this._notes), this._tags.length && (t.tags = this._tags), this._meta.length && (t.meta = this._meta), this._examples.length && (t.examples = this._examples), this._unit && (t.unit = this._unit);
        var o = this._valids.values();
        o.length && (t.valids = o.map(function (e) {
          return u.isRef(e) ? e.toString() : e;
        }));
        var s = this._invalids.values();
        s.length && (t.invalids = s.map(function (e) {
          return u.isRef(e) ? e.toString() : e;
        })), t.rules = [];
        for (var c = 0; c < this._tests.length; ++c) {
          var l = this._tests[c],
            f = {
              name: l.name
            };
          void 0 !== l.arg && (f.arg = u.isRef(l.arg) ? l.arg.toString() : l.arg);
          var p = l.options;
          if (p) {
            if (p.hasRef) {
              f.arg = {};
              for (var h = Object.keys(l.arg), d = 0; d < h.length; ++d) {
                var g = h[d],
                  y = l.arg[g];
                f.arg[g] = u.isRef(y) ? y.toString() : y;
              }
            }
            "string" == typeof p.description ? f.description = p.description : "function" == typeof p.description && (f.description = p.description(f.arg));
          }
          t.rules.push(f);
        }
        t.rules.length || delete t.rules;
        var v = this._getLabel();
        return v && (t.label = v), t;
      }, e.prototype.label = function (e) {
        a.assert(e && "string" == typeof e, "Label name must be a non-empty string");
        var t = this.clone();
        return t._flags.label = e, t;
      }, e.prototype._getLabel = function (e) {
        return this._flags.label || e;
      }, o(e, [{
        key: "schemaType",
        get: function get() {
          return this._type;
        }
      }]), e;
    }(), p.Any.prototype.isImmutable = !0, p.Any.prototype.only = p.Any.prototype.equal = p.Any.prototype.valid, p.Any.prototype.disallow = p.Any.prototype.not = p.Any.prototype.invalid, p.Any.prototype.exist = p.Any.prototype.required, p._try = function (e, t) {
      var r = void 0,
        n = void 0;
      try {
        n = e.apply(null, t);
      } catch (e) {
        r = e;
      }
      return {
        value: n,
        error: r
      };
    };
  }, function (e, t, r) {
    "use strict";

    (function (e) {
      function n() {
        return o.TYPED_ARRAY_SUPPORT ? 2147483647 : 1073741823;
      }
      function i(e, t) {
        if (n() < t) throw new RangeError("Invalid typed array length");
        return o.TYPED_ARRAY_SUPPORT ? (e = new Uint8Array(t), e.__proto__ = o.prototype) : (null === e && (e = new o(t)), e.length = t), e;
      }
      function o(e, t, r) {
        if (!(o.TYPED_ARRAY_SUPPORT || this instanceof o)) return new o(e, t, r);
        if ("number" == typeof e) {
          if ("string" == typeof t) throw new Error("If encoding is specified then the first argument must be a string");
          return c(this, e);
        }
        return a(this, e, t, r);
      }
      function a(e, t, r, n) {
        if ("number" == typeof t) throw new TypeError('"value" argument must not be a number');
        return "undefined" != typeof ArrayBuffer && t instanceof ArrayBuffer ? p(e, t, r, n) : "string" == typeof t ? l(e, t, r) : h(e, t);
      }
      function s(e) {
        if ("number" != typeof e) throw new TypeError('"size" argument must be a number');
        if (e < 0) throw new RangeError('"size" argument must not be negative');
      }
      function u(e, t, r, n) {
        return s(t), t <= 0 ? i(e, t) : void 0 !== r ? "string" == typeof n ? i(e, t).fill(r, n) : i(e, t).fill(r) : i(e, t);
      }
      function c(e, t) {
        if (s(t), e = i(e, t < 0 ? 0 : 0 | d(t)), !o.TYPED_ARRAY_SUPPORT) for (var r = 0; r < t; ++r) {
          e[r] = 0;
        }
        return e;
      }
      function l(e, t, r) {
        if ("string" == typeof r && "" !== r || (r = "utf8"), !o.isEncoding(r)) throw new TypeError('"encoding" must be a valid string encoding');
        var n = 0 | y(t, r);
        e = i(e, n);
        var a = e.write(t, r);
        return a !== n && (e = e.slice(0, a)), e;
      }
      function f(e, t) {
        var r = t.length < 0 ? 0 : 0 | d(t.length);
        e = i(e, r);
        for (var n = 0; n < r; n += 1) {
          e[n] = 255 & t[n];
        }
        return e;
      }
      function p(e, t, r, n) {
        if (t.byteLength, r < 0 || t.byteLength < r) throw new RangeError("'offset' is out of bounds");
        if (t.byteLength < r + (n || 0)) throw new RangeError("'length' is out of bounds");
        return t = void 0 === r && void 0 === n ? new Uint8Array(t) : void 0 === n ? new Uint8Array(t, r) : new Uint8Array(t, r, n), o.TYPED_ARRAY_SUPPORT ? (e = t, e.__proto__ = o.prototype) : e = f(e, t), e;
      }
      function h(e, t) {
        if (o.isBuffer(t)) {
          var r = 0 | d(t.length);
          return e = i(e, r), 0 === e.length ? e : (t.copy(e, 0, 0, r), e);
        }
        if (t) {
          if ("undefined" != typeof ArrayBuffer && t.buffer instanceof ArrayBuffer || "length" in t) return "number" != typeof t.length || Q(t.length) ? i(e, 0) : f(e, t);
          if ("Buffer" === t.type && G(t.data)) return f(e, t.data);
        }
        throw new TypeError("First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.");
      }
      function d(e) {
        if (e >= n()) throw new RangeError("Attempt to allocate Buffer larger than maximum size: 0x" + n().toString(16) + " bytes");
        return 0 | e;
      }
      function g(e) {
        return +e != e && (e = 0), o.alloc(+e);
      }
      function y(e, t) {
        if (o.isBuffer(e)) return e.length;
        if ("undefined" != typeof ArrayBuffer && "function" == typeof ArrayBuffer.isView && (ArrayBuffer.isView(e) || e instanceof ArrayBuffer)) return e.byteLength;
        "string" != typeof e && (e = "" + e);
        var r = e.length;
        if (0 === r) return 0;
        for (var n = !1;;) {
          switch (t) {
            case "ascii":
            case "latin1":
            case "binary":
              return r;
            case "utf8":
            case "utf-8":
            case void 0:
              return J(e).length;
            case "ucs2":
            case "ucs-2":
            case "utf16le":
            case "utf-16le":
              return 2 * r;
            case "hex":
              return r >>> 1;
            case "base64":
              return Z(e).length;
            default:
              if (n) return J(e).length;
              t = ("" + t).toLowerCase(), n = !0;
          }
        }
      }
      function v(e, t, r) {
        var n = !1;
        if ((void 0 === t || t < 0) && (t = 0), t > this.length) return "";
        if ((void 0 === r || r > this.length) && (r = this.length), r <= 0) return "";
        if (r >>>= 0, t >>>= 0, r <= t) return "";
        for (e || (e = "utf8");;) {
          switch (e) {
            case "hex":
              return C(this, t, r);
            case "utf8":
            case "utf-8":
              return k(this, t, r);
            case "ascii":
              return T(this, t, r);
            case "latin1":
            case "binary":
              return R(this, t, r);
            case "base64":
              return O(this, t, r);
            case "ucs2":
            case "ucs-2":
            case "utf16le":
            case "utf-16le":
              return D(this, t, r);
            default:
              if (n) throw new TypeError("Unknown encoding: " + e);
              e = (e + "").toLowerCase(), n = !0;
          }
        }
      }
      function m(e, t, r) {
        var n = e[t];
        e[t] = e[r], e[r] = n;
      }
      function b(e, t, r, n, i) {
        if (0 === e.length) return -1;
        if ("string" == typeof r ? (n = r, r = 0) : r > 2147483647 ? r = 2147483647 : r < -2147483648 && (r = -2147483648), r = +r, isNaN(r) && (r = i ? 0 : e.length - 1), r < 0 && (r = e.length + r), r >= e.length) {
          if (i) return -1;
          r = e.length - 1;
        } else if (r < 0) {
          if (!i) return -1;
          r = 0;
        }
        if ("string" == typeof t && (t = o.from(t, n)), o.isBuffer(t)) return 0 === t.length ? -1 : _(e, t, r, n, i);
        if ("number" == typeof t) return t &= 255, o.TYPED_ARRAY_SUPPORT && "function" == typeof Uint8Array.prototype.indexOf ? i ? Uint8Array.prototype.indexOf.call(e, t, r) : Uint8Array.prototype.lastIndexOf.call(e, t, r) : _(e, [t], r, n, i);
        throw new TypeError("val must be string, number or Buffer");
      }
      function _(e, t, r, n, i) {
        function o(e, t) {
          return 1 === a ? e[t] : e.readUInt16BE(t * a);
        }
        var a = 1,
          s = e.length,
          u = t.length;
        if (void 0 !== n && ("ucs2" === (n = String(n).toLowerCase()) || "ucs-2" === n || "utf16le" === n || "utf-16le" === n)) {
          if (e.length < 2 || t.length < 2) return -1;
          a = 2, s /= 2, u /= 2, r /= 2;
        }
        var c;
        if (i) {
          var l = -1;
          for (c = r; c < s; c++) {
            if (o(e, c) === o(t, -1 === l ? 0 : c - l)) {
              if (-1 === l && (l = c), c - l + 1 === u) return l * a;
            } else -1 !== l && (c -= c - l), l = -1;
          }
        } else for (r + u > s && (r = s - u), c = r; c >= 0; c--) {
          for (var f = !0, p = 0; p < u; p++) {
            if (o(e, c + p) !== o(t, p)) {
              f = !1;
              break;
            }
          }
          if (f) return c;
        }
        return -1;
      }
      function w(e, t, r, n) {
        r = Number(r) || 0;
        var i = e.length - r;
        n ? (n = Number(n)) > i && (n = i) : n = i;
        var o = t.length;
        if (o % 2 != 0) throw new TypeError("Invalid hex string");
        n > o / 2 && (n = o / 2);
        for (var a = 0; a < n; ++a) {
          var s = parseInt(t.substr(2 * a, 2), 16);
          if (isNaN(s)) return a;
          e[r + a] = s;
        }
        return a;
      }
      function E(e, t, r, n) {
        return V(J(t, e.length - r), e, r, n);
      }
      function x(e, t, r, n) {
        return V($(t), e, r, n);
      }
      function A(e, t, r, n) {
        return x(e, t, r, n);
      }
      function j(e, t, r, n) {
        return V(Z(t), e, r, n);
      }
      function S(e, t, r, n) {
        return V(Y(t, e.length - r), e, r, n);
      }
      function O(e, t, r) {
        return 0 === t && r === e.length ? H.fromByteArray(e) : H.fromByteArray(e.slice(t, r));
      }
      function k(e, t, r) {
        r = Math.min(e.length, r);
        for (var n = [], i = t; i < r;) {
          var o = e[i],
            a = null,
            s = o > 239 ? 4 : o > 223 ? 3 : o > 191 ? 2 : 1;
          if (i + s <= r) {
            var u, c, l, f;
            switch (s) {
              case 1:
                o < 128 && (a = o);
                break;
              case 2:
                u = e[i + 1], 128 == (192 & u) && (f = (31 & o) << 6 | 63 & u) > 127 && (a = f);
                break;
              case 3:
                u = e[i + 1], c = e[i + 2], 128 == (192 & u) && 128 == (192 & c) && (f = (15 & o) << 12 | (63 & u) << 6 | 63 & c) > 2047 && (f < 55296 || f > 57343) && (a = f);
                break;
              case 4:
                u = e[i + 1], c = e[i + 2], l = e[i + 3], 128 == (192 & u) && 128 == (192 & c) && 128 == (192 & l) && (f = (15 & o) << 18 | (63 & u) << 12 | (63 & c) << 6 | 63 & l) > 65535 && f < 1114112 && (a = f);
            }
          }
          null === a ? (a = 65533, s = 1) : a > 65535 && (a -= 65536, n.push(a >>> 10 & 1023 | 55296), a = 56320 | 1023 & a), n.push(a), i += s;
        }
        return P(n);
      }
      function P(e) {
        var t = e.length;
        if (t <= K) return String.fromCharCode.apply(String, e);
        for (var r = "", n = 0; n < t;) {
          r += String.fromCharCode.apply(String, e.slice(n, n += K));
        }
        return r;
      }
      function T(e, t, r) {
        var n = "";
        r = Math.min(e.length, r);
        for (var i = t; i < r; ++i) {
          n += String.fromCharCode(127 & e[i]);
        }
        return n;
      }
      function R(e, t, r) {
        var n = "";
        r = Math.min(e.length, r);
        for (var i = t; i < r; ++i) {
          n += String.fromCharCode(e[i]);
        }
        return n;
      }
      function C(e, t, r) {
        var n = e.length;
        (!t || t < 0) && (t = 0), (!r || r < 0 || r > n) && (r = n);
        for (var i = "", o = t; o < r; ++o) {
          i += M(e[o]);
        }
        return i;
      }
      function D(e, t, r) {
        for (var n = e.slice(t, r), i = "", o = 0; o < n.length; o += 2) {
          i += String.fromCharCode(n[o] + 256 * n[o + 1]);
        }
        return i;
      }
      function I(e, t, r) {
        if (e % 1 != 0 || e < 0) throw new RangeError("offset is not uint");
        if (e + t > r) throw new RangeError("Trying to access beyond buffer length");
      }
      function L(e, t, r, n, i, a) {
        if (!o.isBuffer(e)) throw new TypeError('"buffer" argument must be a Buffer instance');
        if (t > i || t < a) throw new RangeError('"value" argument is out of bounds');
        if (r + n > e.length) throw new RangeError("Index out of range");
      }
      function N(e, t, r, n) {
        t < 0 && (t = 65535 + t + 1);
        for (var i = 0, o = Math.min(e.length - r, 2); i < o; ++i) {
          e[r + i] = (t & 255 << 8 * (n ? i : 1 - i)) >>> 8 * (n ? i : 1 - i);
        }
      }
      function B(e, t, r, n) {
        t < 0 && (t = 4294967295 + t + 1);
        for (var i = 0, o = Math.min(e.length - r, 4); i < o; ++i) {
          e[r + i] = t >>> 8 * (n ? i : 3 - i) & 255;
        }
      }
      function F(e, t, r, n, i, o) {
        if (r + n > e.length) throw new RangeError("Index out of range");
        if (r < 0) throw new RangeError("Index out of range");
      }
      function U(e, t, r, n, i) {
        return i || F(e, t, r, 4, 3.4028234663852886e38, -3.4028234663852886e38), X.write(e, t, r, n, 23, 4), r + 4;
      }
      function z(e, t, r, n, i) {
        return i || F(e, t, r, 8, 1.7976931348623157e308, -1.7976931348623157e308), X.write(e, t, r, n, 52, 8), r + 8;
      }
      function q(e) {
        if (e = W(e).replace(ee, ""), e.length < 2) return "";
        for (; e.length % 4 != 0;) {
          e += "=";
        }
        return e;
      }
      function W(e) {
        return e.trim ? e.trim() : e.replace(/^\s+|\s+$/g, "");
      }
      function M(e) {
        return e < 16 ? "0" + e.toString(16) : e.toString(16);
      }
      function J(e, t) {
        t = t || 1 / 0;
        for (var r, n = e.length, i = null, o = [], a = 0; a < n; ++a) {
          if ((r = e.charCodeAt(a)) > 55295 && r < 57344) {
            if (!i) {
              if (r > 56319) {
                (t -= 3) > -1 && o.push(239, 191, 189);
                continue;
              }
              if (a + 1 === n) {
                (t -= 3) > -1 && o.push(239, 191, 189);
                continue;
              }
              i = r;
              continue;
            }
            if (r < 56320) {
              (t -= 3) > -1 && o.push(239, 191, 189), i = r;
              continue;
            }
            r = 65536 + (i - 55296 << 10 | r - 56320);
          } else i && (t -= 3) > -1 && o.push(239, 191, 189);
          if (i = null, r < 128) {
            if ((t -= 1) < 0) break;
            o.push(r);
          } else if (r < 2048) {
            if ((t -= 2) < 0) break;
            o.push(r >> 6 | 192, 63 & r | 128);
          } else if (r < 65536) {
            if ((t -= 3) < 0) break;
            o.push(r >> 12 | 224, r >> 6 & 63 | 128, 63 & r | 128);
          } else {
            if (!(r < 1114112)) throw new Error("Invalid code point");
            if ((t -= 4) < 0) break;
            o.push(r >> 18 | 240, r >> 12 & 63 | 128, r >> 6 & 63 | 128, 63 & r | 128);
          }
        }
        return o;
      }
      function $(e) {
        for (var t = [], r = 0; r < e.length; ++r) {
          t.push(255 & e.charCodeAt(r));
        }
        return t;
      }
      function Y(e, t) {
        for (var r, n, i, o = [], a = 0; a < e.length && !((t -= 2) < 0); ++a) {
          r = e.charCodeAt(a), n = r >> 8, i = r % 256, o.push(i), o.push(n);
        }
        return o;
      }
      function Z(e) {
        return H.toByteArray(q(e));
      }
      function V(e, t, r, n) {
        for (var i = 0; i < n && !(i + r >= t.length || i >= e.length); ++i) {
          t[i + r] = e[i];
        }
        return i;
      }
      function Q(e) {
        return e !== e;
      }
      var H = r(33),
        X = r(34),
        G = r(35);
      t.Buffer = o, t.SlowBuffer = g, t.INSPECT_MAX_BYTES = 50, o.TYPED_ARRAY_SUPPORT = void 0 !== e.TYPED_ARRAY_SUPPORT ? e.TYPED_ARRAY_SUPPORT : function () {
        try {
          var e = new Uint8Array(1);
          return e.__proto__ = {
            __proto__: Uint8Array.prototype,
            foo: function foo() {
              return 42;
            }
          }, 42 === e.foo() && "function" == typeof e.subarray && 0 === e.subarray(1, 1).byteLength;
        } catch (e) {
          return !1;
        }
      }(), t.kMaxLength = n(), o.poolSize = 8192, o._augment = function (e) {
        return e.__proto__ = o.prototype, e;
      }, o.from = function (e, t, r) {
        return a(null, e, t, r);
      }, o.TYPED_ARRAY_SUPPORT && (o.prototype.__proto__ = Uint8Array.prototype, o.__proto__ = Uint8Array, "undefined" != typeof Symbol && Symbol.species && o[Symbol.species] === o && Object.defineProperty(o, Symbol.species, {
        value: null,
        configurable: !0
      })), o.alloc = function (e, t, r) {
        return u(null, e, t, r);
      }, o.allocUnsafe = function (e) {
        return c(null, e);
      }, o.allocUnsafeSlow = function (e) {
        return c(null, e);
      }, o.isBuffer = function (e) {
        return !(null == e || !e._isBuffer);
      }, o.compare = function (e, t) {
        if (!o.isBuffer(e) || !o.isBuffer(t)) throw new TypeError("Arguments must be Buffers");
        if (e === t) return 0;
        for (var r = e.length, n = t.length, i = 0, a = Math.min(r, n); i < a; ++i) {
          if (e[i] !== t[i]) {
            r = e[i], n = t[i];
            break;
          }
        }
        return r < n ? -1 : n < r ? 1 : 0;
      }, o.isEncoding = function (e) {
        switch (String(e).toLowerCase()) {
          case "hex":
          case "utf8":
          case "utf-8":
          case "ascii":
          case "latin1":
          case "binary":
          case "base64":
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return !0;
          default:
            return !1;
        }
      }, o.concat = function (e, t) {
        if (!G(e)) throw new TypeError('"list" argument must be an Array of Buffers');
        if (0 === e.length) return o.alloc(0);
        var r;
        if (void 0 === t) for (t = 0, r = 0; r < e.length; ++r) {
          t += e[r].length;
        }
        var n = o.allocUnsafe(t),
          i = 0;
        for (r = 0; r < e.length; ++r) {
          var a = e[r];
          if (!o.isBuffer(a)) throw new TypeError('"list" argument must be an Array of Buffers');
          a.copy(n, i), i += a.length;
        }
        return n;
      }, o.byteLength = y, o.prototype._isBuffer = !0, o.prototype.swap16 = function () {
        var e = this.length;
        if (e % 2 != 0) throw new RangeError("Buffer size must be a multiple of 16-bits");
        for (var t = 0; t < e; t += 2) {
          m(this, t, t + 1);
        }
        return this;
      }, o.prototype.swap32 = function () {
        var e = this.length;
        if (e % 4 != 0) throw new RangeError("Buffer size must be a multiple of 32-bits");
        for (var t = 0; t < e; t += 4) {
          m(this, t, t + 3), m(this, t + 1, t + 2);
        }
        return this;
      }, o.prototype.swap64 = function () {
        var e = this.length;
        if (e % 8 != 0) throw new RangeError("Buffer size must be a multiple of 64-bits");
        for (var t = 0; t < e; t += 8) {
          m(this, t, t + 7), m(this, t + 1, t + 6), m(this, t + 2, t + 5), m(this, t + 3, t + 4);
        }
        return this;
      }, o.prototype.toString = function () {
        var e = 0 | this.length;
        return 0 === e ? "" : 0 === arguments.length ? k(this, 0, e) : v.apply(this, arguments);
      }, o.prototype.equals = function (e) {
        if (!o.isBuffer(e)) throw new TypeError("Argument must be a Buffer");
        return this === e || 0 === o.compare(this, e);
      }, o.prototype.inspect = function () {
        var e = "",
          r = t.INSPECT_MAX_BYTES;
        return this.length > 0 && (e = this.toString("hex", 0, r).match(/.{2}/g).join(" "), this.length > r && (e += " ... ")), "<Buffer " + e + ">";
      }, o.prototype.compare = function (e, t, r, n, i) {
        if (!o.isBuffer(e)) throw new TypeError("Argument must be a Buffer");
        if (void 0 === t && (t = 0), void 0 === r && (r = e ? e.length : 0), void 0 === n && (n = 0), void 0 === i && (i = this.length), t < 0 || r > e.length || n < 0 || i > this.length) throw new RangeError("out of range index");
        if (n >= i && t >= r) return 0;
        if (n >= i) return -1;
        if (t >= r) return 1;
        if (t >>>= 0, r >>>= 0, n >>>= 0, i >>>= 0, this === e) return 0;
        for (var a = i - n, s = r - t, u = Math.min(a, s), c = this.slice(n, i), l = e.slice(t, r), f = 0; f < u; ++f) {
          if (c[f] !== l[f]) {
            a = c[f], s = l[f];
            break;
          }
        }
        return a < s ? -1 : s < a ? 1 : 0;
      }, o.prototype.includes = function (e, t, r) {
        return -1 !== this.indexOf(e, t, r);
      }, o.prototype.indexOf = function (e, t, r) {
        return b(this, e, t, r, !0);
      }, o.prototype.lastIndexOf = function (e, t, r) {
        return b(this, e, t, r, !1);
      }, o.prototype.write = function (e, t, r, n) {
        if (void 0 === t) n = "utf8", r = this.length, t = 0;else if (void 0 === r && "string" == typeof t) n = t, r = this.length, t = 0;else {
          if (!isFinite(t)) throw new Error("Buffer.write(string, encoding, offset[, length]) is no longer supported");
          t |= 0, isFinite(r) ? (r |= 0, void 0 === n && (n = "utf8")) : (n = r, r = void 0);
        }
        var i = this.length - t;
        if ((void 0 === r || r > i) && (r = i), e.length > 0 && (r < 0 || t < 0) || t > this.length) throw new RangeError("Attempt to write outside buffer bounds");
        n || (n = "utf8");
        for (var o = !1;;) {
          switch (n) {
            case "hex":
              return w(this, e, t, r);
            case "utf8":
            case "utf-8":
              return E(this, e, t, r);
            case "ascii":
              return x(this, e, t, r);
            case "latin1":
            case "binary":
              return A(this, e, t, r);
            case "base64":
              return j(this, e, t, r);
            case "ucs2":
            case "ucs-2":
            case "utf16le":
            case "utf-16le":
              return S(this, e, t, r);
            default:
              if (o) throw new TypeError("Unknown encoding: " + n);
              n = ("" + n).toLowerCase(), o = !0;
          }
        }
      }, o.prototype.toJSON = function () {
        return {
          type: "Buffer",
          data: Array.prototype.slice.call(this._arr || this, 0)
        };
      };
      var K = 4096;
      o.prototype.slice = function (e, t) {
        var r = this.length;
        e = ~~e, t = void 0 === t ? r : ~~t, e < 0 ? (e += r) < 0 && (e = 0) : e > r && (e = r), t < 0 ? (t += r) < 0 && (t = 0) : t > r && (t = r), t < e && (t = e);
        var n;
        if (o.TYPED_ARRAY_SUPPORT) n = this.subarray(e, t), n.__proto__ = o.prototype;else {
          var i = t - e;
          n = new o(i, void 0);
          for (var a = 0; a < i; ++a) {
            n[a] = this[a + e];
          }
        }
        return n;
      }, o.prototype.readUIntLE = function (e, t, r) {
        e |= 0, t |= 0, r || I(e, t, this.length);
        for (var n = this[e], i = 1, o = 0; ++o < t && (i *= 256);) {
          n += this[e + o] * i;
        }
        return n;
      }, o.prototype.readUIntBE = function (e, t, r) {
        e |= 0, t |= 0, r || I(e, t, this.length);
        for (var n = this[e + --t], i = 1; t > 0 && (i *= 256);) {
          n += this[e + --t] * i;
        }
        return n;
      }, o.prototype.readUInt8 = function (e, t) {
        return t || I(e, 1, this.length), this[e];
      }, o.prototype.readUInt16LE = function (e, t) {
        return t || I(e, 2, this.length), this[e] | this[e + 1] << 8;
      }, o.prototype.readUInt16BE = function (e, t) {
        return t || I(e, 2, this.length), this[e] << 8 | this[e + 1];
      }, o.prototype.readUInt32LE = function (e, t) {
        return t || I(e, 4, this.length), (this[e] | this[e + 1] << 8 | this[e + 2] << 16) + 16777216 * this[e + 3];
      }, o.prototype.readUInt32BE = function (e, t) {
        return t || I(e, 4, this.length), 16777216 * this[e] + (this[e + 1] << 16 | this[e + 2] << 8 | this[e + 3]);
      }, o.prototype.readIntLE = function (e, t, r) {
        e |= 0, t |= 0, r || I(e, t, this.length);
        for (var n = this[e], i = 1, o = 0; ++o < t && (i *= 256);) {
          n += this[e + o] * i;
        }
        return i *= 128, n >= i && (n -= Math.pow(2, 8 * t)), n;
      }, o.prototype.readIntBE = function (e, t, r) {
        e |= 0, t |= 0, r || I(e, t, this.length);
        for (var n = t, i = 1, o = this[e + --n]; n > 0 && (i *= 256);) {
          o += this[e + --n] * i;
        }
        return i *= 128, o >= i && (o -= Math.pow(2, 8 * t)), o;
      }, o.prototype.readInt8 = function (e, t) {
        return t || I(e, 1, this.length), 128 & this[e] ? -1 * (255 - this[e] + 1) : this[e];
      }, o.prototype.readInt16LE = function (e, t) {
        t || I(e, 2, this.length);
        var r = this[e] | this[e + 1] << 8;
        return 32768 & r ? 4294901760 | r : r;
      }, o.prototype.readInt16BE = function (e, t) {
        t || I(e, 2, this.length);
        var r = this[e + 1] | this[e] << 8;
        return 32768 & r ? 4294901760 | r : r;
      }, o.prototype.readInt32LE = function (e, t) {
        return t || I(e, 4, this.length), this[e] | this[e + 1] << 8 | this[e + 2] << 16 | this[e + 3] << 24;
      }, o.prototype.readInt32BE = function (e, t) {
        return t || I(e, 4, this.length), this[e] << 24 | this[e + 1] << 16 | this[e + 2] << 8 | this[e + 3];
      }, o.prototype.readFloatLE = function (e, t) {
        return t || I(e, 4, this.length), X.read(this, e, !0, 23, 4);
      }, o.prototype.readFloatBE = function (e, t) {
        return t || I(e, 4, this.length), X.read(this, e, !1, 23, 4);
      }, o.prototype.readDoubleLE = function (e, t) {
        return t || I(e, 8, this.length), X.read(this, e, !0, 52, 8);
      }, o.prototype.readDoubleBE = function (e, t) {
        return t || I(e, 8, this.length), X.read(this, e, !1, 52, 8);
      }, o.prototype.writeUIntLE = function (e, t, r, n) {
        if (e = +e, t |= 0, r |= 0, !n) {
          L(this, e, t, r, Math.pow(2, 8 * r) - 1, 0);
        }
        var i = 1,
          o = 0;
        for (this[t] = 255 & e; ++o < r && (i *= 256);) {
          this[t + o] = e / i & 255;
        }
        return t + r;
      }, o.prototype.writeUIntBE = function (e, t, r, n) {
        if (e = +e, t |= 0, r |= 0, !n) {
          L(this, e, t, r, Math.pow(2, 8 * r) - 1, 0);
        }
        var i = r - 1,
          o = 1;
        for (this[t + i] = 255 & e; --i >= 0 && (o *= 256);) {
          this[t + i] = e / o & 255;
        }
        return t + r;
      }, o.prototype.writeUInt8 = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 1, 255, 0), o.TYPED_ARRAY_SUPPORT || (e = Math.floor(e)), this[t] = 255 & e, t + 1;
      }, o.prototype.writeUInt16LE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 2, 65535, 0), o.TYPED_ARRAY_SUPPORT ? (this[t] = 255 & e, this[t + 1] = e >>> 8) : N(this, e, t, !0), t + 2;
      }, o.prototype.writeUInt16BE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 2, 65535, 0), o.TYPED_ARRAY_SUPPORT ? (this[t] = e >>> 8, this[t + 1] = 255 & e) : N(this, e, t, !1), t + 2;
      }, o.prototype.writeUInt32LE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 4, 4294967295, 0), o.TYPED_ARRAY_SUPPORT ? (this[t + 3] = e >>> 24, this[t + 2] = e >>> 16, this[t + 1] = e >>> 8, this[t] = 255 & e) : B(this, e, t, !0), t + 4;
      }, o.prototype.writeUInt32BE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 4, 4294967295, 0), o.TYPED_ARRAY_SUPPORT ? (this[t] = e >>> 24, this[t + 1] = e >>> 16, this[t + 2] = e >>> 8, this[t + 3] = 255 & e) : B(this, e, t, !1), t + 4;
      }, o.prototype.writeIntLE = function (e, t, r, n) {
        if (e = +e, t |= 0, !n) {
          var i = Math.pow(2, 8 * r - 1);
          L(this, e, t, r, i - 1, -i);
        }
        var o = 0,
          a = 1,
          s = 0;
        for (this[t] = 255 & e; ++o < r && (a *= 256);) {
          e < 0 && 0 === s && 0 !== this[t + o - 1] && (s = 1), this[t + o] = (e / a >> 0) - s & 255;
        }
        return t + r;
      }, o.prototype.writeIntBE = function (e, t, r, n) {
        if (e = +e, t |= 0, !n) {
          var i = Math.pow(2, 8 * r - 1);
          L(this, e, t, r, i - 1, -i);
        }
        var o = r - 1,
          a = 1,
          s = 0;
        for (this[t + o] = 255 & e; --o >= 0 && (a *= 256);) {
          e < 0 && 0 === s && 0 !== this[t + o + 1] && (s = 1), this[t + o] = (e / a >> 0) - s & 255;
        }
        return t + r;
      }, o.prototype.writeInt8 = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 1, 127, -128), o.TYPED_ARRAY_SUPPORT || (e = Math.floor(e)), e < 0 && (e = 255 + e + 1), this[t] = 255 & e, t + 1;
      }, o.prototype.writeInt16LE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 2, 32767, -32768), o.TYPED_ARRAY_SUPPORT ? (this[t] = 255 & e, this[t + 1] = e >>> 8) : N(this, e, t, !0), t + 2;
      }, o.prototype.writeInt16BE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 2, 32767, -32768), o.TYPED_ARRAY_SUPPORT ? (this[t] = e >>> 8, this[t + 1] = 255 & e) : N(this, e, t, !1), t + 2;
      }, o.prototype.writeInt32LE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 4, 2147483647, -2147483648), o.TYPED_ARRAY_SUPPORT ? (this[t] = 255 & e, this[t + 1] = e >>> 8, this[t + 2] = e >>> 16, this[t + 3] = e >>> 24) : B(this, e, t, !0), t + 4;
      }, o.prototype.writeInt32BE = function (e, t, r) {
        return e = +e, t |= 0, r || L(this, e, t, 4, 2147483647, -2147483648), e < 0 && (e = 4294967295 + e + 1), o.TYPED_ARRAY_SUPPORT ? (this[t] = e >>> 24, this[t + 1] = e >>> 16, this[t + 2] = e >>> 8, this[t + 3] = 255 & e) : B(this, e, t, !1), t + 4;
      }, o.prototype.writeFloatLE = function (e, t, r) {
        return U(this, e, t, !0, r);
      }, o.prototype.writeFloatBE = function (e, t, r) {
        return U(this, e, t, !1, r);
      }, o.prototype.writeDoubleLE = function (e, t, r) {
        return z(this, e, t, !0, r);
      }, o.prototype.writeDoubleBE = function (e, t, r) {
        return z(this, e, t, !1, r);
      }, o.prototype.copy = function (e, t, r, n) {
        if (r || (r = 0), n || 0 === n || (n = this.length), t >= e.length && (t = e.length), t || (t = 0), n > 0 && n < r && (n = r), n === r) return 0;
        if (0 === e.length || 0 === this.length) return 0;
        if (t < 0) throw new RangeError("targetStart out of bounds");
        if (r < 0 || r >= this.length) throw new RangeError("sourceStart out of bounds");
        if (n < 0) throw new RangeError("sourceEnd out of bounds");
        n > this.length && (n = this.length), e.length - t < n - r && (n = e.length - t + r);
        var i,
          a = n - r;
        if (this === e && r < t && t < n) for (i = a - 1; i >= 0; --i) {
          e[i + t] = this[i + r];
        } else if (a < 1e3 || !o.TYPED_ARRAY_SUPPORT) for (i = 0; i < a; ++i) {
          e[i + t] = this[i + r];
        } else Uint8Array.prototype.set.call(e, this.subarray(r, r + a), t);
        return a;
      }, o.prototype.fill = function (e, t, r, n) {
        if ("string" == typeof e) {
          if ("string" == typeof t ? (n = t, t = 0, r = this.length) : "string" == typeof r && (n = r, r = this.length), 1 === e.length) {
            var i = e.charCodeAt(0);
            i < 256 && (e = i);
          }
          if (void 0 !== n && "string" != typeof n) throw new TypeError("encoding must be a string");
          if ("string" == typeof n && !o.isEncoding(n)) throw new TypeError("Unknown encoding: " + n);
        } else "number" == typeof e && (e &= 255);
        if (t < 0 || this.length < t || this.length < r) throw new RangeError("Out of range index");
        if (r <= t) return this;
        t >>>= 0, r = void 0 === r ? this.length : r >>> 0, e || (e = 0);
        var a;
        if ("number" == typeof e) for (a = t; a < r; ++a) {
          this[a] = e;
        } else {
          var s = o.isBuffer(e) ? e : J(new o(e, n).toString()),
            u = s.length;
          for (a = 0; a < r - t; ++a) {
            this[a + t] = s[a % u];
          }
        }
        return this;
      };
      var ee = /[^+\/0-9A-Za-z-_]/g;
    }).call(t, r(5));
  }, function (e, t, r) {
    "use strict";

    var n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      i = r(0),
      o = r(1);
    t.schema = function (e, t) {
      return void 0 !== t && null !== t && "object" === (void 0 === t ? "undefined" : n(t)) ? t.isJoi ? t : Array.isArray(t) ? e.alternatives().try(t) : t instanceof RegExp ? e.string().regex(t) : t instanceof Date ? e.date().valid(t) : e.object().keys(t) : "string" == typeof t ? e.string().valid(t) : "number" == typeof t ? e.number().valid(t) : "boolean" == typeof t ? e.boolean().valid(t) : o.isRef(t) ? e.valid(t) : (i.assert(null === t, "Invalid schema content:", t), e.valid(null));
    }, t.ref = function (e) {
      return o.isRef(e) ? e : o.create(e);
    };
  }, function (e, t) {
    var r;
    r = function () {
      return this;
    }();
    try {
      r = r || Function("return this")() || (0, eval)("this");
    } catch (e) {
      "object" == typeof window && (r = window);
    }
    e.exports = r;
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    var i = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      o = r(0),
      a = r(20),
      s = {
        annotations: Symbol("joi-annotations")
      };
    s.stringify = function (e, r) {
      var n = void 0 === e ? "undefined" : i(e);
      if (null === e) return "null";
      if ("string" === n) return e;
      if (e instanceof t.Err || "function" === n || "symbol" === n) return e.toString();
      if ("object" === n) {
        if (Array.isArray(e)) {
          for (var o = "", a = 0; a < e.length; ++a) {
            o = o + (o.length ? ", " : "") + s.stringify(e[a], r);
          }
          return r ? "[" + o + "]" : o;
        }
        return e.toString();
      }
      return JSON.stringify(e);
    }, t.Err = function () {
      function e(t, r, i, o, s, u, c) {
        n(this, e), this.isJoi = !0, this.type = t, this.context = r || {}, this.context.key = i.path[i.path.length - 1], this.context.label = i.key, this.path = i.path, this.options = o, this.flags = s, this.message = u, this.template = c;
        var l = this.options.language;
        this.flags.label ? this.context.label = this.flags.label : !l || "" !== this.context.label && null !== this.context.label || (this.context.label = l.root || a.errors.root);
      }
      return e.prototype.toString = function () {
        var e = this;
        if (this.message) return this.message;
        var t = void 0;
        this.template && (t = this.template);
        var r = this.options.language;
        if (void 0 === (t = t || o.reach(r, this.type) || o.reach(a.errors, this.type))) return 'Error code "' + this.type + '" is not defined, your custom type is missing the correct language definition';
        var n = o.reach(r, "messages.wrapArrays");
        if ("boolean" != typeof n && (n = a.errors.messages.wrapArrays), null === t) {
          var i = s.stringify(this.context.reason, n);
          return n ? i.slice(1, -1) : i;
        }
        var u = /\{\{\!?label\}\}/.test(t),
          c = t.length > 2 && "!" === t[0] && "!" === t[1];
        if (c && (t = t.slice(2)), !u && !c) {
          var l = o.reach(r, "key");
          t = "string" == typeof l ? l + t : o.reach(a.errors, "key") + t;
        }
        return t.replace(/\{\{(\!?)([^}]+)\}\}/g, function (t, r, i) {
          var a = o.reach(e.context, i),
            u = s.stringify(a, n);
          return r && e.options.escapeHtml ? o.escapeHtml(u) : u;
        });
      }, e;
    }(), t.create = function (e, r, n, i, o, a, s) {
      return new t.Err(e, r, n, i, o, a, s);
    }, t.process = function (e, t) {
      if (!e || !e.length) return null;
      var r = "",
        n = [],
        i = function e(t, i) {
          for (var o = 0; o < t.length; ++o) {
            var a = t[o];
            if (a instanceof Error) return a;
            if (a.flags.error && "function" != typeof a.flags.error) return a.flags.error;
            var s = void 0;
            if (void 0 === i && (s = a.toString(), r = r + (r ? ". " : "") + s), a.context.reason && a.context.reason.length) {
              var u = e(a.context.reason, a.path);
              if (u) return u;
            } else n.push({
              message: s || a.toString(),
              path: a.path,
              type: a.type,
              context: a.context
            });
          }
        }(e);
      if (i) return i;
      var o = new Error(r);
      return o.isJoi = !0, o.name = "ValidationError", o.details = n, o._object = t, o.annotate = s.annotate, o;
    }, s.safeStringify = function (e, t) {
      return JSON.stringify(e, s.serializer(), t);
    }, s.serializer = function () {
      var e = [],
        t = [],
        r = function r(_r, n) {
          return t[0] === n ? "[Circular ~]" : "[Circular ~." + e.slice(0, t.indexOf(n)).join(".") + "]";
        };
      return function (n, o) {
        if (t.length > 0) {
          var a = t.indexOf(this);
          ~a ? (t.length = a + 1, e.length = a + 1, e[a] = n) : (t.push(this), e.push(n)), ~t.indexOf(o) && (o = r.call(this, n, o));
        } else t.push(o);
        if (o) {
          var u = o[s.annotations];
          if (u) {
            if (Array.isArray(o)) {
              for (var c = [], l = 0; l < o.length; ++l) {
                u.errors[l] && c.push("_$idx$_" + u.errors[l].sort().join(", ") + "_$end$_"), c.push(o[l]);
              }
              o = c;
            } else {
              for (var f = Object.keys(u.errors), p = 0; p < f.length; ++p) {
                var h = f[p];
                o[h + "_$key$_" + u.errors[h].sort().join(", ") + "_$end$_"] = o[h], o[h] = void 0;
              }
              for (var d = Object.keys(u.missing), g = 0; g < d.length; ++g) {
                var y = d[g];
                o["_$miss$_" + y + "|" + u.missing[y] + "_$end$_"] = "__missing__";
              }
            }
            return o;
          }
        }
        return o === 1 / 0 || o === -1 / 0 || Number.isNaN(o) || "function" == typeof o || "symbol" === (void 0 === o ? "undefined" : i(o)) ? "[" + o.toString() + "]" : o;
      };
    }, s.annotate = function (e) {
      var t = e ? "" : "[31m",
        r = e ? "" : "[41m",
        n = e ? "" : "[0m";
      if ("object" !== i(this._object)) return this.details[0].message;
      for (var a = o.clone(this._object || {}), u = this.details.length - 1; u >= 0; --u) {
        for (var c = u + 1, l = this.details[u], f = l.path, p = a, h = 0;; ++h) {
          var d = f[h];
          if (p.isImmutable && (p = p.clone()), !(h + 1 < f.length && p[d] && "string" != typeof p[d])) {
            var g = p[s.annotations] = p[s.annotations] || {
                errors: {},
                missing: {}
              },
              y = p[d],
              v = d || l.context.label;
            void 0 !== y ? (g.errors[v] = g.errors[v] || [], g.errors[v].push(c)) : g.missing[v] = c;
            break;
          }
          p = p[d];
        }
      }
      var m = {
          key: /_\$key\$_([, \d]+)_\$end\$_\"/g,
          missing: /\"_\$miss\$_([^\|]+)\|(\d+)_\$end\$_\"\: \"__missing__\"/g,
          arrayIndex: /\s*\"_\$idx\$_([, \d]+)_\$end\$_\",?\n(.*)/g,
          specials: /"\[(NaN|Symbol.*|-?Infinity|function.*|\(.*)\]"/g
        },
        b = s.safeStringify(a, 2).replace(m.key, function (e, r) {
          return '" ' + t + "[" + r + "]" + n;
        }).replace(m.missing, function (e, i, o) {
          return r + '"' + i + '"' + n + t + " [" + o + "]: -- missing --" + n;
        }).replace(m.arrayIndex, function (e, r, i) {
          return "\n" + i + " " + t + "[" + r + "]" + n;
        }).replace(m.specials, function (e, t) {
          return t;
        });
      b = b + "\n" + t;
      for (var _ = 0; _ < this.details.length; ++_) {
        b = b + "\n[" + (_ + 1) + "] " + this.details[_].message;
      }
      return b += n;
    };
  }, function (e, t) {
    function r() {
      throw new Error("setTimeout has not been defined");
    }
    function n() {
      throw new Error("clearTimeout has not been defined");
    }
    function i(e) {
      if (l === setTimeout) return setTimeout(e, 0);
      if ((l === r || !l) && setTimeout) return l = setTimeout, setTimeout(e, 0);
      try {
        return l(e, 0);
      } catch (t) {
        try {
          return l.call(null, e, 0);
        } catch (t) {
          return l.call(this, e, 0);
        }
      }
    }
    function o(e) {
      if (f === clearTimeout) return clearTimeout(e);
      if ((f === n || !f) && clearTimeout) return f = clearTimeout, clearTimeout(e);
      try {
        return f(e);
      } catch (t) {
        try {
          return f.call(null, e);
        } catch (t) {
          return f.call(this, e);
        }
      }
    }
    function a() {
      g && h && (g = !1, h.length ? d = h.concat(d) : y = -1, d.length && s());
    }
    function s() {
      if (!g) {
        var e = i(a);
        g = !0;
        for (var t = d.length; t;) {
          for (h = d, d = []; ++y < t;) {
            h && h[y].run();
          }
          y = -1, t = d.length;
        }
        h = null, g = !1, o(e);
      }
    }
    function u(e, t) {
      this.fun = e, this.array = t;
    }
    function c() {}
    var l,
      f,
      p = e.exports = {};
    !function () {
      try {
        l = "function" == typeof setTimeout ? setTimeout : r;
      } catch (e) {
        l = r;
      }
      try {
        f = "function" == typeof clearTimeout ? clearTimeout : n;
      } catch (e) {
        f = n;
      }
    }();
    var h,
      d = [],
      g = !1,
      y = -1;
    p.nextTick = function (e) {
      var t = new Array(arguments.length - 1);
      if (arguments.length > 1) for (var r = 1; r < arguments.length; r++) {
        t[r - 1] = arguments[r];
      }
      d.push(new u(e, t)), 1 !== d.length || g || i(s);
    }, u.prototype.run = function () {
      this.fun.apply(null, this.array);
    }, p.title = "browser", p.browser = !0, p.env = {}, p.argv = [], p.version = "", p.versions = {}, p.on = c, p.addListener = c, p.once = c, p.off = c, p.removeListener = c, p.removeAllListeners = c, p.emit = c, p.prependListener = c, p.prependOnceListener = c, p.listeners = function (e) {
      return [];
    }, p.binding = function (e) {
      throw new Error("process.binding is not supported");
    }, p.cwd = function () {
      return "/";
    }, p.chdir = function (e) {
      throw new Error("process.chdir is not supported");
    }, p.umask = function () {
      return 0;
    };
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t, r) {
      return t in e ? Object.defineProperty(e, t, {
        value: r,
        enumerable: !0,
        configurable: !0,
        writable: !0
      }) : e[t] = r, e;
    }
    function o(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function a(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function s(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    function u(e) {
      if (Array.isArray(e)) {
        for (var t = 0, r = Array(e.length); t < e.length; t++) {
          r[t] = e[t];
        }
        return r;
      }
      return Array.from(e);
    }
    var c = Object.assign || function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var r = arguments[t];
          for (var n in r) {
            Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
          }
        }
        return e;
      },
      l = r(0),
      f = r(2),
      p = r(4),
      h = r(6),
      d = r(26),
      g = r(1),
      y = r(11),
      v = {
        alternatives: r(10),
        array: r(22),
        boolean: r(24),
        binary: r(23),
        date: r(12),
        func: r(25),
        number: r(27),
        object: r(13),
        string: r(28)
      };
    v.callWithDefaults = function (e, t) {
      var r;
      return l.assert(this, "Must be invoked on a Joi instance."), this._defaults && (e = this._defaults(e)), e._currentJoi = this, (r = e)._init.apply(r, u(t));
    }, v.root = function () {
      var e = new f(),
        t = e.clone();
      return f.prototype._currentJoi = t, t._currentJoi = t, t.any = function () {
        for (var t = arguments.length, r = Array(t), n = 0; n < t; n++) {
          r[n] = arguments[n];
        }
        return l.assert(0 === r.length, "Joi.any() does not allow arguments."), v.callWithDefaults.call(this, e, r);
      }, t.alternatives = t.alt = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return v.callWithDefaults.call(this, v.alternatives, t);
      }, t.array = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.array() does not allow arguments."), v.callWithDefaults.call(this, v.array, t);
      }, t.boolean = t.bool = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.boolean() does not allow arguments."), v.callWithDefaults.call(this, v.boolean, t);
      }, t.binary = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.binary() does not allow arguments."), v.callWithDefaults.call(this, v.binary, t);
      }, t.date = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.date() does not allow arguments."), v.callWithDefaults.call(this, v.date, t);
      }, t.func = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.func() does not allow arguments."), v.callWithDefaults.call(this, v.func, t);
      }, t.number = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.number() does not allow arguments."), v.callWithDefaults.call(this, v.number, t);
      }, t.object = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return v.callWithDefaults.call(this, v.object, t);
      }, t.string = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return l.assert(0 === t.length, "Joi.string() does not allow arguments."), v.callWithDefaults.call(this, v.string, t);
      }, t.ref = function () {
        return g.create.apply(g, arguments);
      }, t.isRef = function (e) {
        return g.isRef(e);
      }, t.validate = function (r) {
        var n,
          i = (n = (arguments.length <= 1 ? 0 : arguments.length - 1) - 1 + 1, arguments.length <= n ? void 0 : arguments[n]),
          o = "function" == typeof i ? i : null,
          a = (arguments.length <= 1 ? 0 : arguments.length - 1) - (o ? 1 : 0);
        if (0 === a) return e.validate(r, o);
        var s = 2 === a ? arguments.length <= 2 ? void 0 : arguments[2] : {};
        return t.compile(arguments.length <= 1 ? void 0 : arguments[1])._validateWithOptions(r, s, o);
      }, t.describe = function () {
        return (arguments.length ? t.compile(arguments.length <= 0 ? void 0 : arguments[0]) : e).describe();
      }, t.compile = function (e) {
        try {
          return p.schema(this, e);
        } catch (e) {
          throw e.hasOwnProperty("path") && (e.message = e.message + "(" + e.path + ")"), e;
        }
      }, t.assert = function (e, r, n) {
        t.attempt(e, r, n);
      }, t.attempt = function (e, r, n) {
        var i = t.validate(e, r),
          o = i.error;
        if (o) {
          if (!n) throw "function" == typeof o.annotate && (o.message = o.annotate()), o;
          if (!(n instanceof Error)) throw "function" == typeof o.annotate && (o.message = n + " " + o.annotate()), o;
          throw n;
        }
        return i.value;
      }, t.reach = function (e, t) {
        l.assert(e && e instanceof f, "you must provide a joi schema"), l.assert(Array.isArray(t) || "string" == typeof t, "path must be a string or an array of strings");
        var r = "string" == typeof t ? t ? t.split(".") : [] : t.slice();
        return function e(t, r) {
          if (!r.length) return t;
          var n = t._inner.children;
          if (n) for (var i = r.shift(), o = 0; o < n.length; ++o) {
            var a = n[o];
            if (a.key === i) return e(a.schema, r);
          }
        }(e, r);
      }, t.lazy = function (e) {
        return d.set(e);
      }, t.defaults = function (e) {
        var t = this;
        l.assert("function" == typeof e, "Defaults must be a function");
        var r = Object.create(this.any());
        return r = e(r), l.assert(r && r instanceof this.constructor, "defaults() must return a schema"), c(r, this, r.clone()), r._defaults = function (r) {
          return t._defaults && (r = t._defaults(r), l.assert(r instanceof t.constructor, "defaults() must return a schema")), r = e(r), l.assert(r instanceof t.constructor, "defaults() must return a schema"), r;
        }, r;
      }, t.extend = function () {
        for (var e = this, r = arguments.length, n = Array(r), u = 0; u < r; u++) {
          n[u] = arguments[u];
        }
        var d = l.flatten(n);
        l.assert(d.length > 0, "You need to provide at least one extension"), this.assert(d, t.extensionsSchema);
        var m = Object.create(this.any());
        c(m, this);
        for (var b = 0; b < d.length; ++b) {
          !function (r) {
            var n = d[r];
            "function" == typeof n && (n = n(m)), e.assert(n, t.extensionSchema);
            var u = (n.base || e.any()).clone(),
              b = u.constructor,
              _ = function (e) {
                function t() {
                  o(this, t);
                  var r = a(this, e.call(this));
                  return n.base && c(r, u), r._type = n.name, n.language && (r._settings = y.concat(r._settings, {
                    language: i({}, n.name, n.language)
                  })), r;
                }
                return s(t, e), t;
              }(b);
            if (n.coerce && (_.prototype._coerce = function (e, t, r) {
              if (b.prototype._coerce) {
                var i = b.prototype._coerce.call(this, e, t, r);
                if (i.errors) return i;
                e = i.value;
              }
              var o = n.coerce.call(this, e, t, r);
              return o instanceof h.Err ? {
                value: e,
                errors: o
              } : {
                value: o
              };
            }), n.pre && (_.prototype._base = function (e, t, r) {
              if (b.prototype._base) {
                var i = b.prototype._base.call(this, e, t, r);
                if (i.errors) return i;
                e = i.value;
              }
              var o = n.pre.call(this, e, t, r);
              return o instanceof h.Err ? {
                value: e,
                errors: o
              } : {
                value: o
              };
            }), n.rules) for (var w = 0; w < n.rules.length; ++w) {
              !function (t) {
                var r = n.rules[t],
                  i = r.params ? r.params instanceof f ? r.params._inner.children.map(function (e) {
                    return e.key;
                  }) : Object.keys(r.params) : [],
                  o = r.params ? p.schema(e, r.params) : null;
                _.prototype[r.name] = function () {
                  for (var e = arguments.length, t = Array(e), n = 0; n < e; n++) {
                    t[n] = arguments[n];
                  }
                  if (t.length > i.length) throw new Error("Unexpected number of arguments");
                  for (var a = !1, s = {}, u = 0; u < i.length; ++u) {
                    s[i[u]] = t[u], !a && g.isRef(t[u]) && (a = !0);
                  }
                  o && (s = m.attempt(s, o));
                  var c = void 0;
                  if (r.validate) {
                    var p = function p(e, t, n) {
                      return r.validate.call(this, s, e, t, n);
                    };
                    c = this._test(r.name, s, p, {
                      description: r.description,
                      hasRef: a
                    });
                  } else c = this.clone();
                  if (r.setup) {
                    var h = r.setup.call(c, s);
                    void 0 !== h && (l.assert(h instanceof f, "Setup of extension Joi." + this._type + "()." + r.name + "() must return undefined or a Joi object"), c = h);
                  }
                  return c;
                };
              }(w);
            }
            n.describe && (_.prototype.describe = function () {
              var e = b.prototype.describe.call(this);
              return n.describe.call(this, e);
            });
            var E = new _();
            m[n.name] = function () {
              for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
                t[r] = arguments[r];
              }
              return v.callWithDefaults.call(this, E, t);
            };
          }(b);
        }
        return m;
      }, t.extensionSchema = v.object.keys({
        base: v.object.type(f, "Joi object"),
        name: v.string.required(),
        coerce: v.func.arity(3),
        pre: v.func.arity(3),
        language: v.object,
        describe: v.func.arity(1),
        rules: v.array.items(v.object.keys({
          name: v.string.required(),
          setup: v.func.arity(1),
          validate: v.func.arity(4),
          params: [v.object.pattern(/.*/, v.object.type(f, "Joi object")), v.object.type(v.object.constructor, "Joi object")],
          description: [v.string, v.func.arity(1)]
        }).or("setup", "validate"))
      }).strict(), t.extensionsSchema = v.array.items([v.object, v.func.arity(1)]).strict(), t.version = r(36).version, t;
    }, e.exports = v.root();
  }, function (e, t, r) {
    "use strict";

    (function (t) {
      function n(e) {
        if (Array.isArray(e)) {
          for (var t = 0, r = Array(e.length); t < e.length; t++) {
            r[t] = e[t];
          }
          return r;
        }
        return Array.from(e);
      }
      function i(e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }
      var o = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
          return typeof e;
        } : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
        },
        a = r(1),
        s = {};
      s.extendedCheckForValue = function (e, r) {
        var n = void 0 === e ? "undefined" : o(e);
        if ("object" === n) {
          if (e instanceof Date) return function (t) {
            return t instanceof Date && e.getTime() === t.getTime();
          };
          if (t.isBuffer(e)) return function (r) {
            return t.isBuffer(r) && e.length === r.length && e.toString("binary") === r.toString("binary");
          };
        } else if (r && "string" === n) {
          var i = e.toLowerCase();
          return function (e) {
            return "string" == typeof e && i === e.toLowerCase();
          };
        }
        return null;
      }, e.exports = function () {
        function e(t) {
          i(this, e), this._set = new Set(t), this._hasRef = !1;
        }
        return e.prototype.add = function (e, t) {
          var r = a.isRef(e);
          return !r && this.has(e, null, null, !1) ? this : (void 0 !== t && a.push(t, e), this._set.add(e), this._hasRef |= r, this);
        }, e.prototype.merge = function (e, t) {
          var r = !0,
            n = !1,
            i = void 0;
          try {
            for (var o, a = e._set[Symbol.iterator](); !(r = (o = a.next()).done); r = !0) {
              var s = o.value;
              this.add(s);
            }
          } catch (e) {
            n = !0, i = e;
          } finally {
            try {
              !r && a.return && a.return();
            } finally {
              if (n) throw i;
            }
          }
          var u = !0,
            c = !1,
            l = void 0;
          try {
            for (var f, p = t._set[Symbol.iterator](); !(u = (f = p.next()).done); u = !0) {
              var h = f.value;
              this.remove(h);
            }
          } catch (e) {
            c = !0, l = e;
          } finally {
            try {
              !u && p.return && p.return();
            } finally {
              if (c) throw l;
            }
          }
          return this;
        }, e.prototype.remove = function (e) {
          return this._set.delete(e), this;
        }, e.prototype.has = function (e, t, r, n) {
          if (!this._set.size) return !1;
          var i = this._set.has(e);
          if (i) return i;
          var o = s.extendedCheckForValue(e, n);
          if (!o) {
            if (t && this._hasRef) {
              var u = !0,
                c = !1,
                l = void 0;
              try {
                for (var f, p = this._set[Symbol.iterator](); !(u = (f = p.next()).done); u = !0) {
                  var h = f.value;
                  if (a.isRef(h) && (h = h(t.reference || t.parent, r), e === h || Array.isArray(h) && h.includes(e))) return !0;
                }
              } catch (e) {
                c = !0, l = e;
              } finally {
                try {
                  !u && p.return && p.return();
                } finally {
                  if (c) throw l;
                }
              }
            }
            return !1;
          }
          return this._has(e, t, r, o);
        }, e.prototype._has = function (e, t, r, n) {
          var i = !(!t || !this._hasRef),
            o = function o(t) {
              return e === t || n(t);
            },
            s = !0,
            u = !1,
            c = void 0;
          try {
            for (var l, f = this._set[Symbol.iterator](); !(s = (l = f.next()).done); s = !0) {
              var p = l.value;
              if (i && a.isRef(p) && (p = p(t.reference || t.parent, r), Array.isArray(p))) {
                if (p.find(o)) return !0;
              } else if (o(p)) return !0;
            }
          } catch (e) {
            u = !0, c = e;
          } finally {
            try {
              !s && f.return && f.return();
            } finally {
              if (u) throw c;
            }
          }
          return !1;
        }, e.prototype.values = function (e) {
          if (e && e.stripUndefined) {
            var t = [],
              r = !0,
              n = !1,
              i = void 0;
            try {
              for (var o, a = this._set[Symbol.iterator](); !(r = (o = a.next()).done); r = !0) {
                var s = o.value;
                void 0 !== s && t.push(s);
              }
            } catch (e) {
              n = !0, i = e;
            } finally {
              try {
                !r && a.return && a.return();
              } finally {
                if (n) throw i;
              }
            }
            return t;
          }
          return Array.from(this._set);
        }, e.prototype.slice = function () {
          var t = new e(this._set);
          return t._hasRef = this._hasRef, t;
        }, e.prototype.concat = function (t) {
          var r = new e([].concat(n(this._set), n(t._set)));
          return r._hasRef = !!(this._hasRef | t._hasRef), r;
        }, e;
      }();
    }).call(t, r(3).Buffer);
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      u = r(0),
      c = r(2),
      l = r(4),
      f = r(1),
      p = {};
    p.Alternatives = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "alternatives", r._invalids.remove(null), r._inner.matches = [], r;
      }
      return a(t, e), t.prototype._init = function () {
        return arguments.length ? this.try.apply(this, arguments) : this;
      }, t.prototype._base = function (e, t, r) {
        for (var n = [], i = this._inner.matches.length, o = this._baseType, a = 0; a < i; ++a) {
          var s = this._inner.matches[a];
          if (s.schema) {
            var u = s.schema._validate(e, t, r);
            if (!u.errors) return u;
            n = n.concat(u.errors);
          } else {
            var c = s.peek || s.is,
              l = s.is ? s.ref(t.reference || t.parent, r) : e;
            if (c._validate(l, null, r, t.parent).errors) {
              if (s.otherwise) return s.otherwise._validate(e, t, r);
            } else if (s.then) return s.then._validate(e, t, r);
            if (a === i - 1 && o) return o._validate(e, t, r);
          }
        }
        return n.length ? {
          errors: this.createError("alternatives.child", {
            reason: n
          }, t, r)
        } : {
          errors: this.createError("alternatives.base", null, t, r)
        };
      }, t.prototype.try = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        t = u.flatten(t), u.assert(t.length, "Cannot add other alternatives without at least one schema");
        for (var n = this.clone(), i = 0; i < t.length; ++i) {
          var o = l.schema(this._currentJoi, t[i]);
          o._refs.length && (n._refs = n._refs.concat(o._refs)), n._inner.matches.push({
            schema: o
          });
        }
        return n;
      }, t.prototype.when = function (e, t) {
        var r = !1;
        u.assert(f.isRef(e) || "string" == typeof e || (r = e instanceof c), "Invalid condition:", e), u.assert(t, "Missing options"), u.assert("object" === (void 0 === t ? "undefined" : s(t)), "Invalid options"), r ? u.assert(!t.hasOwnProperty("is"), '"is" can not be used with a schema condition') : u.assert(t.hasOwnProperty("is"), 'Missing "is" directive'), u.assert(void 0 !== t.then || void 0 !== t.otherwise, 'options must have at least one of "then" or "otherwise"');
        var n = this.clone(),
          i = void 0;
        r || (i = l.schema(this._currentJoi, t.is), null !== t.is && (f.isRef(t.is) || t.is instanceof c) || (i = i.required()));
        var o = {
          ref: r ? null : l.ref(e),
          peek: r ? e : null,
          is: i,
          then: void 0 !== t.then ? l.schema(this._currentJoi, t.then) : void 0,
          otherwise: void 0 !== t.otherwise ? l.schema(this._currentJoi, t.otherwise) : void 0
        };
        return n._baseType && (o.then = o.then && n._baseType.concat(o.then), o.otherwise = o.otherwise && n._baseType.concat(o.otherwise)), r || (f.push(n._refs, o.ref), n._refs = n._refs.concat(o.is._refs)), o.then && o.then._refs && (n._refs = n._refs.concat(o.then._refs)), o.otherwise && o.otherwise._refs && (n._refs = n._refs.concat(o.otherwise._refs)), n._inner.matches.push(o), n;
      }, t.prototype.describe = function () {
        for (var e = c.prototype.describe.call(this), t = [], r = 0; r < this._inner.matches.length; ++r) {
          var n = this._inner.matches[r];
          if (n.schema) t.push(n.schema.describe());else {
            var i = n.is ? {
              ref: n.ref.toString(),
              is: n.is.describe()
            } : {
              peek: n.peek.describe()
            };
            n.then && (i.then = n.then.describe()), n.otherwise && (i.otherwise = n.otherwise.describe()), t.push(i);
          }
        }
        return e.alternatives = t, e;
      }, t;
    }(c), e.exports = new p.Alternatives();
  }, function (e, t, r) {
    "use strict";

    var n = Object.assign || function (e) {
        for (var t = 1; t < arguments.length; t++) {
          var r = arguments[t];
          for (var n in r) {
            Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
          }
        }
        return e;
      },
      i = r(0);
    t.concat = function (e, t) {
      if (!t) return e;
      for (var r = n({}, e), o = Object.keys(t), a = 0; a < o.length; ++a) {
        var s = o[a];
        "language" === s && r.hasOwnProperty(s) ? r[s] = i.applyToDefaults(r[s], t[s]) : r[s] = t[s];
      }
      return r;
    };
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = r(2),
      u = r(1),
      c = r(0),
      l = {};
    l.isoDate = /^(?:[-+]\d{2})?(?:\d{4}(?!\d{2}\b))(?:(-?)(?:(?:0[1-9]|1[0-2])(?:\1(?:[12]\d|0[1-9]|3[01]))?|W(?:[0-4]\d|5[0-2])(?:-?[1-7])?|(?:00[1-9]|0[1-9]\d|[12]\d{2}|3(?:[0-5]\d|6[1-6])))(?![T]$|[T][\d]+Z$)(?:[T\s](?:(?:(?:[01]\d|2[0-3])(?:(:?)[0-5]\d)?|24\:?00)(?:[.,]\d+(?!:))?)(?:\2[0-5]\d(?:[.,]\d+)?)?(?:[Z]|(?:[+-])(?:[01]\d|2[0-3])(?::?[0-5]\d)?)?)?)?$/, l.invalidDate = new Date(""), l.isIsoDate = function () {
      var e = l.isoDate.toString();
      return function (t) {
        return t && t.toString() === e;
      };
    }(), l.Date = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "date", r;
      }
      return a(t, e), t.prototype._base = function (e, t, r) {
        var n = {
          value: r.convert && l.Date.toDate(e, this._flags.format, this._flags.timestamp, this._flags.multiplier) || e
        };
        if (n.value instanceof Date && !isNaN(n.value.getTime())) n.errors = null;else if (r.convert) {
          var i = void 0;
          i = l.isIsoDate(this._flags.format) ? "isoDate" : this._flags.timestamp ? "timestamp." + this._flags.timestamp : "base", n.errors = this.createError("date." + i, null, t, r);
        } else n.errors = this.createError("date.strict", null, t, r);
        return n;
      }, t.toDate = function (e, t, r, n) {
        if (e instanceof Date) return e;
        if ("string" == typeof e || "number" == typeof e && !isNaN(e) && isFinite(e)) {
          "string" == typeof e && /^[+-]?\d+(\.\d+)?$/.test(e) && (e = parseFloat(e));
          var i = void 0;
          if (i = t && l.isIsoDate(t) ? t.test(e) ? new Date(e) : l.invalidDate : r && n ? /^\s*$/.test(e) ? l.invalidDate : new Date(e * n) : new Date(e), !isNaN(i.getTime())) return i;
        }
        return null;
      }, t.prototype.iso = function () {
        if (this._flags.format === l.isoDate) return this;
        var e = this.clone();
        return e._flags.format = l.isoDate, e;
      }, t.prototype.timestamp = function () {
        var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "javascript",
          t = ["javascript", "unix"];
        if (c.assert(t.includes(e), '"type" must be one of "' + t.join('", "') + '"'), this._flags.timestamp === e) return this;
        var r = this.clone();
        return r._flags.timestamp = e, r._flags.multiplier = "unix" === e ? 1e3 : 1, r;
      }, t.prototype._isIsoDate = function (e) {
        return l.isoDate.test(e);
      }, t;
    }(s), l.compare = function (e, t) {
      return function (r) {
        var n = "now" === r,
          i = u.isRef(r);
        return n || i || (r = l.Date.toDate(r)), c.assert(r, "Invalid date format"), this._test(e, r, function (o, a, s) {
          var u = void 0;
          if (n) u = Date.now();else if (i) {
            if (!(u = l.Date.toDate(r(a.reference || a.parent, s)))) return this.createError("date.ref", {
              ref: r.key
            }, a, s);
            u = u.getTime();
          } else u = r.getTime();
          return t(o.getTime(), u) ? o : this.createError("date." + e, {
            limit: new Date(u)
          }, a, s);
        });
      };
    }, l.Date.prototype.min = l.compare("min", function (e, t) {
      return e >= t;
    }), l.Date.prototype.max = l.compare("max", function (e, t) {
      return e <= t;
    }), l.Date.prototype.greater = l.compare("greater", function (e, t) {
      return e > t;
    }), l.Date.prototype.less = l.compare("less", function (e, t) {
      return e < t;
    }), e.exports = new l.Date();
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      u = r(0),
      c = r(31),
      l = r(2),
      f = r(6),
      p = r(4),
      h = {};
    h.Object = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "object", r._inner.children = null, r._inner.renames = [], r._inner.dependencies = [], r._inner.patterns = [], r;
      }
      return a(t, e), t.prototype._init = function () {
        return arguments.length ? this.keys.apply(this, arguments) : this;
      }, t.prototype._base = function (e, t, r) {
        var n = e,
          i = [],
          o = function o() {
            return {
              value: n,
              errors: i.length ? i : null
            };
          };
        "string" == typeof e && r.convert && (e = h.safeParse(e));
        var a = this._flags.func ? "function" : "object";
        if (!e || (void 0 === e ? "undefined" : s(e)) !== a || Array.isArray(e)) return i.push(this.createError(a + ".base", null, t, r)), o();
        if (!(this._inner.renames.length || this._inner.dependencies.length || this._inner.children || this._inner.patterns.length)) return n = e, o();
        if (n === e) {
          "object" === a ? n = Object.create(Object.getPrototypeOf(e)) : (n = function n() {
            for (var t = arguments.length, r = Array(t), n = 0; n < t; n++) {
              r[n] = arguments[n];
            }
            return e.apply(this, r);
          }, n.prototype = u.clone(e.prototype));
          for (var c = Object.keys(e), l = 0; l < c.length; ++l) {
            n[c[l]] = e[c[l]];
          }
        } else n = e;
        for (var p = {}, d = 0; d < this._inner.renames.length; ++d) {
          var g = this._inner.renames[d];
          if (g.isRegExp) {
            for (var y = Object.keys(n), v = [], m = 0; m < y.length; ++m) {
              g.from.test(y[m]) && v.push(y[m]);
            }
            var b = v.every(function (e) {
              return void 0 === n[e];
            });
            if (g.options.ignoreUndefined && b) continue;
            if (!g.options.multiple && p[g.to] && (i.push(this.createError("object.rename.regex.multiple", {
              from: v,
              to: g.to
            }, t, r)), r.abortEarly)) return o();
            if (Object.prototype.hasOwnProperty.call(n, g.to) && !g.options.override && !p[g.to] && (i.push(this.createError("object.rename.regex.override", {
              from: v,
              to: g.to
            }, t, r)), r.abortEarly)) return o();
            if (b ? delete n[g.to] : n[g.to] = n[v[v.length - 1]], p[g.to] = !0, !g.options.alias) for (var _ = 0; _ < v.length; ++_) {
              delete n[v[_]];
            }
          } else {
            if (g.options.ignoreUndefined && void 0 === n[g.from]) continue;
            if (!g.options.multiple && p[g.to] && (i.push(this.createError("object.rename.multiple", {
              from: g.from,
              to: g.to
            }, t, r)), r.abortEarly)) return o();
            if (Object.prototype.hasOwnProperty.call(n, g.to) && !g.options.override && !p[g.to] && (i.push(this.createError("object.rename.override", {
              from: g.from,
              to: g.to
            }, t, r)), r.abortEarly)) return o();
            void 0 === n[g.from] ? delete n[g.to] : n[g.to] = n[g.from], p[g.to] = !0, g.options.alias || delete n[g.from];
          }
        }
        if (!this._inner.children && !this._inner.patterns.length && !this._inner.dependencies.length) return o();
        var w = new Set(Object.keys(n));
        if (this._inner.children) {
          for (var E = [], x = 0; x < this._inner.children.length; ++x) {
            var A = this._inner.children[x],
              j = A.key,
              S = n[j];
            w.delete(j);
            var O = {
                key: j,
                path: t.path.concat(j),
                parent: n,
                reference: t.reference
              },
              k = A.schema._validate(S, O, r);
            if (k.errors) {
              if (i.push(this.createError("object.child", {
                key: j,
                child: A.schema._getLabel(j),
                reason: k.errors
              }, O, r)), r.abortEarly) return o();
            } else A.schema._flags.strip || void 0 === k.value && k.value !== S ? (E.push(j), n[j] = k.finalValue) : void 0 !== k.value && (n[j] = k.value);
          }
          for (var P = 0; P < E.length; ++P) {
            delete n[E[P]];
          }
        }
        if (w.size && this._inner.patterns.length) {
          var T = !0,
            R = !1,
            C = void 0;
          try {
            for (var D, I = w[Symbol.iterator](); !(T = (D = I.next()).done); T = !0) {
              for (var L = D.value, N = {
                  key: L,
                  path: t.path.concat(L),
                  parent: n,
                  reference: t.reference
                }, B = n[L], F = 0; F < this._inner.patterns.length; ++F) {
                var U = this._inner.patterns[F];
                if (U.regex ? U.regex.test(L) : !U.schema.validate(L).error) {
                  w.delete(L);
                  var z = U.rule._validate(B, N, r);
                  if (z.errors && (i.push(this.createError("object.child", {
                    key: L,
                    child: U.rule._getLabel(L),
                    reason: z.errors
                  }, N, r)), r.abortEarly)) return o();
                  n[L] = z.value;
                }
              }
            }
          } catch (re) {
            R = !0, C = re;
          } finally {
            try {
              !T && I.return && I.return();
            } finally {
              if (R) throw C;
            }
          }
        }
        if (w.size && (this._inner.children || this._inner.patterns.length)) {
          if (r.stripUnknown && !0 !== this._flags.allowUnknown || r.skipFunctions) {
            var q = !!r.stripUnknown && (!0 === r.stripUnknown || !!r.stripUnknown.objects),
              W = !0,
              M = !1,
              J = void 0;
            try {
              for (var $, Y = w[Symbol.iterator](); !(W = ($ = Y.next()).done); W = !0) {
                var Z = $.value;
                q ? (delete n[Z], w.delete(Z)) : "function" == typeof n[Z] && w.delete(Z);
              }
            } catch (re) {
              M = !0, J = re;
            } finally {
              try {
                !W && Y.return && Y.return();
              } finally {
                if (M) throw J;
              }
            }
          }
          if (void 0 !== this._flags.allowUnknown ? !this._flags.allowUnknown : !r.allowUnknown) {
            var V = !0,
              Q = !1,
              H = void 0;
            try {
              for (var X, G = w[Symbol.iterator](); !(V = (X = G.next()).done); V = !0) {
                var K = X.value;
                i.push(this.createError("object.allowUnknown", {
                  child: K
                }, {
                  key: K,
                  path: t.path.concat(K)
                }, r, {}));
              }
            } catch (re) {
              Q = !0, H = re;
            } finally {
              try {
                !V && G.return && G.return();
              } finally {
                if (Q) throw H;
              }
            }
          }
        }
        for (var ee = 0; ee < this._inner.dependencies.length; ++ee) {
          var te = this._inner.dependencies[ee],
            re = h[te.type].call(this, null !== te.key && n[te.key], te.peers, n, {
              key: te.key,
              path: null === te.key ? t.path : t.path.concat(te.key)
            }, r);
          if (re instanceof f.Err && (i.push(re), r.abortEarly)) return o();
        }
        return o();
      }, t.prototype.keys = function (e) {
        u.assert(null === e || void 0 === e || "object" === (void 0 === e ? "undefined" : s(e)), "Object schema must be a valid object"), u.assert(!(e && e instanceof l), "Object schema cannot be a joi schema");
        var t = this.clone();
        if (!e) return t._inner.children = null, t;
        var r = Object.keys(e);
        if (!r.length) return t._inner.children = [], t;
        var n = new c();
        if (t._inner.children) for (var i = 0; i < t._inner.children.length; ++i) {
          var o = t._inner.children[i];
          r.includes(o.key) || n.add(o, {
            after: o._refs,
            group: o.key
          });
        }
        for (var a = 0; a < r.length; ++a) {
          var f = r[a],
            h = e[f];
          try {
            var d = p.schema(this._currentJoi, h);
            n.add({
              key: f,
              schema: d
            }, {
              after: d._refs,
              group: f
            });
          } catch (e) {
            throw e.hasOwnProperty("path") ? e.path = f + "." + e.path : e.path = f, e;
          }
        }
        return t._inner.children = n.nodes, t;
      }, t.prototype.append = function (e) {
        return null === e || void 0 === e || 0 === Object.keys(e).length ? this : this.keys(e);
      }, t.prototype.unknown = function (e) {
        var t = !1 !== e;
        if (this._flags.allowUnknown === t) return this;
        var r = this.clone();
        return r._flags.allowUnknown = t, r;
      }, t.prototype.length = function (e) {
        return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("length", e, function (t, r, n) {
          return Object.keys(t).length === e ? t : this.createError("object.length", {
            limit: e
          }, r, n);
        });
      }, t.prototype.min = function (e) {
        return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("min", e, function (t, r, n) {
          return Object.keys(t).length >= e ? t : this.createError("object.min", {
            limit: e
          }, r, n);
        });
      }, t.prototype.max = function (e) {
        return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("max", e, function (t, r, n) {
          return Object.keys(t).length <= e ? t : this.createError("object.max", {
            limit: e
          }, r, n);
        });
      }, t.prototype.pattern = function (e, t) {
        var r = e instanceof RegExp;
        u.assert(r || e instanceof l, "pattern must be a regex or schema"), u.assert(void 0 !== t, "Invalid rule"), r && (e = new RegExp(e.source, e.ignoreCase ? "i" : void 0));
        try {
          t = p.schema(this._currentJoi, t);
        } catch (e) {
          throw e.hasOwnProperty("path") && (e.message = e.message + "(" + e.path + ")"), e;
        }
        var n = this.clone();
        return r ? n._inner.patterns.push({
          regex: e,
          rule: t
        }) : n._inner.patterns.push({
          schema: e,
          rule: t
        }), n;
      }, t.prototype.schema = function () {
        return this._test("schema", null, function (e, t, r) {
          return e instanceof l ? e : this.createError("object.schema", null, t, r);
        });
      }, t.prototype.with = function (e, t) {
        return u.assert(2 === arguments.length, "Invalid number of arguments, expected 2."), this._dependency("with", e, t);
      }, t.prototype.without = function (e, t) {
        return u.assert(2 === arguments.length, "Invalid number of arguments, expected 2."), this._dependency("without", e, t);
      }, t.prototype.xor = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this._dependency("xor", null, t);
      }, t.prototype.or = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this._dependency("or", null, t);
      }, t.prototype.and = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this._dependency("and", null, t);
      }, t.prototype.nand = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this._dependency("nand", null, t);
      }, t.prototype.requiredKeys = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this.applyFunctionToChildren(t, "required");
      }, t.prototype.optionalKeys = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this.applyFunctionToChildren(t, "optional");
      }, t.prototype.forbiddenKeys = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        return t = u.flatten(t), this.applyFunctionToChildren(t, "forbidden");
      }, t.prototype.rename = function (e, t, r) {
        u.assert("string" == typeof e || e instanceof RegExp, "Rename missing the from argument"), u.assert("string" == typeof t, "Rename missing the to argument"), u.assert(t !== e, "Cannot rename key to same name:", e);
        for (var n = 0; n < this._inner.renames.length; ++n) {
          u.assert(this._inner.renames[n].from !== e, "Cannot rename the same key multiple times");
        }
        var i = this.clone();
        return i._inner.renames.push({
          from: e,
          to: t,
          options: u.applyToDefaults(h.renameDefaults, r || {}),
          isRegExp: e instanceof RegExp
        }), i;
      }, t.prototype.applyFunctionToChildren = function (e, t, r, n) {
        e = [].concat(e), u.assert(e.length > 0, "expected at least one children");
        var i = h.groupChildren(e),
          o = void 0;
        if ("" in i ? (o = this[t].apply(this, r), delete i[""]) : o = this.clone(), o._inner.children) {
          n = n ? n + "." : "";
          for (var a = 0; a < o._inner.children.length; ++a) {
            var s = o._inner.children[a],
              c = i[s.key];
            c && (o._inner.children[a] = {
              key: s.key,
              _refs: s._refs,
              schema: s.schema.applyFunctionToChildren(c, t, r, n + s.key)
            }, delete i[s.key]);
          }
        }
        var l = Object.keys(i);
        return u.assert(0 === l.length, "unknown key(s)", l.join(", ")), o;
      }, t.prototype._dependency = function (e, t, r) {
        r = [].concat(r);
        for (var n = 0; n < r.length; ++n) {
          u.assert("string" == typeof r[n], e, "peers must be a string or array of strings");
        }
        var i = this.clone();
        return i._inner.dependencies.push({
          type: e,
          key: t,
          peers: r
        }), i;
      }, t.prototype.describe = function (e) {
        var t = l.prototype.describe.call(this);
        if (t.rules) for (var r = 0; r < t.rules.length; ++r) {
          var n = t.rules[r];
          n.arg && "object" === s(n.arg) && n.arg.schema && n.arg.ref && (n.arg = {
            schema: n.arg.schema.describe(),
            ref: n.arg.ref.toString()
          });
        }
        if (this._inner.children && !e) {
          t.children = {};
          for (var i = 0; i < this._inner.children.length; ++i) {
            var o = this._inner.children[i];
            t.children[o.key] = o.schema.describe();
          }
        }
        if (this._inner.dependencies.length && (t.dependencies = u.clone(this._inner.dependencies)), this._inner.patterns.length) {
          t.patterns = [];
          for (var a = 0; a < this._inner.patterns.length; ++a) {
            var c = this._inner.patterns[a];
            c.regex ? t.patterns.push({
              regex: c.regex.toString(),
              rule: c.rule.describe()
            }) : t.patterns.push({
              schema: c.schema.describe(),
              rule: c.rule.describe()
            });
          }
        }
        return this._inner.renames.length > 0 && (t.renames = u.clone(this._inner.renames)), t;
      }, t.prototype.assert = function (e, t, r) {
        e = p.ref(e), u.assert(e.isContext || e.depth > 1, "Cannot use assertions for root level references - use direct key rules instead"), r = r || "pass the assertion test";
        try {
          t = p.schema(this._currentJoi, t);
        } catch (e) {
          throw e.hasOwnProperty("path") && (e.message = e.message + "(" + e.path + ")"), e;
        }
        var n = e.path[e.path.length - 1],
          i = e.path.join(".");
        return this._test("assert", {
          schema: t,
          ref: e
        }, function (o, a, s) {
          if (!t._validate(e(o), null, s, o).errors) return o;
          var c = u.merge({}, a);
          return c.key = n, c.path = e.path, this.createError("object.assert", {
            ref: i,
            message: r
          }, c, s);
        });
      }, t.prototype.type = function (e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : e.name;
        u.assert("function" == typeof e, "type must be a constructor function");
        var r = {
          name: t,
          ctor: e
        };
        return this._test("type", r, function (t, n, i) {
          return t instanceof e ? t : this.createError("object.type", {
            type: r.name
          }, n, i);
        });
      }, t;
    }(l), h.safeParse = function (e) {
      try {
        return JSON.parse(e);
      } catch (e) {}
      return e;
    }, h.renameDefaults = {
      alias: !1,
      multiple: !1,
      override: !1
    }, h.groupChildren = function (e) {
      e.sort();
      for (var t = {}, r = 0; r < e.length; ++r) {
        var n = e[r];
        u.assert("string" == typeof n, "children must be strings");
        var i = n.split(".")[0];
        (t[i] = t[i] || []).push(n.substring(i.length + 1));
      }
      return t;
    }, h.keysToLabels = function (e, t) {
      var r = e._inner.children;
      if (!r) return t;
      var n = function n(e) {
        var t = r.find(function (t) {
          return t.key === e;
        });
        return t ? t.schema._getLabel(e) : e;
      };
      return Array.isArray(t) ? t.map(n) : n(t);
    }, h.with = function (e, t, r, n, i) {
      if (void 0 === e) return e;
      for (var o = 0; o < t.length; ++o) {
        var a = t[o];
        if (!Object.prototype.hasOwnProperty.call(r, a) || void 0 === r[a]) return this.createError("object.with", {
          main: n.key,
          mainWithLabel: h.keysToLabels(this, n.key),
          peer: a,
          peerWithLabel: h.keysToLabels(this, a)
        }, n, i);
      }
      return e;
    }, h.without = function (e, t, r, n, i) {
      if (void 0 === e) return e;
      for (var o = 0; o < t.length; ++o) {
        var a = t[o];
        if (Object.prototype.hasOwnProperty.call(r, a) && void 0 !== r[a]) return this.createError("object.without", {
          main: n.key,
          mainWithLabel: h.keysToLabels(this, n.key),
          peer: a,
          peerWithLabel: h.keysToLabels(this, a)
        }, n, i);
      }
      return e;
    }, h.xor = function (e, t, r, n, i) {
      for (var o = [], a = 0; a < t.length; ++a) {
        var s = t[a];
        Object.prototype.hasOwnProperty.call(r, s) && void 0 !== r[s] && o.push(s);
      }
      if (1 === o.length) return e;
      var u = {
        peers: t,
        peersWithLabels: h.keysToLabels(this, t)
      };
      return 0 === o.length ? this.createError("object.missing", u, n, i) : this.createError("object.xor", u, n, i);
    }, h.or = function (e, t, r, n, i) {
      for (var o = 0; o < t.length; ++o) {
        var a = t[o];
        if (Object.prototype.hasOwnProperty.call(r, a) && void 0 !== r[a]) return e;
      }
      return this.createError("object.missing", {
        peers: t,
        peersWithLabels: h.keysToLabels(this, t)
      }, n, i);
    }, h.and = function (e, t, r, n, i) {
      for (var o = [], a = [], s = t.length, u = 0; u < s; ++u) {
        var c = t[u];
        Object.prototype.hasOwnProperty.call(r, c) && void 0 !== r[c] ? a.push(c) : o.push(c);
      }
      if (o.length !== s && a.length !== s) return this.createError("object.and", {
        present: a,
        presentWithLabels: h.keysToLabels(this, a),
        missing: o,
        missingWithLabels: h.keysToLabels(this, o)
      }, n, i);
    }, h.nand = function (e, t, r, n, i) {
      for (var o = [], a = 0; a < t.length; ++a) {
        var s = t[a];
        Object.prototype.hasOwnProperty.call(r, s) && void 0 !== r[s] && o.push(s);
      }
      var c = u.clone(t),
        l = c.splice(0, 1)[0];
      return o.length === t.length ? this.createError("object.nand", {
        main: l,
        mainWithLabel: h.keysToLabels(this, l),
        peers: c,
        peersWithLabels: h.keysToLabels(this, c)
      }, n, i) : null;
    }, e.exports = new h.Object();
  }, function (e, t, r) {
    "use strict";

    var n = {
      rfc3986: {}
    };
    n.generate = function () {
      var e = "|";
      n.rfc3986.ipv4Cidr = "[0-9]|[1-2][0-9]|3[0-2]", n.rfc3986.ipv6Cidr = "(?:0?0?[0-9]|0?[1-9][0-9]|1[01][0-9]|12[0-8])";
      var t = "a-zA-Z0-9-\\._~",
        r = "!\\$&'\\(\\)\\*\\+,;=",
        i = t + "%0-9A-Fa-f" + r + ":@",
        o = "[" + i + "]",
        a = "(?:0?0?[0-9]|0?[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])";
      n.rfc3986.IPv4address = "(?:" + a + "\\.){3}" + a;
      var s = "[0-9A-Fa-f]{1,4}",
        u = "(?:" + s + ":" + s + "|" + n.rfc3986.IPv4address + ")",
        c = "(?:" + s + ":){6}" + u,
        l = "::(?:" + s + ":){5}" + u,
        f = "(?:" + s + ")?::(?:" + s + ":){4}" + u,
        p = "(?:(?:" + s + ":){0,1}" + s + ")?::(?:" + s + ":){3}" + u,
        h = "(?:(?:" + s + ":){0,2}" + s + ")?::(?:" + s + ":){2}" + u,
        d = "(?:(?:" + s + ":){0,3}" + s + ")?::" + s + ":" + u,
        g = "(?:(?:" + s + ":){0,4}" + s + ")?::" + u;
      n.rfc3986.IPv6address = "(?:" + c + e + l + e + f + e + p + e + h + e + d + e + g + e + "(?:(?:[0-9A-Fa-f]{1,4}:){0,5}[0-9A-Fa-f]{1,4})?::[0-9A-Fa-f]{1,4}" + e + "(?:(?:[0-9A-Fa-f]{1,4}:){0,6}[0-9A-Fa-f]{1,4})?::)", n.rfc3986.IPvFuture = "v[0-9A-Fa-f]+\\.[" + t + r + ":]+", n.rfc3986.scheme = "[a-zA-Z][a-zA-Z0-9+-\\.]*";
      var y = "\\[(?:" + n.rfc3986.IPv6address + e + n.rfc3986.IPvFuture + ")\\]",
        v = "(?:" + y + e + n.rfc3986.IPv4address + e + "[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=]{0,255})",
        m = "(?:[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=:]*@)?" + v + "(?::[0-9]*)?",
        b = o + "+",
        _ = "(?:\\/[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=:@]*)*",
        w = "\\/(?:" + b + _ + ")?";
      n.rfc3986.hierPart = "(?:(?:\\/\\/" + m + _ + ")" + e + w + e + "[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=:@]+(?:\\/[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=:@]*)*)", n.rfc3986.relativeRef = "(?:(?:\\/\\/" + m + _ + ")" + e + w + e + "[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=@]+(?:\\/[a-zA-Z0-9-\\._~%0-9A-Fa-f!\\$&'\\(\\)\\*\\+,;=:@]*)*" + e + ")", n.rfc3986.query = "[" + i + "\\/\\?]*(?=#|$)", n.rfc3986.fragment = "[" + i + "\\/\\?]*";
    }, n.generate(), e.exports = n.rfc3986;
  }, function (e, t) {}, function (e, t, r) {
    (function (e, n) {
      function i(e, r) {
        var n = {
          seen: [],
          stylize: a
        };
        return arguments.length >= 3 && (n.depth = arguments[2]), arguments.length >= 4 && (n.colors = arguments[3]), g(r) ? n.showHidden = r : r && t._extend(n, r), w(n.showHidden) && (n.showHidden = !1), w(n.depth) && (n.depth = 2), w(n.colors) && (n.colors = !1), w(n.customInspect) && (n.customInspect = !0), n.colors && (n.stylize = o), u(n, e, n.depth);
      }
      function o(e, t) {
        var r = i.styles[t];
        return r ? "[" + i.colors[r][0] + "m" + e + "[" + i.colors[r][1] + "m" : e;
      }
      function a(e, t) {
        return e;
      }
      function s(e) {
        var t = {};
        return e.forEach(function (e, r) {
          t[e] = !0;
        }), t;
      }
      function u(e, r, n) {
        if (e.customInspect && r && S(r.inspect) && r.inspect !== t.inspect && (!r.constructor || r.constructor.prototype !== r)) {
          var i = r.inspect(n, e);
          return b(i) || (i = u(e, i, n)), i;
        }
        var o = c(e, r);
        if (o) return o;
        var a = Object.keys(r),
          g = s(a);
        if (e.showHidden && (a = Object.getOwnPropertyNames(r)), j(r) && (a.indexOf("message") >= 0 || a.indexOf("description") >= 0)) return l(r);
        if (0 === a.length) {
          if (S(r)) {
            var y = r.name ? ": " + r.name : "";
            return e.stylize("[Function" + y + "]", "special");
          }
          if (E(r)) return e.stylize(RegExp.prototype.toString.call(r), "regexp");
          if (A(r)) return e.stylize(Date.prototype.toString.call(r), "date");
          if (j(r)) return l(r);
        }
        var v = "",
          m = !1,
          _ = ["{", "}"];
        if (d(r) && (m = !0, _ = ["[", "]"]), S(r)) {
          v = " [Function" + (r.name ? ": " + r.name : "") + "]";
        }
        if (E(r) && (v = " " + RegExp.prototype.toString.call(r)), A(r) && (v = " " + Date.prototype.toUTCString.call(r)), j(r) && (v = " " + l(r)), 0 === a.length && (!m || 0 == r.length)) return _[0] + v + _[1];
        if (n < 0) return E(r) ? e.stylize(RegExp.prototype.toString.call(r), "regexp") : e.stylize("[Object]", "special");
        e.seen.push(r);
        var w;
        return w = m ? f(e, r, n, g, a) : a.map(function (t) {
          return p(e, r, n, g, t, m);
        }), e.seen.pop(), h(w, v, _);
      }
      function c(e, t) {
        if (w(t)) return e.stylize("undefined", "undefined");
        if (b(t)) {
          var r = "'" + JSON.stringify(t).replace(/^"|"$/g, "").replace(/'/g, "\\'").replace(/\\"/g, '"') + "'";
          return e.stylize(r, "string");
        }
        return m(t) ? e.stylize("" + t, "number") : g(t) ? e.stylize("" + t, "boolean") : y(t) ? e.stylize("null", "null") : void 0;
      }
      function l(e) {
        return "[" + Error.prototype.toString.call(e) + "]";
      }
      function f(e, t, r, n, i) {
        for (var o = [], a = 0, s = t.length; a < s; ++a) {
          R(t, String(a)) ? o.push(p(e, t, r, n, String(a), !0)) : o.push("");
        }
        return i.forEach(function (i) {
          i.match(/^\d+$/) || o.push(p(e, t, r, n, i, !0));
        }), o;
      }
      function p(e, t, r, n, i, o) {
        var a, s, c;
        if (c = Object.getOwnPropertyDescriptor(t, i) || {
          value: t[i]
        }, c.get ? s = c.set ? e.stylize("[Getter/Setter]", "special") : e.stylize("[Getter]", "special") : c.set && (s = e.stylize("[Setter]", "special")), R(n, i) || (a = "[" + i + "]"), s || (e.seen.indexOf(c.value) < 0 ? (s = y(r) ? u(e, c.value, null) : u(e, c.value, r - 1), s.indexOf("\n") > -1 && (s = o ? s.split("\n").map(function (e) {
          return "  " + e;
        }).join("\n").substr(2) : "\n" + s.split("\n").map(function (e) {
          return "   " + e;
        }).join("\n"))) : s = e.stylize("[Circular]", "special")), w(a)) {
          if (o && i.match(/^\d+$/)) return s;
          a = JSON.stringify("" + i), a.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/) ? (a = a.substr(1, a.length - 2), a = e.stylize(a, "name")) : (a = a.replace(/'/g, "\\'").replace(/\\"/g, '"').replace(/(^"|"$)/g, "'"), a = e.stylize(a, "string"));
        }
        return a + ": " + s;
      }
      function h(e, t, r) {
        var n = 0;
        return e.reduce(function (e, t) {
          return n++, t.indexOf("\n") >= 0 && n++, e + t.replace(/\u001b\[\d\d?m/g, "").length + 1;
        }, 0) > 60 ? r[0] + ("" === t ? "" : t + "\n ") + " " + e.join(",\n  ") + " " + r[1] : r[0] + t + " " + e.join(", ") + " " + r[1];
      }
      function d(e) {
        return Array.isArray(e);
      }
      function g(e) {
        return "boolean" == typeof e;
      }
      function y(e) {
        return null === e;
      }
      function v(e) {
        return null == e;
      }
      function m(e) {
        return "number" == typeof e;
      }
      function b(e) {
        return "string" == typeof e;
      }
      function _(e) {
        return "symbol" == typeof e;
      }
      function w(e) {
        return void 0 === e;
      }
      function E(e) {
        return x(e) && "[object RegExp]" === k(e);
      }
      function x(e) {
        return "object" == typeof e && null !== e;
      }
      function A(e) {
        return x(e) && "[object Date]" === k(e);
      }
      function j(e) {
        return x(e) && ("[object Error]" === k(e) || e instanceof Error);
      }
      function S(e) {
        return "function" == typeof e;
      }
      function O(e) {
        return null === e || "boolean" == typeof e || "number" == typeof e || "string" == typeof e || "symbol" == typeof e || void 0 === e;
      }
      function k(e) {
        return Object.prototype.toString.call(e);
      }
      function P(e) {
        return e < 10 ? "0" + e.toString(10) : e.toString(10);
      }
      function T() {
        var e = new Date(),
          t = [P(e.getHours()), P(e.getMinutes()), P(e.getSeconds())].join(":");
        return [e.getDate(), L[e.getMonth()], t].join(" ");
      }
      function R(e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }
      var C = /%[sdj%]/g;
      t.format = function (e) {
        if (!b(e)) {
          for (var t = [], r = 0; r < arguments.length; r++) {
            t.push(i(arguments[r]));
          }
          return t.join(" ");
        }
        for (var r = 1, n = arguments, o = n.length, a = String(e).replace(C, function (e) {
            if ("%%" === e) return "%";
            if (r >= o) return e;
            switch (e) {
              case "%s":
                return String(n[r++]);
              case "%d":
                return Number(n[r++]);
              case "%j":
                try {
                  return JSON.stringify(n[r++]);
                } catch (e) {
                  return "[Circular]";
                }
              default:
                return e;
            }
          }), s = n[r]; r < o; s = n[++r]) {
          y(s) || !x(s) ? a += " " + s : a += " " + i(s);
        }
        return a;
      }, t.deprecate = function (r, i) {
        function o() {
          if (!a) {
            if (n.throwDeprecation) throw new Error(i);
            n.traceDeprecation ? console.trace(i) : console.error(i), a = !0;
          }
          return r.apply(this, arguments);
        }
        if (w(e.process)) return function () {
          return t.deprecate(r, i).apply(this, arguments);
        };
        if (!0 === n.noDeprecation) return r;
        var a = !1;
        return o;
      };
      var D,
        I = {};
      t.debuglog = function (e) {
        if (w(D) && (D = n.env.NODE_DEBUG || ""), e = e.toUpperCase(), !I[e]) if (new RegExp("\\b" + e + "\\b", "i").test(D)) {
          var r = n.pid;
          I[e] = function () {
            var n = t.format.apply(t, arguments);
            console.error("%s %d: %s", e, r, n);
          };
        } else I[e] = function () {};
        return I[e];
      }, t.inspect = i, i.colors = {
        bold: [1, 22],
        italic: [3, 23],
        underline: [4, 24],
        inverse: [7, 27],
        white: [37, 39],
        grey: [90, 39],
        black: [30, 39],
        blue: [34, 39],
        cyan: [36, 39],
        green: [32, 39],
        magenta: [35, 39],
        red: [31, 39],
        yellow: [33, 39]
      }, i.styles = {
        special: "cyan",
        number: "yellow",
        boolean: "yellow",
        undefined: "grey",
        null: "bold",
        string: "green",
        date: "magenta",
        regexp: "red"
      }, t.isArray = d, t.isBoolean = g, t.isNull = y, t.isNullOrUndefined = v, t.isNumber = m, t.isString = b, t.isSymbol = _, t.isUndefined = w, t.isRegExp = E, t.isObject = x, t.isDate = A, t.isError = j, t.isFunction = S, t.isPrimitive = O, t.isBuffer = r(40);
      var L = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      t.log = function () {
        console.log("%s - %s", T(), t.format.apply(t, arguments));
      }, t.inherits = r(39), t._extend = function (e, t) {
        if (!t || !x(t)) return e;
        for (var r = Object.keys(t), n = r.length; n--;) {
          e[r[n]] = t[r[n]];
        }
        return e;
      };
    }).call(t, r(5), r(7));
  }, function (e, t, r) {
    "use strict";

    (function (t) {
      function n(e, t) {
        if (e === t) return 0;
        for (var r = e.length, n = t.length, i = 0, o = Math.min(r, n); i < o; ++i) {
          if (e[i] !== t[i]) {
            r = e[i], n = t[i];
            break;
          }
        }
        return r < n ? -1 : n < r ? 1 : 0;
      }
      function i(e) {
        return t.Buffer && "function" == typeof t.Buffer.isBuffer ? t.Buffer.isBuffer(e) : !(null == e || !e._isBuffer);
      }
      function o(e) {
        return Object.prototype.toString.call(e);
      }
      function a(e) {
        return !i(e) && "function" == typeof t.ArrayBuffer && ("function" == typeof ArrayBuffer.isView ? ArrayBuffer.isView(e) : !!e && (e instanceof DataView || !!(e.buffer && e.buffer instanceof ArrayBuffer)));
      }
      function s(e) {
        if (_.isFunction(e)) {
          if (x) return e.name;
          var t = e.toString(),
            r = t.match(j);
          return r && r[1];
        }
      }
      function u(e, t) {
        return "string" == typeof e ? e.length < t ? e : e.slice(0, t) : e;
      }
      function c(e) {
        if (x || !_.isFunction(e)) return _.inspect(e);
        var t = s(e);
        return "[Function" + (t ? ": " + t : "") + "]";
      }
      function l(e) {
        return u(c(e.actual), 128) + " " + e.operator + " " + u(c(e.expected), 128);
      }
      function f(e, t, r, n, i) {
        throw new A.AssertionError({
          message: r,
          actual: e,
          expected: t,
          operator: n,
          stackStartFunction: i
        });
      }
      function p(e, t) {
        e || f(e, !0, t, "==", A.ok);
      }
      function h(e, t, r, s) {
        if (e === t) return !0;
        if (i(e) && i(t)) return 0 === n(e, t);
        if (_.isDate(e) && _.isDate(t)) return e.getTime() === t.getTime();
        if (_.isRegExp(e) && _.isRegExp(t)) return e.source === t.source && e.global === t.global && e.multiline === t.multiline && e.lastIndex === t.lastIndex && e.ignoreCase === t.ignoreCase;
        if (null !== e && "object" == typeof e || null !== t && "object" == typeof t) {
          if (a(e) && a(t) && o(e) === o(t) && !(e instanceof Float32Array || e instanceof Float64Array)) return 0 === n(new Uint8Array(e.buffer), new Uint8Array(t.buffer));
          if (i(e) !== i(t)) return !1;
          s = s || {
            actual: [],
            expected: []
          };
          var u = s.actual.indexOf(e);
          return -1 !== u && u === s.expected.indexOf(t) || (s.actual.push(e), s.expected.push(t), g(e, t, r, s));
        }
        return r ? e === t : e == t;
      }
      function d(e) {
        return "[object Arguments]" == Object.prototype.toString.call(e);
      }
      function g(e, t, r, n) {
        if (null === e || void 0 === e || null === t || void 0 === t) return !1;
        if (_.isPrimitive(e) || _.isPrimitive(t)) return e === t;
        if (r && Object.getPrototypeOf(e) !== Object.getPrototypeOf(t)) return !1;
        var i = d(e),
          o = d(t);
        if (i && !o || !i && o) return !1;
        if (i) return e = E.call(e), t = E.call(t), h(e, t, r);
        var a,
          s,
          u = S(e),
          c = S(t);
        if (u.length !== c.length) return !1;
        for (u.sort(), c.sort(), s = u.length - 1; s >= 0; s--) {
          if (u[s] !== c[s]) return !1;
        }
        for (s = u.length - 1; s >= 0; s--) {
          if (a = u[s], !h(e[a], t[a], r, n)) return !1;
        }
        return !0;
      }
      function y(e, t, r) {
        h(e, t, !0) && f(e, t, r, "notDeepStrictEqual", y);
      }
      function v(e, t) {
        if (!e || !t) return !1;
        if ("[object RegExp]" == Object.prototype.toString.call(t)) return t.test(e);
        try {
          if (e instanceof t) return !0;
        } catch (e) {}
        return !Error.isPrototypeOf(t) && !0 === t.call({}, e);
      }
      function m(e) {
        var t;
        try {
          e();
        } catch (e) {
          t = e;
        }
        return t;
      }
      function b(e, t, r, n) {
        var i;
        if ("function" != typeof t) throw new TypeError('"block" argument must be a function');
        "string" == typeof r && (n = r, r = null), i = m(t), n = (r && r.name ? " (" + r.name + ")." : ".") + (n ? " " + n : "."), e && !i && f(i, r, "Missing expected exception" + n);
        var o = "string" == typeof n,
          a = !e && _.isError(i),
          s = !e && i && !r;
        if ((a && o && v(i, r) || s) && f(i, r, "Got unwanted exception" + n), e && i && r && !v(i, r) || !e && i) throw i;
      }
      var _ = r(16),
        w = Object.prototype.hasOwnProperty,
        E = Array.prototype.slice,
        x = function () {
          return "foo" === function () {}.name;
        }(),
        A = e.exports = p,
        j = /\s*function\s+([^\(\s]*)\s*/;
      A.AssertionError = function (e) {
        this.name = "AssertionError", this.actual = e.actual, this.expected = e.expected, this.operator = e.operator, e.message ? (this.message = e.message, this.generatedMessage = !1) : (this.message = l(this), this.generatedMessage = !0);
        var t = e.stackStartFunction || f;
        if (Error.captureStackTrace) Error.captureStackTrace(this, t);else {
          var r = new Error();
          if (r.stack) {
            var n = r.stack,
              i = s(t),
              o = n.indexOf("\n" + i);
            if (o >= 0) {
              var a = n.indexOf("\n", o + 1);
              n = n.substring(a + 1);
            }
            this.stack = n;
          }
        }
      }, _.inherits(A.AssertionError, Error), A.fail = f, A.ok = p, A.equal = function (e, t, r) {
        e != t && f(e, t, r, "==", A.equal);
      }, A.notEqual = function (e, t, r) {
        e == t && f(e, t, r, "!=", A.notEqual);
      }, A.deepEqual = function (e, t, r) {
        h(e, t, !1) || f(e, t, r, "deepEqual", A.deepEqual);
      }, A.deepStrictEqual = function (e, t, r) {
        h(e, t, !0) || f(e, t, r, "deepStrictEqual", A.deepStrictEqual);
      }, A.notDeepEqual = function (e, t, r) {
        h(e, t, !1) && f(e, t, r, "notDeepEqual", A.notDeepEqual);
      }, A.notDeepStrictEqual = y, A.strictEqual = function (e, t, r) {
        e !== t && f(e, t, r, "===", A.strictEqual);
      }, A.notStrictEqual = function (e, t, r) {
        e === t && f(e, t, r, "!==", A.notStrictEqual);
      }, A.throws = function (e, t, r) {
        b(!0, e, t, r);
      }, A.doesNotThrow = function (e, t, r) {
        b(!1, e, t, r);
      }, A.ifError = function (e) {
        if (e) throw e;
      };
      var S = Object.keys || function (e) {
        var t = [];
        for (var r in e) {
          w.call(e, r) && t.push(r);
        }
        return t;
      };
    }).call(t, r(5));
  }, function (e, t, r) {
    "use strict";

    (function (e) {
      var r = {};
      t.escapeJavaScript = function (e) {
        if (!e) return "";
        for (var t = "", n = 0; n < e.length; ++n) {
          var i = e.charCodeAt(n);
          r.isSafe(i) ? t += e[n] : t += r.escapeJavaScriptChar(i);
        }
        return t;
      }, t.escapeHtml = function (e) {
        if (!e) return "";
        for (var t = "", n = 0; n < e.length; ++n) {
          var i = e.charCodeAt(n);
          r.isSafe(i) ? t += e[n] : t += r.escapeHtmlChar(i);
        }
        return t;
      }, t.escapeJson = function (e) {
        if (!e) return "";
        var t = void 0;
        return e.replace(/[<>&\u2028\u2029]/g, function (e) {
          return t = e.charCodeAt(0), 60 === t ? "\\u003c" : 62 === t ? "\\u003e" : 38 === t ? "\\u0026" : 8232 === t ? "\\u2028" : "\\u2029";
        });
      }, r.escapeJavaScriptChar = function (t) {
        if (t >= 256) return "\\u" + r.padLeft("" + t, 4);
        var n = e.from(String.fromCharCode(t), "ascii").toString("hex");
        return "\\x" + r.padLeft(n, 2);
      }, r.escapeHtmlChar = function (t) {
        var n = r.namedHtml[t];
        if (void 0 !== n) return n;
        if (t >= 256) return "&#" + t + ";";
        var i = e.from(String.fromCharCode(t), "ascii").toString("hex");
        return "&#x" + r.padLeft(i, 2) + ";";
      }, r.padLeft = function (e, t) {
        for (; e.length < t;) {
          e = "0" + e;
        }
        return e;
      }, r.isSafe = function (e) {
        return void 0 !== r.safeCharCodes[e];
      }, r.namedHtml = {
        38: "&amp;",
        60: "&lt;",
        62: "&gt;",
        34: "&quot;",
        160: "&nbsp;",
        162: "&cent;",
        163: "&pound;",
        164: "&curren;",
        169: "&copy;",
        174: "&reg;"
      }, r.safeCharCodes = function () {
        for (var e = {}, t = 32; t < 123; ++t) {
          (t >= 97 || t >= 65 && t <= 90 || t >= 48 && t <= 57 || 32 === t || 46 === t || 44 === t || 45 === t || 58 === t || 95 === t) && (e[t] = null);
        }
        return e;
      }();
    }).call(t, r(3).Buffer);
  }, function (e, t, r) {
    "use strict";

    (function (e) {
      var n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
          return typeof e;
        } : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
        },
        i = r(38),
        o = {
          hasOwn: Object.prototype.hasOwnProperty,
          indexOf: Array.prototype.indexOf,
          defaultThreshold: 16,
          maxIPv6Groups: 8,
          categories: {
            valid: 1,
            dnsWarn: 7,
            rfc5321: 15,
            cfws: 31,
            deprecated: 63,
            rfc5322: 127,
            error: 255
          },
          diagnoses: {
            valid: 0,
            rfc5321TLD: 9,
            rfc5321TLDNumeric: 10,
            rfc5321QuotedString: 11,
            rfc5321AddressLiteral: 12,
            cfwsComment: 17,
            cfwsFWS: 18,
            undesiredNonAscii: 25,
            deprecatedLocalPart: 33,
            deprecatedFWS: 34,
            deprecatedQTEXT: 35,
            deprecatedQP: 36,
            deprecatedComment: 37,
            deprecatedCTEXT: 38,
            deprecatedIPv6: 39,
            deprecatedCFWSNearAt: 49,
            rfc5322Domain: 65,
            rfc5322TooLong: 66,
            rfc5322LocalTooLong: 67,
            rfc5322DomainTooLong: 68,
            rfc5322LabelTooLong: 69,
            rfc5322DomainLiteral: 70,
            rfc5322DomainLiteralOBSDText: 71,
            rfc5322IPv6GroupCount: 72,
            rfc5322IPv62x2xColon: 73,
            rfc5322IPv6BadCharacter: 74,
            rfc5322IPv6MaxGroups: 75,
            rfc5322IPv6ColonStart: 76,
            rfc5322IPv6ColonEnd: 77,
            errExpectingDTEXT: 129,
            errNoLocalPart: 130,
            errNoDomain: 131,
            errConsecutiveDots: 132,
            errATEXTAfterCFWS: 133,
            errATEXTAfterQS: 134,
            errATEXTAfterDomainLiteral: 135,
            errExpectingQPair: 136,
            errExpectingATEXT: 137,
            errExpectingQTEXT: 138,
            errExpectingCTEXT: 139,
            errBackslashEnd: 140,
            errDotStart: 141,
            errDotEnd: 142,
            errDomainHyphenStart: 143,
            errDomainHyphenEnd: 144,
            errUnclosedQuotedString: 145,
            errUnclosedComment: 146,
            errUnclosedDomainLiteral: 147,
            errFWSCRLFx2: 148,
            errFWSCRLFEnd: 149,
            errCRNoLF: 150,
            errUnknownTLD: 160,
            errDomainTooShort: 161
          },
          components: {
            localpart: 0,
            domain: 1,
            literal: 2,
            contextComment: 3,
            contextFWS: 4,
            contextQuotedString: 5,
            contextQuotedPair: 6
          }
        };
      o.specials = function () {
        var e = '()<>[]:;@\\,."',
          t = new Array(256);
        t.fill(!1);
        for (var r = 0; r < e.length; ++r) {
          t[e.codePointAt(r)] = !0;
        }
        return function (e) {
          return t[e];
        };
      }(), o.c0Controls = function () {
        var e = new Array(256);
        e.fill(!1);
        for (var t = 0; t < 33; ++t) {
          e[t] = !0;
        }
        return function (t) {
          return e[t];
        };
      }(), o.c1Controls = function () {
        var e = new Array(256);
        e.fill(!1);
        for (var t = 127; t < 160; ++t) {
          e[t] = !0;
        }
        return function (t) {
          return e[t];
        };
      }(), o.regex = {
        ipV4: /\b(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/,
        ipV6: /^[a-fA-F\d]{0,4}$/
      }, o.normalizeSupportsNul = "\0" === "\0".normalize("NFC"), o.nulNormalize = function (e) {
        return e.split("\0").map(function (e) {
          return e.normalize("NFC");
        }).join("\0");
      }, o.normalize = function (e) {
        return !o.normalizeSupportsNul && e.indexOf("\0") >= 0 ? o.nulNormalize(e) : e.normalize("NFC");
      }, o.checkIpV6 = function (e) {
        return e.every(function (e) {
          return o.regex.ipV6.test(e);
        });
      }, o.validDomain = function (e, t) {
        return t.tldBlacklist ? Array.isArray(t.tldBlacklist) ? -1 === o.indexOf.call(t.tldBlacklist, e) : !o.hasOwn.call(t.tldBlacklist, e) : Array.isArray(t.tldWhitelist) ? -1 !== o.indexOf.call(t.tldWhitelist, e) : o.hasOwn.call(t.tldWhitelist, e);
      }, t.validate = o.validate = function (t, r, a) {
        r = r || {}, t = o.normalize(t), "function" == typeof r && (a = r, r = {}), "function" != typeof a && (a = null);
        var s = void 0,
          u = void 0;
        if ("number" == typeof r.errorLevel ? (s = !0, u = r.errorLevel) : (s = !!r.errorLevel, u = o.diagnoses.valid), r.tldWhitelist) if ("string" == typeof r.tldWhitelist) r.tldWhitelist = [r.tldWhitelist];else if ("object" !== n(r.tldWhitelist)) throw new TypeError("expected array or object tldWhitelist");
        if (r.tldBlacklist) if ("string" == typeof r.tldBlacklist) r.tldBlacklist = [r.tldBlacklist];else if ("object" !== n(r.tldBlacklist)) throw new TypeError("expected array or object tldBlacklist");
        if (r.minDomainAtoms && (r.minDomainAtoms !== (0 | +r.minDomainAtoms) || r.minDomainAtoms < 0)) throw new TypeError("expected positive integer minDomainAtoms");
        var c = o.diagnoses.valid,
          l = function l(e) {
            e > c && (c = e);
          };
        !(void 0 === r.allowUnicode || !!r.allowUnicode) && /[^\x00-\x7f]/.test(t) && l(o.diagnoses.undesiredNonAscii);
        for (var f = {
            now: o.components.localpart,
            prev: o.components.localpart,
            stack: [o.components.localpart]
          }, p = "", h = {
            local: "",
            domain: ""
          }, d = {
            locals: [""],
            domains: [""]
          }, g = 0, y = 0, v = 0, m = void 0, b = !1, _ = !1, w = t.length, E = void 0, x = 0; x < w; x += E.length) {
          switch (E = String.fromCodePoint(t.codePointAt(x)), f.now) {
            case o.components.localpart:
              switch (E) {
                case "(":
                  0 === y ? l(0 === g ? o.diagnoses.cfwsComment : o.diagnoses.deprecatedComment) : (l(o.diagnoses.cfwsComment), _ = !0), f.stack.push(f.now), f.now = o.components.contextComment;
                  break;
                case ".":
                  0 === y ? l(0 === g ? o.diagnoses.errDotStart : o.diagnoses.errConsecutiveDots) : (_ && l(o.diagnoses.deprecatedLocalPart), _ = !1, y = 0, ++g, h.local += E, d.locals[g] = "");
                  break;
                case '"':
                  0 === y ? (l(0 === g ? o.diagnoses.rfc5321QuotedString : o.diagnoses.deprecatedLocalPart), h.local += E, d.locals[g] += E, y += e.byteLength(E, "utf8"), _ = !0, f.stack.push(f.now), f.now = o.components.contextQuotedString) : l(o.diagnoses.errExpectingATEXT);
                  break;
                case "\r":
                  if (w === ++x || "\n" !== t[x]) {
                    l(o.diagnoses.errCRNoLF);
                    break;
                  }
                case " ":
                case "\t":
                  0 === y ? l(0 === g ? o.diagnoses.cfwsFWS : o.diagnoses.deprecatedFWS) : _ = !0, f.stack.push(f.now), f.now = o.components.contextFWS, p = E;
                  break;
                case "@":
                  if (1 !== f.stack.length) throw new Error("unexpected item on context stack");
                  0 === h.local.length ? l(o.diagnoses.errNoLocalPart) : 0 === y ? l(o.diagnoses.errDotEnd) : e.byteLength(h.local, "utf8") > 64 ? l(o.diagnoses.rfc5322LocalTooLong) : f.prev !== o.components.contextComment && f.prev !== o.components.contextFWS || l(o.diagnoses.deprecatedCFWSNearAt), f.now = o.components.domain, f.stack[0] = o.components.domain, g = 0, y = 0, _ = !1;
                  break;
                default:
                  if (_) switch (f.prev) {
                    case o.components.contextComment:
                    case o.components.contextFWS:
                      l(o.diagnoses.errATEXTAfterCFWS);
                      break;
                    case o.components.contextQuotedString:
                      l(o.diagnoses.errATEXTAfterQS);
                      break;
                    default:
                      throw new Error("more atext found where none is allowed, but unrecognized prev context: " + f.prev);
                  } else f.prev = f.now, m = E.codePointAt(0), (o.specials(m) || o.c0Controls(m) || o.c1Controls(m)) && l(o.diagnoses.errExpectingATEXT), h.local += E, d.locals[g] += E, y += e.byteLength(E, "utf8");
              }
              break;
            case o.components.domain:
              switch (E) {
                case "(":
                  0 === y ? l(0 === g ? o.diagnoses.deprecatedCFWSNearAt : o.diagnoses.deprecatedComment) : (_ = !0, l(o.diagnoses.cfwsComment)), f.stack.push(f.now), f.now = o.components.contextComment;
                  break;
                case ".":
                  var A = i.encode(d.domains[g]).length;
                  0 === y ? l(0 === g ? o.diagnoses.errDotStart : o.diagnoses.errConsecutiveDots) : b ? l(o.diagnoses.errDomainHyphenEnd) : A > 63 && l(o.diagnoses.rfc5322LabelTooLong), _ = !1, y = 0, ++g, d.domains[g] = "", h.domain += E;
                  break;
                case "[":
                  0 === h.domain.length ? (_ = !0, y += e.byteLength(E, "utf8"), f.stack.push(f.now), f.now = o.components.literal, h.domain += E, d.domains[g] += E, h.literal = "") : l(o.diagnoses.errExpectingATEXT);
                  break;
                case "\r":
                  if (w === ++x || "\n" !== t[x]) {
                    l(o.diagnoses.errCRNoLF);
                    break;
                  }
                case " ":
                case "\t":
                  0 === y ? l(0 === g ? o.diagnoses.deprecatedCFWSNearAt : o.diagnoses.deprecatedFWS) : (l(o.diagnoses.cfwsFWS), _ = !0), f.stack.push(f.now), f.now = o.components.contextFWS, p = E;
                  break;
                default:
                  if (_) switch (f.prev) {
                    case o.components.contextComment:
                    case o.components.contextFWS:
                      l(o.diagnoses.errATEXTAfterCFWS);
                      break;
                    case o.components.literal:
                      l(o.diagnoses.errATEXTAfterDomainLiteral);
                      break;
                    default:
                      throw new Error("more atext found where none is allowed, but unrecognized prev context: " + f.prev);
                  }
                  m = E.codePointAt(0), b = !1, o.specials(m) || o.c0Controls(m) || o.c1Controls(m) ? l(o.diagnoses.errExpectingATEXT) : "-" === E ? (0 === y && l(o.diagnoses.errDomainHyphenStart), b = !0) : (m < 48 || m > 122 && m < 192 || m > 57 && m < 65 || m > 90 && m < 97) && l(o.diagnoses.rfc5322Domain), h.domain += E, d.domains[g] += E, y += e.byteLength(E, "utf8");
              }
              break;
            case o.components.literal:
              switch (E) {
                case "]":
                  if (c < o.categories.deprecated) {
                    var j = -1,
                      S = h.literal,
                      O = o.regex.ipV4.exec(S);
                    if (O && 0 !== (j = O.index) && (S = S.slice(0, j) + "0:0"), 0 === j) l(o.diagnoses.rfc5321AddressLiteral);else if ("ipv6:" !== S.slice(0, 5).toLowerCase()) l(o.diagnoses.rfc5322DomainLiteral);else {
                      var k = S.slice(5),
                        P = o.maxIPv6Groups,
                        T = k.split(":");
                      j = k.indexOf("::"), ~j ? j !== k.lastIndexOf("::") ? l(o.diagnoses.rfc5322IPv62x2xColon) : (0 !== j && j !== k.length - 2 || ++P, T.length > P ? l(o.diagnoses.rfc5322IPv6MaxGroups) : T.length === P && l(o.diagnoses.deprecatedIPv6)) : T.length !== P && l(o.diagnoses.rfc5322IPv6GroupCount), l(":" === k[0] && ":" !== k[1] ? o.diagnoses.rfc5322IPv6ColonStart : ":" === k[k.length - 1] && ":" !== k[k.length - 2] ? o.diagnoses.rfc5322IPv6ColonEnd : o.checkIpV6(T) ? o.diagnoses.rfc5321AddressLiteral : o.diagnoses.rfc5322IPv6BadCharacter);
                    }
                  } else l(o.diagnoses.rfc5322DomainLiteral);
                  h.domain += E, d.domains[g] += E, y += e.byteLength(E, "utf8"), f.prev = f.now, f.now = f.stack.pop();
                  break;
                case "\\":
                  l(o.diagnoses.rfc5322DomainLiteralOBSDText), f.stack.push(f.now), f.now = o.components.contextQuotedPair;
                  break;
                case "\r":
                  if (w === ++x || "\n" !== t[x]) {
                    l(o.diagnoses.errCRNoLF);
                    break;
                  }
                case " ":
                case "\t":
                  l(o.diagnoses.cfwsFWS), f.stack.push(f.now), f.now = o.components.contextFWS, p = E;
                  break;
                default:
                  if (127 !== (m = E.codePointAt(0)) && o.c1Controls(m) || 0 === m || "[" === E) {
                    l(o.diagnoses.errExpectingDTEXT);
                    break;
                  }
                  (o.c0Controls(m) || 127 === m) && l(o.diagnoses.rfc5322DomainLiteralOBSDText), h.literal += E, h.domain += E, d.domains[g] += E, y += e.byteLength(E, "utf8");
              }
              break;
            case o.components.contextQuotedString:
              switch (E) {
                case "\\":
                  f.stack.push(f.now), f.now = o.components.contextQuotedPair;
                  break;
                case "\r":
                  if (w === ++x || "\n" !== t[x]) {
                    l(o.diagnoses.errCRNoLF);
                    break;
                  }
                case "\t":
                  h.local += " ", d.locals[g] += " ", y += e.byteLength(E, "utf8"), l(o.diagnoses.cfwsFWS), f.stack.push(f.now), f.now = o.components.contextFWS, p = E;
                  break;
                case '"':
                  h.local += E, d.locals[g] += E, y += e.byteLength(E, "utf8"), f.prev = f.now, f.now = f.stack.pop();
                  break;
                default:
                  m = E.codePointAt(0), 127 !== m && o.c1Controls(m) || 0 === m || 10 === m ? l(o.diagnoses.errExpectingQTEXT) : (o.c0Controls(m) || 127 === m) && l(o.diagnoses.deprecatedQTEXT), h.local += E, d.locals[g] += E, y += e.byteLength(E, "utf8");
              }
              break;
            case o.components.contextQuotedPair:
              m = E.codePointAt(0), 127 !== m && o.c1Controls(m) ? l(o.diagnoses.errExpectingQPair) : (m < 31 && 9 !== m || 127 === m) && l(o.diagnoses.deprecatedQP), f.prev = f.now, f.now = f.stack.pop();
              var R = "\\" + E;
              switch (f.now) {
                case o.components.contextComment:
                  break;
                case o.components.contextQuotedString:
                  h.local += R, d.locals[g] += R, y += 2;
                  break;
                case o.components.literal:
                  h.domain += R, d.domains[g] += R, y += 2;
                  break;
                default:
                  throw new Error("quoted pair logic invoked in an invalid context: " + f.now);
              }
              break;
            case o.components.contextComment:
              switch (E) {
                case "(":
                  f.stack.push(f.now), f.now = o.components.contextComment;
                  break;
                case ")":
                  f.prev = f.now, f.now = f.stack.pop();
                  break;
                case "\\":
                  f.stack.push(f.now), f.now = o.components.contextQuotedPair;
                  break;
                case "\r":
                  if (w === ++x || "\n" !== t[x]) {
                    l(o.diagnoses.errCRNoLF);
                    break;
                  }
                case " ":
                case "\t":
                  l(o.diagnoses.cfwsFWS), f.stack.push(f.now), f.now = o.components.contextFWS, p = E;
                  break;
                default:
                  if (0 === (m = E.codePointAt(0)) || 10 === m || 127 !== m && o.c1Controls(m)) {
                    l(o.diagnoses.errExpectingCTEXT);
                    break;
                  }
                  (o.c0Controls(m) || 127 === m) && l(o.diagnoses.deprecatedCTEXT);
              }
              break;
            case o.components.contextFWS:
              if ("\r" === p) {
                if ("\r" === E) {
                  l(o.diagnoses.errFWSCRLFx2);
                  break;
                }
                ++v > 1 ? l(o.diagnoses.deprecatedFWS) : v = 1;
              }
              switch (E) {
                case "\r":
                  w !== ++x && "\n" === t[x] || l(o.diagnoses.errCRNoLF);
                  break;
                case " ":
                case "\t":
                  break;
                default:
                  "\r" === p && l(o.diagnoses.errFWSCRLFEnd), v = 0, f.prev = f.now, f.now = f.stack.pop(), --x;
              }
              p = E;
              break;
            default:
              throw new Error("unknown context: " + f.now);
          }
          if (c > o.categories.rfc5322) break;
        }
        if (c < o.categories.rfc5322) {
          var C = i.encode(h.domain).length;
          if (f.now === o.components.contextQuotedString) l(o.diagnoses.errUnclosedQuotedString);else if (f.now === o.components.contextQuotedPair) l(o.diagnoses.errBackslashEnd);else if (f.now === o.components.contextComment) l(o.diagnoses.errUnclosedComment);else if (f.now === o.components.literal) l(o.diagnoses.errUnclosedDomainLiteral);else if ("\r" === E) l(o.diagnoses.errFWSCRLFEnd);else if (0 === h.domain.length) l(o.diagnoses.errNoDomain);else if (0 === y) l(o.diagnoses.errDotEnd);else if (b) l(o.diagnoses.errDomainHyphenEnd);else if (C > 255) l(o.diagnoses.rfc5322DomainTooLong);else if (e.byteLength(h.local, "utf8") + C + 1 > 254) l(o.diagnoses.rfc5322TooLong);else if (y > 63) l(o.diagnoses.rfc5322LabelTooLong);else if (r.minDomainAtoms && d.domains.length < r.minDomainAtoms) l(o.diagnoses.errDomainTooShort);else if (r.tldWhitelist || r.tldBlacklist) {
            var D = d.domains[g];
            o.validDomain(D, r) || l(o.diagnoses.errUnknownTLD);
          }
        }
        if (c < o.categories.dnsWarn) {
          d.domains[g].codePointAt(0) <= 57 && l(o.diagnoses.rfc5321TLDNumeric);
        }
        c < u && (c = o.diagnoses.valid);
        var I = s ? c : c < o.defaultThreshold;
        return a && a(I), I;
      }, t.diagnoses = o.validate.diagnoses = function () {
        for (var e = {}, t = Object.keys(o.diagnoses), r = 0; r < t.length; ++r) {
          var n = t[r];
          e[n] = o.diagnoses[n];
        }
        return e;
      }(), t.normalize = o.normalize;
    }).call(t, r(3).Buffer);
  }, function (e, t, r) {
    "use strict";

    t.errors = {
      root: "value",
      key: '"{{!label}}" ',
      messages: {
        wrapArrays: !0
      },
      any: {
        unknown: "is not allowed",
        invalid: "contains an invalid value",
        empty: "is not allowed to be empty",
        required: "is required",
        allowOnly: "must be one of {{valids}}",
        default: "threw an error when running default method"
      },
      alternatives: {
        base: "not matching any of the allowed alternatives",
        child: null
      },
      array: {
        base: "must be an array",
        includes: "at position {{pos}} does not match any of the allowed types",
        includesSingle: 'single value of "{{!label}}" does not match any of the allowed types',
        includesOne: "at position {{pos}} fails because {{reason}}",
        includesOneSingle: 'single value of "{{!label}}" fails because {{reason}}',
        includesRequiredUnknowns: "does not contain {{unknownMisses}} required value(s)",
        includesRequiredKnowns: "does not contain {{knownMisses}}",
        includesRequiredBoth: "does not contain {{knownMisses}} and {{unknownMisses}} other required value(s)",
        excludes: "at position {{pos}} contains an excluded value",
        excludesSingle: 'single value of "{{!label}}" contains an excluded value',
        min: "must contain at least {{limit}} items",
        max: "must contain less than or equal to {{limit}} items",
        length: "must contain {{limit}} items",
        ordered: "at position {{pos}} fails because {{reason}}",
        orderedLength: "at position {{pos}} fails because array must contain at most {{limit}} items",
        ref: 'references "{{ref}}" which is not a positive integer',
        sparse: "must not be a sparse array",
        unique: "position {{pos}} contains a duplicate value"
      },
      boolean: {
        base: "must be a boolean"
      },
      binary: {
        base: "must be a buffer or a string",
        min: "must be at least {{limit}} bytes",
        max: "must be less than or equal to {{limit}} bytes",
        length: "must be {{limit}} bytes"
      },
      date: {
        base: "must be a number of milliseconds or valid date string",
        format: "must be a string with one of the following formats {{format}}",
        strict: "must be a valid date",
        min: 'must be larger than or equal to "{{limit}}"',
        max: 'must be less than or equal to "{{limit}}"',
        less: 'must be less than "{{limit}}"',
        greater: 'must be greater than "{{limit}}"',
        isoDate: "must be a valid ISO 8601 date",
        timestamp: {
          javascript: "must be a valid timestamp or number of milliseconds",
          unix: "must be a valid timestamp or number of seconds"
        },
        ref: 'references "{{ref}}" which is not a date'
      },
      function: {
        base: "must be a Function",
        arity: "must have an arity of {{n}}",
        minArity: "must have an arity greater or equal to {{n}}",
        maxArity: "must have an arity lesser or equal to {{n}}",
        ref: "must be a Joi reference",
        class: "must be a class"
      },
      lazy: {
        base: "!!schema error: lazy schema must be set",
        schema: "!!schema error: lazy schema function must return a schema"
      },
      object: {
        base: "must be an object",
        child: '!!child "{{!child}}" fails because {{reason}}',
        min: "must have at least {{limit}} children",
        max: "must have less than or equal to {{limit}} children",
        length: "must have {{limit}} children",
        allowUnknown: '!!"{{!child}}" is not allowed',
        with: '!!"{{mainWithLabel}}" missing required peer "{{peerWithLabel}}"',
        without: '!!"{{mainWithLabel}}" conflict with forbidden peer "{{peerWithLabel}}"',
        missing: "must contain at least one of {{peersWithLabels}}",
        xor: "contains a conflict between exclusive peers {{peersWithLabels}}",
        or: "must contain at least one of {{peersWithLabels}}",
        and: "contains {{presentWithLabels}} without its required peers {{missingWithLabels}}",
        nand: '!!"{{mainWithLabel}}" must not exist simultaneously with {{peersWithLabels}}',
        assert: '!!"{{ref}}" validation failed because "{{ref}}" failed to {{message}}',
        rename: {
          multiple: 'cannot rename child "{{from}}" because multiple renames are disabled and another key was already renamed to "{{to}}"',
          override: 'cannot rename child "{{from}}" because override is disabled and target "{{to}}" exists',
          regex: {
            multiple: 'cannot rename children {{from}} because multiple renames are disabled and another key was already renamed to "{{to}}"',
            override: 'cannot rename children {{from}} because override is disabled and target "{{to}}" exists'
          }
        },
        type: 'must be an instance of "{{type}}"',
        schema: "must be a Joi instance"
      },
      number: {
        base: "must be a number",
        min: "must be larger than or equal to {{limit}}",
        max: "must be less than or equal to {{limit}}",
        less: "must be less than {{limit}}",
        greater: "must be greater than {{limit}}",
        float: "must be a float or double",
        integer: "must be an integer",
        negative: "must be a negative number",
        positive: "must be a positive number",
        precision: "must have no more than {{limit}} decimal places",
        ref: 'references "{{ref}}" which is not a number',
        multiple: "must be a multiple of {{multiple}}",
        port: "must be a valid port"
      },
      string: {
        base: "must be a string",
        min: "length must be at least {{limit}} characters long",
        max: "length must be less than or equal to {{limit}} characters long",
        length: "length must be {{limit}} characters long",
        alphanum: "must only contain alpha-numeric characters",
        token: "must only contain alpha-numeric and underscore characters",
        regex: {
          base: 'with value "{{!value}}" fails to match the required pattern: {{pattern}}',
          name: 'with value "{{!value}}" fails to match the {{name}} pattern',
          invert: {
            base: 'with value "{{!value}}" matches the inverted pattern: {{pattern}}',
            name: 'with value "{{!value}}" matches the inverted {{name}} pattern'
          }
        },
        email: "must be a valid email",
        uri: "must be a valid uri",
        uriRelativeOnly: "must be a valid relative uri",
        uriCustomScheme: "must be a valid uri with a scheme matching the {{scheme}} pattern",
        isoDate: "must be a valid ISO 8601 date",
        guid: "must be a valid GUID",
        hex: "must only contain hexadecimal characters",
        hexAlign: "hex decoded representation must be byte aligned",
        base64: "must be a valid base64 string",
        hostname: "must be a valid hostname",
        normalize: "must be unicode normalized in the {{form}} form",
        lowercase: "must only contain lowercase characters",
        uppercase: "must only contain uppercase characters",
        trim: "must not have leading or trailing whitespace",
        creditCard: "must be a credit card",
        ref: 'references "{{ref}}" which is not a number',
        ip: "must be a valid ip address with a {{cidr}} CIDR",
        ipVersion: "must be a valid ip address of one of the following versions {{version}} with a {{cidr}} CIDR"
      }
    };
  }, function (e, t, r) {
    "use strict";

    var n = r(8);
    t.options = n.object({
      abortEarly: n.boolean(),
      convert: n.boolean(),
      allowUnknown: n.boolean(),
      skipFunctions: n.boolean(),
      stripUnknown: [n.boolean(), n.object({
        arrays: n.boolean(),
        objects: n.boolean()
      }).or("arrays", "objects")],
      language: n.object(),
      presence: n.string().only("required", "optional", "forbidden", "ignore"),
      raw: n.boolean(),
      context: n.object(),
      strip: n.boolean(),
      noDefaults: n.boolean(),
      escapeHtml: n.boolean()
    }).strict();
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
        return typeof e;
      } : function (e) {
        return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
      },
      u = r(2),
      c = r(4),
      l = r(1),
      f = r(0),
      p = {};
    p.fastSplice = function (e, t) {
      for (var r = t; r < e.length;) {
        e[r++] = e[r];
      }
      --e.length;
    }, p.Array = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "array", r._inner.items = [], r._inner.ordereds = [], r._inner.inclusions = [], r._inner.exclusions = [], r._inner.requireds = [], r._flags.sparse = !1, r;
      }
      return a(t, e), t.prototype._base = function (e, t, r) {
        var n = {
          value: e
        };
        "string" == typeof e && r.convert && p.safeParse(e, n);
        var i = Array.isArray(n.value),
          o = i;
        if (r.convert && this._flags.single && !i && (n.value = [n.value], i = !0), !i) return n.errors = this.createError("array.base", null, t, r), n;
        if ((this._inner.inclusions.length || this._inner.exclusions.length || this._inner.requireds.length || this._inner.ordereds.length || !this._flags.sparse) && (o && (n.value = n.value.slice(0)), n.errors = this._checkItems.call(this, n.value, o, t, r), n.errors && o && r.convert && this._flags.single)) {
          var a = n.errors;
          n.value = [n.value], n.errors = this._checkItems.call(this, n.value, o, t, r), n.errors && (n.errors = a, n.value = n.value[0]);
        }
        return n;
      }, t.prototype._checkItems = function (e, t, r, n) {
        for (var i = [], o = void 0, a = this._inner.requireds.slice(), s = this._inner.ordereds.slice(), u = this._inner.inclusions.concat(a), c = e.length, l = 0; l < c; ++l) {
          o = !1;
          var f = e[l],
            h = !1,
            d = t ? l : r.key,
            g = t ? r.path.concat(l) : r.path,
            y = {
              key: d,
              path: g,
              parent: r.parent,
              reference: r.reference
            },
            v = void 0;
          if (this._flags.sparse || void 0 !== f) {
            for (var m = 0; m < this._inner.exclusions.length; ++m) {
              if (v = this._inner.exclusions[m]._validate(f, y, {}), !v.errors) {
                if (i.push(this.createError(t ? "array.excludes" : "array.excludesSingle", {
                  pos: l,
                  value: f
                }, {
                  key: r.key,
                  path: y.path
                }, n)), o = !0, n.abortEarly) return i;
                s.shift();
                break;
              }
            }
            if (!o) {
              if (this._inner.ordereds.length) {
                if (s.length > 0) {
                  var b = s.shift();
                  if (v = b._validate(f, y, n), v.errors) {
                    if (i.push(this.createError("array.ordered", {
                      pos: l,
                      reason: v.errors,
                      value: f
                    }, {
                      key: r.key,
                      path: y.path
                    }, n)), n.abortEarly) return i;
                  } else if (b._flags.strip) p.fastSplice(e, l), --l, --c;else {
                    if (!this._flags.sparse && void 0 === v.value) {
                      if (i.push(this.createError("array.sparse", null, {
                        key: r.key,
                        path: y.path,
                        pos: l
                      }, n)), n.abortEarly) return i;
                      continue;
                    }
                    e[l] = v.value;
                  }
                  continue;
                }
                if (!this._inner.items.length) {
                  if (i.push(this.createError("array.orderedLength", {
                    pos: l,
                    limit: this._inner.ordereds.length
                  }, {
                    key: r.key,
                    path: y.path
                  }, n)), n.abortEarly) return i;
                  continue;
                }
              }
              for (var _ = [], w = a.length, E = 0; E < w; ++E) {
                if (v = _[E] = a[E]._validate(f, y, n), !v.errors) {
                  if (e[l] = v.value, h = !0, p.fastSplice(a, E), --E, --w, !this._flags.sparse && void 0 === v.value && (i.push(this.createError("array.sparse", null, {
                    key: r.key,
                    path: y.path,
                    pos: l
                  }, n)), n.abortEarly)) return i;
                  break;
                }
              }
              if (!h) {
                var x = !!n.stripUnknown && (!0 === n.stripUnknown || !!n.stripUnknown.arrays);
                w = u.length;
                for (var A = 0; A < w; ++A) {
                  var j = u[A],
                    S = a.indexOf(j);
                  if (-1 !== S) v = _[S];else if (v = j._validate(f, y, n), !v.errors) {
                    j._flags.strip ? (p.fastSplice(e, l), --l, --c) : this._flags.sparse || void 0 !== v.value ? e[l] = v.value : (i.push(this.createError("array.sparse", null, {
                      key: r.key,
                      path: y.path,
                      pos: l
                    }, n)), o = !0), h = !0;
                    break;
                  }
                  if (1 === w) {
                    if (x) {
                      p.fastSplice(e, l), --l, --c, h = !0;
                      break;
                    }
                    if (i.push(this.createError(t ? "array.includesOne" : "array.includesOneSingle", {
                      pos: l,
                      reason: v.errors,
                      value: f
                    }, {
                      key: r.key,
                      path: y.path
                    }, n)), o = !0, n.abortEarly) return i;
                    break;
                  }
                }
                if (!o && this._inner.inclusions.length && !h) {
                  if (x) {
                    p.fastSplice(e, l), --l, --c;
                    continue;
                  }
                  if (i.push(this.createError(t ? "array.includes" : "array.includesSingle", {
                    pos: l,
                    value: f
                  }, {
                    key: r.key,
                    path: y.path
                  }, n)), n.abortEarly) return i;
                }
              }
            }
          } else {
            if (i.push(this.createError("array.sparse", null, {
              key: r.key,
              path: y.path,
              pos: l
            }, n)), n.abortEarly) return i;
            s.shift();
          }
        }
        return a.length && this._fillMissedErrors.call(this, i, a, r, n), s.length && this._fillOrderedErrors.call(this, i, s, r, n), i.length ? i : null;
      }, t.prototype.describe = function () {
        var e = u.prototype.describe.call(this);
        if (this._inner.ordereds.length) {
          e.orderedItems = [];
          for (var t = 0; t < this._inner.ordereds.length; ++t) {
            e.orderedItems.push(this._inner.ordereds[t].describe());
          }
        }
        if (this._inner.items.length) {
          e.items = [];
          for (var r = 0; r < this._inner.items.length; ++r) {
            e.items.push(this._inner.items[r].describe());
          }
        }
        return e;
      }, t.prototype.items = function () {
        for (var e = this, t = this.clone(), r = arguments.length, n = Array(r), i = 0; i < r; i++) {
          n[i] = arguments[i];
        }
        return f.flatten(n).forEach(function (r, n) {
          try {
            r = c.schema(e._currentJoi, r);
          } catch (e) {
            throw e.hasOwnProperty("path") ? e.path = n + "." + e.path : e.path = n, e.message = e.message + "(" + e.path + ")", e;
          }
          t._inner.items.push(r), "required" === r._flags.presence ? t._inner.requireds.push(r) : "forbidden" === r._flags.presence ? t._inner.exclusions.push(r.optional()) : t._inner.inclusions.push(r);
        }), t;
      }, t.prototype.ordered = function () {
        for (var e = this, t = this.clone(), r = arguments.length, n = Array(r), i = 0; i < r; i++) {
          n[i] = arguments[i];
        }
        return f.flatten(n).forEach(function (r, n) {
          try {
            r = c.schema(e._currentJoi, r);
          } catch (e) {
            throw e.hasOwnProperty("path") ? e.path = n + "." + e.path : e.path = n, e.message = e.message + "(" + e.path + ")", e;
          }
          t._inner.ordereds.push(r);
        }), t;
      }, t.prototype.min = function (e) {
        var t = l.isRef(e);
        return f.assert(Number.isSafeInteger(e) && e >= 0 || t, "limit must be a positive integer or reference"), this._test("min", e, function (r, n, i) {
          var o = void 0;
          if (t) {
            if (o = e(n.reference || n.parent, i), !(Number.isSafeInteger(o) && o >= 0)) return this.createError("array.ref", {
              ref: e.key
            }, n, i);
          } else o = e;
          return r.length >= o ? r : this.createError("array.min", {
            limit: e,
            value: r
          }, n, i);
        });
      }, t.prototype.max = function (e) {
        var t = l.isRef(e);
        return f.assert(Number.isSafeInteger(e) && e >= 0 || t, "limit must be a positive integer or reference"), this._test("max", e, function (r, n, i) {
          var o = void 0;
          if (t) {
            if (o = e(n.reference || n.parent, i), !(Number.isSafeInteger(o) && o >= 0)) return this.createError("array.ref", {
              ref: e.key
            }, n, i);
          } else o = e;
          return r.length <= o ? r : this.createError("array.max", {
            limit: e,
            value: r
          }, n, i);
        });
      }, t.prototype.length = function (e) {
        var t = l.isRef(e);
        return f.assert(Number.isSafeInteger(e) && e >= 0 || t, "limit must be a positive integer or reference"), this._test("length", e, function (r, n, i) {
          var o = void 0;
          if (t) {
            if (o = e(n.reference || n.parent, i), !(Number.isSafeInteger(o) && o >= 0)) return this.createError("array.ref", {
              ref: e.key
            }, n, i);
          } else o = e;
          return r.length === o ? r : this.createError("array.length", {
            limit: e,
            value: r
          }, n, i);
        });
      }, t.prototype.unique = function (e) {
        f.assert(void 0 === e || "function" == typeof e || "string" == typeof e, "comparator must be a function or a string");
        var t = {};
        return "string" == typeof e ? t.path = e : "function" == typeof e && (t.comparator = e), this._test("unique", t, function (e, r, n) {
          for (var i = {
              string: Object.create(null),
              number: Object.create(null),
              undefined: Object.create(null),
              boolean: Object.create(null),
              object: new Map(),
              function: new Map(),
              custom: new Map()
            }, o = t.comparator || f.deepEqual, a = 0; a < e.length; ++a) {
            var u = t.path ? f.reach(e[a], t.path) : e[a],
              c = t.comparator ? i.custom : i[void 0 === u ? "undefined" : s(u)];
            if (c) if (c instanceof Map) {
              for (var l = c.entries(), p = void 0; !(p = l.next()).done;) {
                if (o(p.value[0], u)) {
                  var h = {
                      key: r.key,
                      path: r.path.concat(a),
                      parent: r.parent,
                      reference: r.reference
                    },
                    d = {
                      pos: a,
                      value: e[a],
                      dupePos: p.value[1],
                      dupeValue: e[p.value[1]]
                    };
                  return t.path && (d.path = t.path), this.createError("array.unique", d, h, n);
                }
              }
              c.set(u, a);
            } else {
              if (void 0 !== c[u]) {
                var g = {
                    key: r.key,
                    path: r.path.concat(a),
                    parent: r.parent,
                    reference: r.reference
                  },
                  y = {
                    pos: a,
                    value: e[a],
                    dupePos: c[u],
                    dupeValue: e[c[u]]
                  };
                return t.path && (y.path = t.path), this.createError("array.unique", y, g, n);
              }
              c[u] = a;
            }
          }
          return e;
        });
      }, t.prototype.sparse = function (e) {
        var t = void 0 === e || !!e;
        if (this._flags.sparse === t) return this;
        var r = this.clone();
        return r._flags.sparse = t, r;
      }, t.prototype.single = function (e) {
        var t = void 0 === e || !!e;
        if (this._flags.single === t) return this;
        var r = this.clone();
        return r._flags.single = t, r;
      }, t.prototype._fillMissedErrors = function (e, t, r, n) {
        for (var i = [], o = 0, a = 0; a < t.length; ++a) {
          var s = t[a]._getLabel();
          s ? i.push(s) : ++o;
        }
        i.length ? o ? e.push(this.createError("array.includesRequiredBoth", {
          knownMisses: i,
          unknownMisses: o
        }, {
          key: r.key,
          path: r.path
        }, n)) : e.push(this.createError("array.includesRequiredKnowns", {
          knownMisses: i
        }, {
          key: r.key,
          path: r.path
        }, n)) : e.push(this.createError("array.includesRequiredUnknowns", {
          unknownMisses: o
        }, {
          key: r.key,
          path: r.path
        }, n));
      }, t.prototype._fillOrderedErrors = function (e, t, r, n) {
        for (var i = [], o = 0; o < t.length; ++o) {
          "required" === f.reach(t[o], "_flags.presence") && i.push(t[o]);
        }
        i.length && this._fillMissedErrors.call(this, e, i, r, n);
      }, t;
    }(u), p.safeParse = function (e, t) {
      try {
        var r = JSON.parse(e);
        Array.isArray(r) && (t.value = r);
      } catch (e) {}
    }, e.exports = new p.Array();
  }, function (e, t, r) {
    "use strict";

    (function (t) {
      function n(e, t) {
        for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
          var i = r[n],
            o = Object.getOwnPropertyDescriptor(t, i);
          o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
        }
        return e;
      }
      function i(e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }
      function o(e, t) {
        if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !t || "object" != typeof t && "function" != typeof t ? e : t;
      }
      function a(e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
      }
      var s = r(2),
        u = r(0),
        c = {};
      c.Binary = function (e) {
        function r() {
          i(this, r);
          var t = o(this, e.call(this));
          return t._type = "binary", t;
        }
        return a(r, e), r.prototype._base = function (e, r, n) {
          var i = {
            value: e
          };
          if ("string" == typeof e && n.convert) try {
            i.value = t.from(e, this._flags.encoding);
          } catch (e) {}
          return i.errors = t.isBuffer(i.value) ? null : this.createError("binary.base", null, r, n), i;
        }, r.prototype.encoding = function (e) {
          if (u.assert(t.isEncoding(e), "Invalid encoding:", e), this._flags.encoding === e) return this;
          var r = this.clone();
          return r._flags.encoding = e, r;
        }, r.prototype.min = function (e) {
          return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("min", e, function (t, r, n) {
            return t.length >= e ? t : this.createError("binary.min", {
              limit: e,
              value: t
            }, r, n);
          });
        }, r.prototype.max = function (e) {
          return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("max", e, function (t, r, n) {
            return t.length <= e ? t : this.createError("binary.max", {
              limit: e,
              value: t
            }, r, n);
          });
        }, r.prototype.length = function (e) {
          return u.assert(Number.isSafeInteger(e) && e >= 0, "limit must be a positive integer"), this._test("length", e, function (t, r, n) {
            return t.length === e ? t : this.createError("binary.length", {
              limit: e,
              value: t
            }, r, n);
          });
        }, r;
      }(s), e.exports = new c.Binary();
    }).call(t, r(3).Buffer);
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = r(2),
      u = r(0),
      c = {
        Set: r(9)
      };
    c.Boolean = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "boolean", r._flags.insensitive = !0, r._inner.truthySet = new c.Set(), r._inner.falsySet = new c.Set(), r;
      }
      return a(t, e), t.prototype._base = function (e, t, r) {
        var n = {
          value: e
        };
        if ("string" == typeof e && r.convert) {
          var i = this._flags.insensitive ? e.toLowerCase() : e;
          n.value = "true" === i || "false" !== i && e;
        }
        return "boolean" != typeof n.value && (n.value = !!this._inner.truthySet.has(e, null, null, this._flags.insensitive) || !this._inner.falsySet.has(e, null, null, this._flags.insensitive) && e), n.errors = "boolean" == typeof n.value ? null : this.createError("boolean.base", null, t, r), n;
      }, t.prototype.truthy = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        var n = this.clone();
        t = u.flatten(t);
        for (var i = 0; i < t.length; ++i) {
          var o = t[i];
          u.assert(void 0 !== o, "Cannot call truthy with undefined"), n._inner.truthySet.add(o);
        }
        return n;
      }, t.prototype.falsy = function () {
        for (var e = arguments.length, t = Array(e), r = 0; r < e; r++) {
          t[r] = arguments[r];
        }
        var n = this.clone();
        t = u.flatten(t);
        for (var i = 0; i < t.length; ++i) {
          var o = t[i];
          u.assert(void 0 !== o, "Cannot call falsy with undefined"), n._inner.falsySet.add(o);
        }
        return n;
      }, t.prototype.insensitive = function (e) {
        var t = void 0 === e || !!e;
        if (this._flags.insensitive === t) return this;
        var r = this.clone();
        return r._flags.insensitive = t, r;
      }, t.prototype.describe = function () {
        var e = s.prototype.describe.call(this);
        return e.truthy = [!0].concat(this._inner.truthySet.values()), e.falsy = [!1].concat(this._inner.falsySet.values()), e;
      }, t;
    }(s), e.exports = new c.Boolean();
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = r(0),
      u = r(13),
      c = r(1),
      l = {};
    l.Func = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._flags.func = !0, r;
      }
      return a(t, e), t.prototype.arity = function (e) {
        return s.assert(Number.isSafeInteger(e) && e >= 0, "n must be a positive integer"), this._test("arity", e, function (t, r, n) {
          return t.length === e ? t : this.createError("function.arity", {
            n: e
          }, r, n);
        });
      }, t.prototype.minArity = function (e) {
        return s.assert(Number.isSafeInteger(e) && e > 0, "n must be a strict positive integer"), this._test("minArity", e, function (t, r, n) {
          return t.length >= e ? t : this.createError("function.minArity", {
            n: e
          }, r, n);
        });
      }, t.prototype.maxArity = function (e) {
        return s.assert(Number.isSafeInteger(e) && e >= 0, "n must be a positive integer"), this._test("maxArity", e, function (t, r, n) {
          return t.length <= e ? t : this.createError("function.maxArity", {
            n: e
          }, r, n);
        });
      }, t.prototype.ref = function () {
        return this._test("ref", null, function (e, t, r) {
          return c.isRef(e) ? e : this.createError("function.ref", null, t, r);
        });
      }, t.prototype.class = function () {
        return this._test("class", null, function (e, t, r) {
          return /^\s*class\s/.test(e.toString()) ? e : this.createError("function.class", null, t, r);
        });
      }, t;
    }(u.constructor), e.exports = new l.Func();
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = r(2),
      u = r(0),
      c = {};
    c.Lazy = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "lazy", r;
      }
      return a(t, e), t.prototype._base = function (e, t, r) {
        var n = {
            value: e
          },
          i = this._flags.lazy;
        if (!i) return n.errors = this.createError("lazy.base", null, t, r), n;
        var o = i();
        return o instanceof s ? o._validate(e, t, r) : (n.errors = this.createError("lazy.schema", null, t, r), n);
      }, t.prototype.set = function (e) {
        u.assert("function" == typeof e, "You must provide a function as first argument");
        var t = this.clone();
        return t._flags.lazy = e, t;
      }, t;
    }(s), e.exports = new c.Lazy();
  }, function (e, t, r) {
    "use strict";

    function n(e, t) {
      for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
        var i = r[n],
          o = Object.getOwnPropertyDescriptor(t, i);
        o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
      }
      return e;
    }
    function i(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }
    function o(e, t) {
      if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return !t || "object" != typeof t && "function" != typeof t ? e : t;
    }
    function a(e, t) {
      if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
      e.prototype = Object.create(t && t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
    }
    var s = r(2),
      u = r(1),
      c = r(0),
      l = {
        precisionRx: /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/
      };
    l.Number = function (e) {
      function t() {
        i(this, t);
        var r = o(this, e.call(this));
        return r._type = "number", r._invalids.add(1 / 0), r._invalids.add(-1 / 0), r;
      }
      return a(t, e), t.prototype._base = function (e, t, r) {
        var n = {
          errors: null,
          value: e
        };
        if ("string" == typeof e && r.convert) {
          var i = parseFloat(e);
          n.value = isNaN(i) || !isFinite(e) ? NaN : i;
        }
        var o = "number" == typeof n.value && !isNaN(n.value);
        if (r.convert && "precision" in this._flags && o) {
          var a = Math.pow(10, this._flags.precision);
          n.value = Math.round(n.value * a) / a;
        }
        return n.errors = o ? null : this.createError("number.base", null, t, r), n;
      }, t.prototype.multiple = function (e) {
        var t = u.isRef(e);
        return t || (c.assert("number" == typeof e && isFinite(e), "multiple must be a number"), c.assert(e > 0, "multiple must be greater than 0")), this._test("multiple", e, function (r, n, i) {
          var o = t ? e(n.reference || n.parent, i) : e;
          return !t || "number" == typeof o && isFinite(o) ? r % o == 0 ? r : this.createError("number.multiple", {
            multiple: e,
            value: r
          }, n, i) : this.createError("number.ref", {
            ref: e.key
          }, n, i);
        });
      }, t.prototype.integer = function () {
        return this._test("integer", void 0, function (e, t, r) {
          return Number.isSafeInteger(e) ? e : this.createError("number.integer", {
            value: e
          }, t, r);
        });
      }, t.prototype.negative = function () {
        return this._test("negative", void 0, function (e, t, r) {
          return e < 0 ? e : this.createError("number.negative", {
            value: e
          }, t, r);
        });
      }, t.prototype.positive = function () {
        return this._test("positive", void 0, function (e, t, r) {
          return e > 0 ? e : this.createError("number.positive", {
            value: e
          }, t, r);
        });
      }, t.prototype.precision = function (e) {
        c.assert(Number.isSafeInteger(e), "limit must be an integer"), c.assert(!("precision" in this._flags), "precision already set");
        var t = this._test("precision", e, function (t, r, n) {
          var i = t.toString().match(l.precisionRx);
          return Math.max((i[1] ? i[1].length : 0) - (i[2] ? parseInt(i[2], 10) : 0), 0) <= e ? t : this.createError("number.precision", {
            limit: e,
            value: t
          }, r, n);
        });
        return t._flags.precision = e, t;
      }, t.prototype.port = function () {
        return this._test("port", void 0, function (e, t, r) {
          return !Number.isSafeInteger(e) || e < 0 || e > 65535 ? this.createError("number.port", {
            value: e
          }, t, r) : e;
        });
      }, t;
    }(s), l.compare = function (e, t) {
      return function (r) {
        var n = u.isRef(r),
          i = "number" == typeof r && !isNaN(r);
        return c.assert(i || n, "limit must be a number or reference"), this._test(e, r, function (i, o, a) {
          var s = void 0;
          if (n) {
            if ("number" != typeof (s = r(o.reference || o.parent, a)) || isNaN(s)) return this.createError("number.ref", {
              ref: r.key
            }, o, a);
          } else s = r;
          return t(i, s) ? i : this.createError("number." + e, {
            limit: s,
            value: i
          }, o, a);
        });
      };
    }, l.Number.prototype.min = l.compare("min", function (e, t) {
      return e >= t;
    }), l.Number.prototype.max = l.compare("max", function (e, t) {
      return e <= t;
    }), l.Number.prototype.greater = l.compare("greater", function (e, t) {
      return e > t;
    }), l.Number.prototype.less = l.compare("less", function (e, t) {
      return e < t;
    }), e.exports = new l.Number();
  }, function (e, t, r) {
    "use strict";

    (function (t) {
      function n(e, t) {
        for (var r = Object.getOwnPropertyNames(t), n = 0; n < r.length; n++) {
          var i = r[n],
            o = Object.getOwnPropertyDescriptor(t, i);
          o && o.configurable && void 0 === e[i] && Object.defineProperty(e, i, o);
        }
        return e;
      }
      function i(e, t) {
        if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
      }
      function o(e, t) {
        if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        return !t || "object" != typeof t && "function" != typeof t ? e : t;
      }
      function a(e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function, not " + typeof t);
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: !1,
            writable: !0,
            configurable: !0
          }
        }), t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : n(e, t));
      }
      var s = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
          return typeof e;
        } : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
        },
        u = r(15),
        c = r(0),
        l = void 0,
        f = r(2),
        p = r(1),
        h = r(12),
        d = r(30),
        g = r(29),
        y = {
          uriRegex: d.createUriRegex(),
          ipRegex: g.createIpRegex(["ipv4", "ipv6", "ipvfuture"], "optional"),
          guidBrackets: {
            "{": "}",
            "[": "]",
            "(": ")",
            "": ""
          },
          guidVersions: {
            uuidv1: "1",
            uuidv2: "2",
            uuidv3: "3",
            uuidv4: "4",
            uuidv5: "5"
          },
          cidrPresences: ["required", "optional", "forbidden"],
          normalizationForms: ["NFC", "NFD", "NFKC", "NFKD"]
        };
      y.String = function (e) {
        function t() {
          i(this, t);
          var r = o(this, e.call(this));
          return r._type = "string", r._invalids.add(""), r;
        }
        return a(t, e), t.prototype._base = function (e, t, r) {
          if ("string" == typeof e && r.convert) {
            if (this._flags.normalize && (e = e.normalize(this._flags.normalize)), this._flags.case && (e = "upper" === this._flags.case ? e.toLocaleUpperCase() : e.toLocaleLowerCase()), this._flags.trim && (e = e.trim()), this._inner.replacements) for (var n = 0; n < this._inner.replacements.length; ++n) {
              var i = this._inner.replacements[n];
              e = e.replace(i.pattern, i.replacement);
            }
            if (this._flags.truncate) for (var o = 0; o < this._tests.length; ++o) {
              var a = this._tests[o];
              if ("max" === a.name) {
                e = e.slice(0, a.arg);
                break;
              }
            }
            this._flags.byteAligned && e.length % 2 != 0 && (e = "0" + e);
          }
          return {
            value: e,
            errors: "string" == typeof e ? null : this.createError("string.base", {
              value: e
            }, t, r)
          };
        }, t.prototype.insensitive = function () {
          if (this._flags.insensitive) return this;
          var e = this.clone();
          return e._flags.insensitive = !0, e;
        }, t.prototype.creditCard = function () {
          return this._test("creditCard", void 0, function (e, t, r) {
            for (var n = e.length, i = 0, o = 1; n--;) {
              var a = e.charAt(n) * o;
              i += a - 9 * (a > 9), o ^= 3;
            }
            return i % 10 == 0 && i > 0 ? e : this.createError("string.creditCard", {
              value: e
            }, t, r);
          });
        }, t.prototype.regex = function (e, t) {
          c.assert(e instanceof RegExp, "pattern must be a RegExp");
          var r = {
            pattern: new RegExp(e.source, e.ignoreCase ? "i" : void 0)
          };
          "string" == typeof t ? r.name = t : "object" === (void 0 === t ? "undefined" : s(t)) && (r.invert = !!t.invert, t.name && (r.name = t.name));
          var n = ["string.regex", r.invert ? ".invert" : "", r.name ? ".name" : ".base"].join("");
          return this._test("regex", r, function (e, t, i) {
            return r.pattern.test(e) ^ r.invert ? e : this.createError(n, {
              name: r.name,
              pattern: r.pattern,
              value: e
            }, t, i);
          });
        }, t.prototype.alphanum = function () {
          return this._test("alphanum", void 0, function (e, t, r) {
            return /^[a-zA-Z0-9]+$/.test(e) ? e : this.createError("string.alphanum", {
              value: e
            }, t, r);
          });
        }, t.prototype.token = function () {
          return this._test("token", void 0, function (e, t, r) {
            return /^\w+$/.test(e) ? e : this.createError("string.token", {
              value: e
            }, t, r);
          });
        }, t.prototype.email = function (e) {
          return e && (c.assert("object" === (void 0 === e ? "undefined" : s(e)), "email options must be an object"), c.assert(void 0 === e.checkDNS, "checkDNS option is not supported"), c.assert(void 0 === e.tldWhitelist || "object" === s(e.tldWhitelist), "tldWhitelist must be an array or object"), c.assert(void 0 === e.minDomainAtoms || Number.isSafeInteger(e.minDomainAtoms) && e.minDomainAtoms > 0, "minDomainAtoms must be a positive integer"), c.assert(void 0 === e.errorLevel || "boolean" == typeof e.errorLevel || Number.isSafeInteger(e.errorLevel) && e.errorLevel >= 0, "errorLevel must be a non-negative integer or boolean")), this._test("email", e, function (t, n, i) {
            l = l || r(19);
            try {
              var o = l.validate(t, e);
              if (!0 === o || 0 === o) return t;
            } catch (e) {}
            return this.createError("string.email", {
              value: t
            }, n, i);
          });
        }, t.prototype.ip = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
            t = y.ipRegex;
          c.assert("object" === (void 0 === e ? "undefined" : s(e)), "options must be an object"), e.cidr ? (c.assert("string" == typeof e.cidr, "cidr must be a string"), e.cidr = e.cidr.toLowerCase(), c.assert(c.contain(y.cidrPresences, e.cidr), "cidr must be one of " + y.cidrPresences.join(", ")), e.version || "optional" === e.cidr || (t = g.createIpRegex(["ipv4", "ipv6", "ipvfuture"], e.cidr))) : e.cidr = "optional";
          var r = void 0;
          if (e.version) {
            Array.isArray(e.version) || (e.version = [e.version]), c.assert(e.version.length >= 1, "version must have at least 1 version specified"), r = [];
            for (var n = 0; n < e.version.length; ++n) {
              var i = e.version[n];
              c.assert("string" == typeof i, "version at position " + n + " must be a string"), i = i.toLowerCase(), c.assert(g.versions[i], "version at position " + n + " must be one of " + Object.keys(g.versions).join(", ")), r.push(i);
            }
            r = c.unique(r), t = g.createIpRegex(r, e.cidr);
          }
          return this._test("ip", e, function (n, i, o) {
            return t.test(n) ? n : r ? this.createError("string.ipVersion", {
              value: n,
              cidr: e.cidr,
              version: r
            }, i, o) : this.createError("string.ip", {
              value: n,
              cidr: e.cidr
            }, i, o);
          });
        }, t.prototype.uri = function (e) {
          var t = "",
            r = !1,
            n = !1,
            i = y.uriRegex;
          if (e) {
            if (c.assert("object" === (void 0 === e ? "undefined" : s(e)), "options must be an object"), e.scheme) {
              c.assert(e.scheme instanceof RegExp || "string" == typeof e.scheme || Array.isArray(e.scheme), "scheme must be a RegExp, String, or Array"), Array.isArray(e.scheme) || (e.scheme = [e.scheme]), c.assert(e.scheme.length >= 1, "scheme must have at least 1 scheme specified");
              for (var o = 0; o < e.scheme.length; ++o) {
                var a = e.scheme[o];
                c.assert(a instanceof RegExp || "string" == typeof a, "scheme at position " + o + " must be a RegExp or String"), t += t ? "|" : "", a instanceof RegExp ? t += a.source : (c.assert(/[a-zA-Z][a-zA-Z0-9+-\.]*/.test(a), "scheme at position " + o + " must be a valid scheme"), t += c.escapeRegex(a));
              }
            }
            e.allowRelative && (r = !0), e.relativeOnly && (n = !0);
          }
          return (t || r || n) && (i = d.createUriRegex(t, r, n)), this._test("uri", e, function (e, r, o) {
            return i.test(e) ? e : n ? this.createError("string.uriRelativeOnly", {
              value: e
            }, r, o) : t ? this.createError("string.uriCustomScheme", {
              scheme: t,
              value: e
            }, r, o) : this.createError("string.uri", {
              value: e
            }, r, o);
          });
        }, t.prototype.isoDate = function () {
          return this._test("isoDate", void 0, function (e, t, r) {
            if (h._isIsoDate(e)) {
              if (!r.convert) return e;
              var n = new Date(e);
              if (!isNaN(n.getTime())) return n.toISOString();
            }
            return this.createError("string.isoDate", {
              value: e
            }, t, r);
          });
        }, t.prototype.guid = function (e) {
          var t = "";
          if (e && e.version) {
            Array.isArray(e.version) || (e.version = [e.version]), c.assert(e.version.length >= 1, "version must have at least 1 valid version specified");
            for (var r = new Set(), n = 0; n < e.version.length; ++n) {
              var i = e.version[n];
              c.assert("string" == typeof i, "version at position " + n + " must be a string"), i = i.toLowerCase();
              var o = y.guidVersions[i];
              c.assert(o, "version at position " + n + " must be one of " + Object.keys(y.guidVersions).join(", ")), c.assert(!r.has(o), "version at position " + n + " must not be a duplicate."), t += o, r.add(o);
            }
          }
          var a = new RegExp("^([\\[{\\(]?)[0-9A-F]{8}([:-]?)[0-9A-F]{4}\\2?[" + (t || "0-9A-F") + "][0-9A-F]{3}\\2?[" + (t ? "89AB" : "0-9A-F") + "][0-9A-F]{3}\\2?[0-9A-F]{12}([\\]}\\)]?)$", "i");
          return this._test("guid", e, function (e, t, r) {
            var n = a.exec(e);
            return n ? y.guidBrackets[n[1]] !== n[n.length - 1] ? this.createError("string.guid", {
              value: e
            }, t, r) : e : this.createError("string.guid", {
              value: e
            }, t, r);
          });
        }, t.prototype.hex = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
          c.assert("object" === (void 0 === e ? "undefined" : s(e)), "hex options must be an object"), c.assert(void 0 === e.byteAligned || "boolean" == typeof e.byteAligned, "byteAligned must be boolean");
          var t = !0 === e.byteAligned,
            r = /^[a-f0-9]+$/i,
            n = this._test("hex", r, function (e, n, i) {
              return r.test(e) ? t && e.length % 2 != 0 ? this.createError("string.hexAlign", {
                value: e
              }, n, i) : e : this.createError("string.hex", {
                value: e
              }, n, i);
            });
          return t && (n._flags.byteAligned = !0), n;
        }, t.prototype.base64 = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
          c.assert("object" === (void 0 === e ? "undefined" : s(e)), "base64 options must be an object"), c.assert(void 0 === e.paddingRequired || "boolean" == typeof e.paddingRequired, "paddingRequired must be boolean");
          var t = !1 === e.paddingRequired ? e.paddingRequired : e.paddingRequired || !0,
            r = t ? /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/ : /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}(==)?|[A-Za-z0-9+\/]{3}=?)?$/;
          return this._test("base64", r, function (e, t, n) {
            return r.test(e) ? e : this.createError("string.base64", {
              value: e
            }, t, n);
          });
        }, t.prototype.hostname = function () {
          var e = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
          return this._test("hostname", void 0, function (t, r, n) {
            return t.length <= 255 && e.test(t) || u.isIPv6(t) ? t : this.createError("string.hostname", {
              value: t
            }, r, n);
          });
        }, t.prototype.normalize = function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "NFC";
          c.assert(c.contain(y.normalizationForms, e), "normalization form must be one of " + y.normalizationForms.join(", "));
          var t = this._test("normalize", e, function (t, r, n) {
            return n.convert || t === t.normalize(e) ? t : this.createError("string.normalize", {
              value: t,
              form: e
            }, r, n);
          });
          return t._flags.normalize = e, t;
        }, t.prototype.lowercase = function () {
          var e = this._test("lowercase", void 0, function (e, t, r) {
            return r.convert || e === e.toLocaleLowerCase() ? e : this.createError("string.lowercase", {
              value: e
            }, t, r);
          });
          return e._flags.case = "lower", e;
        }, t.prototype.uppercase = function () {
          var e = this._test("uppercase", void 0, function (e, t, r) {
            return r.convert || e === e.toLocaleUpperCase() ? e : this.createError("string.uppercase", {
              value: e
            }, t, r);
          });
          return e._flags.case = "upper", e;
        }, t.prototype.trim = function () {
          var e = this._test("trim", void 0, function (e, t, r) {
            return r.convert || e === e.trim() ? e : this.createError("string.trim", {
              value: e
            }, t, r);
          });
          return e._flags.trim = !0, e;
        }, t.prototype.replace = function (e, t) {
          "string" == typeof e && (e = new RegExp(c.escapeRegex(e), "g")), c.assert(e instanceof RegExp, "pattern must be a RegExp"), c.assert("string" == typeof t, "replacement must be a String");
          var r = this.clone();
          return r._inner.replacements || (r._inner.replacements = []), r._inner.replacements.push({
            pattern: e,
            replacement: t
          }), r;
        }, t.prototype.truncate = function (e) {
          var t = void 0 === e || !!e;
          if (this._flags.truncate === t) return this;
          var r = this.clone();
          return r._flags.truncate = t, r;
        }, t;
      }(f), y.compare = function (e, r) {
        return function (n, i) {
          var o = p.isRef(n);
          return c.assert(Number.isSafeInteger(n) && n >= 0 || o, "limit must be a positive integer or reference"), c.assert(!i || t.isEncoding(i), "Invalid encoding:", i), this._test(e, n, function (t, a, s) {
            var u = void 0;
            if (o) {
              if (u = n(a.reference || a.parent, s), !Number.isSafeInteger(u)) return this.createError("string.ref", {
                ref: n.key
              }, a, s);
            } else u = n;
            return r(t, u, i) ? t : this.createError("string." + e, {
              limit: u,
              value: t,
              encoding: i
            }, a, s);
          });
        };
      }, y.String.prototype.min = y.compare("min", function (e, r, n) {
        return (n ? t.byteLength(e, n) : e.length) >= r;
      }), y.String.prototype.max = y.compare("max", function (e, r, n) {
        return (n ? t.byteLength(e, n) : e.length) <= r;
      }), y.String.prototype.length = y.compare("length", function (e, r, n) {
        return (n ? t.byteLength(e, n) : e.length) === r;
      }), y.String.prototype.uuid = y.String.prototype.guid, e.exports = new y.String();
    }).call(t, r(3).Buffer);
  }, function (e, t, r) {
    "use strict";

    var n = r(14),
      i = {
        Ip: {
          cidrs: {
            ipv4: {
              required: "\\/(?:" + n.ipv4Cidr + ")",
              optional: "(?:\\/(?:" + n.ipv4Cidr + "))?",
              forbidden: ""
            },
            ipv6: {
              required: "\\/" + n.ipv6Cidr,
              optional: "(?:\\/" + n.ipv6Cidr + ")?",
              forbidden: ""
            },
            ipvfuture: {
              required: "\\/" + n.ipv6Cidr,
              optional: "(?:\\/" + n.ipv6Cidr + ")?",
              forbidden: ""
            }
          },
          versions: {
            ipv4: n.IPv4address,
            ipv6: n.IPv6address,
            ipvfuture: n.IPvFuture
          }
        }
      };
    i.Ip.createIpRegex = function (e, t) {
      for (var r = void 0, n = 0; n < e.length; ++n) {
        var o = e[n];
        r ? r += "|" + i.Ip.versions[o] + i.Ip.cidrs[o][t] : r = "^(?:" + i.Ip.versions[o] + i.Ip.cidrs[o][t];
      }
      return new RegExp(r + ")$");
    }, e.exports = i.Ip;
  }, function (e, t, r) {
    "use strict";

    var n = r(14),
      i = {
        Uri: {
          createUriRegex: function createUriRegex(e, t, r) {
            var i = n.scheme,
              o = void 0;
            if (r) o = "(?:" + n.relativeRef + ")";else {
              e && (i = "(?:" + e + ")");
              var a = "(?:" + i + ":" + n.hierPart + ")";
              o = t ? "(?:" + a + "|" + n.relativeRef + ")" : a;
            }
            return new RegExp("^" + o + "(?:\\?" + n.query + ")?(?:#" + n.fragment + ")?$");
          }
        }
      };
    e.exports = i.Uri;
  }, function (e, t, r) {
    "use strict";

    var n = r(0),
      i = {};
    e.exports = i.Topo = function () {
      this._items = [], this.nodes = [];
    }, i.Topo.prototype.add = function (e, t) {
      var r = this;
      t = t || {};
      var i = [].concat(t.before || []),
        o = [].concat(t.after || []),
        a = t.group || "?",
        s = t.sort || 0;
      n.assert(-1 === i.indexOf(a), "Item cannot come before itself:", a), n.assert(-1 === i.indexOf("?"), "Item cannot come before unassociated items"), n.assert(-1 === o.indexOf(a), "Item cannot come after itself:", a), n.assert(-1 === o.indexOf("?"), "Item cannot come after unassociated items"), [].concat(e).forEach(function (e, t) {
        var n = {
          seq: r._items.length,
          sort: s,
          before: i,
          after: o,
          group: a,
          node: e
        };
        r._items.push(n);
      });
      var u = this._sort();
      return n.assert(!u, "item", "?" !== a ? "added into group " + a : "", "created a dependencies error"), this.nodes;
    }, i.Topo.prototype.merge = function (e) {
      e = [].concat(e);
      for (var t = 0; t < e.length; ++t) {
        var r = e[t];
        if (r) for (var o = 0; o < r._items.length; ++o) {
          var a = n.shallow(r._items[o]);
          this._items.push(a);
        }
      }
      this._items.sort(i.mergeSort);
      for (var s = 0; s < this._items.length; ++s) {
        this._items[s].seq = s;
      }
      var u = this._sort();
      return n.assert(!u, "merge created a dependencies error"), this.nodes;
    }, i.mergeSort = function (e, t) {
      return e.sort === t.sort ? 0 : e.sort < t.sort ? -1 : 1;
    }, i.Topo.prototype._sort = function () {
      for (var e = {}, t = Object.create(null), r = Object.create(null), n = 0; n < this._items.length; ++n) {
        var i = this._items[n],
          o = i.seq,
          a = i.group;
        r[a] = r[a] || [], r[a].push(o), e[o] = i.before;
        for (var s = i.after, u = 0; u < s.length; ++u) {
          t[s[u]] = (t[s[u]] || []).concat(o);
        }
      }
      for (var c = Object.keys(e), l = 0; l < c.length; ++l) {
        for (var f = c[l], p = [], h = Object.keys(e[f]), d = 0; d < h.length; ++d) {
          var g = e[f][h[d]];
          r[g] = r[g] || [];
          for (var y = 0; y < r[g].length; ++y) {
            p.push(r[g][y]);
          }
        }
        e[f] = p;
      }
      for (var v = Object.keys(t), m = 0; m < v.length; ++m) {
        var b = v[m];
        if (r[b]) for (var _ = 0; _ < r[b].length; ++_) {
          var w = r[b][_];
          e[w] = e[w].concat(t[b]);
        }
      }
      var E = void 0,
        x = {};
      c = Object.keys(e);
      for (var A = 0; A < c.length; ++A) {
        var j = c[A];
        E = e[j];
        for (var S = 0; S < E.length; ++S) {
          x[E[S]] = (x[E[S]] || []).concat(j);
        }
      }
      for (var O = {}, k = [], P = 0; P < this._items.length; ++P) {
        var T = P;
        if (x[P]) {
          T = null;
          for (var R = 0; R < this._items.length; ++R) {
            if (!0 !== O[R]) {
              x[R] || (x[R] = []);
              for (var C = x[R].length, D = 0, I = 0; I < C; ++I) {
                O[x[R][I]] && ++D;
              }
              if (D === C) {
                T = R;
                break;
              }
            }
          }
        }
        null !== T && (O[T] = !0, k.push(T));
      }
      if (k.length !== this._items.length) return new Error("Invalid dependencies");
      for (var L = {}, N = 0; N < this._items.length; ++N) {
        var B = this._items[N];
        L[B.seq] = B;
      }
      var F = [];
      this._items = k.map(function (e) {
        var t = L[e];
        return F.push(t.node), t;
      }), this.nodes = F;
    };
  }, function (e, t, r) {
    "use strict";

    var n = r(8);
    e.exports = n;
  }, function (e, t, r) {
    "use strict";

    function n(e) {
      var t = e.length;
      if (t % 4 > 0) throw new Error("Invalid string. Length must be a multiple of 4");
      return "=" === e[t - 2] ? 2 : "=" === e[t - 1] ? 1 : 0;
    }
    function i(e) {
      return 3 * e.length / 4 - n(e);
    }
    function o(e) {
      var t,
        r,
        i,
        o,
        a,
        s = e.length;
      o = n(e), a = new f(3 * s / 4 - o), r = o > 0 ? s - 4 : s;
      var u = 0;
      for (t = 0; t < r; t += 4) {
        i = l[e.charCodeAt(t)] << 18 | l[e.charCodeAt(t + 1)] << 12 | l[e.charCodeAt(t + 2)] << 6 | l[e.charCodeAt(t + 3)], a[u++] = i >> 16 & 255, a[u++] = i >> 8 & 255, a[u++] = 255 & i;
      }
      return 2 === o ? (i = l[e.charCodeAt(t)] << 2 | l[e.charCodeAt(t + 1)] >> 4, a[u++] = 255 & i) : 1 === o && (i = l[e.charCodeAt(t)] << 10 | l[e.charCodeAt(t + 1)] << 4 | l[e.charCodeAt(t + 2)] >> 2, a[u++] = i >> 8 & 255, a[u++] = 255 & i), a;
    }
    function a(e) {
      return c[e >> 18 & 63] + c[e >> 12 & 63] + c[e >> 6 & 63] + c[63 & e];
    }
    function s(e, t, r) {
      for (var n, i = [], o = t; o < r; o += 3) {
        n = (e[o] << 16) + (e[o + 1] << 8) + e[o + 2], i.push(a(n));
      }
      return i.join("");
    }
    function u(e) {
      for (var t, r = e.length, n = r % 3, i = "", o = [], a = 0, u = r - n; a < u; a += 16383) {
        o.push(s(e, a, a + 16383 > u ? u : a + 16383));
      }
      return 1 === n ? (t = e[r - 1], i += c[t >> 2], i += c[t << 4 & 63], i += "==") : 2 === n && (t = (e[r - 2] << 8) + e[r - 1], i += c[t >> 10], i += c[t >> 4 & 63], i += c[t << 2 & 63], i += "="), o.push(i), o.join("");
    }
    t.byteLength = i, t.toByteArray = o, t.fromByteArray = u;
    for (var c = [], l = [], f = "undefined" != typeof Uint8Array ? Uint8Array : Array, p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", h = 0, d = p.length; h < d; ++h) {
      c[h] = p[h], l[p.charCodeAt(h)] = h;
    }
    l["-".charCodeAt(0)] = 62, l["_".charCodeAt(0)] = 63;
  }, function (e, t) {
    t.read = function (e, t, r, n, i) {
      var o,
        a,
        s = 8 * i - n - 1,
        u = (1 << s) - 1,
        c = u >> 1,
        l = -7,
        f = r ? i - 1 : 0,
        p = r ? -1 : 1,
        h = e[t + f];
      for (f += p, o = h & (1 << -l) - 1, h >>= -l, l += s; l > 0; o = 256 * o + e[t + f], f += p, l -= 8) {
        ;
      }
      for (a = o & (1 << -l) - 1, o >>= -l, l += n; l > 0; a = 256 * a + e[t + f], f += p, l -= 8) {
        ;
      }
      if (0 === o) o = 1 - c;else {
        if (o === u) return a ? NaN : 1 / 0 * (h ? -1 : 1);
        a += Math.pow(2, n), o -= c;
      }
      return (h ? -1 : 1) * a * Math.pow(2, o - n);
    }, t.write = function (e, t, r, n, i, o) {
      var a,
        s,
        u,
        c = 8 * o - i - 1,
        l = (1 << c) - 1,
        f = l >> 1,
        p = 23 === i ? Math.pow(2, -24) - Math.pow(2, -77) : 0,
        h = n ? 0 : o - 1,
        d = n ? 1 : -1,
        g = t < 0 || 0 === t && 1 / t < 0 ? 1 : 0;
      for (t = Math.abs(t), isNaN(t) || t === 1 / 0 ? (s = isNaN(t) ? 1 : 0, a = l) : (a = Math.floor(Math.log(t) / Math.LN2), t * (u = Math.pow(2, -a)) < 1 && (a--, u *= 2), t += a + f >= 1 ? p / u : p * Math.pow(2, 1 - f), t * u >= 2 && (a++, u /= 2), a + f >= l ? (s = 0, a = l) : a + f >= 1 ? (s = (t * u - 1) * Math.pow(2, i), a += f) : (s = t * Math.pow(2, f - 1) * Math.pow(2, i), a = 0)); i >= 8; e[r + h] = 255 & s, h += d, s /= 256, i -= 8) {
        ;
      }
      for (a = a << i | s, c += i; c > 0; e[r + h] = 255 & a, h += d, a /= 256, c -= 8) {
        ;
      }
      e[r + h - d] |= 128 * g;
    };
  }, function (e, t) {
    var r = {}.toString;
    e.exports = Array.isArray || function (e) {
      return "[object Array]" == r.call(e);
    };
  }, function (e, t) {
    e.exports = {
      _args: [["joi@13.4.0", "/Users/hkernbach/Git/joi-browser"]],
      _development: !0,
      _from: "joi@13.4.0",
      _id: "joi@13.4.0",
      _inBundle: !1,
      _integrity: "sha512-JuK4GjEu6j7zr9FuVe2MAseZ6si/8/HaY0qMAejfDFHp7jcH4OKE937mIHM5VT4xDS0q7lpQbszbxKV9rm0yUg==",
      _location: "/joi",
      _phantomChildren: {},
      _requested: {
        type: "version",
        registry: !0,
        raw: "joi@13.4.0",
        name: "joi",
        escapedName: "joi",
        rawSpec: "13.4.0",
        saveSpec: null,
        fetchSpec: "13.4.0"
      },
      _requiredBy: ["#DEV:/"],
      _resolved: "https://registry.npmjs.org/joi/-/joi-13.4.0.tgz",
      _spec: "13.4.0",
      _where: "/Users/hkernbach/Git/joi-browser",
      bugs: {
        url: "https://github.com/hapijs/joi/issues"
      },
      dependencies: {
        hoek: "5.x.x",
        isemail: "3.x.x",
        topo: "3.x.x"
      },
      description: "Object schema validation",
      devDependencies: {
        code: "5.x.x",
        hapitoc: "1.x.x",
        lab: "15.x.x"
      },
      engines: {
        node: ">=8.9.0"
      },
      homepage: "https://github.com/hapijs/joi",
      keywords: ["hapi", "schema", "validation"],
      license: "BSD-3-Clause",
      main: "lib/index.js",
      name: "joi",
      repository: {
        type: "git",
        url: "git://github.com/hapijs/joi.git"
      },
      scripts: {
        test: "lab -t 100 -a code -L",
        "test-cov-html": "lab -r html -o coverage.html -a code",
        "test-debug": "lab -a code",
        toc: "hapitoc",
        version: "npm run toc && git add API.md README.md"
      },
      version: "13.4.0"
    };
  }, function (e, t, r) {
    (function (e) {
      function r(e, t) {
        for (var r = 0, n = e.length - 1; n >= 0; n--) {
          var i = e[n];
          "." === i ? e.splice(n, 1) : ".." === i ? (e.splice(n, 1), r++) : r && (e.splice(n, 1), r--);
        }
        if (t) for (; r--; r) {
          e.unshift("..");
        }
        return e;
      }
      function n(e, t) {
        if (e.filter) return e.filter(t);
        for (var r = [], n = 0; n < e.length; n++) {
          t(e[n], n, e) && r.push(e[n]);
        }
        return r;
      }
      var i = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/,
        o = function o(e) {
          return i.exec(e).slice(1);
        };
      t.resolve = function () {
        for (var t = "", i = !1, o = arguments.length - 1; o >= -1 && !i; o--) {
          var a = o >= 0 ? arguments[o] : e.cwd();
          if ("string" != typeof a) throw new TypeError("Arguments to path.resolve must be strings");
          a && (t = a + "/" + t, i = "/" === a.charAt(0));
        }
        return t = r(n(t.split("/"), function (e) {
          return !!e;
        }), !i).join("/"), (i ? "/" : "") + t || ".";
      }, t.normalize = function (e) {
        var i = t.isAbsolute(e),
          o = "/" === a(e, -1);
        return e = r(n(e.split("/"), function (e) {
          return !!e;
        }), !i).join("/"), e || i || (e = "."), e && o && (e += "/"), (i ? "/" : "") + e;
      }, t.isAbsolute = function (e) {
        return "/" === e.charAt(0);
      }, t.join = function () {
        var e = Array.prototype.slice.call(arguments, 0);
        return t.normalize(n(e, function (e, t) {
          if ("string" != typeof e) throw new TypeError("Arguments to path.join must be strings");
          return e;
        }).join("/"));
      }, t.relative = function (e, r) {
        function n(e) {
          for (var t = 0; t < e.length && "" === e[t]; t++) {
            ;
          }
          for (var r = e.length - 1; r >= 0 && "" === e[r]; r--) {
            ;
          }
          return t > r ? [] : e.slice(t, r - t + 1);
        }
        e = t.resolve(e).substr(1), r = t.resolve(r).substr(1);
        for (var i = n(e.split("/")), o = n(r.split("/")), a = Math.min(i.length, o.length), s = a, u = 0; u < a; u++) {
          if (i[u] !== o[u]) {
            s = u;
            break;
          }
        }
        for (var c = [], u = s; u < i.length; u++) {
          c.push("..");
        }
        return c = c.concat(o.slice(s)), c.join("/");
      }, t.sep = "/", t.delimiter = ":", t.dirname = function (e) {
        var t = o(e),
          r = t[0],
          n = t[1];
        return r || n ? (n && (n = n.substr(0, n.length - 1)), r + n) : ".";
      }, t.basename = function (e, t) {
        var r = o(e)[2];
        return t && r.substr(-1 * t.length) === t && (r = r.substr(0, r.length - t.length)), r;
      }, t.extname = function (e) {
        return o(e)[3];
      };
      var a = "b" === "ab".substr(-1) ? function (e, t, r) {
        return e.substr(t, r);
      } : function (e, t, r) {
        return t < 0 && (t = e.length + t), e.substr(t, r);
      };
    }).call(t, r(7));
  }, function (e, t, r) {
    (function (e, n) {
      var i;
      !function (o) {
        function a(e) {
          throw new RangeError(C[e]);
        }
        function s(e, t) {
          for (var r = e.length, n = []; r--;) {
            n[r] = t(e[r]);
          }
          return n;
        }
        function u(e, t) {
          var r = e.split("@"),
            n = "";
          return r.length > 1 && (n = r[0] + "@", e = r[1]), e = e.replace(R, "."), n + s(e.split("."), t).join(".");
        }
        function c(e) {
          for (var t, r, n = [], i = 0, o = e.length; i < o;) {
            t = e.charCodeAt(i++), t >= 55296 && t <= 56319 && i < o ? (r = e.charCodeAt(i++), 56320 == (64512 & r) ? n.push(((1023 & t) << 10) + (1023 & r) + 65536) : (n.push(t), i--)) : n.push(t);
          }
          return n;
        }
        function l(e) {
          return s(e, function (e) {
            var t = "";
            return e > 65535 && (e -= 65536, t += L(e >>> 10 & 1023 | 55296), e = 56320 | 1023 & e), t += L(e);
          }).join("");
        }
        function f(e) {
          return e - 48 < 10 ? e - 22 : e - 65 < 26 ? e - 65 : e - 97 < 26 ? e - 97 : w;
        }
        function p(e, t) {
          return e + 22 + 75 * (e < 26) - ((0 != t) << 5);
        }
        function h(e, t, r) {
          var n = 0;
          for (e = r ? I(e / j) : e >> 1, e += I(e / t); e > D * x >> 1; n += w) {
            e = I(e / D);
          }
          return I(n + (D + 1) * e / (e + A));
        }
        function d(e) {
          var t,
            r,
            n,
            i,
            o,
            s,
            u,
            c,
            p,
            d,
            g = [],
            y = e.length,
            v = 0,
            m = O,
            b = S;
          for (r = e.lastIndexOf(k), r < 0 && (r = 0), n = 0; n < r; ++n) {
            e.charCodeAt(n) >= 128 && a("not-basic"), g.push(e.charCodeAt(n));
          }
          for (i = r > 0 ? r + 1 : 0; i < y;) {
            for (o = v, s = 1, u = w; i >= y && a("invalid-input"), c = f(e.charCodeAt(i++)), (c >= w || c > I((_ - v) / s)) && a("overflow"), v += c * s, p = u <= b ? E : u >= b + x ? x : u - b, !(c < p); u += w) {
              d = w - p, s > I(_ / d) && a("overflow"), s *= d;
            }
            t = g.length + 1, b = h(v - o, t, 0 == o), I(v / t) > _ - m && a("overflow"), m += I(v / t), v %= t, g.splice(v++, 0, m);
          }
          return l(g);
        }
        function g(e) {
          var t,
            r,
            n,
            i,
            o,
            s,
            u,
            l,
            f,
            d,
            g,
            y,
            v,
            m,
            b,
            A = [];
          for (e = c(e), y = e.length, t = O, r = 0, o = S, s = 0; s < y; ++s) {
            (g = e[s]) < 128 && A.push(L(g));
          }
          for (n = i = A.length, i && A.push(k); n < y;) {
            for (u = _, s = 0; s < y; ++s) {
              (g = e[s]) >= t && g < u && (u = g);
            }
            for (v = n + 1, u - t > I((_ - r) / v) && a("overflow"), r += (u - t) * v, t = u, s = 0; s < y; ++s) {
              if (g = e[s], g < t && ++r > _ && a("overflow"), g == t) {
                for (l = r, f = w; d = f <= o ? E : f >= o + x ? x : f - o, !(l < d); f += w) {
                  b = l - d, m = w - d, A.push(L(p(d + b % m, 0))), l = I(b / m);
                }
                A.push(L(p(l, 0))), o = h(r, v, n == i), r = 0, ++n;
              }
            }
            ++r, ++t;
          }
          return A.join("");
        }
        function y(e) {
          return u(e, function (e) {
            return P.test(e) ? d(e.slice(4).toLowerCase()) : e;
          });
        }
        function v(e) {
          return u(e, function (e) {
            return T.test(e) ? "xn--" + g(e) : e;
          });
        }
        var m = ("object" == typeof t && t && t.nodeType, "object" == typeof e && e && e.nodeType, "object" == typeof n && n);
        var b,
          _ = 2147483647,
          w = 36,
          E = 1,
          x = 26,
          A = 38,
          j = 700,
          S = 72,
          O = 128,
          k = "-",
          P = /^xn--/,
          T = /[^\x20-\x7E]/,
          R = /[\x2E\u3002\uFF0E\uFF61]/g,
          C = {
            overflow: "Overflow: input needs wider integers to process",
            "not-basic": "Illegal input >= 0x80 (not a basic code point)",
            "invalid-input": "Invalid input"
          },
          D = w - E,
          I = Math.floor,
          L = String.fromCharCode;
        b = {
          version: "1.4.1",
          ucs2: {
            decode: c,
            encode: l
          },
          decode: d,
          encode: g,
          toASCII: v,
          toUnicode: y
        }, void 0 !== (i = function () {
          return b;
        }.call(t, r, t, e)) && (e.exports = i);
      }();
    }).call(t, r(41)(e), r(5));
  }, function (e, t) {
    "function" == typeof Object.create ? e.exports = function (e, t) {
      e.super_ = t, e.prototype = Object.create(t.prototype, {
        constructor: {
          value: e,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      });
    } : e.exports = function (e, t) {
      e.super_ = t;
      var r = function r() {};
      r.prototype = t.prototype, e.prototype = new r(), e.prototype.constructor = e;
    };
  }, function (e, t) {
    e.exports = function (e) {
      return e && "object" == typeof e && "function" == typeof e.copy && "function" == typeof e.fill && "function" == typeof e.readUInt8;
    };
  }, function (e, t) {
    e.exports = function (e) {
      return e.webpackPolyfill || (e.deprecate = function () {}, e.paths = [], e.children || (e.children = []), Object.defineProperty(e, "loaded", {
        enumerable: !0,
        get: function get() {
          return e.l;
        }
      }), Object.defineProperty(e, "id", {
        enumerable: !0,
        get: function get() {
          return e.i;
        }
      }), e.webpackPolyfill = 1), e;
    };
  }]);
});