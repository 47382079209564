import axios from 'axios'
import { getAdminUrl, getBaseUrl } from '../utils/dbmsClient';
declare var dbmsHelper: { [key: string]: any };


const client = axios.create({
  baseURL: getAdminUrl(),
})

const clientApi = axios.create({
  baseURL: getBaseUrl(),
})

for (const c of [client, clientApi]) {
  c.interceptors.request.use(
    config => {
      config.headers = config.headers || {}
      config.headers.Authorization = `Bearer ${dbmsHelper.getCurrentJwt()}`
      return config
    }
  )
}


export const explain = async (query: string, args?: Record<string, any>) => {
  const res = await client.post<{msg: string}>('/monitor/query/explain', {
      query,
      bindVars: args,
  })
  return res.data.msg
}

export const profile = async (query: string, args?: Record<string, any>) => {
  const res = await client.post<{msg: string}>('/monitor/query/profile', {
      query,
      bindVars: args,
  })
  return res.data.msg
}

export const getReplicationMode = async (): Promise<{mode: number, role: string}> => {
    const res = await client.get<{mode: number, role: string}>('/monitor/replication/mode')
    return res.data
}

type ReplicationPingProps = {
    endpoint: string,
    username: string,
    password: string,
}

export const isPingableEndpoint = async (props: ReplicationPingProps): Promise<boolean> => {
    try {
        const res = await client.post<{result: boolean}>('/monitor/replication/ping', props)
        return res.data.result === true
    } catch (e) {
        return false
    }
}

export const stopApplier = async (isGlobal?: boolean) => {
    const info = await clientApi.put(`/replication/applier-stop${isGlobal?'?global=true':''}`)
    return info.data
}

export const getApplierState = async (isGlobal?: boolean) => {
    const info = await clientApi.get(`/replication/applier-state${isGlobal?'?global=true':''}`)
    return info.data
}

export const updateApplierConfig = async (config: Record<string, any>, isGlobal?: boolean) => {
  const info = await clientApi.put(`/replication/applier-config${isGlobal?'?global=true':''}`, config)
  return info.data
}

export const startApplierConfig = async (isGlobal?: boolean) => {
  const info = await clientApi.put(`/replication/applier-start${isGlobal?'?global=true':''}`)
  return info.data
}
