import { Alert } from 'antd'
import React, { useEffect, useState } from 'react'
import { checkEndpoint } from '../../utils/checkEndpoint'

type ConnectionStatusProps = {
    url: string
    username: string
    password: string
}

export const ConnectionStatus = ({url, username, password}: ConnectionStatusProps) => {
    const [inited, setInited] = useState(false)
    const [connectionStatus, setConnectionStatus] = useState(false)

    useEffect(() => {
        setInited(false)
        if (url) {
            checkEndpoint(url, username, password).then(setConnectionStatus).finally(() => setInited(true))
        } else {
            setInited(true)
            setConnectionStatus(false)
        }
    }, [url, username, password])

    return <Alert
        message={inited ? (connectionStatus ? 'Connected' : 'Not connected') : 'Checking...'}
        type={inited ? (connectionStatus ? 'success' : 'error') : 'info'}
    />
}
