module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="subMenuEntries top">\r\n    <li class="subMenuEntry pull-left">\r\n        <div class="breadcrumb"></div>\r\n    </li>\r\n    <li id="healthStatus" class="infoEntry subMenuEntry pull-right positive">\r\n        <a class="info health-info">Status:&nbsp</a>\r\n        ';
 if (frontendConfig.clusterApiJwtPolicy !== 'jwt-all') { 
__p+='\r\n            <a class="state health-state">GOOD</a>\r\n        ';
 } else { 
__p+='\r\n            <a class="state health-state" title="cluster API is inaccessible due to missing permissions">n/a</a>\r\n        ';
 } 
__p+='\r\n    </li>\r\n    <li id="userBar" class="infoEntry subMenuEntry pull-right" style="margin-right: 10px;">\r\n    </li>\r\n    <li id="dbStatus" class="infoEntry subMenuEntry pull-right positive">\r\n    </li>\r\n    <li class="infoEntry subMenuEntry pull-right">\r\n        <div id="switchDbsSpinner" style="display:none" class="spin">Loading...</div>\r\n    </li>\r\n</ul>\r\n\r\n<ul class="subMenuEntries bottom">\r\n</ul>\r\n';
}
return __p;
};
