module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="arangoToolbar arangoToolbarTop arangoFoxxToolbarTop">\r\n    <div class="pull-left">\r\n    </div>\r\n\r\n    <div class="pull-right">\r\n    </div>\r\n</div>\r\n<div class="contentDiv createModalDialog">\r\n    <div id="uploadFoxxes" class="foxxesInner modal-body">\r\n        <div>\r\n            <p>\r\n                Upload a Foxx service bundle. The Foxx service bundle should be a zip archive containing all service\r\n                files as a directory structure, including the manifest and any required <code\r\n                        style="padding: 0px 2px; font-weight: 10pt;">node_modules</code> dependencies. If your service\r\n                doesn\'t have any dependencies, configuration or scripts you can also upload a single JavaScript file\r\n                that will act as the service\'s entry point or "main" file.\r\n            </p>\r\n            <table>\r\n                <tr class="tableRow" style="display: none">\r\n                    <th class="relationInfoTh">\r\n                        Enable Legacy Mode:\r\n                    </th>\r\n                    <th class="relationInfoTh">\r\n                        <input type="checkbox" id="zip-app-islegacy" value="true">\r\n                    </th>\r\n                    <th>\r\n              <span class="modalTooltips fa fa-info-circle"\r\n                    title="Legacy Compatibility Mode allows mounting some Foxx services written for Dbms 2.8 or older. This overrides the Dbms version specified in the service manifest. See the Foxx documentation for more information on running legacy services in Dbms 3.">\r\n              </span>\r\n                    </th>\r\n                </tr>\r\n            </table>\r\n        </div>\r\n        <div id="upload-foxx-zip">Upload File</div>\r\n    </div>\r\n    <div class="modal-footer">\r\n        ';
 if (upgrade) { 
__p+='\r\n            <button id="installUploadService" class="btn-success" style="float:right" disabled>Replace</button>\r\n        ';
 } else { 
__p+='\r\n            <button id="installUploadService" class="btn-success" style="float:right" disabled>Install</button>\r\n        ';
 } 
__p+='\r\n    </div>\r\n</div>\r\n</div>\r\n';
}
return __p;
};
