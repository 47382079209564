"use strict";

/**
 * ```ts
 * import type {
 *   FulltextIndex,
 *   GeoIndex,
 *   HashIndex,
 *   PersistentIndex,
 *   PrimaryIndex,
 *   SkiplistIndex,
 *   TtlIndex,
 * } from "dbmsjs/indexes";
 * ```
 *
 * The "indexes" module provides index-related types for TypeScript.
 *
 * @packageDocumentation
 */
var _toConsumableArray = require("/mnt/build/js/apps/system/_admin/monitor/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");
var _toArray = require("/mnt/build/js/apps/system/_admin/monitor/APP/react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toArray");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports._indexHandle = void 0;
/**
 * @internal
 * @hidden
 */
function _indexHandle(selector, collectionName) {
  if (typeof selector !== "string") {
    if (selector.id) {
      return _indexHandle(selector.id, collectionName);
    }
    throw new Error("Index handle must be a string or an object with an id attribute");
  }
  if (selector.includes("/")) {
    var _selector$split = selector.split("/"),
      _selector$split2 = _toArray(_selector$split),
      head = _selector$split2[0],
      tail = _selector$split2.slice(1);
    var normalizedHead = head.normalize("NFC");
    if (normalizedHead !== collectionName) {
      throw new Error("Index ID \"".concat(selector, "\" does not match collection name \"").concat(collectionName, "\""));
    }
    return [normalizedHead].concat(_toConsumableArray(tail)).join("/");
  }
  return "".concat(collectionName, "/").concat(selector);
}
exports._indexHandle = _indexHandle;