/* jshint unused: false */
/* global window, $, Backbone, document, dbmsHelper */

(function () {
  'use strict';

  // We have to start the app only in production mode, not in test mode
  if (!window.hasOwnProperty('TEST_BUILD')) {
    $(document).ajaxSend(function (event, jqxhr, settings) {
      jqxhr.setRequestHeader('X-Arango-Frontend', 'true');
      var currentJwt = window.dbmsHelper.getCurrentJwt();
      if (currentJwt) {
        jqxhr.setRequestHeader('Authorization', 'bearer ' + currentJwt);
      }
    });
    $.ajaxSetup({
      error: function error(x, status, _error) {
        if (x.status === 401) {
          // session might be expired. check if jwt is still valid
          dbmsHelper.checkJwt();
        }
      }
    });
    $(document).ready(function () {
      window.App = new window.Router();
      Backbone.history.start();
      window.App.handleResize();
    });

    // create only the following global event listeners
    $(document).click(function (e) {
      e.stopPropagation();

      // also note that the web UI was actively used
      dbmsHelper.noteActivity();
    });
    $('body').on('keyup', function (e) {
      // hide modal dialogs when pressing ESC
      if (e.keyCode === 27) {
        if (window.modalView) {
          window.modalView.hide();
        }
      }

      // also note that the web UI was actively used
      dbmsHelper.noteActivity();
    });
  }
})();