type Column = {
    key: string,
}

export const columnSort = (a: Column, b: Column): number => {
  const a_key = a.key
  const b_key = b.key

  if (a_key[0] === '_' && b_key[0] !== '_') {
    return 1
  }

  if (a_key[0] !== '_' && b_key[0] === '_') {
    return -1
  }

  if (a_key < b_key) {
    return -1;
  }
  if (a_key > b_key) {
    return 1;
  }
  return 0;
}
