module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var genClass = "pure-u-1-5"; 
__p+='\n';
 var genClass2 = "pure-u-1-5"; 
__p+='\n\n<div id="userPermissionView" class="innerContent">\n    <div class="pure-g pure-table pure-table-header pure-title" style="clear: both; width: auto;">\n        <div class="pure-table-row">\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' left">Database</div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Administrate <i class="fa fa-info-circle tippy"\n                                                             title="Allows creating/dropping of relations and setting user permissions in the database."></i>\n            </div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Access <i class="fa fa-info-circle tippy"\n                                                       title="Allows access to the database. User cannot create or drop relations."></i>\n            </div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">No access <i class="fa fa-info-circle tippy"\n                                                          title="User has no access to the database."></i></div>\n            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid">Use default <i class="fa fa-info-circle tippy"\n                                                            title="Access level is unspecified. Database default (*) will be used."></i>\n            </div>\n        </div>\n    </div>\n\n    <div class="pure-g pure-table pure-table-body" style="width: auto;">\n        ';
 _.each(permissions, function(content, db) { 
__p+='\n            ';
 var extraClass = ''; 
__p+='\n            ';
 if (db.charAt(0) === '*') { 
__p+='\n                ';
 extraClass = 'noAction'; 
__p+='\n            ';
 } 
__p+='\n\n        <div class="pure-table-row db-row noHover '+
((__t=( extraClass ))==null?'':__t)+
'" id="'+
((__t=( db ))==null?'':__t)+
'-db">\n            <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' left db-label">\n\n                ';
 if (db.charAt(0) !== '*') { 
__p+='\n                    <i class="fa fa-caret-right" style="width: 10px;"></i>\n                    <i class="fa fa-caret-down" style="display: none; width: 10px;"></i>\n                ';
 } 
__p+='\n                ';
 if (db.charAt(0) === '*') { 
__p+='\n                    <b style="margin-right: 5px;">*</b> <i class="fa fa-info-circle tippy"\n                                                           title="Default access level for databases, if authorization level is not specified."></i>\n                ';
 } else { 
__p+='\n                    '+
((__t=( db ))==null?'':__t)+
'\n                ';
 } 
__p+='\n            </div>\n\n            ';
 var permissionsDB = content.permission; 
__p+='\n\n            ';
 if (permissionsDB === 'rw') { 
__p+='\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readWrite" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="rw" checked>\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readOnly" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="ro">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox noAccess" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="none">\n                </div>\n                ';
 if (db.charAt(0) !== '*') { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                        <input class="dbCheckbox useDefault" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="undefined">\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"></div>\n                ';
 } 
__p+='\n            ';
 } else if (permissionsDB === 'ro') { 
__p+='\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readWrite" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readOnly" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'" checked>\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox noAccess" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="none">\n                </div>\n                ';
 if (db.charAt(0) !== '*') { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                        <input class="dbCheckbox useDefault" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="undefined">\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"></div>\n                ';
 } 
__p+='\n            ';
 } else if (permissionsDB === 'none') { 
__p+='\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readWrite" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readOnly" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox noAccess" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="none" checked>\n                </div>\n                ';
 if (db.charAt(0) !== '*') { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                        <input class="dbCheckbox useDefault" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="undefined">\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"></div>\n                ';
 } 
__p+='\n            ';
 } else { 
__p+='\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readWrite" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox readOnly" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="'+
((__t=( db ))==null?'':__t)+
'">\n                </div>\n                <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                    <input class="dbCheckbox noAccess" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="none">\n                </div>\n                ';
 if (db.charAt(0) !== '*') { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid">\n                        <input class="dbCheckbox useDefault" type="radio" name="'+
((__t=( db ))==null?'':__t)+
'" permission="undefined"\n                               checked>\n                    </div>\n                ';
 } else { 
__p+='\n                    <div class="'+
((__t=( genClass2 ))==null?'':__t)+
' mid"></div>\n                ';
 } 
__p+='\n            ';
 } 
__p+='\n\n            <div class="relation-row pure-table-row noHover inner '+
((__t=( extraClass ))==null?'':__t)+
'" style="display: none">\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' left inner relation-label relation-label-first"><span class="collLabel">Collections<span>\n                </div>\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner relation-label">Read/Write</div>\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner relation-label">Read only</div>\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner relation-label relation-label-last">No access</div>\n                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner relation-label relation-label-last">Use default</div>\n                ';
 _.each(content.relations, function(permissionCollection, relationName) { 
__p+='\n                    ';
 if (db.charAt(0) !== '*') { 
__p+='\n                        ';
 if (relationName.charAt(0) !== '_') { 
__p+='\n\n                            ';
 if (relationName !== '*') { 
__p+='\n                <div class="pure-table-row noHover" id="'+
((__t=( relationName ))==null?'':__t)+
'-relation">\n                    ';
 } else { 
__p+='\n                    <div class="pure-table-row noHover" id="___-relation" style="margin-bottom: 0px;">\n                        ';
 } 
__p+='\n\n                        ';
 if (relationName === '*') { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' left inner"><span class="collName"><b\n                                            style="margin-right: 7px"> '+
((__t=( relationName ))==null?'':__t)+
'</b></span><i\n                                        class="fa fa-info-circle tippy"\n                                        title="Default access level for relations in '+
((__t=( db ))==null?'':__t)+
', if authentication level is not specified."></i>\n                            </div>\n                        ';
 } else { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' left inner"><span class="collName">'+
((__t=( relationName ))==null?'':__t)+
'</span>\n                            </div>\n                        ';
 } 
__p+='\n\n                        ';
 if (permissionCollection === 'rw') { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readWrite" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'" checked>\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readOnly" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox noAccess" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            ';
 if (relationName.charAt(0) !== '*') { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                    <input class="collCheckbox useDefault" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                           name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                           permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                                </div>\n                            ';
 } else { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner"></div>\n                            ';
 } 
__p+='\n                        ';
 } else if (permissionCollection === 'ro') { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readWrite" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readOnly" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'" checked>\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox noAccess" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            ';
 if (relationName.charAt(0) !== '*') { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                    <input class="collCheckbox useDefault" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                           name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                           permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                                </div>\n                            ';
 } else { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner"></div>\n                            ';
 } 
__p+='\n                        ';
 } else if (permissionCollection === 'none') { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readWrite" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readOnly" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox noAccess" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'" checked>\n                            </div>\n                            ';
 if (relationName.charAt(0) !== '*') { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                    <input class="collCheckbox useDefault" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                           name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                           permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                                </div>\n                            ';
 } else { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner"></div>\n                            ';
 } 
__p+='\n                        ';
 } else { 
__p+='\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readWrite" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox readOnly" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                <input class="collCheckbox noAccess" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                       name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                       permission="'+
((__t=( relationName ))==null?'':__t)+
'">\n                            </div>\n                            ';
 if (relationName.charAt(0) !== '*') { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner">\n                                    <input class="collCheckbox useDefault" type="radio" db="'+
((__t=( db ))==null?'':__t)+
'"\n                                           name="'+
((__t=( db ))==null?'':__t)+
'-'+
((__t=( relationName ))==null?'':__t)+
'" relation="'+
((__t=( relationName ))==null?'':__t)+
'"\n                                           permission="'+
((__t=( relationName ))==null?'':__t)+
'" checked>\n                                </div>\n                            ';
 } else { 
__p+='\n                                <div class="'+
((__t=( genClass ))==null?'':__t)+
' mid inner"></div>\n                            ';
 } 
__p+='\n                        ';
 } 
__p+='\n\n                    </div>\n                    ';
 } 
__p+='\n                    ';
 } 
__p+='\n                    ';
 }); 
__p+='\n                </div>\n\n            </div>\n\n            ';
 }); 
__p+='\n\n        </div>\n\n    </div>\n';
}
return __p;
};
