"use strict";

/**
 * Error codes handled by dbmsjs.
 *
 * See also {@link https://www.arangodb.com/docs/stable/appendix-error-codes.html | ArangoDB error documentation}.
 *
 * @packageDocumentation
 * @internal
 * @hidden
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ERROR_DBMS_CONFLICT = exports.ERROR_DBMS_MAINTENANCE_MODE = exports.GRAPH_NOT_FOUND = exports.DATABASE_NOT_FOUND = exports.VIEW_NOT_FOUND = exports.COLLECTION_NOT_FOUND = exports.DOCUMENT_NOT_FOUND = exports.ANALYZER_NOT_FOUND = exports.TRANSACTION_NOT_FOUND = void 0;
exports.TRANSACTION_NOT_FOUND = 10;
exports.ANALYZER_NOT_FOUND = 1202;
exports.DOCUMENT_NOT_FOUND = 1202;
exports.COLLECTION_NOT_FOUND = 1203;
exports.VIEW_NOT_FOUND = 1203;
exports.DATABASE_NOT_FOUND = 1228;
exports.GRAPH_NOT_FOUND = 1924;
exports.ERROR_DBMS_MAINTENANCE_MODE = 503;
exports.ERROR_DBMS_CONFLICT = 1200;