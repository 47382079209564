module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<ul class="hotkeysList">\n    ';
 _.each(content, function(categories) { 
__p+='\n\n    <li class="hotkeysLabel">'+
((__t=( categories.name ))==null?'':__t)+
'</li>\n\n    ';
 _.each(categories.content, function(values) { 
__p+='\n    <li class="hotkeysContent">\n        <div class="hotkeysContentLabel">'+
((__t=( values.label ))==null?'':__t)+
'</div>\n        <div class="hotkeysicons">&nbsp;&nbsp;'+
((__t=( values.letter ))==null?'':__t)+
'&nbsp;&nbsp;</div>\n    </li>\n    ';
 }); 
__p+='\n\n    ';
 }); 
__p+='\n    <ul>\n\n';
}
return __p;
};
