import React from 'react'
import { Button, ConfigProvider, theme } from 'antd'
import { openAddModal } from './model'
import { ConnectorForm } from './components/ConnectorForm'

export const ConnectorsPage = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#725c70',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className="headerBar">
        <div className="title add" id="createPage">
          <Button type="primary" onClick={() => openAddModal()}>
            Configure replication
          </Button>
        </div>
      </div>
      <ConnectorForm />
    </ConfigProvider>
  )
}
