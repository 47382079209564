module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var appInfos = attributes.app.split(":"); 
__p+='\r\n<div id="change-foxx" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"\r\n     aria-hidden="true" style="display:none">\r\n    <div class="modal-header">\r\n        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>\r\n        <a class="dbmsHeader">Modify Service</a>\r\n    </div>\r\n    <div class="modal-body">\r\n        <table>\r\n            <tr>\r\n                <th class="relationTh">Name:</th>\r\n                <th class="relationTh"><strong>'+
((__t=( appInfos[1] ))==null?'':__t)+
'</strong></th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Documentation:</th>\r\n                <th class="relationTh">'+
((__t=( documentationJsonUrl ))==null?'':__t)+
'</th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Mount:</th>\r\n                <th class="relationTh"><input type="text" id="change-mount-point" name="mountpoint"\r\n                                              value="'+
((__t=( attributes.mount ))==null?'':__t)+
'"/></th>\r\n                <th><span class="modalTooltips fa fa-info-circle"\r\n                          title="The path where the app can be reached."></span></th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Version:</th>\r\n                <th class="relationTh">\r\n                    <select>\r\n                        <option selected>'+
((__t=( appInfos[2] ))==null?'':__t)+
'</option>\r\n                    </select>\r\n                <th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">System:</th>\r\n                <th class="relationTh">\r\n                    '+
((__t=( attributes.isSystem ? "Yes" : "No" ))==null?'':__t)+
'\r\n                <th>\r\n            </tr>\r\n            <tr>\r\n                <th class="relationTh">Status:</th>\r\n                <th class="relationTh">\r\n                    ';
 if (attributes.active) { 
__p+='\r\n                        <div class="modal-text active">\r\n                            Active\r\n                        </div>\r\n                    ';
 } else { 
__p+='\r\n                        <div class="modal-text inactive">\r\n                            Inactive\r\n                        </div>\r\n                    ';
 } 
__p+='\r\n                </th>\r\n            </tr>\r\n        </table>\r\n    </div>\r\n    <div id="colFooter" class="modal-footer">\r\n        <button id="uninstall"\r\n                class="btn-danger"'+
((__t=( (attributes.isSystem || attributes.development) ? " disabled" : "" ))==null?'':__t)+
'>Uninstall\r\n        </button>\r\n        <button id="change" class="btn-success pull-right">Save</button>\r\n        ';
 if (false && attributes.active) { 
__p+='\r\n            <button id="deactivate" class="btn-warning pull-right" style="margin-right:8px" disabled>Deactivate\r\n            </button>\r\n        ';
 } else if (false) { 
__p+='\r\n            <button id="activate" class="btn-success pull-right" style="margin-right:8px" disabled>Activate</button>\r\n        ';
 } 
__p+='\r\n    </div>\r\n</div>\r\n';
}
return __p;
};
