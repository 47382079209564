module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="innerContent">\r\n    <div id="viewProcessing" class="infoBox" style="margin-top: 10px; margin-bottom: 30px; display: none">\r\n        <h4>Processing <i class="fa fa-spinner fa-spin"></i></h4>\r\n        <p>The view is currently in processing. No editing possible right now.</p>\r\n    </div>\r\n    <div id="propertiesEditor" class="document-editor" style="border-top: 1px solid rgba(140,138,137,.25)"></div>\r\n    <div class="bottomButtonBar">\r\n        <button id="savePropertiesButton" class="btn-close pull-right" disabled>Save</button>\r\n        ';
 if (!window.frontendConfig.isCluster) { 
__p+='\r\n            <button id="renameViewButton" class="btn-warning pull-right">Rename</button>\r\n        ';
 } 
__p+='\r\n        <button id="deleteViewButton" class="btn-danger pull-right">Delete</button>\r\n        <span class="buttonBarInfo pull-right"></span>\r\n    </div>\r\n</div>\r\n  ';
}
return __p;
};
